import {
    GET_DOLARES, GET_DOLARES_SUCCESS, 
    UPDATE_DOLAR,ADD_NEW_DOLAR,
    GET_DOLAR_VIGENTE, GET_DOLAR_VIGENTE_SUCCESS, 
    GET_CECOS_SOLICITUD, GET_CECOS_SOLICITUD_SUCCESS,UPDATE_CECO_SOLICITUD,ADD_NEW_CECO_SOLICITUD, GET_CECO_SOLICITUD, GET_CECO_SOLICITUD_SUCCESS
} from './actionTypes'

export const getDolares = (params) => ({
  type: GET_DOLARES,
  payload: params
});

export const getDolaresSuccess = (params) => ({
    type: GET_DOLARES_SUCCESS,
    payload: params
});
  
export const updateDolar = (dolar, params) => ({
  type: UPDATE_DOLAR,
  payload: {dolar, params}
});

export const addNewDolar = (dolar, params) => ({
  type: ADD_NEW_DOLAR,
  payload: {dolar, params},
});

export const getDolarVigente = () => ({
  type: GET_DOLAR_VIGENTE,
});

export const getDolarVigenteSuccess = (data) => ({
  type: GET_DOLAR_VIGENTE_SUCCESS,
  payload: data,
});


export const getCecosSolicitud = (params) => ({
  type: GET_CECOS_SOLICITUD,
  payload: params
});

export const getCecosSolicitudSuccess = (params) => ({
  type: GET_CECOS_SOLICITUD_SUCCESS,
  payload: params
});
  
export const updateCecoSolicitud = (ceco, params) => ({
  type: UPDATE_CECO_SOLICITUD,
  payload: {ceco, params}
});

export const addNewCecoSolicitud = (ceco, params) => ({
  type: ADD_NEW_CECO_SOLICITUD,
  payload: {ceco, params},
});

export const getCecoSolicitud = (params) => ({
  type: GET_CECO_SOLICITUD,
  payload: params
});

export const getCecoSolicitudSuccess = (params) => ({
  type: GET_CECO_SOLICITUD_SUCCESS,
  payload: params
});