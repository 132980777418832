import {
   
    GET_SESIONES_ALUMNOS,
    GET_SESIONES_ALUMNOS_SUCCESS,
    
    GET_SESIONES_ACADEMICOS,
    GET_SESIONES_ACADEMICOS_SUCCESS,

    GET_LISTASESIONESACADEMICOSPORRANGOFECHAS,
    GET_LISTASESIONESACADEMICOSPORRANGOFECHAS_SUCCESS,

    GET_DIAS_SESIONES,
    GET_DIAS_SESIONES_SUCCESS,    

    DELETE_SESIONES

  } from "./actionTypes";


 export const getSesionesAlumnos = (params) => ({
    type: GET_SESIONES_ALUMNOS,
    payload: params
 });
  
 export const getSesionesAlumnosSuccess = (data) => ({
      type: GET_SESIONES_ALUMNOS_SUCCESS,
      payload: data
 });

 export const getSesionesAcademicos = (params) => ({
    type: GET_SESIONES_ACADEMICOS,
    payload: params
 });
  
 export const getSesionesAcademicosSuccess = (data) => ({
      type: GET_SESIONES_ACADEMICOS_SUCCESS,
      payload: data
 });

  export const getListaSesionesAcademicosPorRangoFechas = (params) => ({
    type: GET_LISTASESIONESACADEMICOSPORRANGOFECHAS,
    payload: params
 });
  
  export const getListaSesionesAcademicosPorRangoFechasSuccess = (data) => ({
      type: GET_LISTASESIONESACADEMICOSPORRANGOFECHAS_SUCCESS,
      payload: data
 });

 export const getDiasSesiones = (params) => ({
   type: GET_DIAS_SESIONES,
   payload: params
});
 
export const getDiasSesionesSuccess = (data) => ({
     type: GET_DIAS_SESIONES_SUCCESS,
     payload: data
});

export const deleteSesiones = params => ({
     type: DELETE_SESIONES,
     payload: params 
});