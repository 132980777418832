import {
  ADD_NEW_ESTUDIANTE,
  ADD_NEW_ESTUDIO_ESTUDIANTE,
  ADD_NEW_LABORAL_ESTUDIANTE,
  DELETE_ESTUDIO_ESTUDIANTE,
  GET_ESTUDIANTES,
  GET_ESTUDIANTES_SUCCESS,
  GET_ESTUDIOS_ESTUDIANTE,
  GET_ESTUDIOS_ESTUDIANTE_SUCCESS,
  GET_LABORAL_ESTUDIANTE,
  GET_LABORAL_ESTUDIANTE_SUCCESS,
  UPDATE_ESTUDIANTE,
  GET_ESTUDIANTE, GET_ESTUDIANTE_SUCCESS,
  GET_ESTUDIANTE_BY_DOCUMENTO,
  GET_INFO_ESTUDIANTE_CARGA_CORPORATIVOS,
  GET_INFO_ESTUDIANTE_CARGA_CORPORATIVOS_SUCCESS,
  GET_VALIDA_ESTUDIANTE_VIAJE_PROGRAMA,
  GET_VALIDA_ESTUDIANTE_VIAJE_PROGRAMA_SUCCESS,
  GET_VIAJES_EN_CURSO_ESTUDIANTE, GET_VIAJES_EN_CURSO_ESTUDIANTE_SUCCESS,
  GET_MODULOS_VIAJE, GET_MODULOS_VIAJE_SUCCESS, GET_NOTAS_ESTUDIANTE_MODULO, GET_NOTAS_ESTUDIANTE_MODULO_SUCCESS,
  GET_CERTIFICADO_ALUMNO_REGULAR, GET_CERTIFICADO_ALUMNO_REGULAR_SUCCESS, RESET_CERTIFICADO,
  GET_CERTIFICADO_ALUMNO_NOTAS, GET_CERTIFICADO_ALUMNO_NOTAS_SUCCESS,
  GET_ESTUDIANTE_BY_DOCUMENTO_SUCCESS,
  RESET_ESTUDIANTE_BY_DOCUMENTO,
  RESET_ESTUDIANTE, GET_ESTUDIANTE_EXTERNO, GET_ESTUDIANTE_EXTERNO_SUCCESS,
  GET_ASISTENCIA_ESTUDIANTE_MODULO, GET_ASISTENCIA_ESTUDIANTE_MODULO_SUCCESS,
  GET_CERTIFICADO_ALUMNO_REGULAR_INTERNO,
  GET_CERTIFICADO_ALUMNO_REGULAR_INTERNO_SUCCESS,
} from "./actionTypes";

export const getEstudiantes = (params) => ({
  type: GET_ESTUDIANTES,
  payload: params
});

export const getEstudiantesExterno = () => ({
  type: GET_ESTUDIANTES,
});

export const getEstudiantesSuccess = (estudiantes) => ({
  type: GET_ESTUDIANTES_SUCCESS,
  payload: estudiantes
});

export const getEstudiosEstudiante = (id) => ({
  type: GET_ESTUDIOS_ESTUDIANTE,
  payload: id
});

export const getEstudiosEstudianteSuccess = (estudios) => ({
  type: GET_ESTUDIOS_ESTUDIANTE_SUCCESS,
  payload: estudios
});

export const getLaboralEstudiante = (id) => ({
  type: GET_LABORAL_ESTUDIANTE,
  payload: id
});

export const getLaboralEstudianteSuccess = (laboral) => ({
  type: GET_LABORAL_ESTUDIANTE_SUCCESS,
  payload: laboral
});

export const getEstudianteByDocumento = (documento, callback) => ({
  type: GET_ESTUDIANTE_BY_DOCUMENTO,
  payload: { documento, callback }
});

export const getEstudianteByDocumentoSuccess = (estudiante) => ({
  type: GET_ESTUDIANTE_BY_DOCUMENTO_SUCCESS,
  payload: estudiante
});

export const getInfoEstudianteCargaCorporativos = (id) => ({
  type: GET_INFO_ESTUDIANTE_CARGA_CORPORATIVOS,
  payload: id
});

export const getInfoEstudianteCargaCorporativosSuccess = (info) => ({
  type: GET_INFO_ESTUDIANTE_CARGA_CORPORATIVOS_SUCCESS,
  payload: info
});

export const getValidaEstudianteViajePrograma = (params) => ({
  type: GET_VALIDA_ESTUDIANTE_VIAJE_PROGRAMA,
  payload: params
});

export const getValidaEstudianteViajeProgramaSuccess = (info) => ({
  type: GET_VALIDA_ESTUDIANTE_VIAJE_PROGRAMA_SUCCESS,
  payload: info
});

export const addNewEstudiante = (estudiante, formImg = null, fetchData = null) => ({
  type: ADD_NEW_ESTUDIANTE,
  payload: { estudiante, formImg, fetchData }
});

export const addNewEstudioEstudiante = (estudio, fetchData = null) => ({
  type: ADD_NEW_ESTUDIO_ESTUDIANTE,
  payload: { estudio, fetchData }
});

export const addNewLaboralEstudiante = (laboral, fetchData = null) => ({
  type: ADD_NEW_LABORAL_ESTUDIANTE,
  payload: { laboral, fetchData }
});

export const updateEstudiante = (estudiante, formImg = null, fetchData = null) => ({
  type: UPDATE_ESTUDIANTE,
  payload: { estudiante, formImg, fetchData }
});

export const deleteEstudioEstudiante = (id, fetchData = null) => ({
  type: DELETE_ESTUDIO_ESTUDIANTE,
  payload: { id, fetchData }
});

export const getEstudiante = (id) => ({
  type: GET_ESTUDIANTE,
  payload: id
});

export const getEstudianteSuccess = (estudiante) => ({
  type: GET_ESTUDIANTE_SUCCESS,
  payload: estudiante
});

export const getViajesEnCursoEstudiante = () => ({
  type: GET_VIAJES_EN_CURSO_ESTUDIANTE,
});

export const getViajesEnCursoEstudianteSuccess = (params) => ({
  type: GET_VIAJES_EN_CURSO_ESTUDIANTE_SUCCESS,
  payload: params
});

export const getModulosViaje = (params) => ({
  type: GET_MODULOS_VIAJE,
  payload: params
});

export const getModulosViajeSuccess = (params) => ({
  type: GET_MODULOS_VIAJE_SUCCESS,
  payload: params
});

export const getNotasEstudianteModulo = (params) => ({
  type: GET_NOTAS_ESTUDIANTE_MODULO,
  payload: params
});

export const getNotasEstudianteModuloSuccess = (params) => ({
  type: GET_NOTAS_ESTUDIANTE_MODULO_SUCCESS,
  payload: params
});

export const getCertificadoAlumnoRegular = (params) => ({
  type: GET_CERTIFICADO_ALUMNO_REGULAR,
  payload: params
});

export const getCertificadoAlumnoRegularSuccess = (params) => ({
  type: GET_CERTIFICADO_ALUMNO_REGULAR_SUCCESS,
  payload: params
});

export const getCertificadoAlumnoRegularInterno = (params) => ({
  type: GET_CERTIFICADO_ALUMNO_REGULAR_INTERNO,
  payload: params
});

export const getCertificadoAlumnoRegularInternoSuccess = (data) => ({
  type: GET_CERTIFICADO_ALUMNO_REGULAR_INTERNO_SUCCESS,
  payload: data
});

export const resetCertificado = () => ({
  type: RESET_CERTIFICADO,
});

export const resetEstudiante = () => ({
  type: RESET_ESTUDIANTE
});

export const resetEstudianteByDocumento = () => ({
  type: RESET_ESTUDIANTE_BY_DOCUMENTO
});

export const getCertificadoAlumnoNotas = (params) => ({
  type: GET_CERTIFICADO_ALUMNO_NOTAS,
  payload: params
});

export const getCertificadoAlumnoNotasSuccess = (params) => ({
  type: GET_CERTIFICADO_ALUMNO_NOTAS_SUCCESS,
  payload: params
});

export const getEstudianteExterno = (id) => ({
  type: GET_ESTUDIANTE_EXTERNO,
  payload: id
});

export const getEstudianteExternoSuccess = (estudiante) => ({
  type: GET_ESTUDIANTE_EXTERNO_SUCCESS,
  payload: estudiante
});

export const getAsistenciaEstudianteModulo = (params) => ({
  type: GET_ASISTENCIA_ESTUDIANTE_MODULO,
  payload: params
});

export const getAsistenciaEstudianteModuloSuccess = (params) => ({
  type: GET_ASISTENCIA_ESTUDIANTE_MODULO_SUCCESS,
  payload: params
});