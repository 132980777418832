import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { FINALIZAR_PERSONIFICAR, LOGIN_USER, LOGOUT_USER, PERSONIFICAR } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

//Include Helper File with needed methods
import {
  postLogin,
  postPersonificar,
  postFinalizarPersonificacion,
} from "../../../helpers/backend_helper";
import { toast } from "react-toastify";
import { isEmpty, isNil } from "ramda";

function* loginUser({ payload: { user, history } }) {
  try {
    localStorage.removeItem("version-update-needed");
    localStorage.removeItem("authUser");

    const response = yield call(
      postLogin,
      JSON.stringify({
        username: user.username,
        password: user.password,
      })
    );

    if (response.data.success === true) {
      const dataSesion = {
        data: { first_name: response.data.result.nombre },
        token: response.data.result.token,
      };
      localStorage.setItem("authUser", JSON.stringify(dataSesion));
      yield put(loginSuccess(response.data));
      if (response.data.result.reset_pass)
        sessionStorage.setItem("reset-password", true);

      if (!isNil(history.location?.state?.path) && !isEmpty(history.location?.state?.path)) {
        history.push(history.location.state.path);
      }
      else
        history.push("/");
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      yield put(apiError(response.data.message));
    }
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_CENTER,
    });
    yield put(apiError(error));
  }
}

function* logoutUser() {
  try {
    localStorage.removeItem("authUser");
    yield put(logoutUserSuccess(LOGOUT_USER, true));
  } catch (error) {
    yield put(apiError(LOGOUT_USER, error));
  }
}

function* personificar({ payload: { user, history } }) {
  try {
    const response = yield call(postPersonificar, user);

    if (response.data.success) {
      const dataSesion = {
        data: { first_name: response.data.result.nombre },
        token: response.data.result.token,
      };
      localStorage.setItem("authUser", JSON.stringify(dataSesion));
      yield put(loginSuccess(response.data));
      if (response.data.result.reset_pass)
        sessionStorage.setItem("reset-password", true);

      history.push("/");
    } else {
      toast.error(response, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_CENTER,
    });
    yield put(apiError(error));
  }
}

function* finalizarPersonificar({ payload: { history } }) {
  try {
    const response = yield call(postFinalizarPersonificacion);

    if (response.data.success) {
      const dataSesion = {
        data: { first_name: response.data.result.nombre },
        token: response.data.result.token,
      };
      localStorage.setItem("authUser", JSON.stringify(dataSesion));
      yield put(loginSuccess(response.data));
      if (response.data.result.reset_pass)
        sessionStorage.setItem("reset-password", true);

      history.push("/usuarios");
    } else {
      toast.error(response, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_CENTER,
    });
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
  yield takeLatest(PERSONIFICAR, personificar);
  yield takeLatest(FINALIZAR_PERSONIFICAR, finalizarPersonificar);
}

export default authSaga;
