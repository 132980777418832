import { takeEvery, call, put, takeLatest } from "redux-saga/effects"
import { toast } from 'react-toastify';

import {
    GET_FBYMES, GET_FBYMESRANGO, GET_FBYMESRANGOCOMPARATIVO, GET_FBYSEMANAS, GET_FBYDIAMES, GET_FBYMESRANGOTIPO, GET_NVBYMES, 
    GET_NVBYMESHOME, GET_NVBYMESTIPO, GET_NVTIPOSPAGO, GET_NVCANTIDADBYMESTIPO,
    POST_CARGADATAAUGE, POST_CARGADATAAUGE_SUCCESS, GET_BOLETABYMESRANGO
} from './actionTypes'


import { getFacturasByMes, getFacturasByMesRango, getFacturasByMesRangoComparativo, getFacturasBySemanas, 
         getFacturasByDiaMes, getFacturasByMesRangoTipo, getNVByMes, getNVByMesHome, getNVByMesTipo, getNVTiposPago, getNVCantidadByMesTipo,
         postCargaDataAuge,
         getBoletasByMesRango} from "../../../helpers/backend_helper";

import { getFacturasByMesSuccess, getFacturasByMesRangoSuccess, getFacturasByMesRangoComparativoSuccess, getFacturasBySemanasSuccess, 
        getFacturasByDiaMesSuccess, getFacturasByMesRangoTipoSuccess, getNVByMesSuccess, getNVByMesHomeSuccess, getNVByMesTipoSuccess, getNVTiposPagoSuccess, getNVCantidadByMesTipoSuccess,
        postCargaDataAugeSuccess, 
        getBoletasByMesRangoSuccess} from "./actions";

function* fetchFacturasByMes({payload : year}){
    try {
        const response = yield call(getFacturasByMes, year)
        yield put(getFacturasByMesSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchFacturasByMesRango({payload : params}){
    try {
        const response = yield call(getFacturasByMesRango, params)
        yield put(getFacturasByMesRangoSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchFacturasByMesRangoComparativo({payload : params}){
    try {
        const response = yield call(getFacturasByMesRangoComparativo, params)
        yield put(getFacturasByMesRangoComparativoSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchFacturasBySemanas({payload : year}){
    try {
        const response = yield call(getFacturasBySemanas, year)
        yield put(getFacturasBySemanasSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchFacturasByDiaMes({payload : params}){
    try {
        const response = yield call(getFacturasByDiaMes, params)
        yield put(getFacturasByDiaMesSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchFacturasByMesRangoTipo({payload : params}){
    try {
        const response = yield call(getFacturasByMesRangoTipo, params)
        yield put(getFacturasByMesRangoTipoSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchBoletasByMesRango({payload : params}){
    try {
        const response = yield call(getBoletasByMesRango, params)
        yield put(getBoletasByMesRangoSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchNVByMes({payload : year}){
    try {
        const response = yield call(getNVByMes, year)
        yield put(getNVByMesSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchNVByMesHome({payload : year}){
    try {
        const response = yield call(getNVByMesHome, year)
        yield put(getNVByMesHomeSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchNVByMesTipo({payload : year}){
    try {
        const response = yield call(getNVByMesTipo, year)
        yield put(getNVByMesTipoSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchNVTiposPago({payload : params}){
    try {
        const response = yield call(getNVTiposPago, params)
        yield put(getNVTiposPagoSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchNVCantidadByMesTipo({payload : params}){
    try {
        const response = yield call(getNVCantidadByMesTipo, params)
        yield put(getNVCantidadByMesTipoSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchCargaDataAuge({payload: fileId}){
    try {
        const response = yield call(postCargaDataAuge, fileId);
        yield put(postCargaDataAugeSuccess(response))
        toast.success("Archivo cargado con éxito", { autoClose: 3000 });
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}


function* finanzasDashboardSaga() {
    yield takeEvery(GET_FBYMES, fetchFacturasByMes);
    yield takeEvery(GET_FBYMESRANGO, fetchFacturasByMesRango);
    yield takeEvery(GET_FBYMESRANGOCOMPARATIVO, fetchFacturasByMesRangoComparativo);
    yield takeEvery(GET_FBYSEMANAS, fetchFacturasBySemanas);
    yield takeEvery(GET_FBYDIAMES, fetchFacturasByDiaMes);
    yield takeEvery(GET_FBYMESRANGOTIPO, fetchFacturasByMesRangoTipo);
    yield takeEvery(GET_BOLETABYMESRANGO, fetchBoletasByMesRango);
    yield takeEvery(GET_NVBYMES, fetchNVByMes);
    yield takeEvery(GET_NVBYMESHOME, fetchNVByMesHome);
    yield takeEvery(GET_NVBYMESTIPO, fetchNVByMesTipo);
    yield takeEvery(GET_NVTIPOSPAGO, fetchNVTiposPago);
    yield takeEvery(GET_NVCANTIDADBYMESTIPO, fetchNVCantidadByMesTipo);
    yield takeLatest(POST_CARGADATAAUGE, fetchCargaDataAuge)   
}

export default finanzasDashboardSaga