import { GET_INFO_PAGO, GET_INFO_PAGO_SUCCESS } from "./actionTypes";

const INIT_STATE = {
  pago : {},
  loading : false
};

const Pagos = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INFO_PAGO:
      return { ...state, loading: true };
    case GET_INFO_PAGO_SUCCESS:
      return { ...state, pago: action.payload, loading: false };
    default:
      return state;
  }
}

export default Pagos;