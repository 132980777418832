import { GET_LIST_SUCCESS } from "./actionTypes"
import { equals } from "ramda";

const INIT_STATE = {
  lists: [],
}

const Lists = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LIST_SUCCESS:
      {
        // const index = state.lists.findIndex(list => list.name === action.payload.data.name && list.paramId === action.payload.data.paramId);
        let index = -1;
        if(action.payload.paramId !== null){
          index = state.lists.findIndex(list => list.name === action.payload.params && equals(list.paramId, action.payload.paramId) );
        }else{
          index = state.lists.findIndex(list => list.name === action.payload.params);
        }
        if (index !== -1) {
          // Actualiza la lista si existe
          return {
            ...state,
            lists: [
              ...state.lists.slice(0, index),
              {name: action.payload.params, data: action.payload.data, paramId: action.payload.paramId},
              ...state.lists.slice(index + 1)
            ]
          };
        } else {
          // push de la lista si no existe
          return {
            ...state,
            lists: [...state.lists.slice(0, action.payload.params), {name: action.payload.params, data: action.payload.data, paramId: action.payload.paramId}, ...state.lists.slice(action.payload.params)]
          };
        }
      }
    default:
      return state
  }
}

export default Lists