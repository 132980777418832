import {
  GET_CAPTCHA,
  GET_IMG_CAPTCHA,
  POST_CAPTCHA,
  POST_CAPTCHA_SUCCESS,
  RESET_RESP_CAPTCHA
} from './actionTypes'

export const getCaptcha = () => ({
  type: GET_CAPTCHA
});

export const getCaptchaSuccess = (idCaptcha) => ({
  type: GET_IMG_CAPTCHA,
  payload: idCaptcha
});

export const validaCaptcha = (idCaptcha, valor) => ({
  type: POST_CAPTCHA,
  payload: {idCaptcha, valor}
});

export const validaCaptchaSuccess = (resCaptcha) => ({
  type: POST_CAPTCHA_SUCCESS,
  payload: resCaptcha
});

export const resetRespCaptcha = () => ({
  type: RESET_RESP_CAPTCHA
})