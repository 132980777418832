import { GET_ACADEMICOS_CENTRO_BY_DIRECTOR_SUCCESS, 
         GET_ACADEMICOS_SUCCESS, GET_ACAD_DIAS_DISPONIBLES_SUCCESS, GET_ACAD_DIAS_DISPONIBLES_ALL_SUCCESS, GET_ACAD_DIAS_BLOQUEO_SUCCESS,
         ADD_NEW_ACAD_DIAS_DISPONIBLE, ADD_NEW_ACAD_DIAS_BLOQUEO, DELETE_ACAD_DIAS_DISPONIBLE, DELETE_ACAD_DIAS_BLOQUEO, UPDATE_ACAD_DIAS_DISPONIBLE,
        RESET_REPORTE_DISPONIBILIDAD, GET_REPORTE_DISPONIBILIDAD_YEAR_SUCCESS, RESET_REPORTE_BLOQUEOS, GET_REPORTE_BLOQUEOS_YEAR_SUCCESS,
        RESET_REPORTE_AMBOS, GET_REPORTE_AMBOS_YEAR_SUCCESS, GET_ACADEMICO_BY_USERID_SUCCESS, GET_ACADEMICOS_ALL_SUCCESS,
        VALIDA_ACADEMICO, GET_ESTUDIOS_ACADEMICO_SUCCESS, GET_DOCUMENTOS_ACADEMICO_SUCCESS, GET_ACADEMICO_USER_SUCCESS, RESET_ACADEMICO_USER} from "./actionTypes";

const INIT_STATE = {
  academicos: [],
  academicosCentro : [],
  acadDiasDisponibles : [],
  acadDiasDisponiblesAll : [],
  acadDiasBloqueo : [],
  paginacion: {},
  archivoExcel: null,
  archivoExcelB: null,
  academico: {},
  arrAcademicosAll: [],
  estudiosAcademico: [],
  documentosAcademico: []
};


const Academicos = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ACADEMICOS_SUCCESS:
      return {
        ...state,
        loading: false,
        paginacion : JSON.parse(action.payload.headers['x-pagination']),        
        academicos: action.payload.data,
      };
    case GET_ACADEMICO_BY_USERID_SUCCESS:
      return {
        ...state,
        academico: action.payload.data,
      };
      case GET_ACADEMICOS_CENTRO_BY_DIRECTOR_SUCCESS:
        return {
          ...state,
          academicosCentro: action.payload.data,
        };
      case GET_ACAD_DIAS_DISPONIBLES_SUCCESS:
        return {
          ...state,
          paginacion : JSON.parse(action.payload.headers['x-pagination']),
          acadDiasDisponibles: action.payload.data,
        };        
      case GET_ACAD_DIAS_DISPONIBLES_ALL_SUCCESS:
        return {
          ...state,
          paginacion : JSON.parse(action.payload.headers['x-pagination']),
          acadDiasDisponiblesAll: action.payload.data,
        };                
      case GET_ACAD_DIAS_BLOQUEO_SUCCESS:
        return {
          ...state,
          acadDiasBloqueo: action.payload.data,
        };   
      case ADD_NEW_ACAD_DIAS_DISPONIBLE:
        return {
          ...state,
        }                       
      case ADD_NEW_ACAD_DIAS_BLOQUEO:
        return {
          ...state,
        }
      case DELETE_ACAD_DIAS_DISPONIBLE:
        return {
          ...state,
        }        
      case DELETE_ACAD_DIAS_BLOQUEO:
        return {
          ...state,
        }
      case UPDATE_ACAD_DIAS_DISPONIBLE:
          return{
              ...state
          }        
    case GET_REPORTE_DISPONIBILIDAD_YEAR_SUCCESS:
      return {
        ...state,
        archivoExcel: action.payload.data
      }      
    case RESET_REPORTE_DISPONIBILIDAD:
      return {
        ...state,
        archivoExcel: null
      };          
      case GET_REPORTE_BLOQUEOS_YEAR_SUCCESS:
        return {
          ...state,
          archivoExcelB: action.payload.data
        }      
      case RESET_REPORTE_BLOQUEOS:
        return {
          ...state,
          archivoExcelB: null
        };                
      case GET_REPORTE_AMBOS_YEAR_SUCCESS:
        return {
          ...state,
          archivoExcelA: action.payload.data
        }             
      case RESET_REPORTE_AMBOS:
        return {
          ...state,
          archivoExcelA: null
        };
      case GET_ACADEMICOS_ALL_SUCCESS:
        return {
          ...state,
          arrAcademicosAll: action.payload.data
        }    
      case VALIDA_ACADEMICO:
          return{
              ...state
          }   
      case GET_ESTUDIOS_ACADEMICO_SUCCESS:
        return {
          ...state,
          estudiosAcademico: action.payload.data,
        };
      case GET_DOCUMENTOS_ACADEMICO_SUCCESS:
        return {
          ...state,
          documentosAcademico: action.payload.data,
        };  
      case GET_ACADEMICO_USER_SUCCESS:
        return {
          ...state,
          academico: action.payload.data,
        };    
        case RESET_ACADEMICO_USER:
          return {
            ...state,
            academico: null
          };
                
    default:
      return state;
  }
};

export default Academicos;
