import {
  DELETE_DIA_EVENTO,
  GET_CATEGORIAS,
  GET_CATEGORIAS_SUCCESS,
  GET_EVENTO,
  GET_EVENTOS_ANIO,
  GET_EVENTOS_ANIO_SUCCESS,
  GET_EVENTOS_CALENDARIO,
  GET_EVENTOS_CALENDARIO_SUCCESS,
  GET_EVENTO_ACTIVIDADES,
  GET_EVENTO_ACTIVIDADES_SUCCESS,
  GET_EVENTO_ANIO_ALL,
  GET_EVENTO_ANIO_ALL_SUCCESS,
  GET_EVENTO_ESTADOS,
  GET_EVENTO_ESTADOS_ITEMS,
  GET_EVENTO_ESTADOS_ITEMS_SUCCESS,
  GET_EVENTO_ESTADOS_SUCCESS,
  GET_EVENTO_MODALIDADES,
  GET_EVENTO_MODALIDADES_SUCCESS,
  GET_EVENTO_PROXIMOS,
  GET_EVENTO_PROXIMOS_SUCCESS,
  GET_EVENTO_REALIZADOS,
  GET_EVENTO_REALIZADOS_SUCCESS,
  GET_EVENTO_SUCCESS,
  GET_ITEMS_EVENTO,
  GET_ITEMS_EVENTO_SUCCESS,
  GET_REPORTE_EVENTOS_ANIO,
  GET_REPORTE_EVENTOS_ANIO_SUCCESS,
  GET_SALDO_PPTO_CENTRO,
  GET_SALDO_PPTO_CENTRO_DIRECTOR,
  GET_SALDO_PPTO_CENTRO_DIRECTOR_SUCCESS,
  GET_SALDO_PPTO_CENTRO_SUCCESS,
  GET_TIPO_EVENTOS,
  GET_TIPO_EVENTOS_SUCCESS,
  POST_EVENTO,
  POST_EVENTO_FAIL,
  POST_EVENTO_SUCCESS,
  RESET_EVENTO,
  RESET_REPORTE_EVENTO,
  UPDATE_CANCELA_EVENTO,
  UPDATE_DETALLE_EVENTO,
  UPDATE_ESTADO_EVENTO,
  UPDATE_EVENTO,
  UPDATE_GESTION_EVENTO,
  UPDATE_GESTION_MASIVA_EVENTO,
} from "./actionTypes";

export const getCategorias = () => ({
  type: GET_CATEGORIAS,
});

export const getCategoriasSuccess = (data) => ({
  type: GET_CATEGORIAS_SUCCESS,
  payload: data,
});

export const getTipoEventos = (idCategoria) => ({
  type: GET_TIPO_EVENTOS,
  payload: idCategoria,
});

export const getTipoEventosSuccess = (data) => ({
  type: GET_TIPO_EVENTOS_SUCCESS,
  payload: data,
});

export const getItemsEventos = (idTipoEvento) => ({
  type: GET_ITEMS_EVENTO,
  payload: idTipoEvento,
});

export const getItemsEventosSuccess = (data) => ({
  type: GET_ITEMS_EVENTO_SUCCESS,
  payload: data,
});

export const crearEvento = (evento) => ({
  type: POST_EVENTO,
  payload: evento,
});

export const crearEventoSuccess = () => ({
  type: POST_EVENTO_SUCCESS,
});

export const crearEventoFail = () => ({
  type: POST_EVENTO_FAIL,
});

export const resetEvento = () => ({
  type: RESET_EVENTO,
});

export const getEventosAnio = (eventoParameters, anio) => ({
  type: GET_EVENTOS_ANIO,
  payload: { eventoParameters, anio },
});

export const getEventosAnioSuccess = (data) => ({
  type: GET_EVENTOS_ANIO_SUCCESS,
  payload: data,
});

export const getAllEventosAnio = (eventoParameters, anio) => ({
  type: GET_EVENTO_ANIO_ALL,
  payload: { eventoParameters, anio },
});

export const getAllEventosAnioSuccess = (data) => ({
  type: GET_EVENTO_ANIO_ALL_SUCCESS,
  payload: data,
});

export const getEventosProximosAnio = (eventoParameters, anio) => ({
  type: GET_EVENTO_PROXIMOS,
  payload: { eventoParameters, anio },
});

export const getEventosProximosAnioSuccess = (data) => ({
  type: GET_EVENTO_PROXIMOS_SUCCESS,
  payload: data,
});

export const getEventosRealizadosAnio = (eventoParameters, anio) => ({
  type: GET_EVENTO_REALIZADOS,
  payload: { eventoParameters, anio },
});

export const getEventosRealizadosAnioSuccess = (data) => ({
  type: GET_EVENTO_REALIZADOS_SUCCESS,
  payload: data,
});

export const getActividadesEventos = (eventoParameters, anio) => ({
  type: GET_EVENTO_ACTIVIDADES,
  payload: { eventoParameters, anio },
});

export const getActividadesEventosSuccess = (data) => ({
  type: GET_EVENTO_ACTIVIDADES_SUCCESS,
  payload: data,
});

export const getEvento = (idEvento) => ({
  type: GET_EVENTO,
  payload: idEvento,
});

export const getEventoSuccess = (data) => ({
  type: GET_EVENTO_SUCCESS,
  payload: data,
});

export const getEventoModadilades = () => ({
  type: GET_EVENTO_MODALIDADES,
});

export const getEventoModadiladesSuccess = (data) => ({
  type: GET_EVENTO_MODALIDADES_SUCCESS,
  payload: data,
});

export const getEventoEstados = () => ({
  type: GET_EVENTO_ESTADOS,
});

export const getEventoEstadosSuccess = (data) => ({
  type: GET_EVENTO_ESTADOS_SUCCESS,
  payload: data,
});

export const getEventoEstadosItems = () => ({
  type: GET_EVENTO_ESTADOS_ITEMS,
});

export const getEventoEstadosItemsSuccess = (data) => ({
  type: GET_EVENTO_ESTADOS_ITEMS_SUCCESS,
  payload: data,
});

export const getSaldoPptoCentroByDirector = (year) => ({
  type: GET_SALDO_PPTO_CENTRO_DIRECTOR,
  payload: year,
});

export const getSaldoPptoCentroByDirectorSuccess = (data) => ({
  type: GET_SALDO_PPTO_CENTRO_DIRECTOR_SUCCESS,
  payload: data,
});

export const getSaldoPptoCentro = (idCentro, year) => ({
  type: GET_SALDO_PPTO_CENTRO,
  payload: {idCentro, year},
});

export const getSaldoPptoCentroSuccess = (data) => ({
  type: GET_SALDO_PPTO_CENTRO_SUCCESS,
  payload: data,
});

export const getReporteEventosAnio = (year) => ({
  type: GET_REPORTE_EVENTOS_ANIO,
  payload: year
})

export const getReporteEventosAnioSuccess = (fileName) => ({
  type: GET_REPORTE_EVENTOS_ANIO_SUCCESS,
  payload: fileName
})

export const resetReporteEvento = () => ({
  type: RESET_REPORTE_EVENTO,
});

export const postUpdateEvento = (idEvento, dataEvento, fetchData) => ({
  type: UPDATE_EVENTO,
  payload: { idEvento, dataEvento, fetchData },
});

export const postUpdateDetalleEvento = (idEvento, eventoDetalle) => ({
  type: UPDATE_DETALLE_EVENTO,
  payload: { idEvento, eventoDetalle },
});

export const updateGestionEvento = (eventoDetalle, recargaActividad = false, params = {}, anio = null) => ({
  type: UPDATE_GESTION_EVENTO,
  payload: { eventoDetalle, recargaActividad, params, anio },
});

export const updateGestionEventosMasiva = (eventoParameters, anio, obs, estado) => ({
  type: UPDATE_GESTION_MASIVA_EVENTO,
  payload: { eventoParameters, anio, obs, estado },
});

export const updateEstadoEvento = (idEvento, estado, params, anio, observacion = null) => ({
  type: UPDATE_ESTADO_EVENTO,
  payload: { idEvento, estado, params, anio, observacion },
});

export const updateCancelaEvento = (idEvento, params, anio, justificacion) => ({
  type: UPDATE_CANCELA_EVENTO,
  payload: { idEvento, params, anio, justificacion },
});

export const deleteDiaEvento = (idEvento, fechas) => ({
  type: DELETE_DIA_EVENTO,
  payload: { idEvento, fechas },
});

export const getEventosCalendario = () => ({
  type: GET_EVENTOS_CALENDARIO,
});

export const getEventosCalendarioSuccess = (data) => ({
  type: GET_EVENTOS_CALENDARIO_SUCCESS,
  payload: data
});
