import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Input,
  Label,
  FormFeedback,
  Modal,
  ModalBody,
} from "reactstrap";
import ParticlesAuth from "./ParticlesAuth";
import logoLight from "../../assets/images/logo-dcs-blanco.png";

//formik
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { updatePasswordDCS } from "../../store/actions";

const ResetPassword = () => {
  document.title = "DCS Intranet | Resetear contraseña";

  const [modalResetPassword, setModalResetPassword] = useState(true);
  const { passwordUpdated } = useSelector((state) => ({
    passwordUpdated: state.UserManagement.passwordDCSUpdated,
  }));
  const dispatch = useDispatch();

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      password: "",
      confrim_password: "",
      passwordOld: "",
    },
    validationSchema: Yup.object({
      passwordOld: Yup.string().required(
        "El campo contraseña actual es obligatorio"
      ),
      password: Yup.string()
        .min(8, "La contraseña debe tener al menos 8 caracteres")
        .matches(RegExp("(.*[a-z].*)"), "Al menos una letra en minúscula")
        .matches(RegExp("(.*[A-Z].*)"), "Al menos una letra en mayúscula")
        .matches(RegExp("(.*[0-9].*)"), "Al menos un número")
        .when("passwordOld", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().notOneOf(
            [Yup.ref("passwordOld")],
            "No puede utilizar la misma contraseña actual"
          ),
        })
        .required("El campo contraseña es obligatorio"),
      confrim_password: Yup.string()
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Ambas contraseñas deben coincidir"
          ),
        })
        .required("Debe ingresar la confirmación de contraseña"),
    }),
    onSubmit: (values) => {
      dispatch(updatePasswordDCS(values.passwordOld, values.password));
    },
  });
  return (
    <Modal
      size="xl"
      isOpen={modalResetPassword}
      className="modal-fullscreen"
      id="exampleModalFullscreen"
    >
      <ModalBody style={{ backgroundColor: "#f2f2f7" }}>
        <ParticlesAuth>
          <div className="auth-page-content">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="text-center mb-4 text-white-50">
                    <div>
                      <Link to="/" className="d-inline-block auth-logo">
                        <img src={logoLight} alt="" height="150" />
                      </Link>
                    </div>
                    <p className="mt-3 fs-15 fw-medium">Intranet DCS</p>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  <Card className="mt-4">
                    <CardBody className="p-4">
                      {passwordUpdated !== true && (
                        <div>
                          <div className="text-center mt-2">
                            <h5 className="text-primary">
                              Crear nueva contraseña
                            </h5>
                            <p className="text-muted">
                              Por su seguridad, debe crear una nueva contraseña.
                            </p>
                          </div>
                          <div className="p-2">
                            <Form
                              onSubmit={validation.handleSubmit}
                              action="/auth-signin-basic"
                            >
                              <div className="mb-3">
                                <Label
                                  className="form-label"
                                  htmlFor="password-input-old"
                                >
                                  Contraseña Actual
                                </Label>
                                <div className="position-relative auth-pass-inputgroup">
                                  <Input
                                    type={"password"}
                                    className="form-control pe-5 password-input"
                                    placeholder="Ingrese su contraseña actual"
                                    id="password-input-old"
                                    name="passwordOld"
                                    value={validation.values.passwordOld}
                                    onBlur={validation.handleBlur}
                                    onChange={validation.handleChange}
                                    invalid={
                                      validation.errors.passwordOld &&
                                      validation.touched.passwordOld
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.errors.passwordOld &&
                                  validation.touched.passwordOld ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.passwordOld}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </div>
                              <div className="mb-3">
                                <Label
                                  className="form-label"
                                  htmlFor="password-input"
                                >
                                  Nueva Contraseña
                                </Label>
                                <div className="position-relative auth-pass-inputgroup">
                                  <Input
                                    type={"password"}
                                    className="form-control pe-5 password-input"
                                    placeholder="Ingrese su nueva contraseña"
                                    id="password-input"
                                    name="password"
                                    value={validation.values.password}
                                    onBlur={validation.handleBlur}
                                    onChange={validation.handleChange}
                                    invalid={
                                      validation.errors.password &&
                                      validation.touched.password
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.errors.password &&
                                  validation.touched.password ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.password}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                                <div id="passwordInput" className="form-text">
                                  <ul>
                                    <li>Debe utilizar al menos 8 caracteres</li>
                                    <li>Al menos una letra en minúscula</li>
                                    <li>Al menos una letra en mayúscula</li>
                                    <li>Al menos un número</li>
                                  </ul>
                                </div>
                              </div>

                              <div className="mb-3">
                                <Label
                                  className="form-label"
                                  htmlFor="confirm-password-input"
                                >
                                  Confirmar Nueva Contraseña
                                </Label>
                                <div className="position-relative auth-pass-inputgroup mb-3">
                                  <Input
                                    type={"password"}
                                    className="form-control pe-5 password-input"
                                    placeholder="Confirme su nueva contraseña"
                                    id="confirm-password-input"
                                    name="confrim_password"
                                    value={validation.values.confrim_password}
                                    onBlur={validation.handleBlur}
                                    onChange={validation.handleChange}
                                    invalid={
                                      validation.errors.confrim_password &&
                                      validation.touched.confrim_password
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.errors.confrim_password &&
                                  validation.touched.confrim_password ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.confrim_password}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </div>

                              <div
                                id="password-contain"
                                className="p-3 bg-light mb-2 rounded"
                              >
                                <h5 className="fs-13">
                                  Password must contain:
                                </h5>
                                <p
                                  id="pass-length"
                                  className="invalid fs-12 mb-2"
                                >
                                  Minimum <b>8 characters</b>
                                </p>
                                <p
                                  id="pass-lower"
                                  className="invalid fs-12 mb-2"
                                >
                                  At <b>lowercase</b> letter (a-z)
                                </p>
                                <p
                                  id="pass-upper"
                                  className="invalid fs-12 mb-2"
                                >
                                  At least <b>uppercase</b> letter (A-Z)
                                </p>
                                <p
                                  id="pass-number"
                                  className="invalid fs-12 mb-0"
                                >
                                  A least <b>number</b> (0-9)
                                </p>
                              </div>

                              <div className="mt-4">
                                <Button
                                  color="success"
                                  className="w-100"
                                  type="submit"
                                >
                                  Cambiar Contraseña
                                </Button>
                              </div>
                            </Form>
                          </div>
                        </div>
                      )}
                      {passwordUpdated === true && (
                        <div className="text-center mt-2">
                          <h5 className="text-primary">
                            Contraseña actualizada correctamente
                          </h5>
                          <p className="text-muted">
                            Por favor, vuelva a iniciar sesión en el sitio.
                          </p>
                          <div className="mt-4">
                            <Link
                              to="/logout"
                              className="btn btn-success w-100"
                            >
                              Ir a Iniciar Sesión
                            </Link>
                          </div>
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </ParticlesAuth>
      </ModalBody>
    </Modal>
  );
};

export default ResetPassword;
