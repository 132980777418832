import { call, put, takeLatest } from "redux-saga/effects";
import { addNewModulo, getLista, getModulos, getModulosByCodigo, getSyllabusModulo, postFile, updateModulo, getModulosByAcademico, getEvaluacionesByModuloVersion, getModuloVersion, addNewEvaluacionModulo, updateEvaluacionModulo, removeEvaluacionModulo, updatePromedioModuloVersion, addNewEvaluacionNota, updateNotaEvaluacionModulo, removeNotaEvaluacionModulo, getSesionesModulo, addNewAsistenciaModulo, getAsistenciaModulo, getHorarioModulo, postMulitpleFile, updateEstadoActaModulo } from "../../helpers/backend_helper";
import { getEstadoModulosSuccess, getModulosByCodigoSuccess, getModulosSuccess, getSyllabusModuloSuccess, getModulosByAcademicoSuccess, getEvaluacionesByModuloVersionSuccess, getModuloVersionSuccess, getSesionesModuloSuccess, getAsistenciaModuloSuccess, updateNotaEvaluacionModuloSuccess, getHorarioModuloSuccess } from "./actions";
import { toast } from "react-toastify";
import { ADD_NEW_MODULO, GET_ESTADO_MODULOS, GET_MODULOS, GET_MODULOS_BY_CODIGO, GET_SYLLABUS_MODULO, UPDATE_MODULO, GET_MODULOS_BY_ACADEMICO, GET_EVALUACIONES_BY_MODULO_VERSION, GET_MODULO_VERSION, ADD_NEW_EVALUACION_MODULO, UPDATE_EVALUACION_MODULO, DELETE_EVALUACION_MODULO, UPDATE_PROMEDIO_MODULO_VERSION, ADD_NEW_NOTA_EVALUACION_MODULO, UPDATE_NOTA_EVALUACION_MODULO, DELETE_NOTA_EVALUACION_MODULO, GET_SESIONES_MODULO, ADD_NEW_ASISTENCIA_MODULO, GET_ASISTENCIA_MODULO, GET_HORARIO_MODULO, UPDATE_ESTADO_ACTA_MODULO } from "./actionsTypes";
import { isNil } from "ramda";

function* createModulo({ payload: { data, fetchData, isFormPrograma, formData } }) {
  try {

    if (!isNil(formData)) {
      const responseFile = yield call(postFile, formData);
      data = { ...data, archivoSyllabusId: responseFile.data.fileId };
    }  
    const response = yield call(addNewModulo, data)
    if (isFormPrograma)
      fetchData(response.data)
    else
      fetchData()
      toast.success("Módulo ingresado con éxito", { autoClose: 3000 });
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* createEvaluacionModulo({ payload: data }) {
  try {
    const responseN = yield call(addNewEvaluacionModulo, data)
    const response = yield call(getEvaluacionesByModuloVersion, { moduloVersionId: data.moduloVersionId })
    yield put(getEvaluacionesByModuloVersionSuccess(response))
    toast.success("Evaluación creada correctamente", {
      position: toast.POSITION.TOP_RIGHT,
    });
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* createNotaEvaluacionModulo({ payload: data }) {
  try {
    const responseN = yield call(addNewEvaluacionNota, data)
    const response = yield call(getEvaluacionesByModuloVersion, { moduloVersionId: data.moduloVersionId })
    yield put(getEvaluacionesByModuloVersionSuccess(response))
    toast.success("Nota ingresada correctamente", {
      position: toast.POSITION.TOP_RIGHT,
    });
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* createAsistenciaModulo({ payload: { data, moduloVersionId, setAsistenciaOk } }) {
  try {
    const responseN = yield call(addNewAsistenciaModulo, data)
    const response = yield call(getSesionesModulo, { id: moduloVersionId })
    setAsistenciaOk()
    yield put(getSesionesModuloSuccess(response))
    toast.success("Asistencia ingresada correctamente", {
      position: toast.POSITION.TOP_RIGHT,
    });
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* fetchModulos({ payload: params }) {
  try {
    const response = yield call(getModulos, params)
    yield put(getModulosSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* fetchModulosByCodigo({ payload: codigo }) {
  try {
    const response = yield call(getModulosByCodigo, { codigo })
    yield put(getModulosByCodigoSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* fetchEstadoModulos() {
  try {
    const response = yield call(getLista, 'GetModulosEstados')
    yield put(getEstadoModulosSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* fetchSyllabusModulo({ payload: idModulo }) {
  try {
    const response = yield call(getSyllabusModulo, { idModulo })
    yield put(getSyllabusModuloSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* fetchEvalucionesByModuloVersion({ payload: idModuloVersion }) {
  try {
    const response = yield call(getEvaluacionesByModuloVersion, { moduloVersionId: idModuloVersion })
    yield put(getEvaluacionesByModuloVersionSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* fetchSesionesModulo({ payload: id }) {
  try {
    const response = yield call(getSesionesModulo, { id })
    yield put(getSesionesModuloSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* fetchAsistenciaModulo({ payload: id }) {
  try {
    const response = yield call(getAsistenciaModulo, { id })
    yield put(getAsistenciaModuloSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* fetchModuloVersion({ payload: idModuloVersion }) {
  try {
    const response = yield call(getModuloVersion, { idModuloVersion })
    yield put(getModuloVersionSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* fetchHorarioModulo({ payload: id }) {
  try {
    const response = yield call(getHorarioModulo, { id })
    yield put(getHorarioModuloSuccess(response))
  }
  catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* putModulo({ payload: { data, fetchData, formFileSence, archivoSenceId, formData, archivoSyllabusId } }) {
  try {



    if (data.archivoSence && !isNil(formFileSence)) {
      const responseFile = yield call(postFile, formFileSence);
      data = { ...data, archivoSenceId: responseFile.data.fileId };
    }
    else if (!isNil(archivoSenceId)) {
      data = { ...data, archivoSenceId: archivoSenceId };
    }



    /* **************************
    
    
    PVG 27112024 ACA NO FUNCIONA SUBIR EL ARCHIVO DE ABAJO, HE REVISADO Y ESTÁ TODO BIEN.
    
    
    ************************** */


    if (data.archivoSyllabus && !isNil(formData)) {
      const responseFile1 = yield call(postFile, formData);
      data = { ...data, archivoSyllabusId: responseFile1.data.fileId };
    }
    else if (!isNil(archivoSyllabusId)) {
      data = { ...data, archivoSyllabusId: archivoSyllabusId };
    }    

    const response = yield call(updateModulo, data)
    fetchData()
    toast.success("Módulo actualizado con éxito", { autoClose: 3000 });
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* putEvaluacionModulo({ payload: { data, moduloVersionId } }) {
  try {
    const responseU = yield call(updateEvaluacionModulo, { ...data })
    const response = yield call(getEvaluacionesByModuloVersion, { moduloVersionId })
    yield put(getEvaluacionesByModuloVersionSuccess(response))
    toast.success("Evaluación actualizada correctamente", {
      position: toast.POSITION.TOP_RIGHT,
    });
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* putPromedioModuloVersion({ payload: { data, callback } }) {
  try {
    const response = yield call(updatePromedioModuloVersion, { ...data })
    if(callback)
      callback()
    toast.success("Promedio actualizado correctamente", {
      position: toast.POSITION.TOP_RIGHT,
    });
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* putNotaEvaluacionModulo({ payload: data }) {
  try {
    const responseN = yield call(updateNotaEvaluacionModulo, data)
    yield put(updateNotaEvaluacionModuloSuccess(data))
    toast.success("Nota actualizada correctamente", {
      position: toast.POSITION.TOP_RIGHT,
    });
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* putEstadoActaModulo({ payload: { data, callback } }) {
  try {
    const response = yield call(updateEstadoActaModulo, data)
    callback && callback()
    toast.success("Estado de acta actualizado correctamente", {
      position: toast.POSITION.TOP_RIGHT,
    });
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* fetchModulosByAcademico({ payload: params }) {
  try {
    const response = yield call(getModulosByAcademico, params)
    yield put(getModulosByAcademicoSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* deleteEvaluacionModulo({ payload: { idEvaluacionModulo, moduloVersionId } }) {
  try {
    const responseD = yield call(removeEvaluacionModulo, { evaluacionId: idEvaluacionModulo })
    const response = yield call(getEvaluacionesByModuloVersion, { moduloVersionId })
    yield put(getEvaluacionesByModuloVersionSuccess(response))
    toast.success("Evaluación eliminada correctamente", {
      position: toast.POSITION.TOP_RIGHT,
    });
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* deleteNotaEvaluacionModulo({ payload: params }) {
  try {
    const responseD = yield call(removeNotaEvaluacionModulo, params)
    const response = yield call(getEvaluacionesByModuloVersion, { moduloVersionId: params.moduloVersionId })
    yield put(getEvaluacionesByModuloVersionSuccess(response))
    toast.success("Nota eliminada correctamente", {
      position: toast.POSITION.TOP_RIGHT,
    });
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* modulosSaga() {
  yield takeLatest(GET_MODULOS, fetchModulos)
  yield takeLatest(ADD_NEW_MODULO, createModulo)
  yield takeLatest(ADD_NEW_EVALUACION_MODULO, createEvaluacionModulo)
  yield takeLatest(ADD_NEW_NOTA_EVALUACION_MODULO, createNotaEvaluacionModulo)
  yield takeLatest(ADD_NEW_ASISTENCIA_MODULO, createAsistenciaModulo)
  yield takeLatest(UPDATE_MODULO, putModulo)
  yield takeLatest(UPDATE_EVALUACION_MODULO, putEvaluacionModulo)
  yield takeLatest(UPDATE_PROMEDIO_MODULO_VERSION, putPromedioModuloVersion)
  yield takeLatest(UPDATE_NOTA_EVALUACION_MODULO, putNotaEvaluacionModulo)
  yield takeLatest(UPDATE_ESTADO_ACTA_MODULO, putEstadoActaModulo)
  yield takeLatest(GET_ESTADO_MODULOS, fetchEstadoModulos)
  yield takeLatest(GET_SYLLABUS_MODULO, fetchSyllabusModulo)
  yield takeLatest(GET_MODULOS_BY_CODIGO, fetchModulosByCodigo)
  yield takeLatest(GET_MODULOS_BY_ACADEMICO, fetchModulosByAcademico)
  yield takeLatest(GET_MODULO_VERSION, fetchModuloVersion)
  yield takeLatest(GET_EVALUACIONES_BY_MODULO_VERSION, fetchEvalucionesByModuloVersion)
  yield takeLatest(GET_SESIONES_MODULO, fetchSesionesModulo)
  yield takeLatest(GET_ASISTENCIA_MODULO, fetchAsistenciaModulo)
  yield takeLatest(GET_HORARIO_MODULO, fetchHorarioModulo)
  yield takeLatest(DELETE_EVALUACION_MODULO, deleteEvaluacionModulo)
  yield takeLatest(DELETE_NOTA_EVALUACION_MODULO, deleteNotaEvaluacionModulo)
}

export default modulosSaga