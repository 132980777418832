import { POST_VALIDA_DOCUMENTO, POST_VALIDA_DOCUMENTO_ERROR, POST_VALIDA_DOCUMENTO_SUCCESS } from "./actionTypes";

const INIT_STATE = {
  documento: null,
  nombreDocumento: null,
  dataDocumento: null,
  error: false,
  loading: false,
}

const ValidaDocumento = (state = INIT_STATE, action) => {
  switch (action.type) {
    case POST_VALIDA_DOCUMENTO:
      return {
        ...state,
        loading: true,
      };
    case POST_VALIDA_DOCUMENTO_SUCCESS:
      return {
        ...state,
        documento: action.payload.archivoContenido,
        nombreDocumento: action.payload.archivoNombre,
        dataDocumento: action.payload.datosJson,
        loading: false,
      };
    case POST_VALIDA_DOCUMENTO_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
}

export default ValidaDocumento;