import {
    GET_USERS_SUCCESS,
    GET_USERS_FAIL,
    GET_USERS,
    GET_ALL_PERFILES_SUCCESS,
    GET_ALL_PERFILES_FAIL,
    GET_ALL_ROLES_SUCCESS,
    UPDATE_USER,
    UPDATE_PASSWORD_DCS_SUCCESS,
    RECUPERA_PASSWORD_USER_SUCCESS,
    CAMBIA_PASSWORD_USER_SUCCESS,
    ADD_NEW_USER_SUCCESS,
    RESET_NEW_USER_MANAGEMENT,
    ADD_NEW_USER_FAIL,
    ADD_NEW_USER,
} from './actionTypes'

const INIT_STATE = {
    usuarios: [],
    perfiles: [],
    roles: [],
    error: "",
    paginacion: {},
    loading: false,
    usuarioCargadoExito: false,
    passwordDCSUpdated: null,
    passwordDCSRecupera: null,
    passwordDCSChanged: null,
}

const UserManagement = (state = INIT_STATE, action) => {
    switch(action.type){
        case GET_USERS:
            return {
              ...state,
              loading: true,
            }
        case GET_USERS_SUCCESS:
            return{
                ...state,
                usuarios: action.payload.data, 
                paginacion : JSON.parse(action.payload.headers['x-pagination']),
                loading: false
            }
        case GET_USERS_FAIL:
            return{
                ...state,
                error: action.payload
            }
        case GET_ALL_PERFILES_SUCCESS:
            return{
                ...state,
                perfiles: action.payload.data
            }
        case GET_ALL_PERFILES_FAIL:
            return{
                ...state
            }
        case GET_ALL_ROLES_SUCCESS:
            return{
                ...state,
                roles: action.payload.data
            }
        case ADD_NEW_USER:
            return{
                ...state,
                loading: true
            }
        case ADD_NEW_USER_SUCCESS:
            return{
                ...state,
                usuarioCargadoExito: true
            }
        case ADD_NEW_USER_FAIL:
            return{
                ...state,
                loading: false
            }
        case UPDATE_USER:
            return{
                ...state
            }
        case UPDATE_PASSWORD_DCS_SUCCESS:
            return{
                ...state,
                passwordDCSUpdated: true
            }
        case RECUPERA_PASSWORD_USER_SUCCESS:
            return{
                ...state,
                passwordDCSRecupera: true
            }
        case CAMBIA_PASSWORD_USER_SUCCESS:
            return{
                ...state,
                passwordDCSChanged: true
            }
        case RESET_NEW_USER_MANAGEMENT:
            return{
                ...state,
                usuarioCargadoExito: false,
            }
        default:
            return state
    }
}

export default UserManagement