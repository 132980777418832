import {
    GET_TIPO_SOLICITUDES,
    GET_TIPO_SOLICITUDES_SUCCESS,
    ADD_NEW_SOLICITUD_FONDO, UPDATE_SOLICITUD_FONDO, GET_SOLICITUDES_FONDO, GET_SOLICITUDES_FONDO_SUCCESS,
    GET_SOLICITUDES_FONDO_POR_ACADEMICO, GET_SOLICITUDES_FONDO_POR_ACADEMICO_SUCCESS, GET_TIPO_ASIGNACION,
    GET_TIPO_ASIGNACION_SUCCESS,CAMBIA_ESTADO_SOLICITUD_FONDO, GET_TIPO_VIATICO, GET_TIPO_VIATICO_SUCCESS,
    GET_VIATICOPORPAIS, GET_VIATICOPORPAIS_SUCCESS, ADD_DIASVIAJE_SOLICITUD, GET_DIASVIAJE_SOLICITUD, GET_DIASVIAJE_SOLICITUD_SUCCESS, 
    RESET_DIASVIAJE_SOLICITUD, VALORIZA_SOLICITUD_FONDO, GET_SOLICITUD_HISTORIAL, GET_SOLICITUD_HISTORIAL_SUCCESS, 
    GET_PPTO_INVESTIGACION_POR_ACADEMICO, GET_PPTO_INVESTIGACION_POR_ACADEMICO_SUCCESS, RESET_PPTOS_INVESTIGACION,
    GET_PPTO_ACADEMICO_SALDO_PENDIENTE, GET_PPTO_ACADEMICO_SALDO_PENDIENTE_SUCCESS, GET_REPORTE_SOLICITUD_FONDOS_SUCCESS, 
    GET_REPORTE_PRESUPUESTO_INVESTIGACION_SUCCESS, GET_REPORTE_FONDOS_INVESTIGACION_SUCCESS, GET_ESTADO_SOLICITUD_FONDO_SUCCESS, 
    GET_TIPO_FONDO_INVESTIGACION_SUCCESS, GET_FONDOS_INVESTIGACION_SUCCESS, RESET_VIATICOPORPAIS, UPDATE_VALORES_SOLICITUD_FONDO,
    GET_PPTO_INVESTIGACION_POR_DEPTO, GET_PPTO_INVESTIGACION_POR_DEPTO_SUCCESS, GET_PPTO_DEPTO_PENDIENTE, GET_PPTO_DEPTO_PENDIENTE_SUCCESS,
    GET_PPTO_ACADEMICO_SALDO_PENDIENTE_CONCILIA, GET_PPTO_ACADEMICO_SALDO_PENDIENTE_CONCILIA_SUCCESS, GET_REPORTE_SOLICITUD_FONDOS_AYUDANTES_SUCCESS,
    GET_CECO_SOLICITUD_FONDO_SUCCESS, ADD_NEW_CECO_SOLICITUD_FONDO, UPDATE_CECO_SOLICITUD_FONDO, GET_CECOADDED, RESET_CECO_SOLICITUD, GET_CECO_SOLICITUD_BY_CODIGO, 
    GET_CECO_SOLICITUD_BY_CODIGO_SUCCESS
} from './actionTypes'

const INIT_STATE = {
    arrTiposSolicitud: [],
    arrSolicitudes: [],
    arrTiposAsignacion: [],
    viaticoPorPaisArray: [],
    diasViajeArray: [],
    historialSolicitudArray: [],
    pptoInvestigacionByAcadArray: [],
    pptoInvestigacionPendienteByAcadArray: [],
    paginacion: {},
    error: "",
    reporteXLS: null,
    arrEstadosSolicitud: [],
    arrTiposFondoInvestigacion: [],
    arrFondosInvestigacion: [],
    pptoInvestigacionPorDeptoArray: [],
    pptoInvestigacionPorDeptoPendienteArray: [],
    ceco: {},
    cecoAddedArray: [],
    cecoIngresado: {}
}

const SolicitudesFondo = (state = INIT_STATE, action) => {
    switch(action.type){
        case GET_TIPO_SOLICITUDES:
            return {
              ...state,
              loading: true,
            }
        case GET_TIPO_SOLICITUDES_SUCCESS:
            return {
              ...state,
              loading: false,
              arrTiposSolicitud: action.payload.data
            }  
        case ADD_NEW_SOLICITUD_FONDO:
            return {
            ...state,
          }  
        case UPDATE_SOLICITUD_FONDO:
            return{
                ...state
            }          
        case GET_SOLICITUDES_FONDO:
            return {
              ...state,
              loading: true
            }
        case GET_SOLICITUDES_FONDO_SUCCESS:
            return {
              ...state,
              loading: false,
              paginacion : JSON.parse(action.payload.headers['x-pagination']),
              arrSolicitudes: action.payload.data
            }
        case GET_SOLICITUDES_FONDO_POR_ACADEMICO:
            return {
              ...state,
              loading: true
            }
        case GET_SOLICITUDES_FONDO_POR_ACADEMICO_SUCCESS:
            return {
              ...state,
              loading: false,
              paginacion : JSON.parse(action.payload.headers['x-pagination']),
              arrSolicitudes: action.payload.data
            }            
        case GET_TIPO_ASIGNACION:
            return {
              ...state,
              loading: true
            }
        case GET_TIPO_ASIGNACION_SUCCESS:
            return {
              ...state,
              loading: false,
              arrTiposAsignacion: action.payload.data
            }                  
        case CAMBIA_ESTADO_SOLICITUD_FONDO:
            return {
            ...state,
          }
        case GET_TIPO_VIATICO:
            return {
              ...state,
              loading: true
            }          
        case GET_TIPO_VIATICO_SUCCESS:
            return {
              ...state,
              loading: false,
              arrTiposViatico: action.payload.data
            }
        case GET_VIATICOPORPAIS:
            return {
              ...state,
              loading: true
            }          
        case GET_VIATICOPORPAIS_SUCCESS:
            return {
              ...state,
              viaticoPorPaisArray: action.payload,
            }                      
        case ADD_DIASVIAJE_SOLICITUD:
            return {
            ...state,
          }  
        case GET_DIASVIAJE_SOLICITUD:
            return {
              ...state,
              loading: true
            }
        case GET_DIASVIAJE_SOLICITUD_SUCCESS:
            return {
              ...state,
              loading: false,
              diasViajeArray: action.payload.data
            }                    
        case RESET_DIASVIAJE_SOLICITUD:
            return {
              ...state,
              diasViajeArray: []
            }                  
        case VALORIZA_SOLICITUD_FONDO:
            return{
                ...state
            }   
        case GET_SOLICITUD_HISTORIAL:
            return {
              ...state,
              loading: true
            }
        case GET_SOLICITUD_HISTORIAL_SUCCESS:
            return {
              ...state,
              loading: false,
              historialSolicitudArray: action.payload.data
            }
        case GET_PPTO_INVESTIGACION_POR_ACADEMICO:
            return {
              ...state,
              loading: true
            }
        case GET_PPTO_INVESTIGACION_POR_ACADEMICO_SUCCESS:
            return {
              ...state,
              loading: false,
              pptoInvestigacionByAcadArray: action.payload.data
            }                  
        case RESET_PPTOS_INVESTIGACION:
            return {
              ...state,
              pptoInvestigacionByAcadArray: []
            }  
        case GET_PPTO_ACADEMICO_SALDO_PENDIENTE:
            return {
              ...state,
              loading: true
            }
        case GET_PPTO_ACADEMICO_SALDO_PENDIENTE_SUCCESS:
            return {
              ...state,
              loading: false,
              pptoInvestigacionPendienteByAcadArray: action.payload.data
            }
        case GET_REPORTE_SOLICITUD_FONDOS_SUCCESS:
            return {
              ...state,
              reporteXLS: action.payload.data
            }
        case GET_REPORTE_PRESUPUESTO_INVESTIGACION_SUCCESS:
            return {
              ...state,
              reporteXLS: action.payload.data
            }
        case GET_REPORTE_FONDOS_INVESTIGACION_SUCCESS:
            return {
              ...state,
              reporteXLS: action.payload.data
            }
        case GET_ESTADO_SOLICITUD_FONDO_SUCCESS:
            return {
              ...state,
              arrEstadosSolicitud: action.payload.data
            }
        case GET_TIPO_FONDO_INVESTIGACION_SUCCESS:
            return {
              ...state,
              arrTiposFondoInvestigacion: action.payload.data
            }
        case GET_FONDOS_INVESTIGACION_SUCCESS:
            return {
              ...state,
              arrFondosInvestigacion: action.payload.data,
              paginacion : JSON.parse(action.payload.headers['x-pagination']),
            }   
        case RESET_VIATICOPORPAIS:
            return {
              ...state,
              viaticoPorPaisArray: []
            }    
        case UPDATE_VALORES_SOLICITUD_FONDO:
            return {
            ...state,
          }  
          case GET_PPTO_INVESTIGACION_POR_DEPTO:
            return {
              ...state,
              loading: true
            }
        case GET_PPTO_INVESTIGACION_POR_DEPTO_SUCCESS:
            return {
              ...state,
              loading: false,
              pptoInvestigacionPorDeptoArray: action.payload.data
            }
        case GET_PPTO_DEPTO_PENDIENTE:
            return {
              ...state,
              loading: true
            }
        case GET_PPTO_DEPTO_PENDIENTE_SUCCESS:
            return {
              ...state,
              loading: false,
              pptoInvestigacionPorDeptoPendienteArray: action.payload.data
            }  
        case GET_PPTO_ACADEMICO_SALDO_PENDIENTE_CONCILIA:
            return {
              ...state,
              loading: true
            }
        case GET_PPTO_ACADEMICO_SALDO_PENDIENTE_CONCILIA_SUCCESS:
            return {
              ...state,
              loading: false,
              pptoInvestigacionPendienteByAcadArray: action.payload.data
            }
        case GET_REPORTE_SOLICITUD_FONDOS_AYUDANTES_SUCCESS:
            return {
              ...state,
              reporteXLS: action.payload.data
            }   
        case GET_CECO_SOLICITUD_FONDO_SUCCESS:
          return {
            ...state,
            ceco: action.payload.data,
          } 
        case ADD_NEW_CECO_SOLICITUD_FONDO:
            return {
            ...state,
          }
        case UPDATE_CECO_SOLICITUD_FONDO:
            return{
                ...state
            }
        case GET_CECOADDED:
            return {
              ...state,
              cecoAddedArray: action.payload,
            }      
        case RESET_CECO_SOLICITUD:
            return {
              ...state,
              ceco: {},
              cecoAddedArray: [],
              cecoIngresado: {},
            }     
        case GET_CECO_SOLICITUD_BY_CODIGO:
            return {
              ...state,
              loading: true
            }          
        case GET_CECO_SOLICITUD_BY_CODIGO_SUCCESS:
            return {
              ...state,
              cecoIngresado: action.payload,
            }                                                                     
        default:  
            return state
    }
}

export default SolicitudesFondo