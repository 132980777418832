import { GET_ADJUDICACIONES_SUCCESS, GET_CROSSREF, GET_CROSSREF_FAIL, GET_CROSSREF_SUCCESS, 
      GET_PUBLICACIONES_SUCCESS, RESET_CROSSREF,
      GET_PREMIO_PUB_HISTORIAL, GET_PREMIO_PUB_HISTORIAL_SUCCESS, 
      POST_CARGARANKINGJCR,
      POST_CARGADETALLERANKINGJCR,
      POST_CARGADETALLERANKINGJCR_SUCCESS,
      GET_RANKINGJCR_BY_ISSN, GET_RANKINGJCR_BY_ISSN_SUCCESS,
      GET_RANKINGJCR_DETALLE_ALL, GET_RANKINGJCR_DETALLE_ALL_SUCCESS,
      GET_MAX_ANIO_PREMIO_ADJUDICACION, GET_MAX_ANIO_PREMIO_ADJUDICACION_SUCCESS,
      GET_REPORTE_PREMIO_PUB_SUCCESS, GET_REPORTE_ADJUDICACION_SUCCESS, GET_RANKINGJCR_BY_ISSN_VAL, GET_RANKINGJCR_BY_ISSN_VAL_SUCCESS, RESET_ARCHIVOEXCEL} from "./actionTypes";

const INIT_STATE = {
  crossref: {},
  cargandoCrossref: false,
  publicaciones: [],
  adjudicaciones: [],
  historialPremioPubArray: [],
  cargadetallerankingjcr: [],
  rankingjcr: [],
  rankingjcrdetalle: [],
  paginacion: {},
  varMaxAnio: 0,
  reporteXLS: null,
  rankingjcrVal: [],
};

const Premios = (state = INIT_STATE, action) => {
//console.log("action", action)
  switch (action.type) {
    case GET_CROSSREF:
      return {
        ...state,
        cargandoCrossref: true
      };
    case GET_CROSSREF_SUCCESS:
      return {
        ...state,
        crossref: action.payload.data,
        cargandoCrossref: false
      };
    case GET_CROSSREF_FAIL:
      return {
        ...state,
        crossref: {},
        cargandoCrossref: false
      };
    case GET_PUBLICACIONES_SUCCESS:
      return {
        ...state,
        paginacion : JSON.parse(action.payload.headers['x-pagination']),
        publicaciones: action.payload.data,
      };
    case GET_ADJUDICACIONES_SUCCESS:
      return {
        ...state,
        paginacion : JSON.parse(action.payload.headers['x-pagination']),
        adjudicaciones: action.payload.data,
      };
    case RESET_CROSSREF:
      return {
        ...state,
        crossref: {}
      }
    case GET_PREMIO_PUB_HISTORIAL:
        return {
          ...state,
          loading: true
        }
    case GET_PREMIO_PUB_HISTORIAL_SUCCESS:
        return {
          ...state,
          loading: false,
          historialPremioPubArray: action.payload.params.data
        }      
    case POST_CARGARANKINGJCR:
        return {
        ...state,
      }          
    case POST_CARGADETALLERANKINGJCR:
        return {
          ...state,
          loading: true
        }
    case POST_CARGADETALLERANKINGJCR_SUCCESS:
        return {
          ...state,
          loading: false,
          cargadetallerankingjcr: action.payload.data
        }
    case GET_RANKINGJCR_BY_ISSN:
        return {
          ...state,
          loading: true
        }
    case GET_RANKINGJCR_BY_ISSN_SUCCESS:
        return {
          ...state,
          loading: false,
          rankingjcr: action.payload.data
        }          
      case GET_RANKINGJCR_DETALLE_ALL:
        return {
          ...state,
          loading: true
        }
    case GET_RANKINGJCR_DETALLE_ALL_SUCCESS:
        return {
          ...state,
          loading: false,
          paginacion : JSON.parse(action.payload.headers['x-pagination']),
          rankingjcrdetalle: action.payload.data
        }          
      case GET_MAX_ANIO_PREMIO_ADJUDICACION:
        return {
          ...state,
          loading: true
        }
    case GET_MAX_ANIO_PREMIO_ADJUDICACION_SUCCESS:
        return {
          ...state,
          loading: false,
          varMaxAnio: action.payload.data
        }   
    case GET_REPORTE_PREMIO_PUB_SUCCESS:
        return {
          ...state,
          reporteXLS: action.payload.data
        }                      
    case GET_REPORTE_ADJUDICACION_SUCCESS:
      return {
        ...state,
        reporteXLS: action.payload.data
      }                              
    
    case GET_RANKINGJCR_BY_ISSN_VAL:
        return {
          ...state,
          loading: true
        }
    case GET_RANKINGJCR_BY_ISSN_VAL_SUCCESS:
        return {
          ...state,
          loading: false,
          rankingjcrVal: action.payload.data
        }   
    case RESET_ARCHIVOEXCEL:
      return {
        ...state,
        reporteXLS: {}
      }                        
    default:   
    return state;
  }
};

export default Premios;
