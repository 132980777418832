import { takeEvery, put, call, fork, takeLatest, all, delay } from "redux-saga/effects"
import { toast } from 'react-toastify';
import { isEmpty, isNil } from "ramda";

import {
    GET_TIPO_SOLICITUDES,
    ADD_NEW_SOLICITUD_FONDO, UPDATE_SOLICITUD_FONDO, GET_SOLICITUDES_FONDO,
    GET_TIPO_ASIGNACION,
    CAMBIA_ESTADO_SOLICITUD_FONDO, GET_TIPO_VIATICO, GET_VIATICOPORPAIS, ADD_DIASVIAJE_SOLICITUD, GET_DIASVIAJE_SOLICITUD, 
    VALORIZA_SOLICITUD_FONDO, GET_SOLICITUD_HISTORIAL, GET_PPTO_INVESTIGACION_POR_ACADEMICO, GET_SOLICITUDES_FONDO_POR_ACADEMICO, 
    GET_PPTO_ACADEMICO_SALDO_PENDIENTE, GET_REPORTE_SOLICITUD_FONDOS, GET_REPORTE_PRESUPUESTO_INVESTIGACION, GET_REPORTE_FONDOS_INVESTIGACION, 
    GET_ESTADO_SOLICITUD_FONDO, GET_TIPO_FONDO_INVESTIGACION, GET_FONDOS_INVESTIGACION, ADD_NEW_FONDO_INVESTIGACION, UPDATE_ACADEMICOS_FONDO, 
    UPDATE_FONDO_INVESTIGACION, DELETE_FONDO_INVESTIGACION, UPDATE_VALORES_SOLICITUD_FONDO, GET_PPTO_INVESTIGACION_POR_DEPTO, GET_PPTO_DEPTO_PENDIENTE,
    GET_PPTO_ACADEMICO_SALDO_PENDIENTE_CONCILIA, GET_REPORTE_SOLICITUD_FONDOS_AYUDANTES, GET_FILES_SOLICITUD, GET_CECO_SOLICITUD_FONDO, ADD_NEW_CECO_SOLICITUD_FONDO, 
    UPDATE_CECO_SOLICITUD_FONDO, GET_CECO_SOLICITUD_BY_CODIGO
} from './actionTypes'

import {
    getListaTipoSolicitudes, addNewSolicitudFondo, getSolicitudes, postFile, updateSolicitudFondo, 
    addNewSubAreaInvestigacion, addNewTemaInvestigacion, getLista, addNewSolicitudFondoHistorial, 
    updateEstadoSolicitudFondo, getViaticoPorPais, addDiasViajeSolicitud, getDiasViajeSolicitud, 
    getSolicitudHistorial, getPptoInvestigacionPorAcademico, getSolicitudesPorAcademico, getPptoAcademicoSaldoPendiente, 
    getReporteSolicitudesFondos, getReportePresupuestoInvestigacion, getReporteFondosInvestigacion, getEstadoSolicitudFondo, 
    getTipoFondoInvestigacion, getFondosInvestigacion, addNewFondoInvestigacion, updateAcademicosFondo, 
    updateFondoInvestigacion, deleteFondoInvestigacion, updateValoresSolicitudFondo, getPptoInvestigacionPorDepto, getPptoDeptoPendiente, 
    getPptoAcademicoSaldoPendienteConcilia, getReporteSolicitudesFondosAyudantes, getFilesSolicitudById, getCecoSolicitudFondo, addNewCecoSolicitud, updateCecoSolicitud,
    getCecoSolicitudByCodigo
} from '../../helpers/backend_helper'

import { getTipoSolicitudesSuccess, getSolicitudesDeFondoSuccess, getTipoAsignacionSuccess, 
        getTipoViaticoSuccess, getViaticoPorPaisSuccess, getDiasViajeSolicitudSuccess, getSolicitudHistorialSuccess, 
        getPptoInvestigacionPorAcademicoSuccess, getSolicitudesDeFondoPorAcademicoSuccess, getPptoAcademicoSaldoPendienteSuccess, 
        getReporteSolicitudesDeFondoSuccess, getReportePresupuestoInvestigacionSuccess, getReporteFondosInvestigacionSuccess, getEstadoSolicitudFondoSuccess, 
        getTipoFondoInvestigacionSuccess, getFondosInvestigacionSuccess, getPptoInvestigacionPorDeptoSuccess, getPptoDeptoPendienteSuccess,
        getPptoAcademicoSaldoPendienteConciliaSuccess, getReporteSolicitudesDeFondoAyudantesSuccess,getCecoSolicitudFondoSuccess, getCecoAdded,
        getCecoSolicitudByCodigoSuccess } from "./actions";

import FileSaver from "file-saver";

function* fetchTipoSolicitudes(){
    try {
        const response = yield call(getListaTipoSolicitudes)
        yield put(getTipoSolicitudesSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* addSolicitud({payload: {solicitud, params, formData, formData1, tipoTransac}}){
    try {

        let codSubArea = 0
        if(solicitud.InvestigacionSubareaSolId.value==='@@'){
            const objSubarea = {nombre: solicitud.InvestigacionSubareaSolId.label, solAreasId: solicitud.InvestigacionAreaSolId}
            const responseSubArea = yield call(addNewSubAreaInvestigacion, objSubarea);
            codSubArea = responseSubArea.data.objSubarea.id
        }else{
            codSubArea = solicitud.InvestigacionSubareaSolId.value
        }
        if(solicitud.InvestigacionTemaSolId_obj.value==='@@'){
            const objTema = {nombre: solicitud.InvestigacionTemaSolId_obj.label, solSubareasId: codSubArea}
            const responseTema = yield call(addNewTemaInvestigacion, objTema);
            solicitud.investigacionTemaSolId = responseTema.data.objTema.id
        }

        if(formData){
            const responseFile = yield call(postFile, formData);
            switch(solicitud.SolicitudTipoId){
                case 2:
                    solicitud.archivoFacturaCompraLibroId = responseFile.data.fileId
                    console.log("responseFile.data.fileId", responseFile.data.fileId)
                break;
                case 3:
                    solicitud.archivoFacturaSoftwareId = responseFile.data.fileId
                break;
                case 4:
                    solicitud.archivoTrasladoViajeId = responseFile.data.fileId
                break;                
                case 5: 
                    solicitud.doctoOriginalTraducId = responseFile.data.fileId
                break;                        
                case 6:
                    solicitud.archivoOtrosId = responseFile.data.fileId
                break; 
                case 7:
                    solicitud.archivoTrasladoViajeId = responseFile.data.fileId
                break;                                
                default:           
            }
        }


        if(formData1){
            const responseFile1 = yield call(postFile, formData1);
            switch(solicitud.SolicitudTipoId){
                case 4:
                    solicitud.archivoAutorizacionDirectorDeptoId = responseFile1.data.fileId    
                break;  
                case 5:
                    solicitud.doctoTraducidoId = responseFile1.data.fileId
                break;                             
                default:           
            }
        }        
        

        //return false;

        const response = yield call(addNewSolicitudFondo, solicitud)
        const histObj = {estadoId: 0, solicitudId: response.data.objSolicitud.id}
        const responseHistorial = yield call(addNewSolicitudFondoHistorial, histObj)

        if(tipoTransac===2){
            // ** opcion guardar y enviar
            let objCE = {estadoId: 6, solicitudId: response.data.objSolicitud.id} 
            const responseHis = yield call(addNewSolicitudFondoHistorial, objCE)
            let solicitud = {idSolicitud: response.data.objSolicitud.id, idEstado: 6}
            const responseEstado = yield call(updateEstadoSolicitudFondo, response.data.objSolicitud.id, solicitud);
        }

        const responseSolicitudes = yield call(getSolicitudesPorAcademico, params)
        yield put(getSolicitudesDeFondoPorAcademicoSuccess(responseSolicitudes))
        toast.success("Solicitud creada con éxito", { autoClose: 3000 });
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* cambiaEstadoSolicitud({payload: {obj, params, acad}}){
    try {

        const response = yield call(addNewSolicitudFondoHistorial, obj)

        let solicitud = {idSolicitud: obj.solicitudId, idEstado: obj.estadoId, observacion: obj.Comentario, rol: obj.rol, montoValoriza: obj.estadoId === 8 ? obj.montoTotalSolicitud : null }
        const responseEstado = yield call(updateEstadoSolicitudFondo, obj.solicitudId, solicitud);


        if(acad){
            let param = {academico_id: 0}
            const responseSaldo = yield call(getPptoAcademicoSaldoPendiente, param)
            yield put(getPptoAcademicoSaldoPendienteSuccess(responseSaldo))       
        } 

        let responseSolicitudes = []
        if(acad){
            responseSolicitudes = yield call(getSolicitudesPorAcademico, params)
            yield put(getSolicitudesDeFondoPorAcademicoSuccess(responseSolicitudes))
            //yield call(getPptoAcademicoSaldoPendiente, null)
        }else{

            //if(obj.estadoId===8){
                responseSolicitudes = yield call(getSolicitudes, {...params, filtroPendientes: true})    
            /*}else{
                responseSolicitudes = yield call(getSolicitudes, params)
            }*/

            yield put(getSolicitudesDeFondoSuccess(responseSolicitudes))
        }


        toast.success("Solicitud actualizada con éxito", { autoClose: 3000 });
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* putSolicitud({payload: {solicitud, params, formData, formData1, acad, tipoTransac}}){


    try {


        if(solicitud.solicitudEstadoId === 8){
            if(!isNil(formData)){
                const responseFile = yield call(postFile, formData);
                solicitud.archivoVueloViajeId = responseFile.data.fileId
            }
            if(!isNil(formData1)){
                const responseFile1 = yield call(postFile, formData1);
                solicitud.archivoFacturaViajeId = responseFile1.data.fileId
            }
        }

        let codSubArea = 0

        // ** estado 23 modifica CAD pos Aprobación    
        if(solicitud.solicitudEstadoId !== 23){
            if(solicitud.InvestigacionSubareaSolId.value==='@@'){
                const objSubarea = {nombre: solicitud.InvestigacionSubareaSolId.label, solAreasId: solicitud.InvestigacionAreaSolId.value}
                const responseSubArea = yield call(addNewSubAreaInvestigacion, objSubarea);
                codSubArea = responseSubArea.data.objSubarea.id
            }else{
                codSubArea = solicitud.InvestigacionSubareaSolId.value
            }
            if(solicitud.InvestigacionTemaSolId_obj.value==='@@'){
                const objTema = {nombre: solicitud.InvestigacionTemaSolId_obj.label, solSubareasId: codSubArea}
                const responseTema = yield call(addNewTemaInvestigacion, objTema);
                solicitud.investigacionTemaSolId = responseTema.data.objTema.id
            }
        }
        
        if(formData !== null){
            const responseFile = yield call(postFile, formData);
            switch(solicitud.SolicitudTipoId){
                case 2:
                    solicitud.archivoFacturaCompraLibroId = responseFile.data.fileId
                break;
                case 3:
                    solicitud.archivoFacturaSoftwareId = responseFile.data.fileId
                break;
                case 4:
                    solicitud.archivoTrasladoViajeId = responseFile.data.fileId
                break;                                
                case 5: 
                    solicitud.doctoOriginalTraducId = responseFile.data.fileId
                break;                        
                case 6:
                    solicitud.archivoOtrosId = responseFile.data.fileId
                break; 
                case 7:
                    solicitud.archivoTrasladoViajeId = responseFile.data.fileId
                break;                
                default:           
            }
        }

        if(formData1 !== null){
            let responseFile1 = null
            responseFile1 = yield call(postFile, formData1);    
            switch(solicitud.SolicitudTipoId){
                case 4:
                    solicitud.archivoAutorizacionDirectorDeptoId = responseFile1.data.fileId    
                break;                                
                case 5: 
                    solicitud.doctoTraducidoId = responseFile1.data.fileId
                break;                        
                default:           
            }
        }        


        let varIdSolicitud = solicitud.id;

        const histObj = {estadoId: solicitud.solicitudEstadoId, solicitudId: solicitud.id}
        const responseHistorial = yield call(addNewSolicitudFondoHistorial, histObj)


        const response = yield call(updateSolicitudFondo, solicitud)
        let responseSolicitudes = []

        if(acad){


            if(tipoTransac===2){
                // ** opcion guardar y enviar
                let objCE = {estadoId: 6, solicitudId: varIdSolicitud} 
                const responseHis = yield call(addNewSolicitudFondoHistorial, objCE)
                let solicitud = {idSolicitud: varIdSolicitud, idEstado: 6}
                const responseEstado = yield call(updateEstadoSolicitudFondo, varIdSolicitud, solicitud);
            }

            responseSolicitudes = yield call(getSolicitudesPorAcademico, params)
            yield put(getSolicitudesDeFondoPorAcademicoSuccess(responseSolicitudes))

            let param = {academico_id: 0}
            const responseSaldo = yield call(getPptoAcademicoSaldoPendiente, param)
            yield put(getPptoAcademicoSaldoPendienteSuccess(responseSaldo))  
           



        }else{

            if(solicitud.solicitudEstadoId === 16 || solicitud.solicitudEstadoId === 14 || solicitud.solicitudEstadoId === 8){
                responseSolicitudes = yield call(getSolicitudes, {...params, filtroPendientes: true})
            }else{
                responseSolicitudes = yield call(getSolicitudes, params)
            }   
            
            yield put(getSolicitudesDeFondoSuccess(responseSolicitudes))
        }

        toast.success("Solicitud actualizada con éxito", { autoClose: 3000 });
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}


function* valorizaSolicitud({payload: {solicitud, params, formData = null, formData1 = null, newEstado}}){

    try {

        if(newEstado === 1){

            let responseCeco    
            yield delay(3000);
            responseCeco = yield call(getCecoSolicitudFondo, {fondoId: solicitud.presupuestoInvestigacion?.fondoInvestigacion?.id, academicoId: solicitud.academico?.id})

            if(!responseCeco.data?.id){
                responseCeco = yield call(getCecoSolicitudFondo, {fondoId: solicitud.presupuestoInvestigacion?.fondoInvestigacion?.id, academicoId: solicitud.academico?.id})
            }

            solicitud.cecoSolicitudId = responseCeco.data.id

            if(solicitud.solicitudTipoId === 4){
                if(!isNil(formData)){

                    let hasFile = false;
                    for (let [key, value] of formData.entries()) {
                      if (key === "FileDetails" && value instanceof File) {
                        hasFile = true;
                        break;
                      }
                    }
                    console.log("hasFile 1", hasFile)
                    if(hasFile){
                        const responseFile1 = yield call(postFile, formData);
                        solicitud.ArchivoVisaId = responseFile1.data.fileId
                    }

                }
                if(!isNil(formData1)){

                    let hasFile = false;
                    for (let [key, value] of formData1.entries()) {
                      if (key === "FileDetails" && value instanceof File) {
                        hasFile = true;
                        break;
                      }
                    }

                    console.log("hasFile 2", hasFile)
                    if(hasFile){
                        const responseFile2 = yield call(postFile, formData1);
                        solicitud.ArchivoAutorizacionDirectorDeptoId = responseFile2.data.fileId
                    }   
                }
            }

        }else{
            if(!isNil(formData)){
                const responseFile3 = yield call(postFile, formData);
                solicitud.archivoVueloViajeId = responseFile3.data.fileId
            }
            if(!isNil(formData1)){
                const responseFile4 = yield call(postFile, formData1);
                solicitud.archivoFacturaViajeId = responseFile4.data.fileId
            }
        }


        const response = yield call(updateSolicitudFondo, solicitud)
        
        if(newEstado !== 1) {
            toast.success("Solicitud actualizada con éxito", { autoClose: 3000 });
            const responseSolicitudes = yield call(getSolicitudes, {...params, filtroPendientes: true})    
            yield put(getSolicitudesDeFondoSuccess(responseSolicitudes))
        }
        
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}



function* fetchSolicitudes({payload : {params, filtrarPendientes}}){
    try {
        const response = yield call(getSolicitudes, {...params, filtroPendientes: filtrarPendientes})
        yield put(getSolicitudesDeFondoSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchSolicitudesPorAcademico({payload : params}){
    try {
        const response = yield call(getSolicitudesPorAcademico, params)
        yield put(getSolicitudesDeFondoPorAcademicoSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}



function* fetchDiasViaje({payload : params}){
    try {
        const response = yield call(getDiasViajeSolicitud, params)
        yield put(getDiasViajeSolicitudSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchSolicitudHistorial({payload : params}){
    try {
        const response = yield call(getSolicitudHistorial, params)
        yield put(getSolicitudHistorialSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchPptoInvestigacionByAcad({payload : params}){
    try {
        const response = yield call(getPptoInvestigacionPorAcademico, params)
        yield put(getPptoInvestigacionPorAcademicoSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchPptoAcademicoSaldoPendiente({payload : params}){
    try {
        const response = yield call(getPptoAcademicoSaldoPendiente, params)
        yield put(getPptoAcademicoSaldoPendienteSuccess(response))
    } catch (error) {
        /*toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });*/
    }
}

function* fetchPptoAcademicoSaldoPendienteConcilia({payload : params}){
    try {
        const response = yield call(getPptoAcademicoSaldoPendienteConcilia, params)
        yield put(getPptoAcademicoSaldoPendienteConciliaSuccess(response))
    } catch (error) {
        /*toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });*/
    }
}


function* fetchTipoAsignacion({payload : params}){
    try {
        const response = yield call(getLista, "GetSolTiposAsignacion")
        yield put(getTipoAsignacionSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchTipoViatico({payload : params}){
    try {
        const response = yield call(getLista, "GetSolTiposViatico")
        yield put(getTipoViaticoSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchViaticoPorPais({payload: params}){
    try {
      const response = yield call(getViaticoPorPais, params)
      yield put(getViaticoPorPaisSuccess(response.data))
    } catch (error) {
      // yield put(getEventsFail(error))
    }
}

/*function* fetchViaticoPorPais({payload: codPais}){
    try {
      const response = yield call(getViaticoPorPais, {codPais})
      yield put(getViaticoPorPaisSuccess(response.data))
    } catch (error) {
      // yield put(getEventsFail(error))
    }
  }*/  

function* fetchReporteSolicitudesFondos({payload : params}){
    try {
        const response = yield call(getReporteSolicitudesFondos, params)
        yield put(getReporteSolicitudesDeFondoSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchReportePresupuestoInvestigacion({payload : params}){
    try {
        const response = yield call(getReportePresupuestoInvestigacion, params)
        yield put(getReportePresupuestoInvestigacionSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchReporteFondosInvestigacion({payload : params}){
    try {
        const response = yield call(getReporteFondosInvestigacion, params)
        yield put(getReporteFondosInvestigacionSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchEstadosSolicitudesFondo(){
    try {
        const response = yield call(getEstadoSolicitudFondo)
        yield put(getEstadoSolicitudFondoSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchTipoFondoInvestigacion(){
    try {
        const response = yield call(getTipoFondoInvestigacion)
        yield put(getTipoFondoInvestigacionSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchFondosInvestigacion({payload: params}){
    try {
        const response = yield call(getFondosInvestigacion, params)
        yield put(getFondosInvestigacionSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* postNewFondoInvestigacion({payload: {fondoInvestigacion, fetchData}}){
    try {
        const response = yield call(addNewFondoInvestigacion,fondoInvestigacion)
        const response2 = yield call(fetchData)
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* cambiaAcademicosFondo({payload: data}){
    try {
        const response = yield call(updateAcademicosFondo,data)
        const responseFI = yield call(getFondosInvestigacion)
        yield put(getFondosInvestigacionSuccess(responseFI))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* putFondoInvestigacion({payload: {id, data}}){
    try {

        const response = yield call(updateFondoInvestigacion,id,data)
        const responseFI = yield call(getFondosInvestigacion)
        yield put(getFondosInvestigacionSuccess(responseFI))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* delFondoInvestigacion({payload: id}){
    try {
        const response = yield call(deleteFondoInvestigacion,id)
        const responseFI = yield call(getFondosInvestigacion)
        yield put(getFondosInvestigacionSuccess(responseFI))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* putValoresSolicitud({payload: {obj, params, formData}}){
    try {

        let histObj = {estadoId: obj.solicitudEstadoId, solicitudId: obj.idSolicitud}

        //if(obj.solicitudEstadoId===24){

        let idArchivo = null;
        if(formData){
            const responseFile = yield call(postFile, formData);
            idArchivo = responseFile.data.fileId
        }

        histObj = {...histObj,
        comentario: obj.comentarioConciliacion,
        archivoConciliacionId: idArchivo
        }
        //}

        const response = yield call(addNewSolicitudFondoHistorial, histObj)
        const responseEstado = yield call(updateValoresSolicitudFondo, obj.idSolicitud, obj);

        let responseSolicitudes = []
        responseSolicitudes = yield call(getSolicitudes, params)
        yield put(getSolicitudesDeFondoSuccess(responseSolicitudes))

        const responseLista = yield call(getPptoDeptoPendiente, {})
        yield put(getPptoDeptoPendienteSuccess(responseLista))

        toast.success("Solicitud actualizada con éxito", { autoClose: 3000 });
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchPptoInvestigacionPorDepto({payload : params}){
    try {
        const response = yield call(getPptoInvestigacionPorDepto, params)
        yield put(getPptoInvestigacionPorDeptoSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchPptoDeptoPendiente({payload : params}){
    try {

        const response = yield call(getPptoDeptoPendiente, params)
        yield put(getPptoDeptoPendienteSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchReporteSolicitudesFondosAyudantes({payload : params}){
    try {
        const response = yield call(getReporteSolicitudesFondosAyudantes, params)
        yield put(getReporteSolicitudesDeFondoAyudantesSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchFilesSolicitud({ payload: id }) {
    try {
      const response = yield call(getFilesSolicitudById, id);
        //console.log("response", response)
        //return false;

      const contentDisposition = response.headers["content-disposition"];
  
      let fileName = "descarga";
  
      if (contentDisposition) {
        contentDisposition.match(/filename="(.+)"|filename=(.+);/).forEach(element => {
          if (element && !element.includes("filename=")) {
            fileName = element
          }
        });
      }
  
      FileSaver.saveAs(response.data, fileName);
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

function* fetchCecoFondo({payload : params}){
    try {
        const response = yield call(getCecoSolicitudFondo, params)
        yield put(getCecoSolicitudFondoSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
 }  

function* addCecoFondo({payload: ceco}){
    try {
        const response = yield call(addNewCecoSolicitud, ceco)
        yield put(getCecoAdded(response.data.objCeco))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
} 

function* putCeco({payload: {ceco}}){
    try {
        const response = yield call(updateCecoSolicitud, ceco)
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchCecoByCodigo({payload: codigo}){
    try {
      const response = yield call(getCecoSolicitudByCodigo, codigo)
      yield put(getCecoSolicitudByCodigoSuccess(response.data))
    } catch (error) {
      // yield put(getEventsFail(error))
    }
  }

function* SolicitudesFondoSaga() {
    yield takeEvery(GET_TIPO_SOLICITUDES, fetchTipoSolicitudes)
    yield takeLatest(ADD_NEW_SOLICITUD_FONDO, addSolicitud)
    yield takeLatest(UPDATE_SOLICITUD_FONDO, putSolicitud)
    yield takeEvery(GET_SOLICITUDES_FONDO, fetchSolicitudes)   
    yield takeEvery(GET_SOLICITUDES_FONDO_POR_ACADEMICO, fetchSolicitudesPorAcademico)   
    yield takeEvery(GET_TIPO_ASIGNACION, fetchTipoAsignacion)   
    yield takeLatest(CAMBIA_ESTADO_SOLICITUD_FONDO, cambiaEstadoSolicitud)
    yield takeEvery(GET_TIPO_VIATICO, fetchTipoViatico)  
    yield takeEvery(GET_VIATICOPORPAIS, fetchViaticoPorPais) 
    yield takeEvery(GET_DIASVIAJE_SOLICITUD, fetchDiasViaje)   
    yield takeLatest(VALORIZA_SOLICITUD_FONDO, valorizaSolicitud)
    yield takeEvery(GET_SOLICITUD_HISTORIAL, fetchSolicitudHistorial)   
    yield takeEvery(GET_PPTO_INVESTIGACION_POR_ACADEMICO, fetchPptoInvestigacionByAcad)   
    yield takeEvery(GET_PPTO_ACADEMICO_SALDO_PENDIENTE, fetchPptoAcademicoSaldoPendiente)   
    yield takeLatest(GET_REPORTE_SOLICITUD_FONDOS, fetchReporteSolicitudesFondos)
    yield takeLatest(GET_REPORTE_PRESUPUESTO_INVESTIGACION, fetchReportePresupuestoInvestigacion)
    yield takeLatest(GET_REPORTE_FONDOS_INVESTIGACION, fetchReporteFondosInvestigacion)
    yield takeLatest(GET_ESTADO_SOLICITUD_FONDO, fetchEstadosSolicitudesFondo)
    yield takeLatest(GET_TIPO_FONDO_INVESTIGACION, fetchTipoFondoInvestigacion)
    yield takeLatest(GET_FONDOS_INVESTIGACION, fetchFondosInvestigacion)
    yield takeLatest(ADD_NEW_FONDO_INVESTIGACION, postNewFondoInvestigacion)
    yield takeLatest(UPDATE_ACADEMICOS_FONDO, cambiaAcademicosFondo)
    yield takeLatest(UPDATE_FONDO_INVESTIGACION, putFondoInvestigacion)
    yield takeLatest(DELETE_FONDO_INVESTIGACION, delFondoInvestigacion)
    yield takeLatest(UPDATE_VALORES_SOLICITUD_FONDO, putValoresSolicitud)
    yield takeEvery(GET_PPTO_INVESTIGACION_POR_DEPTO, fetchPptoInvestigacionPorDepto)   
    yield takeEvery(GET_PPTO_DEPTO_PENDIENTE, fetchPptoDeptoPendiente)
    yield takeEvery(GET_PPTO_ACADEMICO_SALDO_PENDIENTE_CONCILIA, fetchPptoAcademicoSaldoPendienteConcilia)   
    yield takeLatest(GET_REPORTE_SOLICITUD_FONDOS_AYUDANTES, fetchReporteSolicitudesFondosAyudantes)
    yield takeLatest(GET_FILES_SOLICITUD, fetchFilesSolicitud)
    yield takeEvery(GET_CECO_SOLICITUD_FONDO, fetchCecoFondo) 
    yield takeLatest(ADD_NEW_CECO_SOLICITUD_FONDO, addCecoFondo)      
    yield takeLatest(UPDATE_CECO_SOLICITUD_FONDO, putCeco)
    yield takeLatest(GET_CECO_SOLICITUD_BY_CODIGO, fetchCecoByCodigo)
}

export default SolicitudesFondoSaga