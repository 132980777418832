import { ADD_DOCUMENTOS_VIAJE, ADD_NEW_CARGA_CORPORATIVO, ADD_NEW_DESCUENTO_VIAJE, ADD_NEW_FINANCIAMIENTO_VIAJE, ADD_NEW_VIAJE_PARTICULAR, ENVIA_FACTURA_FINANCIAMIENTO, GET_CARGAS_CORPORATIVOS, GET_CARGAS_CORPORATIVOS_SUCCESS, GET_VIAJE, GET_VIAJES, GET_VIAJES_SUCCESS, GET_VISTA_PREVIA_VIAJE, UPDATE_ESTADO_VIAJE, UPDATE_VIAJE_DESCUENTO, UPDATE_VIAJE_FINANCIAMIENTO, UPDATE_VIAJE_FINANCIAMIENTO_FACTURA } from "./actionTypes";

export const addNewCargaCorporativo = (cargaCorporativo, file, callback) => ({
  type: ADD_NEW_CARGA_CORPORATIVO,
  payload: { cargaCorporativo, file, callback }
});

export const addNewViajeParticular = (viajeParticular, callback) => ({
  type: ADD_NEW_VIAJE_PARTICULAR,
  payload: { viajeParticular, callback }
});

export const addNewDescuentoViaje = (descuentoViaje, file, callback) => ({
  type: ADD_NEW_DESCUENTO_VIAJE,
  payload: { descuentoViaje, file, callback }
});

export const addNewFinanciamientoViaje = (financiamientoViaje, callback) => ({
  type: ADD_NEW_FINANCIAMIENTO_VIAJE,
  payload: { financiamientoViaje, callback }
});

export const addDocumentosViaje = (params, callback) => ({
  type: ADD_DOCUMENTOS_VIAJE,
  payload: { params, callback }
});

export const getCargasCorporativos = (params) => ({
  type: GET_CARGAS_CORPORATIVOS,
  payload: params
});

export const getCargasCorporativosSuccess = (cargasCorporativos) => ({
  type: GET_CARGAS_CORPORATIVOS_SUCCESS,
  payload: cargasCorporativos
});

export const getViajes = (params) => ({
  type: GET_VIAJES,
  payload: params
});

export const getViajesSuccess = (viajes) => ({
  type: GET_VIAJES_SUCCESS,
  payload: viajes
});

export const getViaje = (params, callback) => ({
  type: GET_VIAJE,
  payload: {params, callback}
});

export const getVistaPreviaViaje = (params) => ({
  type: GET_VISTA_PREVIA_VIAJE,
  payload: params
});

export const updateEstadoViaje = (viaje, callback) => ({
  type: UPDATE_ESTADO_VIAJE,
  payload: { viaje, callback }
});

export const updateViajeFinanciamiento = (viajeFinanciamiento, callback) => ({
  type: UPDATE_VIAJE_FINANCIAMIENTO,
  payload: { viajeFinanciamiento, callback }
});

export const updateViajeFinanciamientoFactura = (viajeFinanciamiento, file, callback) => ({
  type: UPDATE_VIAJE_FINANCIAMIENTO_FACTURA,
  payload: { viajeFinanciamiento, file, callback }
});

export const updateViajeDescuento = (viajeDescuento, callback) => ({
  type: UPDATE_VIAJE_DESCUENTO,
  payload: { viajeDescuento, callback }
});

export const enviaFacturaFinanciamiento = (financiamientoId, callback) => ({
  type: ENVIA_FACTURA_FINANCIAMIENTO,
  payload: { financiamientoId, callback }
});