import { GET_VALOR_DOLAR_SUCCESS } from "./actionsTypes";

const INIT_STATE = {
  valorDolar: null,
}

const Monedas = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_VALOR_DOLAR_SUCCESS:
      return {
        ...state,
        valorDolar: action.payload.data,
      }
    default:
      return state
  }
}

export default Monedas;