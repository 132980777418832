export const GET_ESTUDIANTES = "GET_ESTUDIANTES";
export const GET_ESTUDIANTES_SUCCESS = "GET_ESTUDIANTES_SUCCESS";
export const GET_ESTUDIOS_ESTUDIANTE = "GET_ESTUDIOS_ESTUDIANTE";
export const GET_ESTUDIOS_ESTUDIANTE_SUCCESS = "GET_ESTUDIOS_ESTUDIANTE_SUCCESS";
export const GET_LABORAL_ESTUDIANTE = "GET_LABORAL_ESTUDIANTE";
export const GET_LABORAL_ESTUDIANTE_SUCCESS = "GET_LABORAL_ESTUDIANTE_SUCCESS";
export const GET_ESTUDIANTE_BY_DOCUMENTO = "GET_ESTUDIANTE_BY_DOCUMENTO";
export const GET_ESTUDIANTE_BY_DOCUMENTO_SUCCESS = "GET_ESTUDIANTE_BY_DOCUMENTO_SUCCESS";
export const GET_INFO_ESTUDIANTE_CARGA_CORPORATIVOS = "GET_INFO_ESTUDIANTE_CARGA_CORPORATIVOS";
export const GET_INFO_ESTUDIANTE_CARGA_CORPORATIVOS_SUCCESS = "GET_INFO_ESTUDIANTE_CARGA_CORPORATIVOS_SUCCESS";
export const GET_VALIDA_ESTUDIANTE_VIAJE_PROGRAMA = "GET_VALIDA_ESTUDIANTE_VIAJE_PROGRAMA";
export const GET_VALIDA_ESTUDIANTE_VIAJE_PROGRAMA_SUCCESS = "GET_VALIDA_ESTUDIANTE_VIAJE_PROGRAMA_SUCCESS";
export const ADD_NEW_ESTUDIANTE = "ADD_NEW_ESTUDIANTE";
export const ADD_NEW_ESTUDIO_ESTUDIANTE = "ADD_NEW_ESTUDIO_ESTUDIANTE";
export const ADD_NEW_LABORAL_ESTUDIANTE = "ADD_NEW_LABORAL_ESTUDIANTE";
export const UPDATE_ESTUDIANTE = "UPDATE_ESTUDIANTE";
export const DELETE_ESTUDIO_ESTUDIANTE = "DELETE_ESTUDIO_ESTUDIANTE";
export const GET_ESTUDIANTE = "GET_ESTUDIANTE";
export const GET_ESTUDIANTE_SUCCESS = "GET_ESTUDIANTE_SUCCESS";
export const GET_VIAJES_EN_CURSO_ESTUDIANTE = "GET_VIAJES_EN_CURSO_ESTUDIANTE";
export const GET_VIAJES_EN_CURSO_ESTUDIANTE_SUCCESS = "GET_VIAJES_EN_CURSO_ESTUDIANTE_SUCCESS";
export const GET_MODULOS_VIAJE = "GET_MODULOS_VIAJE";
export const GET_MODULOS_VIAJE_SUCCESS = "GET_MODULOS_VIAJE_SUCCESS";
export const GET_NOTAS_ESTUDIANTE_MODULO = "GET_NOTAS_ESTUDIANTE_MODULO";
export const GET_NOTAS_ESTUDIANTE_MODULO_SUCCESS = "GET_NOTAS_ESTUDIANTE_MODULO_SUCCESS";
export const GET_CERTIFICADO_ALUMNO_REGULAR = "GET_CERTIFICADO_ALUMNO_REGULAR";
export const GET_CERTIFICADO_ALUMNO_REGULAR_SUCCESS = "GET_CERTIFICADO_ALUMNO_REGULAR_SUCCESS";
export const RESET_CERTIFICADO = "RESET_CERTIFICADO";
export const RESET_ESTUDIANTE = "RESET_ESTUDIANTE";
export const RESET_ESTUDIANTE_BY_DOCUMENTO = "RESET_ESTUDIANTE_BY_DOCUMENTO";
export const GET_CERTIFICADO_ALUMNO_NOTAS = "GET_CERTIFICADO_ALUMNO_NOTAS";
export const GET_CERTIFICADO_ALUMNO_NOTAS_SUCCESS = "GET_CERTIFICADO_ALUMNO_NOTAS_SUCCESS";
export const GET_CERTIFICADO_ALUMNO_REGULAR_INTERNO = "GET_CERTIFICADO_ALUMNO_REGULAR_INTERNO";
export const GET_CERTIFICADO_ALUMNO_REGULAR_INTERNO_SUCCESS = "GET_CERTIFICADO_ALUMNO_REGULAR_INTERNO_SUCCESS";
export const GET_ESTUDIANTE_EXTERNO = "GET_ESTUDIANTE_EXTERNO";
export const GET_ESTUDIANTE_EXTERNO_SUCCESS = "GET_ESTUDIANTE_EXTERNO_SUCCESS";
export const GET_ASISTENCIA_ESTUDIANTE_MODULO = "GET_ASISTENCIA_ESTUDIANTE_MODULO";
export const GET_ASISTENCIA_ESTUDIANTE_MODULO_SUCCESS = "GET_ASISTENCIA_ESTUDIANTE_MODULO_SUCCESS";

