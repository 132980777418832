import {

    GET_FACTURABYRANGO,
    GET_FACTURABYRANGO_SUCCESS,

    GET_FACTURABYNUMFACTURA,
    GET_FACTURABYNUMFACTURA_SUCCESS,

    GET_FACTURAMOVIMIENTOSBYNUMFACTURA,
    GET_FACTURAMOVIMIENTOSBYNUMFACTURA_SUCCESS,

    GET_FACTURACUOTASBYNUMFACTURA,
    GET_FACTURACUOTASBYNUMFACTURA_SUCCESS,

    GET_FACTURAHISTORICOMEDIOPAGOSBYNUMFACTURA,
    GET_FACTURAHISTORICOMEDIOPAGOSBYNUMFACTURA_SUCCESS,

    GET_LISTAESTADOFACTURA,
    GET_LISTAESTADOFACTURA_SUCCESS,

    GET_LISTASUBESTADOFACTURA,
    GET_LISTASUBESTADOFACTURA_SUCCESS,

    GET_LISTATIPOSEGUIMIENTOMANUAL,
    GET_LISTATIPOSEGUIMIENTOMANUAL_SUCCESS,

    GET_LISTAORGANISMOS,
    GET_LISTAORGANISMOS_SUCCESS,

    GET_LISTABANCOS,
    GET_LISTABANCOS_SUCCESS,

    GET_LISTACUENTACORRIENTE,
    GET_LISTACUENTACORRIENTE_SUCCESS,

    GET_SEGUIMIENTOSFACTURABYNUMFACTURA,
    GET_SEGUIMIENTOSFACTURABYNUMFACTURA_SUCCESS,

    GET_MONTOSFACTURASDASHBOARD,
    GET_MONTOSFACTURASDASHBOARD_SUCCESS,

    GET_MONTOSFACTURASPAGADASCONMEDIODEPAGODASHBOARD,
    GET_MONTOSFACTURASPAGADASCONMEDIODEPAGODASHBOARD_SUCCESS,

    GET_MONTOSFACTURASMOROSASSINMEDIODEPAGODASHBOARD,
    GET_MONTOSFACTURASMOROSASSINMEDIODEPAGODASHBOARD_SUCCESS,

    GET_FACTURASEMITIDASDASHBOARDPORRANGOFECHAS,
    GET_FACTURASEMITIDASDASHBOARDPORRANGOFECHAS_SUCCESS,

    GET_FACTURASEMITIDASPAGADASDASHBOARDPORRANGOFECHAS,
    GET_FACTURASEMITIDASPAGADASDASHBOARDPORRANGOFECHAS_SUCCESS,

    GET_FACTURASEMITIDASVERSUSCOMPORTAMIENTODEPAGODASHBOARDPORRANGOFECHAS,
    GET_FACTURASEMITIDASVERSUSCOMPORTAMIENTODEPAGODASHBOARDPORRANGOFECHAS_SUCCESS,

    GET_GESTIONCOBRANZAFACTURASMOROSASPORRANGOFECHAS,
    GET_GESTIONCOBRANZAFACTURASMOROSASPORRANGOFECHAS_SUCCESS,

    ADD_GESTIONSEGUIMIENTOCOBRANZA,
    ADD_GESTIONSEGUIMIENTOCOBRANZA_ADDED,

} from './actionTypes'

const INIT_STATE = {
    facturasArray: [],
    paginacion: {},
    paginacionMov: {},
    paginacionCuot: {},
    paginacionHist: {},
    paginacionSeg: {},
    factInfoGeneral: {},
    facturaMovimientos: [],
    facturaCuotas: [],
    facturaHistory: [],
    listaEstadofacturas: [],
    listaSubEstadofacturas: [],
    listaTipoSeguimientoManual: [],
    listaOrganismos: [],
    listaBancos: [],
    listaCuentaCorriente: [],
    listaSeguimientos: [],
    montosFacturasDashboard: {},
    resultGestionCobranza: "",
    listaMontosFacturasMntoPagDashboard: [],
    listaMontosFacturasMorosasSinMedioPagoDashboard:[],
    listaFacturasEmitidasDashboard: [],
    listaFacturasEmitidasPagadasDashboard: [],
    listaFacturasEmitidasVersusComportamientoPagoDashboard: [],
    listaGestionCobranzaFacturasMorosas:[],
    error: "",
    loading: false
}

const Cobranza = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_FACTURABYRANGO:
            return {
                ...state,
                loading: true
            }
        case GET_FACTURABYRANGO_SUCCESS:
            return {
                ...state,
                loading: false,
                facturasArray: action.payload.data,
                paginacion: JSON.parse(action.payload.headers["x-pagination"]),
            };
        case GET_FACTURABYNUMFACTURA:
            return {
                ...state,
                loading: true,

            }
        case GET_FACTURABYNUMFACTURA_SUCCESS:
            return {
                ...state,
                loading: false,
                factInfoGeneral: action.payload.data,
            };
        case GET_FACTURAMOVIMIENTOSBYNUMFACTURA:
            return {
                ...state,
                loading: true
            }
        case GET_FACTURAMOVIMIENTOSBYNUMFACTURA_SUCCESS:
            return {
                ...state,
                loading: false,
                facturaMovimientos: action.payload.data,
                paginacionMov: JSON.parse(action.payload.headers["x-pagination"]),
            };
        case GET_FACTURACUOTASBYNUMFACTURA:
            return {
                ...state,
                loading: true
            }
        case GET_FACTURACUOTASBYNUMFACTURA_SUCCESS:
            return {
                ...state,
                loading: false,
                facturaCuotas: action.payload.data,
                paginacionCuot: JSON.parse(action.payload.headers["x-pagination"]),
            };
        case GET_FACTURAHISTORICOMEDIOPAGOSBYNUMFACTURA:
            return {
                ...state,
                loading: true
            }
        case GET_FACTURAHISTORICOMEDIOPAGOSBYNUMFACTURA_SUCCESS:
            return {
                ...state,
                loading: false,
                facturaHistory: action.payload.data,
                paginacionHist: JSON.parse(action.payload.headers["x-pagination"]),
            };
        case GET_LISTAESTADOFACTURA:
            return {
                ...state,
                loading: true
            }
        case GET_LISTAESTADOFACTURA_SUCCESS:
            return {
                ...state,
                loading: false,
                listaEstadofacturas: action.payload.data,
            };
        case GET_LISTASUBESTADOFACTURA:
            return {
                ...state,
                loading: true
            }
        case GET_LISTASUBESTADOFACTURA_SUCCESS:
            return {
                ...state,
                loading: false,
                listaSubEstadofacturas: action.payload.data,
            };
        case GET_LISTATIPOSEGUIMIENTOMANUAL:
            return {
                ...state,
                loading: true
            }
        case GET_LISTATIPOSEGUIMIENTOMANUAL_SUCCESS:
            return {
                ...state,
                loading: false,
                listaTipoSeguimientoManual: action.payload.data,
            };
        case GET_LISTAORGANISMOS:
            return {
                ...state,
                loading: true
            }
        case GET_LISTAORGANISMOS_SUCCESS:
            return {
                ...state,
                loading: false,
                listaOrganismos: action.payload.data,
            };
        case GET_LISTABANCOS:
            return {
                ...state,
                loading: true
            }
        case GET_LISTABANCOS_SUCCESS:
            return {
                ...state,
                loading: false,
                listaBancos: action.payload.data,
            };
        case GET_LISTACUENTACORRIENTE:
            return {
                ...state,
                loading: true
            }
        case GET_LISTACUENTACORRIENTE_SUCCESS:
            return {
                ...state,
                loading: false,
                listaCuentaCorriente: action.payload.data,
            };
        case GET_SEGUIMIENTOSFACTURABYNUMFACTURA:
            return {
                ...state,
                loading: true
            }
        case GET_SEGUIMIENTOSFACTURABYNUMFACTURA_SUCCESS:
            return {
                ...state,
                loading: false,
                listaSeguimientos: action.payload.data,
                paginacionSeg: JSON.parse(action.payload.headers["x-pagination"]),
            };
        case GET_MONTOSFACTURASDASHBOARD:
            return {
                ...state,
                loading: true
            }
        case GET_MONTOSFACTURASDASHBOARD_SUCCESS:
            return {
                ...state,
                loading: false,
                montosFacturasDashboard: action.payload.data,
            };
        case GET_MONTOSFACTURASPAGADASCONMEDIODEPAGODASHBOARD:
            return {
                ...state,
                loading: true
            }
        case GET_MONTOSFACTURASPAGADASCONMEDIODEPAGODASHBOARD_SUCCESS:
            return {
                ...state,
                loading: false,
                listaMontosFacturasMntoPagDashboard: action.payload.data,
            };
        case GET_MONTOSFACTURASMOROSASSINMEDIODEPAGODASHBOARD:
                return {
                    ...state,
                    loading: true
                }
        case GET_MONTOSFACTURASMOROSASSINMEDIODEPAGODASHBOARD_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    listaMontosFacturasMorosasSinMedioPagoDashboard: action.payload.data,
                };    
        case GET_FACTURASEMITIDASDASHBOARDPORRANGOFECHAS:
            return {
                ...state,
                loading: true
            }
        case GET_FACTURASEMITIDASDASHBOARDPORRANGOFECHAS_SUCCESS:
            return {
                ...state,
                loading: false,
                listaFacturasEmitidasDashboard: action.payload.data,
            };
        case GET_FACTURASEMITIDASPAGADASDASHBOARDPORRANGOFECHAS:
            return {
                ...state,
                loading: true
            }
        case GET_FACTURASEMITIDASPAGADASDASHBOARDPORRANGOFECHAS_SUCCESS:
            return {
                ...state,
                loading: false,
                listaFacturasEmitidasPagadasDashboard: action.payload.data,
            };
        case GET_FACTURASEMITIDASVERSUSCOMPORTAMIENTODEPAGODASHBOARDPORRANGOFECHAS:
                return {
                    ...state,
                    loading: true
                }
        case GET_FACTURASEMITIDASVERSUSCOMPORTAMIENTODEPAGODASHBOARDPORRANGOFECHAS_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    listaFacturasEmitidasVersusComportamientoPagoDashboard: action.payload.data,
                };

        case GET_GESTIONCOBRANZAFACTURASMOROSASPORRANGOFECHAS:
                    return {
                        ...state,
                        loading: true
                    }
        case GET_GESTIONCOBRANZAFACTURASMOROSASPORRANGOFECHAS_SUCCESS:
                    return {
                        ...state,
                        loading: false,
                        listaGestionCobranzaFacturasMorosas: action.payload.data,
                    };                
        case ADD_GESTIONSEGUIMIENTOCOBRANZA:
            return {
                ...state,
                loading: true
            }
        case ADD_GESTIONSEGUIMIENTOCOBRANZA_ADDED:
            return {
                ...state,
                loading: false,
                resultGestionCobranza: action.payload.data,
            };
        default:
            return state
    }
}

export default Cobranza
