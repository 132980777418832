import {

    GET_SESIONES_ALUMNOS,
    GET_SESIONES_ALUMNOS_SUCCESS,

    GET_SESIONES_ACADEMICOS,
    GET_SESIONES_ACADEMICOS_SUCCESS,

    GET_LISTASESIONESACADEMICOSPORRANGOFECHAS,
    GET_LISTASESIONESACADEMICOSPORRANGOFECHAS_SUCCESS,

    GET_DIAS_SESIONES,
    GET_DIAS_SESIONES_SUCCESS,

    DELETE_SESIONES,

} from './actionTypes'


const INIT_STATE = {
    listaSesionesAlumnos: [],
    listaSesionesAcademicos: [],
    listaSesionesAcademicosAll:[],
    diasSesiones: [],
    paginacion: {},
    error: "",
    loading: false
}

const Sesiones = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_SESIONES_ALUMNOS:
            return {
                ...state,
                loading: true
            }
        case GET_SESIONES_ALUMNOS_SUCCESS:
            return {
                ...state,
                loading: false,
                listaSesionesAlumnos: action.payload.data,
            };
        case GET_SESIONES_ACADEMICOS:
            return {
                ...state,
                loading: true
            }
        case GET_SESIONES_ACADEMICOS_SUCCESS:
            return {
                ...state,
                loading: false,
                listaSesionesAcademicos: action.payload.data,
            };
        case GET_LISTASESIONESACADEMICOSPORRANGOFECHAS:
            return {
                ...state,
                loading: true
            }
        case GET_LISTASESIONESACADEMICOSPORRANGOFECHAS_SUCCESS:
            return {
                ...state,
                loading: false,
                listaSesionesAcademicosAll: action.payload.data,
                paginacion: JSON.parse(action.payload.headers["x-pagination"]),
            };
        case GET_DIAS_SESIONES:
            return {
                ...state,
                loading: true
            }
        case GET_DIAS_SESIONES_SUCCESS:
            return {
                ...state,
                loading: false,
                diasSesiones: action.payload.data,
            };            
        case DELETE_SESIONES:
            return {
                ...state,
            }                        
        default:
            return state
    }
}

export default Sesiones