import {
    GET_TIPO_SOLICITUDES, GET_TIPO_SOLICITUDES_SUCCESS, ADD_NEW_SOLICITUD_FONDO, UPDATE_SOLICITUD_FONDO, 
    GET_SOLICITUDES_FONDO, 
    GET_SOLICITUDES_FONDO_SUCCESS, GET_SOLICITUDES_FONDO_POR_ACADEMICO, GET_SOLICITUDES_FONDO_POR_ACADEMICO_SUCCESS,
    GET_TIPO_ASIGNACION, GET_TIPO_ASIGNACION_SUCCESS, CAMBIA_ESTADO_SOLICITUD_FONDO, GET_TIPO_VIATICO, GET_TIPO_VIATICO_SUCCESS,
    GET_VIATICOPORPAIS, GET_VIATICOPORPAIS_SUCCESS, ADD_DIASVIAJE_SOLICITUD, GET_DIASVIAJE_SOLICITUD, GET_DIASVIAJE_SOLICITUD_SUCCESS, RESET_DIASVIAJE_SOLICITUD,
    VALORIZA_SOLICITUD_FONDO, GET_SOLICITUD_HISTORIAL, GET_SOLICITUD_HISTORIAL_SUCCESS, GET_PPTO_INVESTIGACION_POR_ACADEMICO, GET_PPTO_INVESTIGACION_POR_ACADEMICO_SUCCESS,
    RESET_PPTOS_INVESTIGACION, GET_PPTO_ACADEMICO_SALDO_PENDIENTE, GET_PPTO_ACADEMICO_SALDO_PENDIENTE_SUCCESS ,     
    GET_REPORTE_SOLICITUD_FONDOS,
    GET_REPORTE_SOLICITUD_FONDOS_SUCCESS,
    GET_REPORTE_PRESUPUESTO_INVESTIGACION,
    GET_REPORTE_PRESUPUESTO_INVESTIGACION_SUCCESS,
    GET_REPORTE_FONDOS_INVESTIGACION,
    GET_REPORTE_FONDOS_INVESTIGACION_SUCCESS,
    GET_ESTADO_SOLICITUD_FONDO,
    GET_ESTADO_SOLICITUD_FONDO_SUCCESS,
    GET_TIPO_FONDO_INVESTIGACION,
    GET_TIPO_FONDO_INVESTIGACION_SUCCESS,
    GET_FONDOS_INVESTIGACION,
    GET_FONDOS_INVESTIGACION_SUCCESS,
    ADD_NEW_FONDO_INVESTIGACION,
    UPDATE_ACADEMICOS_FONDO,
    UPDATE_FONDO_INVESTIGACION,
    DELETE_FONDO_INVESTIGACION, RESET_VIATICOPORPAIS, UPDATE_VALORES_SOLICITUD_FONDO, GET_PPTO_INVESTIGACION_POR_DEPTO, 
    GET_PPTO_INVESTIGACION_POR_DEPTO_SUCCESS, GET_PPTO_DEPTO_PENDIENTE, GET_PPTO_DEPTO_PENDIENTE_SUCCESS, GET_PPTO_ACADEMICO_SALDO_PENDIENTE_CONCILIA, 
    GET_PPTO_ACADEMICO_SALDO_PENDIENTE_CONCILIA_SUCCESS,  GET_REPORTE_SOLICITUD_FONDOS_AYUDANTES, GET_REPORTE_SOLICITUD_FONDOS_AYUDANTES_SUCCESS,
    GET_FILES_SOLICITUD, GET_FILES_SOLICITUD_SUCCESS, GET_CECO_SOLICITUD_FONDO, GET_CECO_SOLICITUD_FONDO_SUCCESS, ADD_NEW_CECO_SOLICITUD_FONDO,
    UPDATE_CECO_SOLICITUD_FONDO, GET_CECOADDED, RESET_CECO_SOLICITUD, GET_CECO_SOLICITUD_BY_CODIGO, GET_CECO_SOLICITUD_BY_CODIGO_SUCCESS
} from './actionTypes'

export const getTipoSolicitudes = () => ({
  type: GET_TIPO_SOLICITUDES,
});

export const getTipoSolicitudesSuccess = (params) => ({
  type: GET_TIPO_SOLICITUDES_SUCCESS,
  payload: params 
});

export const addNewSolicitudFondo = (solicitud, params, formData, formData1 = null, tipoTransac = null) => ({
  type: ADD_NEW_SOLICITUD_FONDO,
  payload: {solicitud, params, formData, formData1, tipoTransac},
});

export const updateSolicitudFondo = (solicitud, params, formData, formData1 = null, acad = false, tipoTransac = null) => ({
  type: UPDATE_SOLICITUD_FONDO,
  payload: {solicitud, params, formData, formData1, acad, tipoTransac}
});

export const getSolicitudesDeFondo = (params, filtrarPendientes = false) => ({
  type: GET_SOLICITUDES_FONDO,
  payload: {params, filtrarPendientes}
});

export const getSolicitudesDeFondoSuccess = (params) => ({
    type: GET_SOLICITUDES_FONDO_SUCCESS,
    payload: params
});

export const getSolicitudesDeFondoPorAcademico = (params) => ({
  type: GET_SOLICITUDES_FONDO_POR_ACADEMICO,
  payload: params
});

export const getSolicitudesDeFondoPorAcademicoSuccess = (params) => ({
    type: GET_SOLICITUDES_FONDO_POR_ACADEMICO_SUCCESS,
    payload: params
});

export const getTipoAsignacion = (params) => ({
  type: GET_TIPO_ASIGNACION,
  payload: params
});

export const getTipoAsignacionSuccess = (params) => ({
    type: GET_TIPO_ASIGNACION_SUCCESS,
    payload: params 
});

export const cambiaEstadoSolicitudFondo = (obj, params, acad = false) => ({
  type: CAMBIA_ESTADO_SOLICITUD_FONDO,
  payload: {obj, params, acad},
});

export const getTipoViatico = (params) => ({
  type: GET_TIPO_VIATICO,
  payload: params
});

export const getTipoViaticoSuccess = (params) => ({
    type: GET_TIPO_VIATICO_SUCCESS,
    payload: params 
});

export const getViaticoPorPais = (params) => ({
  type: GET_VIATICOPORPAIS,
  payload: params
});
export const getViaticoPorPaisSuccess = (params) => ({
  type: GET_VIATICOPORPAIS_SUCCESS,
  payload: params
});
export const addDiasViajeSolicitud = (data) => ({
  type: ADD_DIASVIAJE_SOLICITUD,
  payload: {data},
});

export const getDiasViajeSolicitud = (params) => ({
  type: GET_DIASVIAJE_SOLICITUD,
  payload: params
});

export const getDiasViajeSolicitudSuccess = (params) => ({
    type: GET_DIASVIAJE_SOLICITUD_SUCCESS,
    payload: params
});

export const resetDiasViajeSolicitud = () => ({
  type: RESET_DIASVIAJE_SOLICITUD,
});

export const valorizaSolicitudFondo = (solicitud, params, formData, formData1, newEstado) => ({
  type: VALORIZA_SOLICITUD_FONDO,
  payload: {solicitud, params, formData, formData1, newEstado}
});

export const getSolicitudHistorial = (params) => ({
  type: GET_SOLICITUD_HISTORIAL,
  payload: params
});

export const getSolicitudHistorialSuccess = (params) => ({
    type: GET_SOLICITUD_HISTORIAL_SUCCESS,
    payload: params
});

export const getPptoInvestigacionPorAcademico = (params) => ({
  type: GET_PPTO_INVESTIGACION_POR_ACADEMICO,
  payload: params
});

export const getPptoInvestigacionPorAcademicoSuccess = (params) => ({
    type: GET_PPTO_INVESTIGACION_POR_ACADEMICO_SUCCESS,
    payload: params
});

export const resetPptosInvestigacion = () => ({
  type: RESET_PPTOS_INVESTIGACION,
});

export const getPptoAcademicoSaldoPendiente = (params) => ({
  type: GET_PPTO_ACADEMICO_SALDO_PENDIENTE,
  payload: params
});

export const getPptoAcademicoSaldoPendienteSuccess = (params) => ({
    type: GET_PPTO_ACADEMICO_SALDO_PENDIENTE_SUCCESS,
    payload: params
});


export const getReporteSolicitudesDeFondo = (params) => ({
  type: GET_REPORTE_SOLICITUD_FONDOS,
  payload: params
});

export const getReporteSolicitudesDeFondoSuccess = (data) => ({
  type: GET_REPORTE_SOLICITUD_FONDOS_SUCCESS,
  payload: data
});

export const getReportePresupuestoInvestigacion = () => ({
  type: GET_REPORTE_PRESUPUESTO_INVESTIGACION
});

export const getReportePresupuestoInvestigacionSuccess = (data) => ({
  type: GET_REPORTE_PRESUPUESTO_INVESTIGACION_SUCCESS,
  payload: data
});

export const getReporteFondosInvestigacion = () => ({
  type: GET_REPORTE_FONDOS_INVESTIGACION
});

export const getReporteFondosInvestigacionSuccess = (data) => ({
  type: GET_REPORTE_FONDOS_INVESTIGACION_SUCCESS,
  payload: data
});

export const getEstadoSolicitudFondo = () => ({
  type: GET_ESTADO_SOLICITUD_FONDO
});

export const getEstadoSolicitudFondoSuccess = (data) => ({
  type: GET_ESTADO_SOLICITUD_FONDO_SUCCESS,
  payload: data
});

export const getTipoFondoInvestigacion = () => ({
  type: GET_TIPO_FONDO_INVESTIGACION
});

export const getTipoFondoInvestigacionSuccess = (data) => ({
  type: GET_TIPO_FONDO_INVESTIGACION_SUCCESS,
  payload: data
});

export const getFondosInvestigacion = (params) => ({
  type: GET_FONDOS_INVESTIGACION,
  payload: params
});

export const getFondosInvestigacionSuccess = (data) => ({
  type: GET_FONDOS_INVESTIGACION_SUCCESS,
  payload: data
});

export const addNewFondoInvestigacion = (fondoInvestigacion, fetchData) => ({
  type: ADD_NEW_FONDO_INVESTIGACION,
  payload: {fondoInvestigacion, fetchData},
});

export const updateAcademicosFondo = (data) => ({
  type: UPDATE_ACADEMICOS_FONDO,
  payload: data,
});

export const UpdateFondoInvestigacion = (id, data) => ({
  type: UPDATE_FONDO_INVESTIGACION,
  payload: {id, data},
});

export const deleteFondoInvestigacion = (id) => ({
  type: DELETE_FONDO_INVESTIGACION,
  payload: id,
});

export const resetViaticoPorPais = () => ({
  type: RESET_VIATICOPORPAIS,
});

export const updateValoresSolicitudFondo = (obj, params, formData) => ({
  type: UPDATE_VALORES_SOLICITUD_FONDO,
  payload: {obj, params, formData},
});

export const getPptoInvestigacionPorDepto = (params) => ({
  type: GET_PPTO_INVESTIGACION_POR_DEPTO,
  payload: params
});

export const getPptoInvestigacionPorDeptoSuccess = (params) => ({
    type: GET_PPTO_INVESTIGACION_POR_DEPTO_SUCCESS,
    payload: params
});

export const getPptoDeptoPendiente = (params) => ({
  type: GET_PPTO_DEPTO_PENDIENTE,
  payload: params
});

export const getPptoDeptoPendienteSuccess = (params) => ({
  type: GET_PPTO_DEPTO_PENDIENTE_SUCCESS,
  payload: params
});

export const getPptoAcademicoSaldoPendienteConcilia = (params) => ({
  type: GET_PPTO_ACADEMICO_SALDO_PENDIENTE_CONCILIA,
  payload: params
});

export const getPptoAcademicoSaldoPendienteConciliaSuccess = (params) => ({
    type: GET_PPTO_ACADEMICO_SALDO_PENDIENTE_CONCILIA_SUCCESS,
    payload: params
});

export const getReporteSolicitudesDeFondoAyudantes = (params) => ({
  type: GET_REPORTE_SOLICITUD_FONDOS_AYUDANTES,
  payload: params
});

export const getReporteSolicitudesDeFondoAyudantesSuccess = (data) => ({
  type: GET_REPORTE_SOLICITUD_FONDOS_AYUDANTES_SUCCESS,
  payload: data
});

export const getFilesSolicitud = (params) => ({
  type: GET_FILES_SOLICITUD,
  payload: params
});

export const getCecoSolicitudFondo = (params) => ({
  type: GET_CECO_SOLICITUD_FONDO,
  payload: params
});

export const getCecoSolicitudFondoSuccess = (params) => ({
  type: GET_CECO_SOLICITUD_FONDO_SUCCESS,
  payload: params
});

export const addNewCecoSolicitudFondo = (ceco) => ({
  type: ADD_NEW_CECO_SOLICITUD_FONDO,
  payload: ceco,
});

export const updateCecoSolicitudFondo = (ceco) => ({
  type: UPDATE_CECO_SOLICITUD_FONDO,
  payload: {ceco}
});

export const getCecoAdded = (params) => ({
  type: GET_CECOADDED,
  payload: params
});

export const resetCeco = () => ({
  type: RESET_CECO_SOLICITUD,
});

export const getCecoSolicitudByCodigo = (params) => ({
  type: GET_CECO_SOLICITUD_BY_CODIGO,
  payload: params
});

export const getCecoSolicitudByCodigoSuccess = (params) => ({
  type: GET_CECO_SOLICITUD_BY_CODIGO_SUCCESS,
  payload: params
});