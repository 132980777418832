import { takeEvery, fork, put, all, call, takeLatest } from "redux-saga/effects";

// Login Redux States
import { ADD_NEW_MAILING_PICTURE, ADD_NEW_PROFILE_PICTURE, DELETE_MAILING_PICTURE, DELETE_PROFILE_PICTURE, EDIT_PROFILE, GET_NOTIFICACIONES, GET_USERS_AVATARS, PROFILE_DATA, PROFILE_MENU, PROFILE_USER_INFO, UPDATE_NOTIFICACION, UPDATE_USER_INFO } from "./actionTypes";
import { profileSuccess, profileError, profileMenuSuccess, getNotificacionesSuccess, getUsersAvatarsSuccess, profileDataSuccess, profileUserInfoSuccess, addNewProfilePictureSuccess, updateUserInfoSuccess, addNewMailingPictureSuccess } from "./actions";
import {
  addNewMailingPicture,
  addNewProfilePicture,
  deleteMailingPicture,
  deleteProfilePicture,
  getNotificacionesUser,
  getProfileInfo,
  getProfileMenu,
  getUsersAvatars,
  updateNotificacionUser,
  updateUserInfo
} from "../../../helpers/backend_helper";
import { app } from "../../../config";

function* editProfile({ payload: { user } }) {

  try {
    // if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
    //   const response = yield call(
    //     fireBaseBackend.editProfileAPI,
    //     user.username,
    //     user.idx
    //   );
    //   yield put(profileSuccess(response));
    // } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
    //   const response = yield call(postJwtProfile, "/post-jwt-profile", {
    //     username: user.username,
    //     idx: user.idx,
    //   });
    //   yield put(profileSuccess(response));
    // } else if (process.env.REACT_APP_API_URL) {
    //   const response = yield call(postFakeProfile, user);
    //   yield put(profileSuccess(response));
    // }
  } catch (error) {
    yield put(profileError(error));
  }
}

function* fetchProfileMenu() {
  try {
    const response = yield call(getProfileMenu);
    yield put(profileMenuSuccess(response.data));
  } catch (error) {
    if (error === "Error en la petición al servidor" || error === "Error al leer el token de autorización") {
      const returnUrl = window.location.protocol + "//" + window.location.host + app.path + "login"
      localStorage.removeItem('version-update-needed')
      localStorage.removeItem("authUser");
      window.location.replace(returnUrl)
    }
    yield put(profileError(error));
  }
}

function* fetchNotificacionesUsuario() {
  try {
    const response = yield call(getNotificacionesUser);
    yield put(getNotificacionesSuccess(response.data));
  } catch (error) {
    console.log(error)
  }
}

function* fetchUsersAvatars({payload: users}) {
  try {
    const response = yield call(getUsersAvatars, users);
    yield put(getUsersAvatarsSuccess(response.data));
  } catch (error) {
    console.log(error)
  }
}

function* updateNotificacionUsuario({ payload: id }) {
  try {
    const response = yield call(updateNotificacionUser, id);
  } catch (error) {
    console.log(error)
  }
}

function* fetchProfileUserInfo() {
  try {
    const response = yield call(getProfileInfo);
    yield put(profileUserInfoSuccess(response.data.user));
    yield put(addNewProfilePictureSuccess(response.data.avatar));
    yield put(addNewMailingPictureSuccess(response.data.imgMailing));
  } catch (error) {
    yield put(profileError(error));
  }
}

function* postNewProfilePicture({ payload: picture }) {
  try {
    const response = yield call(addNewProfilePicture, picture);
    const file = picture.get('FileDetails');
    const base64Promise = new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(file);
    });

    const base64Result = yield call(() => base64Promise);
    yield put(addNewProfilePictureSuccess(base64Result));
  } catch (error) {
    console.log(error)
  }
}

function* postNewMailingPicture({ payload: picture }) {
  try {
    const response = yield call(addNewMailingPicture, picture);
    const file = picture.get('FileDetails');
    const base64Promise = new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(file);
    });

    const base64Result = yield call(() => base64Promise);
    yield put(addNewMailingPictureSuccess(base64Result));
  } catch (error) {
    console.log(error)
  }
}

function* putUserInfo({ payload: user }) {
  try {
    const response = yield call(updateUserInfo, user);
    yield put(profileUserInfoSuccess(response.data.user));
    yield put(addNewProfilePictureSuccess(response.data.avatar));
  } catch (error) {
    console.log(error)
  }
}

function* removeProfilePicture() {
  try {
    const response = yield call(deleteProfilePicture);
    yield put(profileUserInfoSuccess(response.data.user));
    yield put(addNewProfilePictureSuccess(response.data.avatar));
  } catch (error) {
    console.log(error)
  }
}

function* removeMailingPicture() {
  try {
    const response = yield call(deleteMailingPicture);
    yield put(profileUserInfoSuccess(response.data.user));
    yield put(addNewMailingPictureSuccess(response.data.imgMailing));
  } catch (error) {
    console.log(error)
  }
}

export function* watchProfile() {
  yield takeLatest(EDIT_PROFILE, editProfile);
  yield takeLatest(PROFILE_MENU, fetchProfileMenu);
  yield takeLatest(GET_USERS_AVATARS, fetchUsersAvatars)
  yield takeLatest(GET_NOTIFICACIONES, fetchNotificacionesUsuario)
  yield takeLatest(PROFILE_USER_INFO, fetchProfileUserInfo);
  yield takeLatest(ADD_NEW_PROFILE_PICTURE, postNewProfilePicture)
  yield takeLatest(ADD_NEW_MAILING_PICTURE, postNewMailingPicture)
  yield takeEvery(UPDATE_NOTIFICACION, updateNotificacionUsuario)
  yield takeLatest(UPDATE_USER_INFO, putUserInfo)
  yield takeLatest(DELETE_PROFILE_PICTURE, removeProfilePicture)
  yield takeLatest(DELETE_MAILING_PICTURE, removeMailingPicture)
}
function* ProfileSaga() {
  yield all([fork(watchProfile)]);
}

export default ProfileSaga;
