import {
    GET_DOLARES,
    GET_DOLARES_SUCCESS,
    GET_DOLAR_VIGENTE_SUCCESS,
    UPDATE_DOLAR,
    ADD_NEW_DOLAR,
    GET_CECOS_SOLICITUD,
    GET_CECOS_SOLICITUD_SUCCESS,
    UPDATE_CECO_SOLICITUD,
    ADD_NEW_CECO_SOLICITUD,
    GET_CECO_SOLICITUD,
    GET_CECO_SOLICITUD_SUCCESS,
} from './actionTypes'

const INIT_STATE = {
    dolaresArray: [],
    paginacion: {},
    dolar: {},
    cecosArray: [],
    ceco: {},
    error: "",
    loading: false
}

const Mantenedores = (state = INIT_STATE, action) => {
    switch(action.type){
        case GET_DOLARES:
            return {
              ...state,
              loading: true
            }
        case GET_DOLARES_SUCCESS:
            return {
              ...state,
              loading: false,
              paginacion : JSON.parse(action.payload.headers['x-pagination']),
              dolaresArray: action.payload.data
            }
        case UPDATE_DOLAR:
            return{
                ...state
            }
        case ADD_NEW_DOLAR:
          return {
            ...state,
          }
        case GET_DOLAR_VIGENTE_SUCCESS:
          return {
            ...state,
            dolar: action.payload.data,
          };
        case GET_CECOS_SOLICITUD:
            return {
              ...state,
              loading: true
            }
        case GET_CECOS_SOLICITUD_SUCCESS:
            return {
              ...state,
              loading: false,
              paginacion : JSON.parse(action.payload.headers['x-pagination']),
              cecosArray: action.payload.data
            }
        case UPDATE_CECO_SOLICITUD:
            return{
                ...state
            }
        case ADD_NEW_CECO_SOLICITUD:
          return {
            ...state,
          }
        case GET_CECO_SOLICITUD_SUCCESS:
          return {
            ...state,
            ceco: action.payload.data,
          };                    
        default:
            return state 
    }
}

export default Mantenedores