import { takeLatest, call, put } from 'redux-saga/effects';
import { ADD_NEW_HISTORIAL, GET_HISTORIAL_BY_IDENTIFICADOR } from './actionsTypes';
import { getHistorialByIdentificador, postHistorial } from '../../helpers/backend_helper';
import { getHistorialByIdentificadorSuccess } from './actions';
import { toast } from 'react-toastify';

function* fetchHistorialByIdentificador({ payload: { idModulo, idIdentificador } }) {
  try {
    const response = yield call(getHistorialByIdentificador, { idModulo, idIdentificador });
    yield put(getHistorialByIdentificadorSuccess(response));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
    yield put(getHistorialByIdentificadorSuccess({data: []}));
  }
}

function* createHistorial({ payload: data }) {
  try {
    const response = yield call(postHistorial, data);
    const responseH = yield call(getHistorialByIdentificador, { idModulo: data.historialModuloId, idIdentificador: data.identificador });
    yield put(getHistorialByIdentificadorSuccess(responseH));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

export default function* historialSaga() {
  yield takeLatest(GET_HISTORIAL_BY_IDENTIFICADOR, fetchHistorialByIdentificador);
  yield takeLatest(ADD_NEW_HISTORIAL, createHistorial);
}