import { call, put, takeLatest } from "redux-saga/effects";
import { POST_VALIDA_DOCUMENTO } from "./actionTypes";
import { postValidaDocumentoError, postValidaDocumentoSuccess } from "./actions";
import { toast } from "react-toastify";
import { postValidaDocumento } from "../../helpers/backend_helper";

function* validaDocumento({ payload: params }) {
  try {
    const response = yield call(postValidaDocumento, JSON.stringify(params));
    yield put(postValidaDocumentoSuccess(response.data));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(postValidaDocumentoError());
  }
}

function* validaDocumentoSaga() {
  yield takeLatest(POST_VALIDA_DOCUMENTO, validaDocumento);
}

export default validaDocumentoSaga;