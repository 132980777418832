import { takeEvery, call, put, takeLatest } from "redux-saga/effects";
import {
  ADD_NEW_PREMIO_ADJUDICACION,
  ADD_NEW_PREMIO_PUBLICACION,
  GET_ADJUDICACIONES,
  GET_CROSSREF,
  GET_PUBLICACIONES,
  UPDATE_ESTADO_PREMIO,
  GET_PREMIO_PUB_HISTORIAL,
  POST_CARGARANKINGJCR,
  POST_CARGADETALLERANKINGJCR,
  GET_RANKINGJCR_BY_ISSN,
  GET_PREMIO_ADJ_HISTORIAL,
  UPDATE_ESTADO_PREMIO_ADJ,
  GET_RANKINGJCR_DETALLE_ALL,
  GET_MAX_ANIO_PREMIO_ADJUDICACION,
  GET_REPORTE_PREMIO_PUB,
  GET_REPORTE_ADJUDICACION,
  GET_RANKINGJCR_BY_ISSN_VAL
} from "./actionTypes";
import {
  addNewPremioAdjudicacion,
  addNewPremioPublicacion,
  getAdjudicaciones,
  getCrossref,
  getPublicaciones,
  postFile,
  updateEstadoPremio,
  addNewPremioPubHistorial,
  getPremioPubHistorial, postCargaRankingjcr, postCargaDetalleRankingjcr, getRankingJCRByIssn,
  getPremioAdjHistorial,addNewPremioAdjHistorial, updateEstadoPremioAdj, getRankingJCRDetalleAll, 
  getMaxAnioPremioAdjudicacion, getReportePremioPub, getReporteAdjudicacion
} from "../../helpers/backend_helper";
import {
  getAdjudicacionesSuccess,
  getCrossrefFail,
  getCrossrefSuccess,
  getPublicacionesSuccess,
  getPremioPubHistorialSuccess,
  postCargaRankingjcrSuccess,
  postCargaDetalleRankingjcrSuccess,
  getRankingJCRByIssnSuccess,
  getPremioAdjHistorialSuccess,
  getRankingJCRDetalleAllSuccess,
  getMaxAnioPremioAdjudicacionSuccess,
  getReportePremioPubSuccess,
  getReporteAdjudicacionSuccess
} from "./actions";

import { toast } from "react-toastify";

function* fetchCrossref({ payload: { doi, checkIngresado } }) {
  //console.log(doi, checkIngresado);
  try {
    const response = yield call(getCrossref, { doi, checkIngresado });
    yield put(getCrossrefSuccess(response));
  } catch (error) {
    yield put(getCrossrefFail());
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchPublicaciones({ payload: params }) {
  try {
    const response = yield call(getPublicaciones, params);
    yield put(getPublicacionesSuccess(response));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchAdjudicaciones({ payload: params }) {
  try {

    
    const response = yield call(getAdjudicaciones, params);
    yield put(getAdjudicacionesSuccess(response));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* postNewPremioPublicacion({ payload: { premio, formData, formData1 } }) {
  try {
    const responseFile = yield call(postFile, formData);
    const responseFile1 = yield call(postFile, formData1);
    const premioEnviado = { ...premio, archivoId: responseFile.data.fileId, archivoResumenPublicacionId: responseFile1.data.fileId };

    const premioAdd = yield call(addNewPremioPublicacion, premioEnviado);
    const histObj = {estadoPremioId: 1, premioPubId: premioAdd.data.objPremio.id}
    const responseHistorial = yield call(addNewPremioPubHistorial, histObj)

    let params = {
      PageNumber: 1,
      PageSize: 10,
      orderBy:"Id desc",
    };

    console.log("params", params)

    const response = yield call(getPublicaciones, params);
    yield put(getPublicacionesSuccess(response));

    toast.success("Solicitud de Premio creada con éxito", { autoClose: 3000 });

  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* postNewPremioAdjudicacion({
  payload: { premio, formDataProyecto, formDataActa, formDataBases },
}) {
  try {
    const responseFileProyecto = yield call(postFile, formDataProyecto);
    const responseFileActa = yield call(postFile, formDataActa);
    const responseFileBases = yield call(postFile, formDataBases);
    const premioEnviado = {
      ...premio,
      archivoProyecto: responseFileProyecto.data.fileId,
      archivoActa: responseFileActa.data.fileId,
      archivoBasesFondo: responseFileBases.data.fileId,
    };



    //console.log("premioEnviado", premioEnviado)
    //return false;


    const premioAdd = yield call(addNewPremioAdjudicacion, premioEnviado);
    const histObj = {estadoPremioId: 1, premioAdjudicacionId: premioAdd.data.objPremio.id}
    const responseHistorial = yield call(addNewPremioAdjHistorial, histObj)    

    let params = { PageNumber: 1, PageSize: 10, orderBy: "Nombre Asc", anioAdjudicacion: new Date().getFullYear() }
    const response = yield call(getAdjudicaciones, params);
    yield put(getAdjudicacionesSuccess(response));

    const responseAnio = yield call(getMaxAnioPremioAdjudicacion)
    yield put(getMaxAnioPremioAdjudicacionSuccess(responseAnio))

    toast.success("Solicitud de Premio creada con éxito", { autoClose: 3000 });
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* putEstadoPremio({ payload: { premio, tipo, estado, titulo, anio, 
                            issn, impactFactor, articleInfluence, rankingArticleInfluence, 
                            grupo, vcomentario, transicion, enviadoAntesFecha, 
                            jornadaAcademico, afiliacion, revistaNoEspecial, dividir, 
                            solicitud180, noSobrepasadoLimite, acadsPremio} }) {
  try {


    yield call(updateEstadoPremio, { premio, tipo, estado, titulo, 
                                     anio, issn, impactFactor, articleInfluence, 
                                     rankingArticleInfluence, grupo, transicion, enviadoAntesFecha, 
                                     jornadaAcademico, afiliacion, revistaNoEspecial, dividir, 
                                     solicitud180, noSobrepasadoLimite, acadsPremio, vcomentario});
    let response;
    if (tipo === "JCR") {


        let params = {
          PageNumber: 1,
          PageSize: 10,
          orderBy:"Id desc",
        };

        response = yield call(getPublicaciones, params);
        yield put(getPublicacionesSuccess(response));

        let histObj = {}
        if(estado === 5 || estado === 3){
          histObj = {estadoPremioId: estado, premioPubId: premio}
        }
        if(estado === 6 || estado === 4){
          histObj = {estadoPremioId: estado, premioPubId: premio, comentario: vcomentario}
        }      
        const responseHistorial = yield call(addNewPremioPubHistorial, histObj)

    } else {
        response = yield call(getAdjudicaciones, null);
        yield put(getAdjudicacionesSuccess(response))
    }
    toast.success("Solicitud de Premio modificada con éxito", { autoClose: 3000 });
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* putEstadoPremioAdj({ payload: { premio, estado, titulo, tipoProyecto, anosInicio, anosTermino, acadsPremio, vcomentario} }) {
  try {

        yield call(updateEstadoPremioAdj, { premio, estado, titulo, tipoProyecto, anosInicio, anosTermino, acadsPremio, vcomentario});

        // ** Siempre debería aprobar en el año actual 
        let params = { PageNumber: 1, PageSize: 10, orderBy: "Id Asc", anioAdjudicacion: new Date().getFullYear() }

        let response;
        response = yield call(getAdjudicaciones, params);
        yield put(getAdjudicacionesSuccess(response))

        let histObj = {}
        if(estado === 5 || estado === 3){
          histObj = {estadoPremioId: estado, premioAdjudicacionId: premio}
        }
        if(estado === 6 || estado === 4){
          histObj = {estadoPremioId: estado, premioAdjudicacionId: premio, comentario: vcomentario}
        }      
        const responseHistorial = yield call(addNewPremioAdjHistorial, histObj)

        toast.success("Solicitud de Premio modificada con éxito", { autoClose: 3000 });

  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchPremioPubHistorial({payload : {params, activeTabPremios}}){
//function* fetchPremioPubHistorial({payload : params}){
  try {
      //console.log("activeTabPremios", activeTabPremios)

      const response = activeTabPremios === "1" ? yield call(getPremioPubHistorial, params) : yield call(getPremioAdjHistorial, params)
      yield put(getPremioPubHistorialSuccess(response))
  } catch (error) {
      toast.error(error, {
          position: toast.POSITION.TOP_RIGHT
        });
  }
}

function* fetchCargaRankingjcr({payload: params}){
  try {
      const response = yield call(postCargaRankingjcr, params);
      yield put(postCargaRankingjcrSuccess(response))
      toast.success("Ranking cargado con éxito", { autoClose: 3000 });
  } catch (error) {
      toast.error(error, {
          position: toast.POSITION.TOP_RIGHT
        });
  }
}

function* fetchCargaDetalleRankingjcr({payload: fileId}){
  try {
      const response = yield call(postCargaDetalleRankingjcr, fileId);
      yield put(postCargaDetalleRankingjcrSuccess(response))
      toast.success("Archivo cargado con éxito", { autoClose: 3000 });
  } catch (error) {
      toast.error(error, {
          position: toast.POSITION.TOP_RIGHT
        });
  }
}

function* fetchRankingJCRByIssn({ payload: params }) {
  try {
    const response = yield call(getRankingJCRByIssn, params);
    yield put(getRankingJCRByIssnSuccess(response));
    if(!response.data){
      toast.error("ISSN no encontrado", { autoClose: 3000 });
    }
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchRankingDetalle({ payload: params }) {
  try {
    const response = yield call(getRankingJCRDetalleAll, params);
    yield put(getRankingJCRDetalleAllSuccess(response));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchMaxAnioPremioAdjudicacion(){
  try {
      const response = yield call(getMaxAnioPremioAdjudicacion)
      yield put(getMaxAnioPremioAdjudicacionSuccess(response))
  } catch (error) {
      toast.error(error, {
          position: toast.POSITION.TOP_RIGHT
        });
  }
}

function* fetchReportePremioPub({payload : params}){
  try {
      const response = yield call(getReportePremioPub, params)
      yield put(getReportePremioPubSuccess(response))
  } catch (error) {
      toast.error(error, {
          position: toast.POSITION.TOP_RIGHT
        });
  }
}

function* fetchReporteAdjudicacion({payload : params}){
  try {
      const response = yield call(getReporteAdjudicacion, params)
      yield put(getReporteAdjudicacionSuccess(response))
  } catch (error) {
      toast.error(error, {
          position: toast.POSITION.TOP_RIGHT
        });
  }
}

function* fetchRankingJCRByIssnVal({ payload: params }) {
  try {
    const response = yield call(getRankingJCRByIssn, params);
    yield put(getRankingJCRByIssnSuccess(response));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* PremiosSaga() {
  yield takeLatest(GET_CROSSREF, fetchCrossref);
  yield takeLatest(ADD_NEW_PREMIO_PUBLICACION, postNewPremioPublicacion);
  yield takeLatest(ADD_NEW_PREMIO_ADJUDICACION, postNewPremioAdjudicacion);
  yield takeLatest(GET_PUBLICACIONES, fetchPublicaciones);
  yield takeLatest(GET_ADJUDICACIONES, fetchAdjudicaciones);
  yield takeLatest(UPDATE_ESTADO_PREMIO, putEstadoPremio);
  yield takeEvery(GET_PREMIO_PUB_HISTORIAL, fetchPremioPubHistorial)   
  yield takeLatest(POST_CARGARANKINGJCR, fetchCargaRankingjcr)
  yield takeLatest(POST_CARGADETALLERANKINGJCR, fetchCargaDetalleRankingjcr)
  yield takeLatest(GET_RANKINGJCR_BY_ISSN, fetchRankingJCRByIssn);
  yield takeLatest(UPDATE_ESTADO_PREMIO_ADJ, putEstadoPremioAdj);
  yield takeLatest(GET_RANKINGJCR_DETALLE_ALL, fetchRankingDetalle);
  yield takeLatest(GET_MAX_ANIO_PREMIO_ADJUDICACION, fetchMaxAnioPremioAdjudicacion);
  yield takeLatest(GET_REPORTE_PREMIO_PUB, fetchReportePremioPub);
  yield takeLatest(GET_REPORTE_ADJUDICACION, fetchReporteAdjudicacion);
  yield takeLatest(GET_RANKINGJCR_BY_ISSN_VAL, fetchRankingJCRByIssnVal);
}

export default PremiosSaga;
