import { toast } from "react-toastify";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  getModulosNotificaciones,
  getNotificacionesSistema,
  postModuloNotificacion,
  postTipoNotificacion,
  sendNotificacion,
  updateEstadoTipoNotificacion,
  updateTipoNotificacion,
} from "../../helpers/backend_helper";
import {
  getModulosNotificacionesSuccess,
  getNotificacionesSistemaSuccess,
  sendNotificacionSuccess,
} from "./actions";
import {
  ADD_NEW_MODULO_NOTIFICACIONES,
  ADD_NEW_TIPO_NOTIFICACION,
  GET_MODULOS_NOTIFICACIONES,
  GET_NOTIFICACIONES_SISTEMA,
  SEND_NOTIFICACION,
  UPDATE_ESTADO_TIPO_NOTIFICACION,
  UPDATE_TIPO_NOTIFICACION,
} from "./actionTypes";

function* fetchNotificacionesSistema({ payload: modulo }) {
  try {
    const response = yield call(getNotificacionesSistema, modulo);
    yield put(getNotificacionesSistemaSuccess(response));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchModulosNotificaciones() {
  try {
    const response = yield call(getModulosNotificaciones);
    yield put(getModulosNotificacionesSuccess(response));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* postModuloNotificaciones({payload: modulo}) {
  try {
    const response = yield call(postModuloNotificacion, modulo);
    const responseModulos = yield call(getModulosNotificaciones);
    yield put(getModulosNotificacionesSuccess(responseModulos));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* postTipoNotificaciones({payload: {tipo, modulo}}) {
  try {
    const response = yield call(postTipoNotificacion, tipo);
    const responseNotificaciones = yield call(getNotificacionesSistema, modulo);
    yield put(getNotificacionesSistemaSuccess(responseNotificaciones));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* putTipoNotificacion({payload: {tipo, modulo}}) {
  try {
    const response = yield call(updateTipoNotificacion, tipo);
    const responseNotificaciones = yield call(getNotificacionesSistema, modulo);
    yield put(getNotificacionesSistemaSuccess(responseNotificaciones));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* putEstadoTipoNotificacion({payload: {id, estado, modulo}}) {
  try {
    const response = yield call(updateEstadoTipoNotificacion, {id, estado});
    const responseNotificaciones = yield call(getNotificacionesSistema, modulo);
    yield put(getNotificacionesSistemaSuccess(responseNotificaciones));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* enviarNotificacion({payload: id}) {
  try {
    const response = yield call(sendNotificacion, id);
    yield put(sendNotificacionSuccess())
    toast.success("Notificaciones enviadas", {
      position: toast.POSITION.TOP_RIGHT,
    });
  } catch (error) {
    yield put(sendNotificacionSuccess())
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* NotificacionesSaga() {
  yield takeLatest(GET_NOTIFICACIONES_SISTEMA, fetchNotificacionesSistema);
  yield takeLatest(GET_MODULOS_NOTIFICACIONES, fetchModulosNotificaciones);
  yield takeLatest(ADD_NEW_MODULO_NOTIFICACIONES, postModuloNotificaciones);
  yield takeLatest(ADD_NEW_TIPO_NOTIFICACION, postTipoNotificaciones);
  yield takeLatest(UPDATE_TIPO_NOTIFICACION, putTipoNotificacion);
  yield takeLatest(UPDATE_ESTADO_TIPO_NOTIFICACION, putEstadoTipoNotificacion);
  yield takeLatest(SEND_NOTIFICACION, enviarNotificacion);
}

export default NotificacionesSaga;
