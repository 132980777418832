import {
    GET_TAREAS,
    GET_TAREAS_SUCCESS,
  } from "./actionTypes";
  
  export const getTareas = () => ({
    type: GET_TAREAS
  });
  
  export const getTareasSuccess = (tareas) => ({
    type: GET_TAREAS_SUCCESS,
    payload: tareas
  });
  
  
   