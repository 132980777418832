export const GET_TIPO_SOLICITUDES = "GET_TIPO_SOLICITUDES";
export const GET_TIPO_SOLICITUDES_SUCCESS = "GET_TIPO_SOLICITUDES_SUCCESS";
export const ADD_NEW_SOLICITUD_FONDO = "ADD_NEW_SOLICITUD_FONDO";
export const ADD_NEW_FONDO_INVESTIGACION = "ADD_NEW_FONDO_INVESTIGACION";
export const UPDATE_SOLICITUD_FONDO = "UPDATE_SOLICITUD_FONDO";
export const GET_SOLICITUDES_FONDO = "GET_SOLICITUDES_FONDO";
export const GET_SOLICITUDES_FONDO_SUCCESS = "GET_SOLICITUDES_FONDO_SUCCESS";
export const GET_SOLICITUDES_FONDO_POR_ACADEMICO = "GET_SOLICITUDES_FONDO_POR_ACADEMICO";
export const GET_SOLICITUDES_FONDO_POR_ACADEMICO_SUCCESS = "GET_SOLICITUDES_FONDO_POR_ACADEMICO_SUCCESS";
export const GET_TIPO_ASIGNACION = "GET_TIPO_ASIGNACION";
export const GET_TIPO_ASIGNACION_SUCCESS = "GET_TIPO_ASIGNACION_SUCCESS";
export const GET_TIPO_VIATICO = "GET_TIPO_VIATICO";
export const GET_TIPO_VIATICO_SUCCESS = "GET_TIPO_VIATICO_SUCCESS";
export const GET_VIATICOPORPAIS = "GET_VIATICOPORPAIS";
export const GET_VIATICOPORPAIS_SUCCESS = "GET_VIATICOPORPAIS_SUCCESS";
export const ADD_DIASVIAJE_SOLICITUD = "ADD_DIASVIAJE_SOLICITUD";
export const GET_DIASVIAJE_SOLICITUD = "GET_DIASVIAJE_SOLICITUD";
export const GET_DIASVIAJE_SOLICITUD_SUCCESS = "GET_DIASVIAJE_SOLICITUD_SUCCESS";
export const RESET_DIASVIAJE_SOLICITUD = "RESET_DIASVIAJE_SOLICITUD";  
export const CAMBIA_ESTADO_SOLICITUD_FONDO = "CAMBIA_ESTADO_SOLICITUD_FONDO";
export const VALORIZA_SOLICITUD_FONDO = "VALORIZA_SOLICITUD_FONDO";
export const GET_SOLICITUD_HISTORIAL = "GET_SOLICITUD_HISTORIAL";
export const GET_SOLICITUD_HISTORIAL_SUCCESS = "GET_SOLICITUD_HISTORIAL_SUCCESS";
export const GET_PPTO_INVESTIGACION_POR_ACADEMICO = "GET_PPTO_INVESTIGACION_POR_ACADEMICO";
export const GET_PPTO_INVESTIGACION_POR_ACADEMICO_SUCCESS = "GET_PPTO_INVESTIGACION_POR_ACADEMICO_SUCCESS";
export const RESET_PPTOS_INVESTIGACION = "RESET_PPTOS_INVESTIGACION";
export const GET_FONDOS_INVESTIGACION = "GET_FONDOS_INVESTIGACION";
export const GET_FONDOS_INVESTIGACION_SUCCESS = "GET_FONDOS_INVESTIGACION_SUCCESS";
export const GET_ESTADO_SOLICITUD_FONDO = "GET_ESTADO_SOLICITUD_FONDO";
export const GET_ESTADO_SOLICITUD_FONDO_SUCCESS = "GET_ESTADO_SOLICITUD_FONDO_SUCCESS";
export const GET_TIPO_FONDO_INVESTIGACION = "GET_TIPO_FONDO_INVESTIGACION";
export const GET_TIPO_FONDO_INVESTIGACION_SUCCESS = "GET_TIPO_FONDO_INVESTIGACION_SUCCESS";
export const GET_REPORTE_PRESUPUESTO_INVESTIGACION = "GET_REPORTE_PRESUPUESTO_INVESTIGACION";
export const GET_REPORTE_PRESUPUESTO_INVESTIGACION_SUCCESS = "GET_REPORTE_PRESUPUESTO_INVESTIGACION_SUCCESS";
export const GET_REPORTE_FONDOS_INVESTIGACION = "GET_REPORTE_FONDOS_INVESTIGACION";
export const GET_REPORTE_FONDOS_INVESTIGACION_SUCCESS = "GET_REPORTE_FONDOS_INVESTIGACION_SUCCESS";
export const GET_REPORTE_SOLICITUD_FONDOS = "GET_REPORTE_SOLICITUD_FONDOS";
export const GET_REPORTE_SOLICITUD_FONDOS_SUCCESS = "GET_REPORTE_SOLICITUD_FONDOS_SUCCESS";
export const GET_PPTO_ACADEMICO_SALDO_PENDIENTE = "GET_PPTO_ACADEMICO_SALDO_PENDIENTE";
export const GET_PPTO_ACADEMICO_SALDO_PENDIENTE_SUCCESS = "GET_PPTO_ACADEMICO_SALDO_PENDIENTE_SUCCESS";
export const UPDATE_ACADEMICOS_FONDO = "UPDATE_ACADEMICOS_FONDO";
export const UPDATE_FONDO_INVESTIGACION = "UPDATE_FONDO_INVESTIGACION";
export const DELETE_FONDO_INVESTIGACION = "DELETE_FONDO_INVESTIGACION";
export const RESET_VIATICOPORPAIS = "RESET_VIATICOPORPAIS";
export const UPDATE_VALORES_SOLICITUD_FONDO = "UPDATE_VALORES_SOLICITUD_FONDO";
export const GET_PPTO_INVESTIGACION_POR_DEPTO = "GET_PPTO_INVESTIGACION_POR_DEPTO";
export const GET_PPTO_INVESTIGACION_POR_DEPTO_SUCCESS = "GET_PPTO_INVESTIGACION_POR_DEPTO_SUCCESS";
export const GET_PPTO_DEPTO_PENDIENTE = "GET_PPTO_DEPTO_PENDIENTE";
export const GET_PPTO_DEPTO_PENDIENTE_SUCCESS = "GET_PPTO_DEPTO_PENDIENTE_SUCCESS";
export const GET_PPTO_ACADEMICO_SALDO_PENDIENTE_CONCILIA = "GET_PPTO_ACADEMICO_SALDO_PENDIENTE_CONCILIA";
export const GET_PPTO_ACADEMICO_SALDO_PENDIENTE_CONCILIA_SUCCESS = "GET_PPTO_ACADEMICO_SALDO_PENDIENTE_CONCILIA_SUCCESS";
export const GET_REPORTE_SOLICITUD_FONDOS_AYUDANTES = "GET_REPORTE_SOLICITUD_FONDOS_AYUDANTES";
export const GET_REPORTE_SOLICITUD_FONDOS_AYUDANTES_SUCCESS = "GET_REPORTE_SOLICITUD_FONDOS_AYUDANTES_SUCCESS";
export const GET_FILES_SOLICITUD = "GET_FILES_SOLICITUD";
export const GET_CECO_SOLICITUD_FONDO = "GET_CECO_SOLICITUD_FONDO";
export const GET_CECO_SOLICITUD_FONDO_SUCCESS = "GET_CECO_SOLICITUD_FONDO_SUCCESS";
export const ADD_NEW_CECO_SOLICITUD_FONDO = "ADD_NEW_CECO_SOLICITUD_FONDO";
export const UPDATE_CECO_SOLICITUD_FONDO = "UPDATE_CECO_SOLICITUD_FONDO";
export const GET_CECOADDED = "GET_CECOADDED";
export const RESET_CECO_SOLICITUD = "RESET_CECO_SOLICITUD";
export const GET_CECO_SOLICITUD_BY_CODIGO = "GET_CECO_SOLICITUD_BY_CODIGO";
export const GET_CECO_SOLICITUD_BY_CODIGO_SUCCESS = "GET_CECO_SOLICITUD_BY_CODIGO_SUCCESS";
