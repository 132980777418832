import {
  GET_CAPTCHA,
  GET_IMG_CAPTCHA,
  POST_CAPTCHA,
  POST_CAPTCHA_SUCCESS,
  RESET_RESP_CAPTCHA
} from './actionTypes'

const INIT_STATE = {
  idCaptcha: "",
  resCaptcha: false,
  loading: false
}

const Captcha = (state = INIT_STATE, action) => {
  switch(action.type){
      case GET_CAPTCHA:
        return {
          ...state,
          resCaptcha: false,
          loading: true,
        }
      case GET_IMG_CAPTCHA:
        return {
          ...state,
          idCaptcha: action.payload,
          loading: false,
          resCaptcha: false
        }
      case POST_CAPTCHA_SUCCESS:
        return {
          ...state,
          resCaptcha: action.payload.result,
        }
        
      case RESET_RESP_CAPTCHA:
        return {
          ...state,
          resCaptcha: false,
          loading: false,
        }
      default:
        return state
  }
}

export default Captcha