import {
    GET_SALAS, GET_SALAS_SUCCESS, 
    UPDATE_SALA,ADD_NEW_SALA,
    GET_TIPO_SALAS, GET_TIPO_SALAS_SUCCESS, 
    GET_SEDES, GET_SEDES_SUCCESS, 
    GET_EQUIPAMIENTO, GET_EQUIPAMIENTO_SUCCESS, 
    ADD_NEW_EQUIPAMIENTO, ADD_NEW_SALAEQUIPAMIENTO, 
    GET_SALAEQUIPAMIENTO, GET_SALAEQUIPAMIENTO_SUCCESS, DELETE_SALAEQUIPAMIENTO, GET_NEWEQUIPAMIENTO_SUCCESS,
    GET_SALAS_BY_SEDE,
    GET_SALAS_BY_SEDE_SUCCESS,
    GET_SALAS_VIRTUALES,
    GET_SALAS_VIRTUALES_SUCCESS
} from './actionTypes'

export const getSalas = (params) => ({
  type: GET_SALAS,
  payload: params
});

export const getSalasSuccess = (params) => ({
    type: GET_SALAS_SUCCESS,
    payload: params
});
  
export const updateSala = (sala, params) => ({
  type: UPDATE_SALA,
  payload: {sala, params}
});

export const addNewSala = (sala, params) => ({
  type: ADD_NEW_SALA,
  payload: {sala, params},
});

export const getTipoSalas = (params) => ({
  type: GET_TIPO_SALAS,
  payload: params
});

export const getTipoSalasSuccess = (params) => ({
    type: GET_TIPO_SALAS_SUCCESS,
    payload: params 
});

export const getSedes = (params) => ({
  type: GET_SEDES,
  payload: params
});

export const getSedesSuccess = (params) => ({
    type: GET_SEDES_SUCCESS,
    payload: params 
});

export const addNewEquipamiento = (equipamiento) => ({
  type: ADD_NEW_EQUIPAMIENTO,
  payload: {equipamiento},
});

export const getEquipamiento = (params) => ({
  type: GET_EQUIPAMIENTO,
  payload: params
});

export const getEquipamientoSuccess = (params) => ({
    type: GET_EQUIPAMIENTO_SUCCESS,
    payload: params 
});

export const addNewSalaEquipamiento = (salaequipamiento, params) => ({
  type: ADD_NEW_SALAEQUIPAMIENTO,
  payload: {salaequipamiento, params},
});

export const getSalaEquipamiento = (params) => ({
  type: GET_SALAEQUIPAMIENTO,
  payload: params
});

export const getSalaEquipamientoSuccess = (params) => ({
    type: GET_SALAEQUIPAMIENTO_SUCCESS,
    payload: params 
});

export const deleteSalaEquipamiento = params => ({
  type: DELETE_SALAEQUIPAMIENTO,
  payload: params 
});

export const getNewEquipamientoSuccess = (params) => ({
  type: GET_NEWEQUIPAMIENTO_SUCCESS,
  payload: params 
});

export const getSalasBySede = (params) => ({
  type: GET_SALAS_BY_SEDE,
  payload: params
});

export const getSalasBySedeSuccess = (data) => ({
  type: GET_SALAS_BY_SEDE_SUCCESS,
  payload: data 
});

export const getSalasVirtuales = () => ({
  type: GET_SALAS_VIRTUALES
});

export const getSalasVirtualesSuccess = (data) => ({
  type: GET_SALAS_VIRTUALES_SUCCESS,
  payload: data 
});