import {
    GET_SALAS,
    GET_SALAS_SUCCESS,
    UPDATE_SALA,
    GET_TIPO_SALAS,
    GET_TIPO_SALAS_SUCCESS,
    GET_SEDES,
    GET_SEDES_SUCCESS,
    ADD_NEW_EQUIPAMIENTO,
    GET_EQUIPAMIENTO,
    GET_EQUIPAMIENTO_SUCCESS,    
    ADD_NEW_SALA,
    ADD_NEW_SALAEQUIPAMIENTO,
    GET_SALAEQUIPAMIENTO,
    GET_SALAEQUIPAMIENTO_SUCCESS,
    DELETE_SALAEQUIPAMIENTO,
    GET_NEWEQUIPAMIENTO_SUCCESS,
    GET_SALAS_BY_SEDE_SUCCESS,
    GET_SALAS_VIRTUALES_SUCCESS
} from './actionTypes'

const INIT_STATE = {
    salasArray: [],
    tipoSalasArray: [],
    salaEquipamientoArray: [],
    sedesArray: [],
    paginacion: {},
    equipamientoArray: [],
    objNewEquipamiento: [], 
    error: "",
    loading: false,
    salasBySede: [],
    salasVirtuales: []
}

const OperacionesSalas = (state = INIT_STATE, action) => {
    switch(action.type){
        case GET_SALAS:
            return {
              ...state,
              loading: true
            }
        case GET_SALAS_SUCCESS:
            return {
              ...state,
              loading: false,
              paginacion : JSON.parse(action.payload.headers['x-pagination']),
              salasArray: action.payload.data
            }
        case UPDATE_SALA:
            return{
                ...state
            }
        case GET_TIPO_SALAS:
            return {
              ...state,
              loading: true
            }
        case GET_TIPO_SALAS_SUCCESS:
            return {
              ...state,
              loading: false,
              tipoSalasArray: action.payload.data
            }
        case GET_SEDES:
            return {
              ...state,
              loading: true
            }
        case GET_SEDES_SUCCESS:
            return {
              ...state,
              loading: false,
              sedesArray: action.payload.data
            } 
        case ADD_NEW_EQUIPAMIENTO:
          return {
            ...state,
          }
        case GET_EQUIPAMIENTO:
            return {
              ...state,
              loading: true
            }
        case GET_EQUIPAMIENTO_SUCCESS:
            return {
              ...state,
              loading: false,
              equipamientoArray: action.payload.data
            } 
        case ADD_NEW_SALA:
          return {
            ...state,
          }
        case ADD_NEW_SALAEQUIPAMIENTO:
          return {
            ...state,
          }   
        case GET_SALAEQUIPAMIENTO:
            return {
              ...state,
              loading: true
            }
        case GET_SALAEQUIPAMIENTO_SUCCESS:
            return {
              ...state,
              loading: false,
              salaEquipamientoArray: action.payload.data
            } 
        case GET_NEWEQUIPAMIENTO_SUCCESS:
            return {
              ...state,
              loading: false,
              objNewEquipamiento: action.payload
            } 
        case DELETE_SALAEQUIPAMIENTO:
          return {
            ...state,
          }
        case GET_SALAS_BY_SEDE_SUCCESS:
            return {
              ...state,
              salasBySede: action.payload.data
            }
        case GET_SALAS_VIRTUALES_SUCCESS:
            return {
              ...state,
              salasVirtuales: action.payload.data
            }
        default:
            return state 
    }
}

export default OperacionesSalas