import { getValorDolar } from "../../helpers/backend_helper";
import { getValorDolarSuccess } from "./actions";

const { takeLatest, call, put } = require("redux-saga/effects");
const { GET_VALOR_DOLAR } = require("./actionsTypes");

function* fetchMoneda() {
  try {
    const response = yield call(getValorDolar);
    yield put(getValorDolarSuccess(response));
  } catch (error) {
    console.log('error', error);
  }
}

function* monedasSaga() {
  yield takeLatest(GET_VALOR_DOLAR, fetchMoneda);
}

export default monedasSaga;