import { call, put, takeLatest } from "redux-saga/effects";
import { ADD_NEW_EMPRESA, ADD_NEW_EMPRESA_CONTACTO, GET_EMPRESA_CONTACTOS, GET_EMPRESAS, UPDATE_EMPRESA } from "./actionTypes";
import { toast } from "react-toastify";
import { addNewEmpresa, addNewEmpresaContacto, getEmpresaContactos, getEmpresas, getLista, updateEmpresa } from "../../helpers/backend_helper";
import { getEmpresaContactosSuccess, getEmpresasSuccess } from "./actions";
import { getListSuccess } from "../actions";

function* fetchEmpresas({ payload: params }) {
  try {
    const response = yield call(getEmpresas, params)
    yield put(getEmpresasSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* fetchEmpresaContactos({ payload: id }) {
  try {
    const response = yield call(getEmpresaContactos, {id})
    yield put(getEmpresaContactosSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* postEmpresa({ payload: { empresa, fetchData } }) {
  try {
    const response = yield call(addNewEmpresa, empresa)
    fetchData && fetchData(response.data)
    toast.success("Empresa creada correctamente", {
      position: toast.POSITION.TOP_RIGHT
    });
    const responseList = yield call(getLista, "GetEmpresas")
    yield put(getListSuccess("GetEmpresas", responseList.data))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* postEmpresaContacto({ payload: { contacto, fetchData } }) {
  try {
    const response = yield call(addNewEmpresaContacto, contacto)
    if(fetchData) fetchData()
    toast.success("Contacto creado correctamente", {
      position: toast.POSITION.TOP_RIGHT
    });
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* putEmpresa({ payload: { empresa, fetchData } }) {
  try {
    const response = yield call(updateEmpresa, empresa)
    if(fetchData) fetchData(response.data)
    toast.success("Empresa actualizada correctamente", {
      position: toast.POSITION.TOP_RIGHT
    });
    const responseList = yield call(getLista, "GetEmpresas")
    yield put(getListSuccess("GetEmpresas", responseList.data))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* empresasSaga() {
  yield takeLatest(GET_EMPRESAS, fetchEmpresas)
  yield takeLatest(GET_EMPRESA_CONTACTOS, fetchEmpresaContactos)
  yield takeLatest(ADD_NEW_EMPRESA, postEmpresa)
  yield takeLatest(ADD_NEW_EMPRESA_CONTACTO, postEmpresaContacto)
  yield takeLatest(UPDATE_EMPRESA, putEmpresa)
}

export default empresasSaga