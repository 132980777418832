import {
  put,
  call,
  takeLatest,
  takeEvery
} from "redux-saga/effects";
import { toast } from "react-toastify";

import {
        ADD_NEW_PROGRAMA,ADD_DATOSWEBPROGRAMA, ADD_DATOSWEBPROGRAMAVERSIONES,
        GET_PROGRAMAS, GET_ESTADOS_PROGRAMA, GET_AREAS_PROGRAMA, UPDATE_PROGRAMA, CLONE_PROGRAMA, GET_PROGRAMA_X_COD_BASE, GET_MODALIDADES_PROGRAMA,
        GET_TIPOS_PROGRAMA, GET_PROGRAMAS_SIN_DECRETO, UPDATE_ESTADO_DECRETO_PROGRAMA, GENERA_SYLLABUS_PROGRAMA, GET_PROGRAMA,
        GET_PROGRAMADATOSWEB,GET_PROGRAMAVERSIONESDATOSWEB
} from "./actionTypes";

import {
        addNewPrograma,addDatosWebPrograma,addDatosWebProgramaVersiones,
        getProgramas, postMulitpleFile, getEstadosPrograma, getAreasPrograma, postFile, updatePrograma, clonePrograma, getProgramaXCodBase,
        getLista, getProgramasSinDecreto, updateEstadoDecretoPrograma, generaSyllabusPrograma, GetHistorialByIdentificadorAndAccion, getPrograma,
        getProgramaDatosWeb,getProgramaVersionesDatosWeb

} from '../../helpers/backend_helper'


import {
          getProgramasSuccess, getEstadosProgramaSuccess, getAreasProgramaSuccess, getProgramaXCodBaseSuccess, getModalidadesProgramaSuccess,
          getTiposProgramaSuccess, getProgramasSinDecretoSuccess, getProgramaSuccess,addDatosWebProgramaAdded,addDatosWebProgramaVersionesAdded,
          getProgramaDatosWebSuccess,getProgramaVersionesDatosWebSuccess

       } from "./actions";

import { isNil } from "ramda";

function* fetchProgramas({ payload: params }) {
  try {
    const response = yield call(getProgramas, params)
    yield put(getProgramasSuccess(response));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchPrograma({ payload: {idPrograma} }) {
  try {
    const response = yield call(getPrograma, {id: idPrograma})
    yield put(getProgramaSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* fetchEstadosPrograma() {
  try {
    const response = yield call(getEstadosPrograma)
    yield put(getEstadosProgramaSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* fetchAreasPrograma() {
  try {
    const response = yield call(getAreasPrograma)
    yield put(getAreasProgramaSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* fetchModalidadesPrograma() {
  try {
    const response = yield call(getLista, 'GetModalidadesProgramas')
    yield put(getModalidadesProgramaSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* fetchTiposPrograma() {
  try {
    const response = yield call(getLista, 'GetPrgTipoPrograma')
    yield put(getTiposProgramaSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}



function* createPrograma({ payload: { programa, files, fetchData } }) {
  try {

    /*
    LOS ARCHIVOS LLEGAN EN EL SIGUIENTE ORDEN:
    0 Descriptor Decreto
    1 Decreto
    2 Fundamentación
    3 Cuenta T
    4 Sence
    */

    const responseFiles = yield call(postMulitpleFile, files)

    let g = 0;
    if (programa.archivoDescriptorDecreto) {
      programa = {
        ...programa,
        decretoDescriptorArchivoId: responseFiles.data.filesId[g]
      }
      g++
    }

    if (programa.archivoDecreto) {
      programa = {
        ...programa,
        decretoArchivoId: responseFiles.data.filesId[g],
      }
      g++
    }

    if (programa.archivoFundamentacion) {
      programa = {
        ...programa,
        decretoFundamentacionArchivoId: responseFiles.data.filesId[g],
      }
      g++
    }

    if (programa.archivoCuentaT) {
      programa = {
        ...programa,
        cuentaTArchivoId: responseFiles.data.filesId[g]
      }
      g++
    }

    if (programa.archivoSence)
      programa = {
        ...programa,
        archivoSenceId: responseFiles.data.filesId[g]
      }


    const response = yield call(addNewPrograma, programa)
    fetchData()
    toast.success("Programa creado con éxito", { autoClose: 3000 });
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* putPrograma({ payload: { programa, files, fetchData, filesUpdate } }) {
  try {

    /*
    LOS ARCHIVOS LLEGAN EN EL SIGUIENTE ORDEN:
    0 Descriptor Decreto
    1 Decreto
    2 Fundamentación
    3 Cuenta T
    4 Sence
    */

    if (filesUpdate.length !== 0) {
      const responseFiles = yield call(postMulitpleFile, files)

      let g = 0;
      if (programa.archivoDescriptorDecreto && filesUpdate.includes("descriptorDecreto")) {
        programa = {
          ...programa,
          decretoDescriptorArchivoId: responseFiles.data.filesId[g]
        }
        g++
      }

      if (programa.archivoDecreto && filesUpdate.includes("decreto")) {
        programa = {
          ...programa,
          decretoArchivoId: responseFiles.data.filesId[g],
        }
        g++
      }

      if (programa.archivoFundamentacion && filesUpdate.includes("fundamentacion")) {
        programa = {
          ...programa,
          decretoFundamentacionArchivoId: responseFiles.data.filesId[g],
        }
        g++
      }

      if (programa.archivoCuentaT && filesUpdate.includes("cuentaT")) {
        programa = {
          ...programa,
          cuentaTArchivoId: responseFiles.data.filesId[g]
        }
        g++
      }

      if (programa.archivoSence && programa.archivoSenceId === null && filesUpdate.includes("sence")) {
        const responseFile = yield call(postFile, files)
        programa = {
          ...programa,
          archivoSenceId: responseFile.data.fileId
        }
      }
    }

    const response = yield call(updatePrograma, programa.id, programa)
    toast.success("Programa modificado con éxito", { autoClose: 3000 });
    fetchData()
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* clonarPrograma({ payload: { data, fetchData, setNuevoProgClon } }) {
  try {
    const response = yield call(clonePrograma, data)
    setNuevoProgClon(response.data.objPrograma)
    toast.success("Programa clonado con éxito", { autoClose: 3000 });
    fetchData()
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}



function* fetchGetProgramaXCodBase({ payload: data }) {
  try {
    const response = yield call(getProgramaXCodBase, data)
    yield put(getProgramaXCodBaseSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* fetchProgramasSinDecreto({ payload: params }) {
  try {
    const response = yield call(getProgramasSinDecreto, params)
    for (let [index, p] of response.data.entries()) {
      const resHistorial = yield call(GetHistorialByIdentificadorAndAccion, { idModulo: 3, idIdentificador: p.id, idAccion: 9 })
      if (resHistorial.data.length > 0) {
        response.data[index].historialDecreto = resHistorial.data.at(-1)
      }
    }

    yield put(getProgramasSinDecretoSuccess(response));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* putProgramaDecretoEstado({ payload: { data, formFile, fetchData } }) {
  
  try {
    if (formFile !== null) {
      const responseFile = yield call(postFile, formFile)
      data = {
        ...data,
        archivoDecreto: responseFile.data.fileId
      }
    }
    const response = yield call(updateEstadoDecretoPrograma, data.id, data)
    fetchData()
    toast.success("Decreto actualizado con éxito", { autoClose: 3000 });
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}


function* generaSyllabusProg({ payload: { data, setNomSyllabusPrograma } }) {
  try {
    const response = yield call(generaSyllabusPrograma, data)
    setNomSyllabusPrograma(response.data.message)
    //toast.success("Syllabus generado con éxito", { autoClose: 3000 });
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}


function* add_DatosWebPrograma({payload: {progDatosWeb,fileTarjeta,fileBanner,callback}})
{
    try 
    {
        let isEmptyfileTarjeta = true; // Asumimos inicialmente que está vacío
        let isEmptyfileBanner = true; // Asumimos inicialmente que está vacío  

         fileTarjeta.forEach((value, key) => {
          isEmptyfileTarjeta = false; //.CAB. Si encontramos al menos una entrada, no está vacío
          //console.log("Pase filetarjeta:",isEmptyfileTarjeta);
         });

         fileBanner.forEach((value, key) => {
          isEmptyfileBanner = false; //.CAB. Si encontramos al menos una entrada, no está vacío
         });  


          if (!isEmptyfileTarjeta)
          {
            //console.log("progDatosWeb antes: ",progDatosWeb);
            const responseFileTarjeta = yield call(postFile, fileTarjeta);
            progDatosWeb = {
              ...progDatosWeb,
              IdImagenTarjeta: responseFileTarjeta.data.fileId,

            }
            
            //console.log("pase por saga idImagenTarjeta:",responseFileTarjeta.data.fileId)
            //console.log("progDatosWeb despues: ",progDatosWeb);

          }
          
          if (!isEmptyfileBanner)
          {
            const responseFileBanner = yield call(postFile, fileBanner);
            progDatosWeb = {
              ...progDatosWeb,
              IdBannerLandigPage: responseFileBanner.data.fileId,
              
            }

          }
           
          
          const response = yield call(addDatosWebPrograma, progDatosWeb)
          yield put(addDatosWebProgramaAdded(response))
          callback && callback();
          toast.success("Registro grabado con éxito", { autoClose: 3000 });
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* add_DatosWebProgramaVersiones({payload: {progVersionDatosWeb,fileTarjeta,fileBanner,fileBrochure,callback}})
{
    try 
    {
        let isEmptyfileTarjeta = true; // Se asume que inicialmente está vacío.
        let isEmptyfileBanner = true; // Se asume que  inicialmente está vacío.  
        let isEmptyfileBrochure = true; // Se Asume que inicialmente está vacío.
        
        
         fileTarjeta.forEach((value, key) => {
          isEmptyfileTarjeta = false; //.CAB. Si encontramos al menos una entrada, no está vacío
         });

         fileBanner.forEach((value, key) => {
          isEmptyfileBanner = false; //.CAB. Si encontramos al menos una entrada, no está vacío
         });  
         
         fileBrochure.forEach((value, key) => {
          isEmptyfileBrochure = false; //.CAB. Si encontramos al menos una entrada, no está vacío
         });
     
          if (!isEmptyfileTarjeta)
          {
            const responseFileTarjeta = yield call(postFile, fileTarjeta);
            progVersionDatosWeb = {
              ...progVersionDatosWeb,
              idImagenTarjeta: responseFileTarjeta.data.fileId,

            }

            
          }
          
          if (!isEmptyfileBanner)
          {
            const responseFileBanner = yield call(postFile, fileBanner);
            progVersionDatosWeb = {
              ...progVersionDatosWeb,
              idBannerLandigPage: responseFileBanner.data.fileId,
              
            }

          }

          if (!isEmptyfileBrochure)
            {

              const responseFileBrochure = yield call(postFile, fileBrochure);
              progVersionDatosWeb = {
                ...progVersionDatosWeb,
                idBrochure: responseFileBrochure.data.fileId,
              }
              //console.log("pase por saga Brochure: ",responseFileBrochure.data.fileId)
  
            }

           
          const response = yield call(addDatosWebProgramaVersiones, progVersionDatosWeb)
          yield put(addDatosWebProgramaVersionesAdded(response))
          callback && callback();
          toast.success("Registro grabado con éxito", { autoClose: 3000 });
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}


function* fetchProgramaDatosWeb({ payload: params })
{
    try 
    {
        const response = yield call(getProgramaDatosWeb,params)
        yield put(getProgramaDatosWebSuccess(response))

    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}



function* fetchProgramaVersionesDatosWeb({ payload: params })
{
    try 
    {
        const response = yield call(getProgramaVersionesDatosWeb,params)
        yield put(getProgramaVersionesDatosWebSuccess(response))

    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}



function* programaSaga() {

  yield takeLatest(GET_PROGRAMAS, fetchProgramas);
  yield takeLatest(GET_PROGRAMA, fetchPrograma);
  yield takeLatest(ADD_NEW_PROGRAMA, createPrograma);
  
  yield takeLatest(GET_ESTADOS_PROGRAMA, fetchEstadosPrograma);
  yield takeLatest(GET_AREAS_PROGRAMA, fetchAreasPrograma);
  yield takeLatest(UPDATE_PROGRAMA, putPrograma);
  yield takeLatest(CLONE_PROGRAMA, clonarPrograma);
  yield takeLatest(GET_PROGRAMA_X_COD_BASE, fetchGetProgramaXCodBase);
  yield takeLatest(GET_MODALIDADES_PROGRAMA, fetchModalidadesPrograma);
  yield takeLatest(GET_TIPOS_PROGRAMA, fetchTiposPrograma);
  yield takeLatest(GET_PROGRAMAS_SIN_DECRETO, fetchProgramasSinDecreto);
  yield takeLatest(UPDATE_ESTADO_DECRETO_PROGRAMA, putProgramaDecretoEstado);
  yield takeLatest(GENERA_SYLLABUS_PROGRAMA, generaSyllabusProg);
  
  
  yield takeLatest(ADD_DATOSWEBPROGRAMA , add_DatosWebPrograma);
  yield takeLatest(ADD_DATOSWEBPROGRAMAVERSIONES , add_DatosWebProgramaVersiones);

  yield takeLatest(GET_PROGRAMADATOSWEB, fetchProgramaDatosWeb);
  yield takeLatest(GET_PROGRAMAVERSIONESDATOSWEB, fetchProgramaVersionesDatosWeb);
  

}

export default programaSaga; 
