import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { withRouter, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import avatarDummy from "../assets/images/users/user-dummy-img.jpg";
import logoFenix from "../assets/images/logo-sm.png";

//import Components
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import RightSidebar from '../Components/Common/RightSidebar';

//import actions
import {
    changeLayout,
    changeSidebarTheme,
    changeLayoutMode,
    changeLayoutWidth,
    changeLayoutPosition,
    changeTopbarTheme,
    changeLeftsidebarSizeType,
    changeLeftsidebarViewType,
    changeSidebarImageType,
    profileMenu,
    profileData,
    getNotificaciones,
    profileUserInfo
} from "../store/actions";

//redux
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useProfile } from '../Components/Hooks/UserHooks';
import { setAuthorization } from '../helpers/api_helper';
import ResetPassword from '../pages/AuthenticationInner/ResetPassword';

const Layout = (props) => {
    let location = useLocation();
    const dispatch = useDispatch();
    const {
        layoutType,
        leftSidebarType,
        layoutModeType,
        layoutWidthType,
        layoutPositionType,
        topbarThemeType,
        leftsidbarSizeType,
        leftSidebarViewType,
        leftSidebarImageType,
    } = useSelector(state => ({
        layoutType: state.Layout.layoutType,
        leftSidebarType: state.Layout.leftSidebarType,
        layoutModeType: state.Layout.layoutModeType,
        layoutWidthType: state.Layout.layoutWidthType,
        layoutPositionType: state.Layout.layoutPositionType,
        topbarThemeType: state.Layout.topbarThemeType,
        leftsidbarSizeType: state.Layout.leftsidbarSizeType,
        leftSidebarViewType: state.Layout.leftSidebarViewType,
        leftSidebarImageType: state.Layout.leftSidebarImageType,
    }), shallowEqual);
    const [resetPassword, setResetPassword] = useState(false);
    const { token } = useProfile();

    const cargaMenu = useCallback(() => {
        dispatch(profileMenu());
    }, [dispatch]);

    const cargaNotificaciones = useCallback(() => {
        dispatch(getNotificaciones());
    }, [dispatch]);

    const checkNewVersion = useCallback(() => {
        const accion = () => {
            window.localStorage.removeItem("version-update-needed"); // remove the storage object
            window.location.reload(); // refresh the browser
        };

        const Msg = ({ closeToast, toastProps }) => (
            <div className="d-flex flex-column align-items-center">
                <div className="mb-2 text-center">
                    <img src={logoFenix} className='mb-2' alt="update" height="50" />
                    <h5 className="mb-2 text-body fw-bold h-1">
                        ¡Nueva versión disponible!
                    </h5>
                    <p className="text-muted">
                        Existe una nueva versión del sistema. Por favor, guarde su trabajo antes de actualizar.
                    </p>
                </div>
                <div className="d-flex gap-2">
                    <button className="btn" onClick={closeToast}>
                        <i className="ri-close-line me-1"></i>
                        Cerrar
                    </button>
                    <button className="btn btn-success" onClick={accion}>
                        <i className="ri-refresh-line me-1"></i>
                        Actualizar ahora
                    </button>
                </div>
            </div>
        );

        if (!location.state && window.localStorage.getItem("version-update-needed")) {
            toast(<Msg />, {
                toastId: "version-update-needed",
                position: "top-right",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
        }
    }, [location.state]);

    useEffect(() => {
        setAuthorization(token);
        // Carga inicial
        cargaMenu();
        cargaNotificaciones();
        checkNewVersion();
    }, [token, cargaMenu, cargaNotificaciones, checkNewVersion]);

    useEffect(() => {
        const menuInterval = setInterval(cargaMenu, 30000);
        return () => clearInterval(menuInterval);
    }, [cargaMenu]);

    useEffect(() => {
        const notificacionesInterval = setInterval(cargaNotificaciones, 60000);
        return () => clearInterval(notificacionesInterval);
    }, [cargaNotificaciones]);

    useEffect(() => {
        const versionInterval = setInterval(checkNewVersion, 600000);
        return () => clearInterval(versionInterval);
    }, [checkNewVersion]);

    useEffect(() => {
        setAuthorization(token);
        setResetPassword(JSON.parse(sessionStorage.getItem("reset-password")))

        if (localStorage.getItem("authUser") === null) {
            const currentPath = window.location.pathname;
            const basePath = currentPath.split('/')[1];

            const loginUrl = `/${basePath}/login`;

            window.location.href = loginUrl;
        }
        var base64Url = localStorage.getItem("authUser").split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split("")
                .map(function (c) {
                    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join("")
        );

        const jwtData = JSON.parse(jsonPayload);
        const obj = JSON.parse(localStorage.getItem("authUser"));

        const roles = jwtData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
        const rolesArray = Array.isArray(roles) ? roles : [roles];
        const rolesFEN = ["AnalistaFEN", "AcademicoFEN", "CoordinadorAdministrativoFEN", "DirectorInvestigacionFEN", "AyudanteFEN"];

        let home_title = "";
        if (rolesArray.every(role => rolesFEN.includes(role))) {
            home_title = "Sistema Investigación FEN";
        } else {
            home_title = "Fénix DCS";
        }

        const dataUsuario = { id: jwtData.id, usuario: jwtData.usuario, nombre: obj.data.first_name, avatar: avatarDummy, personificar: jwtData.personificar === "true", home_title: home_title }
        dispatch(profileData(dataUsuario))
        dispatch(profileUserInfo())
    }, [dispatch, token]);

    /*
    layout settings
    */
    useEffect(() => {
        if (
            layoutType ||
            leftSidebarType ||
            layoutModeType ||
            layoutWidthType ||
            layoutPositionType ||
            topbarThemeType ||
            leftsidbarSizeType ||
            leftSidebarViewType ||
            leftSidebarImageType
        ) {
            dispatch(changeLeftsidebarViewType(leftSidebarViewType));
            dispatch(changeLeftsidebarSizeType(leftsidbarSizeType));
            dispatch(changeSidebarTheme(leftSidebarType));
            dispatch(changeLayoutMode(layoutModeType));
            dispatch(changeLayoutWidth(layoutWidthType));
            dispatch(changeLayoutPosition(layoutPositionType));
            dispatch(changeTopbarTheme(topbarThemeType));
            dispatch(changeLayout(layoutType));
            dispatch(changeSidebarImageType(leftSidebarImageType))
        }
    }, [layoutType,
        leftSidebarType,
        layoutModeType,
        layoutWidthType,
        layoutPositionType,
        topbarThemeType,
        leftsidbarSizeType,
        leftSidebarViewType,
        leftSidebarImageType,
        dispatch]);
    /*
    call dark/light mode
    */
    const onChangeLayoutMode = (value) => {
        if (changeLayoutMode) {
            dispatch(changeLayoutMode(value));
        }
    };

    const [headerClass, setHeaderClass] = useState("");
    // class add remove in header
    useEffect(() => {
        window.addEventListener("scroll", scrollNavigation, true);
    });
    function scrollNavigation() {
        var scrollup = document.documentElement.scrollTop;
        if (scrollup > 50) {
            setHeaderClass("topbar-shadow");
        } else {
            setHeaderClass("");
        }
    }

    const [openLeftCanvas, setOpenLeftCanvas] = useState(false);
    const toggleLeftCanvas = () => {
        setOpenLeftCanvas(!openLeftCanvas);
    };

    return (
        <React.Fragment>
            {resetPassword && <ResetPassword />}
            <div id="layout-wrapper">
                <Header
                    headerClass={headerClass}
                    layoutModeType={layoutModeType}
                    onChangeLayoutMode={onChangeLayoutMode}
                    toggleLeftCanvas={toggleLeftCanvas} />
                <Sidebar layoutType={layoutType} />
                <div className="main-content">{props.children}
                    <Footer />
                </div>
            </div>
            <RightSidebar toggleLeftCanvas={toggleLeftCanvas} open={openLeftCanvas} />
        </React.Fragment>

    );
};

Layout.propTypes = {
    children: PropTypes.object,
};

export default withRouter(Layout);