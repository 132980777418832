import {
    GET_FBYMES, GET_FBYMES_SUCCESS, 
    GET_FBYMESRANGO, GET_FBYMESRANGO_SUCCESS, 
    GET_FBYMESRANGOCOMPARATIVO, GET_FBYMESRANGOCOMPARATIVO_SUCCESS, 
    GET_FBYSEMANAS, GET_FBYSEMANAS_SUCCESS, 
    GET_FBYDIAMES, GET_FBYDIAMES_SUCCESS, 
    GET_FBYMESRANGOTIPO, GET_FBYMESRANGOTIPO_SUCCESS, 
    GET_NVBYMES, GET_NVBYMES_SUCCESS, 
    GET_NVBYMESHOME, GET_NVBYMESHOME_SUCCESS, 
    GET_NVBYMESTIPO, GET_NVBYMESTIPO_SUCCESS,
    GET_NVTIPOSPAGO, GET_NVTIPOSPAGO_SUCCESS,
    GET_NVCANTIDADBYMESTIPO, GET_NVCANTIDADBYMESTIPO_SUCCESS,
    POST_CARGADATAAUGE, POST_CARGADATAAUGE_SUCCESS, GET_BOLETABYMESRANGO, GET_BOLETABYMESRANGO_SUCCESS,
} from './actionTypes'

export const getFacturasByMes = (params) => ({
  type: GET_FBYMES,
  payload: params
});

export const getFacturasByMesSuccess = (params) => ({
  type: GET_FBYMES_SUCCESS,
  payload: params
});

export const getFacturasByMesRango = (params) => ({
  type: GET_FBYMESRANGO,
  payload: params
});

export const getFacturasByMesRangoSuccess = (params) => ({
  type: GET_FBYMESRANGO_SUCCESS,
  payload: params
});

export const getFacturasByMesRangoComparativo = (params) => ({
  type: GET_FBYMESRANGOCOMPARATIVO,
  payload: params
});

export const getFacturasByMesRangoComparativoSuccess = (params) => ({
  type: GET_FBYMESRANGOCOMPARATIVO_SUCCESS,
  payload: params
});

export const getFacturasBySemanas = (params) => ({
  type: GET_FBYSEMANAS,
  payload: params
});

export const getFacturasBySemanasSuccess = (params) => ({
  type: GET_FBYSEMANAS_SUCCESS,
  payload: params
});

export const getFacturasByDiaMes = (params) => ({
  type: GET_FBYDIAMES,
  payload: params
});

export const getFacturasByDiaMesSuccess = (params) => ({
  type: GET_FBYDIAMES_SUCCESS,
  payload: params
});

export const getFacturasByMesRangoTipo = (params) => ({
  type: GET_FBYMESRANGOTIPO,
  payload: params
});

export const getFacturasByMesRangoTipoSuccess = (params) => ({
  type: GET_FBYMESRANGOTIPO_SUCCESS,
  payload: params
});

export const getBoletasByMesRango = (params) => ({
  type: GET_BOLETABYMESRANGO,
  payload: params
});

export const getBoletasByMesRangoSuccess = (params) => ({
  type: GET_BOLETABYMESRANGO_SUCCESS,
  payload: params
});

export const getNVByMes = (params) => ({
  type: GET_NVBYMES,
  payload: params
});

export const getNVByMesSuccess = (params) => ({
  type: GET_NVBYMES_SUCCESS,
  payload: params
});

export const getNVByMesHome = (params) => ({
  type: GET_NVBYMESHOME,
  payload: params
});

export const getNVByMesHomeSuccess = (params) => ({
  type: GET_NVBYMESHOME_SUCCESS,
  payload: params
});

export const getNVByMesTipo = (params) => ({
  type: GET_NVBYMESTIPO,
  payload: params
});

export const getNVByMesTipoSuccess = (params) => ({
  type: GET_NVBYMESTIPO_SUCCESS,
  payload: params
});

export const getNVTiposPago = (params) => ({
  type: GET_NVTIPOSPAGO,
  payload: params
});

export const getNVTiposPagoSuccess = (params) => ({
  type: GET_NVTIPOSPAGO_SUCCESS,
  payload: params
});

export const getNVCantidadByMesTipo = (params) => ({
  type: GET_NVCANTIDADBYMESTIPO,
  payload: params
});

export const getNVCantidadByMesTipoSuccess = (params) => ({
  type: GET_NVCANTIDADBYMESTIPO_SUCCESS,
  payload: params
});

export const postCargaDataAuge = (fileId) => ({
  type: POST_CARGADATAAUGE,
  payload: fileId
});

export const postCargaDataAugeSuccess = (fileId) => ({
  type: POST_CARGADATAAUGE_SUCCESS,
  payload: fileId
});




