import * as Yup from 'yup'
import { rutValidate } from 'rut-helpers';

export default function configurarValidaciones(){
    Yup.addMethod(Yup.string, 'validaRUT', function (){
        return this.test('valida-rut', 'Rut incorrecto', 
        function (valor) {
            if (valor && valor.length > 0){
                return rutValidate(valor)
            }

            return true;
        })
    })
    Yup.addMethod(Yup.string, "unique", function(message, arrayUnique, col) {
        return this.test("unique", message, function(value) {
          const z = arrayUnique.filter(x => x[col] === value)
          if (z.length === 0) {
            return true;
          }

          return this.createError({
            message: message,
          });
        });
      });
}