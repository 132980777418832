import { put, call, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import { POST_MOVER_ARCHIVOS_PENTAHO } from "./actionTypes";
import { moverArchivosPentahoFail, moverArchivosPentahoSuccess } from "./actions";
import { postMoverArchivosAsientosContables } from "../../../helpers/backend_helper";

function* moverArchivosPentaho({ payload: params }) {
  try {
    const response = yield call(postMoverArchivosAsientosContables, params);
    yield put(moverArchivosPentahoSuccess(response));
  } catch (error) {
    console.log(error)
    yield put(moverArchivosPentahoFail(error));
    toast.error("Error al cargar realizar el proceso", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* ControlGestionSaga() {
  yield takeLatest(POST_MOVER_ARCHIVOS_PENTAHO, moverArchivosPentaho);
}

export default ControlGestionSaga;
