import { GET_LIST, GET_LIST_SUCCESS } from "./actionTypes";

export const getList = (backendList, paramId = null) => ({
  type: GET_LIST,
  payload: { backendList, paramId }
});

export const getListSuccess = (params, data, paramId = null) => ({
  type: GET_LIST_SUCCESS,
  payload: { params, data, paramId }
});