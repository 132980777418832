import {
    GET_FBYMES,
    GET_FBYMES_SUCCESS,
    GET_FBYMESRANGO,
    GET_FBYMESRANGO_SUCCESS,
    GET_FBYMESRANGOCOMPARATIVO,
    GET_FBYMESRANGOCOMPARATIVO_SUCCESS,    
    GET_FBYSEMANAS,
    GET_FBYSEMANAS_SUCCESS,
    GET_FBYDIAMES,
    GET_FBYDIAMES_SUCCESS,  
    GET_FBYMESRANGOTIPO,
    GET_FBYMESRANGOTIPO_SUCCESS,      
    GET_NVBYMES,
    GET_NVBYMES_SUCCESS,    
    GET_NVBYMESHOME,
    GET_NVBYMESHOME_SUCCESS,        
    GET_NVBYMESTIPO,
    GET_NVBYMESTIPO_SUCCESS,
    GET_NVTIPOSPAGO,
    GET_NVTIPOSPAGO_SUCCESS,            
    GET_NVCANTIDADBYMESTIPO,
    GET_NVCANTIDADBYMESTIPO_SUCCESS,                
    POST_CARGADATAAUGE,
    POST_CARGADATAAUGE_SUCCESS,
    GET_BOLETABYMESRANGO_SUCCESS,
} from './actionTypes'

const INIT_STATE = {
    facturaspormes: [],
    facturaspormesrango: [],
    facturaspormesrangocomparativo: [],
    facturasporsemanas: [],
    facturaspordiames: [],
    facturaspormesrangotipo: [],
    boletaspormesrango: [],
    nvpormes: [],
    nvpormeshome: [],
    nvpormestipo: [],
    nvtipospago: [],
    nvcantidadbymestipo: [],
    cargadataauge: [],
    error: "",
    loading: false
}

const FinanzasDashboard = (state = INIT_STATE, action) => {
    switch(action.type){
        case GET_FBYMES:
            return {
              ...state,
              loading: true
            }
        case GET_FBYMES_SUCCESS:
            return {
              ...state,
              loading: false,
              facturaspormes: action.payload.data
            }
        case GET_FBYMESRANGO:
            return {
              ...state,
              loading: true
            }
        case GET_FBYMESRANGO_SUCCESS:
            return {
              ...state,
              loading: false,
              facturaspormesrango: action.payload.data,
            }
        case GET_FBYMESRANGOCOMPARATIVO:
            return {
              ...state,
              loading: true
            }                                    
        case GET_FBYMESRANGOCOMPARATIVO_SUCCESS:
            return {
              ...state,
              loading: false,
              facturaspormesrangocomparativo: action.payload.data,
            }                                    
        case GET_FBYSEMANAS:
            return {
              ...state,
              loading: true
            }
        case GET_FBYSEMANAS_SUCCESS:
            return {
              ...state,
              loading: false,
              facturasporsemanas: action.payload.data
            }
        case GET_FBYDIAMES:
            return {
              ...state,
              loading: true
            }
        case GET_FBYDIAMES_SUCCESS:
            return {
              ...state,
              loading: false,
              facturaspordiames: action.payload.data
            }  
        case GET_FBYMESRANGOTIPO:
            return {
              ...state,
              loading: true
            }
        case GET_FBYMESRANGOTIPO_SUCCESS:
            return {
              ...state,
              loading: false,
              facturaspormesrangotipo: action.payload.data,
            }
        case GET_BOLETABYMESRANGO_SUCCESS:
            return {
              ...state,
              boletaspormesrango: action.payload.data,
            }                       
        case GET_NVBYMES:
            return {
              ...state,
              loading: true
            }
        case GET_NVBYMES_SUCCESS:
            return {
              ...state,
              loading: false,
              nvpormes: action.payload.data
            }
        case GET_NVBYMESHOME:
            return {
              ...state,
              loading: true
            }
        case GET_NVBYMESHOME_SUCCESS:
            return {
              ...state,
              loading: false,
              nvpormeshome: action.payload.data
            }            
        case GET_NVBYMESTIPO:
            return {
              ...state,
              loading: true
            }
        case GET_NVBYMESTIPO_SUCCESS:
            return {
              ...state,
              loading: false,
              nvpormestipo: action.payload.data
            }
        case GET_NVTIPOSPAGO:
            return {
              ...state,
              loading: true
            }
        case GET_NVTIPOSPAGO_SUCCESS:
            return {
              ...state,
              loading: false,
              nvtipospago: action.payload.data
            }
        case GET_NVCANTIDADBYMESTIPO:
            return {
              ...state,
              loading: true
            }
        case GET_NVCANTIDADBYMESTIPO_SUCCESS:
            return {
              ...state,
              loading: false,
              nvcantidadbymestipo: action.payload.data
            }                                                                        
        case POST_CARGADATAAUGE:
            return {
              ...state,
              loading: true
            }
        case POST_CARGADATAAUGE_SUCCESS:
            return {
              ...state,
              loading: false,
              cargadataauge: action.payload.data
            }                                                                                    
        default:
            return state
    }
}

export default FinanzasDashboard