import { takeEvery, put, call, fork, takeLatest, all } from "redux-saga/effects"
import { toast } from 'react-toastify';

import {
    ADD_NEW_USER,
    ADD_NEW_PERFIL,
    ADD_NEW_ROL,
    ADD_PERFIL_USER,
    ADD_ROL_PERFIL,
    GET_ALL_PERFILES,
    GET_ALL_ROLES,
    GET_USERS,
    UPDATE_USER,
    UPDATE_USER_PARCIAL,
    UPDATE_PERFIL,
    UPDATE_ROL,
    DELETE_PERFIL_USER,
    DELETE_ROL_PERFIL,
    UPDATE_PASSWORD_DCS,
    RESET_PASSWORD_USER,
    RECUPERA_PASSWORD_USER,
    CAMBIA_PASSWORD_USER
} from './actionTypes'

import {
    getUsersSuccess,
    getAllPerfilesSuccess,
    getAllRolesSuccess,
    updatePasswordDCSSuccess,
    recuperaPasswordUserSuccess,
    cambiaPasswordUserSuccess,
    addNewUserSuccess,
    addNewUserFail
} from './actions'

import {
    getUsers,
    getAllPerfiles,
    getAllRoles,
    updateUser,
    updateUserParcial,
    updatePerfil,
    updateRol,
    addNewUser,
    addNewPerfil,
    addNewRol,
    addNewPerfilUser,
    deletePerfilUser,
    addNewRolPerfil,
    deleteRolPerfil,
    updatePasswordDCS,
    resetPasswordUser,
    recuperaPasswordUser,
    cambiaPasswordUser
} from '../../helpers/backend_helper'

function* fetchUsers({payload : params}){
    try {
        const response = yield call(getUsers, params)
        yield put(getUsersSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchPerfiles(){
    try {
        const response = yield call(getAllPerfiles)
        yield put(getAllPerfilesSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchRoles(){
    try {
        const response = yield call(getAllRoles)
        yield put(getAllRolesSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* putUser({payload: {user, params}}){
    try {
        const response = yield call(updateUser, user)
        const responseUsers = yield call(getUsers, params)
        yield put(getUsersSuccess(responseUsers))
        toast.success("Usuario actualizado con éxito", { autoClose: 3000 });
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* putPerfil({payload: {perfil, values}}){
    try {
        const response = yield call(updatePerfil, perfil, values)
        const responsePerfiles = yield call(getAllPerfiles)
        yield put(getAllPerfilesSuccess(responsePerfiles))
        toast.success("Perfil actualizado con éxito", { autoClose: 3000 });
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* putRol({payload: {rol, values}}){
    try {
        const response = yield call(updateRol, rol, values)
        const responseRoles = yield call(getAllRoles)
        yield put(getAllRolesSuccess(responseRoles))
        toast.success("Rol actualizado con éxito", { autoClose: 3000 });
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* putPasswordDCS({payload}){
    try {
        const response = yield call(updatePasswordDCS, payload)
        yield put(updatePasswordDCSSuccess())
        // toast.success("Rol actualizado con éxito", { autoClose: 3000 });
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* patchUser({payload: {user, upd, params}}){
    try {
        const response = yield call(updateUserParcial, user, upd)
        const responseUsers = yield call(getUsers, params)
        yield put(getUsersSuccess(responseUsers))
        toast.success("Usuario actualizado con éxito", { autoClose: 3000 });
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* addUser({payload: {user, params, perfiles}}){
    try {
        const response = yield call(addNewUser, user)
        yield all(perfiles.map(element =>
            fork(addPerfilUser, {payload: {userId: response.data.id, perfilId: element}})
        ));
        yield put(addNewUserSuccess())
        const responseUsers = yield call(getUsers, params)
        yield put(getUsersSuccess(responseUsers))
        toast.success("Usuario creado con éxito", { autoClose: 3000 });
    } catch (error) {
        yield put(addNewUserFail())
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* addPerfil({payload: {perfil, roles}}){
    try {
        const response = yield call(addNewPerfil, perfil)
        yield all(roles.map(element =>
            fork(addRolPerfil, {payload: {perfilId: response.data.id, rolId: element}})
        ));
        const responsePerfiles = yield call(getAllPerfiles)
        yield put(getAllPerfilesSuccess(responsePerfiles))
        toast.success("Perfil creado con éxito", { autoClose: 3000 });
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* addRol({payload: rol}){
    try {
        const response = yield call(addNewRol, rol)
        const responseRoles = yield call(getAllRoles)
        yield put(getAllRolesSuccess(responseRoles))
        toast.success("Rol creado con éxito", { autoClose: 3000 });
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* addPerfilUser({payload: perfil}){
    try {
        const response = yield call(addNewPerfilUser, perfil)
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* addRolPerfil({payload: params}){
    try {
        const response = yield call(addNewRolPerfil, params)
        const responsePerfiles = yield call(getAllPerfiles)
        yield put(getAllPerfilesSuccess(responsePerfiles))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* resetPassword({payload: {user, email}}){
    try {
        const response = yield call(resetPasswordUser, user)
        toast.success("Instrucciones de reseteo de password enviadas al correo "+ email, {
            position: toast.POSITION.TOP_RIGHT
          });
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* recuperaPassword({payload: email}){
    try {
        const response = yield call(recuperaPasswordUser, email)
        yield put(recuperaPasswordUserSuccess())
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
} 

function* cambiaPassword({payload: {idCambio, password}}){
    try {
        const response = yield call(cambiaPasswordUser, {idCambio, password})
        yield put(cambiaPasswordUserSuccess())
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
} 

function* removePerfilUser({payload: perfil}){
    try {
        const response = yield call(deletePerfilUser, perfil)
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* removeRolPerfil({payload: params}){
    try {
        const response = yield call(deleteRolPerfil, params)
        const responsePerfiles = yield call(getAllPerfiles)
        yield put(getAllPerfilesSuccess(responsePerfiles))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* userManagementSaga() {
    yield takeEvery(GET_USERS, fetchUsers)
    yield takeEvery(GET_ALL_PERFILES, fetchPerfiles)
    yield takeEvery(GET_ALL_ROLES, fetchRoles)
    yield takeLatest(UPDATE_USER, putUser)
    yield takeLatest(UPDATE_USER_PARCIAL, patchUser)
    yield takeLatest(UPDATE_PERFIL, putPerfil)
    yield takeLatest(UPDATE_ROL, putRol)
    yield takeLatest(UPDATE_PASSWORD_DCS, putPasswordDCS)
    yield takeLatest(ADD_NEW_USER, addUser)
    yield takeEvery(ADD_NEW_PERFIL, addPerfil)
    yield takeLatest(ADD_NEW_ROL, addRol)
    yield takeEvery(ADD_PERFIL_USER, addPerfilUser)
    yield takeEvery(ADD_ROL_PERFIL, addRolPerfil)
    yield takeEvery(RESET_PASSWORD_USER, resetPassword)
    yield takeEvery(RECUPERA_PASSWORD_USER, recuperaPassword)
    yield takeEvery(CAMBIA_PASSWORD_USER, cambiaPassword)
    yield takeEvery(DELETE_PERFIL_USER, removePerfilUser)
    yield takeEvery(DELETE_ROL_PERFIL, removeRolPerfil)
}

export default userManagementSaga