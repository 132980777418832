import {
    GET_AYUDANTES,
    GET_AYUDANTES_SUCCESS,
    ADD_NEW_AYUDANTE,
    GET_AYUDANTESADDED,
    GET_AYUDANTE_BY_RUT,
    GET_AYUDANTE_BY_RUT_SUCCESS,
} from './actionTypes'

const INIT_STATE = {
    ayudantesArray: [],
    paginacion: {},
    ayudanteAddedArray: [],
    ayudanteByRutArray: [],
    error: "",
    loading: false
}

const Ayudantes = (state = INIT_STATE, action) => {
    switch(action.type){
        case GET_AYUDANTES:
            return {
              ...state,
              loading: true
            }
        case GET_AYUDANTES_SUCCESS:
            return {
              ...state,
              loading: false,
              //paginacion : JSON.parse(action.payload.headers['x-pagination']),
              ayudantesArray: action.payload.data
            }
        case ADD_NEW_AYUDANTE:
          return {
            ...state,
          }
        case GET_AYUDANTESADDED:
            return {
              ...state,
              ayudanteAddedArray: action.payload,
            }          
        case GET_AYUDANTE_BY_RUT:
            return {
              ...state,
              loading: true
            }          
        case GET_AYUDANTE_BY_RUT_SUCCESS:
            return {
              ...state,
              ayudanteByRutArray: action.payload,
            }                                
            default:    
            return state 
    }
}

export default Ayudantes