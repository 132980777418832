import {
    GET_FERIADOS, GET_FERIADOS_SUCCESS, 
    UPDATE_FERIADO,ADD_NEW_FERIADO,
    DELETE_FERIADO,
    GET_TIPOBLOQUEOS, GET_TIPOBLOQUEOS_SUCCESS, 
    GET_FERIADOSBYRANGO, GET_FERIADOSBYRANGO_SUCCESS,
    ADD_NEW_BLOQUEO, 
} from './actionTypes'

export const getFeriados = (params) => ({
  type: GET_FERIADOS,
  payload: params
});

export const getFeriadosSuccess = (params) => ({
    type: GET_FERIADOS_SUCCESS,
    payload: params
});
  
export const updateFeriado = (feriado, fetchData) => ({
  type: UPDATE_FERIADO,
  payload: {feriado, fetchData}
});

export const addNewFeriado = (feriado, fetchData) => ({
  type: ADD_NEW_FERIADO,
  payload: {feriado, fetchData},
});
export const addNewBloqueo = (bloqueo, fetchData) => ({
  type: ADD_NEW_BLOQUEO,
  payload: {bloqueo, fetchData},
});
export const deleteFeriado = (feriado, fetchData) => ({
  type: DELETE_FERIADO,
  payload: {feriado, fetchData} 
});
export const getTipoBloqueos = () => ({
  type: GET_TIPOBLOQUEOS,
});
export const getTipoBloqueosSuccess = (params) => ({
  type: GET_TIPOBLOQUEOS_SUCCESS,
  payload: params
});
export const getFeriadosByRango = (params) => ({
  type: GET_FERIADOSBYRANGO,
  payload: params
});
export const getFeriadosByRangoSuccess = (params) => ({
  type: GET_FERIADOSBYRANGO_SUCCESS,
  payload: params
});
