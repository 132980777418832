export const GET_FERIADOS = "GET_FERIADOS";
export const GET_FERIADOS_SUCCESS = "GET_FERIADOS_SUCCESS";
export const UPDATE_FERIADO = "UPDATE_FERIADO";
export const ADD_NEW_FERIADO = "ADD_NEW_FERIADO";
export const ADD_NEW_BLOQUEO = "ADD_NEW_BLOQUEO";
export const DELETE_FERIADO = "DELETE_FERIADO";
export const GET_TIPOBLOQUEOS = "GET_TIPOBLOQUEOS";
export const GET_TIPOBLOQUEOS_SUCCESS = "GET_TIPOBLOQUEOS_SUCCESS";
export const GET_FERIADOSBYRANGO = "GET_FERIADOSBYRANGO";
export const GET_FERIADOSBYRANGO_SUCCESS = "GET_FERIADOSBYRANGO_SUCCESS";
