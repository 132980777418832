import { takeEvery, call, put, takeLatest, all, fork} from "redux-saga/effects"
import { toast } from 'react-toastify';

import {
    GET_DOLARES,
    ADD_NEW_DOLAR,
    UPDATE_DOLAR,
    GET_DOLAR_VIGENTE,
    GET_CECOS_SOLICITUD,
    UPDATE_CECO_SOLICITUD,
    ADD_NEW_CECO_SOLICITUD,
    GET_CECO_SOLICITUD,
} from './actionTypes'

import { getDolares, updateDolar, addNewDolar, getDolarVigente, getCecosSolicitud, updateCecoSolicitud, addNewCecoSolicitud, getCecoSolicitud} from "../../helpers/backend_helper";
import { getDolaresSuccess, getDolarVigenteSuccess, getCecosSolicitudSuccess, getCecoSolicitudSuccess } from "./actions";
                                                    

function* fetchDolares({payload : params}){
    try {
        const response = yield call(getDolares, params)
        yield put(getDolaresSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
    
}

function* putDolar({payload: {dolar, params}}){
    try {
        const response = yield call(updateDolar, dolar)
        const responseDolares = yield call(getDolares, params)
        yield put(getDolaresSuccess(responseDolares))
        toast.success("Dólar actualizado con éxito", { autoClose: 3000 });
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* addDolar({payload: {dolar, params}}){
    try {

        const response = yield call(addNewDolar, dolar)
        const responseDolares = yield call(getDolares, params)
        yield put(getDolaresSuccess(responseDolares))
        toast.success("Dólar creado con éxito", { autoClose: 3000 });
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchDolarVigente(){
    try {
        const response = yield call(getDolarVigente)
        yield put(getDolarVigenteSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
 }

function* fetchCecos({payload : params}){
    try {
        const response = yield call(getCecosSolicitud, params)
        yield put(getCecosSolicitudSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
    
}

function* putCeco({payload: {ceco, params}}){
    try {

        console.log("params", params)

        const response = yield call(updateCecoSolicitud, ceco)
        const responseCecos = yield call(getCecosSolicitud, params)
        yield put(getCecosSolicitudSuccess(responseCecos))
        toast.success("Centro de Costo actualizado con éxito", { autoClose: 3000 });
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* addCeco({payload: {ceco, params}}){
    try {

        const response = yield call(addNewCecoSolicitud, ceco)
        const responseCecos = yield call(getCecosSolicitud, params)
        yield put(getCecosSolicitudSuccess(responseCecos))
        toast.success("CECO creado con éxito", { autoClose: 3000 });
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchCeco(){
    try {
        const response = yield call(getCecoSolicitud)
        yield put(getCecoSolicitudSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
 }

function* mantenedoresSaga() {
    yield takeEvery(GET_DOLARES, fetchDolares)   
    yield takeLatest(UPDATE_DOLAR, putDolar)
    yield takeLatest(ADD_NEW_DOLAR, addDolar)
    yield takeEvery(GET_DOLAR_VIGENTE, fetchDolarVigente)   

    yield takeEvery(GET_CECOS_SOLICITUD, fetchCecos)   
    yield takeLatest(UPDATE_CECO_SOLICITUD, putCeco)
    yield takeLatest(ADD_NEW_CECO_SOLICITUD, addCeco)
    yield takeEvery(GET_CECO_SOLICITUD, fetchCeco)       
}



export default mantenedoresSaga