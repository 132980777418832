import {
  CHANGE_LAYOUT,
  CHANGE_SIDEBAR_THEME,
  CHANGE_LAYOUT_MODE,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_LAYOUT_POSITION,
  CHANGE_TOPBAR_THEME,
  CHANGE_SIDEBAR_SIZE_TYPE,
  CHANGE_SIDEBAR_VIEW,
  CHANGE_SIDEBAR_IMAGE_TYPE,
  RESET_VALUE,
  CHANGE_PRELOADER
} from './actionType';

//constants
import {
  layoutTypes,
  leftSidebarTypes,
  layoutModeTypes,
  layoutWidthTypes,
  layoutPositionTypes,
  topbarThemeTypes,
  leftsidbarSizeTypes,
  leftSidebarViewTypes,
  leftSidebarImageTypes,
  preloaderTypes
} from "../../Components/constants/layout";
import { isNil } from 'ramda';

const jsonSettings = localStorage.getItem('intranet-dcs-settings');
const settings = JSON.parse(jsonSettings)
                // let settings = [];
                // if (jsonSettings){
                //     settings = JSON.parse(jsonSettings)
                //     dispatch(changeLeftsidebarViewType(settings.find(s => s.attribute === "data-layout-style").value)); //
                //     dispatch(changeLeftsidebarSizeType(settings.find(s => s.attribute === "data-sidebar-size").value)); //
                //     dispatch(changeSidebarTheme(settings.find(s => s.attribute === "data-sidebar").value)); --
                //     dispatch(changeLayoutMode(settings.find(s => s.attribute === "data-layout-mode").value)); //
                //     dispatch(changeLayoutWidth(layoutWidthType));
                //     // dispatch(changeLayoutWidth(settings.find(s => s.attribute === "data-layout-width").value)); //
                //     dispatch(changeLayoutPosition(settings.find(s => s.attribute === "data-layout-position").value)); //
                //     dispatch(changeTopbarTheme(settings.find(s => s.attribute === "data-topbar").value)); //
                //     dispatch(changeLayout(settings.find(s => s.attribute === "data-layout").value));--
                //     dispatch(changeSidebarImageType(settings.find(s => s.attribute === "data-sidebar-image").value)) //
                // }

const INIT_STATE = {
  layoutType: !isNil(jsonSettings) ? settings.find(s => s.attribute === "data-layout").value : layoutTypes.VERTICAL,
  leftSidebarType: !isNil(jsonSettings) ? settings.find(s => s.attribute === "data-sidebar").value : leftSidebarTypes.DARK,
  layoutModeType: !isNil(jsonSettings) ? settings.find(s => s.attribute === "data-layout-mode").value : layoutModeTypes.LIGHTMODE, //
  layoutWidthType: !isNil(jsonSettings) ? settings.find(s => s.attribute === "data-layout-width").value : layoutWidthTypes.FLUID, //
  layoutPositionType: !isNil(jsonSettings) ? settings.find(s => s.attribute === "data-layout-position").value : layoutPositionTypes.FIXED, //
  topbarThemeType: !isNil(jsonSettings) ? settings.find(s => s.attribute === "data-topbar").value : topbarThemeTypes.LIGHT, //
  leftsidbarSizeType: !isNil(jsonSettings) ? settings.find(s => s.attribute === "data-sidebar-size").value : leftsidbarSizeTypes.SMALLHOVER, //
  leftSidebarViewType: !isNil(jsonSettings) ? settings.find(s => s.attribute === "data-layout-style").value : leftSidebarViewTypes.DEFAULT, //
  leftSidebarImageType: !isNil(jsonSettings) ? settings.find(s => s.attribute === "data-sidebar-image").value : leftSidebarImageTypes.NONE, //
  preloader: preloaderTypes.DISABLE
};

const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LAYOUT:
      return {
        ...state,
        layoutType: action.payload,
      };

    case CHANGE_LAYOUT_MODE:
      return {
        ...state,
        layoutModeType: action.payload,
      };

    case CHANGE_SIDEBAR_THEME:
      return {
        ...state,
        leftSidebarType: action.payload,
      };

    case CHANGE_LAYOUT_WIDTH:
      return {
        ...state,
        layoutWidthType: action.payload,
      };

    case CHANGE_LAYOUT_POSITION:
      return {
        ...state,
        layoutPositionType: action.payload,
      };

    case CHANGE_TOPBAR_THEME:
      return {
        ...state,
        topbarThemeType: action.payload,
      };

    case CHANGE_SIDEBAR_SIZE_TYPE:
      return {
        ...state,
        leftsidbarSizeType: action.payload,
      };

    case CHANGE_SIDEBAR_VIEW:
      return {
        ...state,
        leftSidebarViewType: action.payload,
      };

    case CHANGE_SIDEBAR_IMAGE_TYPE:
      return {
        ...state,
        leftSidebarImageType: action.payload,
      };

    case RESET_VALUE:
      return {
        ...state,
        resetValue: INIT_STATE,
      };
      
    case CHANGE_PRELOADER:
      return {
        ...state,
        preloader: action.payload,
      };
    default:
      return state;
  }
};

export default Layout;
