export const GET_CROSSREF = "GET_CROSSREF";
export const GET_CROSSREF_SUCCESS = "GET_CROSSREF_SUCCESS";
export const GET_CROSSREF_FAIL = "GET_CROSSREF_FAIL";
export const GET_PUBLICACIONES = "GET_PUBLICACIONES";
export const GET_PUBLICACIONES_SUCCESS = "GET_PUBLICACIONES_SUCCESS";
export const GET_ADJUDICACIONES = "GET_ADJUDICACIONES";
export const GET_ADJUDICACIONES_SUCCESS = "GET_ADJUDICACIONES_SUCCESS";
export const ADD_NEW_PREMIO_PUBLICACION = "ADD_NEW_PREMIO_PUBLICACION";
export const ADD_NEW_PREMIO_ADJUDICACION = "ADD_NEW_PREMIO_ADJUDICACION";
export const UPDATE_ESTADO_PREMIO = "UPDATE_ESTADO_PREMIO";
export const RESET_CROSSREF = "RESET_CROSSREF";
export const GET_PREMIO_PUB_HISTORIAL = "GET_PREMIO_PUB_HISTORIAL";
export const GET_PREMIO_PUB_HISTORIAL_SUCCESS = "GET_PREMIO_PUB_HISTORIAL_SUCCESS";

export const POST_CARGARANKINGJCR = "POST_CARGARANKINGJCR";
export const POST_CARGARANKINGJCR_SUCCESS = "POST_CARGARANKINGJCR_SUCCESS";
export const POST_CARGADETALLERANKINGJCR = "POST_CARGADETALLERANKINGJCR";
export const POST_CARGADETALLERANKINGJCR_SUCCESS = "POST_CARGADETALLERANKINGJCR_SUCCESS";
export const GET_RANKINGJCR_BY_ISSN = "GET_RANKINGJCR_BY_ISSN";
export const GET_RANKINGJCR_BY_ISSN_SUCCESS = "GET_RANKINGJCR_BY_ISSN_SUCCESS";
export const UPDATE_ESTADO_PREMIO_ADJ = "UPDATE_ESTADO_PREMIO_ADJ";
export const GET_RANKINGJCR_DETALLE_ALL = "GET_RANKINGJCR_DETALLE_ALL";
export const GET_RANKINGJCR_DETALLE_ALL_SUCCESS = "GET_RANKINGJCR_DETALLE_ALL_SUCCESS";
export const GET_MAX_ANIO_PREMIO_ADJUDICACION = "GET_MAX_ANIO_PREMIO_ADJUDICACION";
export const GET_MAX_ANIO_PREMIO_ADJUDICACION_SUCCESS = "GET_MAX_ANIO_PREMIO_ADJUDICACION_SUCCESS";
export const GET_REPORTE_PREMIO_PUB = "GET_REPORTE_PREMIO_PUB";
export const GET_REPORTE_PREMIO_PUB_SUCCESS = "GET_REPORTE_PREMIO_PUB_SUCCESS";
export const GET_REPORTE_ADJUDICACION = "GET_REPORTE_ADJUDICACION";
export const GET_REPORTE_ADJUDICACION_SUCCESS = "GET_REPORTE_ADJUDICACION_SUCCESS";
export const GET_RANKINGJCR_BY_ISSN_VAL = "GET_RANKINGJCR_BY_ISSN_VAL";
export const GET_RANKINGJCR_BY_ISSN_VAL_SUCCESS = "GET_RANKINGJCR_BY_ISSN_VAL_SUCCESS";
export const RESET_ARCHIVOEXCEL = "RESET_ARCHIVOEXCEL";