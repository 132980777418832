import {
  GET_MODULOS_NOTIFICACIONES_SUCCESS,
  GET_NOTIFICACIONES_SISTEMA_SUCCESS,
  SEND_NOTIFICACION_RESET,
  SEND_NOTIFICACION_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  notificacionesModulo: [],
  modulosNotificaciones: [],
  notificacionEnviada: false,
};

const Notificaciones = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NOTIFICACIONES_SISTEMA_SUCCESS:
      return {
        ...state,
        notificacionesModulo: action.payload.data,
      };
    case GET_MODULOS_NOTIFICACIONES_SUCCESS:
      return {
        ...state,
        modulosNotificaciones: action.payload.data,
      };
    case SEND_NOTIFICACION_SUCCESS:
      return {
        ...state,
        notificacionEnviada: true,
      };
    case SEND_NOTIFICACION_RESET:
      return {
        ...state,
        notificacionEnviada: false,
      };
    default:
      return state;
  }
};

export default Notificaciones;
