import { GET_VALOR_DOLAR, GET_VALOR_DOLAR_SUCCESS } from "./actionsTypes";

export const getValorDolar = (params) => ({
  type: GET_VALOR_DOLAR,
  payload: params
});

export const getValorDolarSuccess = (data) => ({
  type: GET_VALOR_DOLAR_SUCCESS,
  payload: data
});