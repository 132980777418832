import { POST_VALIDA_DOCUMENTO, POST_VALIDA_DOCUMENTO_ERROR, POST_VALIDA_DOCUMENTO_SUCCESS } from "./actionTypes";

export const postValidaDocumento = (params) => ({
  type: POST_VALIDA_DOCUMENTO,
  payload: params 
});

export const postValidaDocumentoSuccess = (data) => ({
  type: POST_VALIDA_DOCUMENTO_SUCCESS,
  payload: data
});

export const postValidaDocumentoError = () => ({
  type: POST_VALIDA_DOCUMENTO_ERROR
});