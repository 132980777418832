
import { GET_ENCUESTA_SUCCESS, GET_ENCUESTA_BY_GUID_SUCCESS, ADD_NEW_ENCUESTA_RESPUESTA
   } from "./actionTypes"
  
  const INIT_STATE = {
    encuesta: {},
  };
  
  const Encuesta = (state = INIT_STATE, action) => {
    switch (action.type) {

      case GET_ENCUESTA_SUCCESS:
        return {
          ...state,
          encuesta: action.payload.data,
        };
      case GET_ENCUESTA_BY_GUID_SUCCESS:
        return {
          ...state,
          encuesta: action.payload.data,
        };  
      case ADD_NEW_ENCUESTA_RESPUESTA:
          return {
          ...state,
        }                
            
      default:
        return state;
    }
  };
  
  export default Encuesta; 
  