import {
  GET_VERSIONES_PROGRAMA, GET_VERSIONES_PROGRAMA_SUCCESS, POST_VERSION_PROGRAMA,
  UPDATE_VERSION_PROGRAMA, GET_VERSIONES_PARRILLA, GET_VERSIONES_PARRILLA_SUCCESS, GET_VERSIONES_PARRILLA_DEE, GET_VERSIONES_PARRILLA_DEE_SUCCESS,
  GET_ESTADOS_VERSION, GET_ESTADOS_VERSION_SUCCESS, CAMBIA_ESTADO_VERSION,
  GET_VERSIONES_PROGRAMA_FULL_SUCCESS,
  GET_COUNT_VERSIONES_PROGRAMAS,
  GET_COUNT_VERSIONES_PROGRAMAS_SUCCESS,
  GET_MODULOS_VERSION_PROGRAMA,
  GET_MODULOS_VERSION_PROGRAMA_SUCCESS,
  UPDATE_PONDERACION_MODULO_VERSION,
  UPDATE_EVALUABLE_MODULO_VERSION,
  UPDATE_PONDERACION_VERSION_PROGRAMA,
  GET_VERSION_PROGRAMA,
  GET_VERSION_PROGRAMA_SUCCESS,
  GET_ESTUDIANTES_VERSION_PROGRAMA,
  GET_ESTUDIANTES_VERSION_PROGRAMA_SUCCESS,
  GET_LIBRO_CLASES_PROGRAMA,
  GET_LIBRO_CLASES_PROGRAMA_SUCCESS,
  GET_SESIONES_VERSION_PROGRAMA,
  GET_SESIONES_VERSION_PROGRAMA_SUCCESS,
  POST_CALENDARIO_PROGRAMA,
  RESET_SESIONES_PROGRAMA,
  GET_CHOQUES_SESIONES_VERSION_PROGRAMA,
  REAGENDA_SESION_PROGRAMA,
  REAGENDA_SESION_PROGRAMA_SUCCESS,
  ENROQUE_MODULOS_PROGRAMA,
  ENROQUE_MODULOS_PROGRAMA_SUCCESS,
  CAMBIAR_FECHA_CALENDARIO_MODULO_PROGRAMA,
  CAMBIAR_FECHA_CALENDARIO_MODULO_PROGRAMA_SUCCESS,
  UPDATE_TIPO_NOTA_VERSION_PROGRAMA,
  DESPLAZA_SESIONES_CALENDARIO,
  DESPLAZA_SESIONES_CALENDARIO_SUCCESS,
  ASIGNA_SALA_VERSION_PROGRAMA,
  ASIGNA_SALA_VERSION_PROGRAMA_SUCCESS,
  UPDATE_ESTADO_CALENDARIO_PROGRAMA,
  GET_CALENDARIO_PDF, GET_VERSION_ESTUDIANTES_REPORTE, GET_VERSION_ESTUDIANTES_REPORTE_SUCCESS
} from "./actionTypes";

export const getVersionesPrograma = (params) => ({
  type: GET_VERSIONES_PROGRAMA,
  payload: params
});

export const getVersionesProgramaSuccess = (data) => ({
  type: GET_VERSIONES_PROGRAMA_SUCCESS,
  payload: data,
});

export const getVersionesProgramaFullSuccess = (data) => ({
  type: GET_VERSIONES_PROGRAMA_FULL_SUCCESS,
  payload: data,
});

export const getVersionPrograma = (id) => ({
  type: GET_VERSION_PROGRAMA,
  payload: id
});

export const getVersionProgramaSuccess = (data) => ({
  type: GET_VERSION_PROGRAMA_SUCCESS,
  payload: data,
});

export const getCountVersionesProgramas = (params) => ({
  type: GET_COUNT_VERSIONES_PROGRAMAS,
  payload: params
});

export const getCountVersionesProgramasSuccess = (data) => ({
  type: GET_COUNT_VERSIONES_PROGRAMAS_SUCCESS,
  payload: data,
});

export const getModulosVersionPrograma = (params) => ({
  type: GET_MODULOS_VERSION_PROGRAMA,
  payload: params
});

export const getModulosVersionProgramaSuccess = (data) => ({
  type: GET_MODULOS_VERSION_PROGRAMA_SUCCESS,
  payload: data,
});

export const getEstudiantesVersionPrograma = (params) => ({
  type: GET_ESTUDIANTES_VERSION_PROGRAMA,
  payload: params
});

export const getEstudiantesVersionProgramaSuccess = (data) => ({
  type: GET_ESTUDIANTES_VERSION_PROGRAMA_SUCCESS,
  payload: data,
});

export const getLibroClasesPrograma = (params) => ({
  type: GET_LIBRO_CLASES_PROGRAMA,
  payload: params
});

export const getLibroClasesProgramaSuccess = (data) => ({
  type: GET_LIBRO_CLASES_PROGRAMA_SUCCESS,
  payload: data,
});

export const getSesionesVersionPrograma = (params) => ({
  type: GET_SESIONES_VERSION_PROGRAMA,
  payload: params
});

export const getSesionesVersionProgramaSuccess = (data) => ({
  type: GET_SESIONES_VERSION_PROGRAMA_SUCCESS,
  payload: data,
});

export const getChoquesSesionesVersionPrograma = (sesiones, callback) => ({
  type: GET_CHOQUES_SESIONES_VERSION_PROGRAMA,
  payload: { sesiones, callback }
});

export const getCalendarioPDF = (params) => ({
  type: GET_CALENDARIO_PDF,
  payload: params
});

export const postVersionPrograma = (params, callback = null) => ({
  type: POST_VERSION_PROGRAMA,
  payload: { params, callback }
});

export const postCalendarioPrograma = (params, callback = null, callbackError = null) => ({
  type: POST_CALENDARIO_PROGRAMA,
  payload: { params, callback, callbackError }
});

export const updateVersionPrograma = (params, callback = null) => ({
  type: UPDATE_VERSION_PROGRAMA,
  payload: { params, callback }
});

export const updatePonderacionVersionPrograma = (params) => ({
  type: UPDATE_PONDERACION_VERSION_PROGRAMA,
  payload: params
});

export const updatePonderacionModuloVersion = (params) => ({
  type: UPDATE_PONDERACION_MODULO_VERSION,
  payload: params
});

export const updateEvaluableModuloVersion = (params) => ({
  type: UPDATE_EVALUABLE_MODULO_VERSION,
  payload: params
});

export const updateTipoNotaVersionPrograma = (data, callback = null) => ({
  type: UPDATE_TIPO_NOTA_VERSION_PROGRAMA,
  payload: { data, callback }
});

export const updateEstadoCalendarioPrograma = (params, callback = null) => ({
  type: UPDATE_ESTADO_CALENDARIO_PROGRAMA,
  payload: { params, callback }
});

export const getVersionesParrilla = (params, filtrarPendientes) => ({
  type: GET_VERSIONES_PARRILLA,
  payload: { params, filtrarPendientes }
});

export const getVersionesParrillaSuccess = (data) => ({
  type: GET_VERSIONES_PARRILLA_SUCCESS,
  payload: data,
});

export const getEstadosVersion = () => ({
  type: GET_ESTADOS_VERSION
});

export const getEstadosVersionSuccess = (data) => ({
  type: GET_ESTADOS_VERSION_SUCCESS,
  payload: data,
});

export const cambiaEstadoVersion = (obj, params, isDirector) => ({
  type: CAMBIA_ESTADO_VERSION,
  payload: { obj, params, isDirector },
});

export const getVersionesParrillaDEE = (params, filtrarPendientes) => ({
  type: GET_VERSIONES_PARRILLA_DEE,
  payload: { params, filtrarPendientes }
});

export const getVersionesParrillaDEESuccess = (data) => ({
  type: GET_VERSIONES_PARRILLA_DEE_SUCCESS,
  payload: data,
});

export const resetSesionesPrograma = () => ({
  type: RESET_SESIONES_PROGRAMA,
});

export const reagendaSesionPrograma = (params, callback = null) => ({
  type: REAGENDA_SESION_PROGRAMA,
  payload: { params, callback }
});

export const reagendaSesionProgramaSuccess = (data) => ({
  type: REAGENDA_SESION_PROGRAMA_SUCCESS,
  payload: data,
});

export const enroqueModulosPrograma = (params, callback = null, callbackError = null) => ({
  type: ENROQUE_MODULOS_PROGRAMA,
  payload: { params, callback, callbackError }
});

export const enroqueModulosProgramaSuccess = (data) => ({
  type: ENROQUE_MODULOS_PROGRAMA_SUCCESS,
  payload: data,
});

export const cambiarFechaCalendarioModuloPrograma = (params, callback = null, callbackError = null) => ({
  type: CAMBIAR_FECHA_CALENDARIO_MODULO_PROGRAMA,
  payload: { params, callback, callbackError }
});

export const cambiarFechaCalendarioModuloProgramaSuccess = (data) => ({
  type: CAMBIAR_FECHA_CALENDARIO_MODULO_PROGRAMA_SUCCESS,
  payload: data,
});

export const desplazaSesionesCalendario = (params, callback = null, callbackError = null) => ({
  type: DESPLAZA_SESIONES_CALENDARIO,
  payload: { params, callback, callbackError }
});

export const desplazaSesionesCalendarioSuccess = (data) => ({
  type: DESPLAZA_SESIONES_CALENDARIO_SUCCESS,
  payload: data,
});

export const asignaSalaVersion = (params, callback = null) => ({
  type: ASIGNA_SALA_VERSION_PROGRAMA,
  payload: { params, callback }
});

export const asignaSalaVersionSuccess = (data) => ({
  type: ASIGNA_SALA_VERSION_PROGRAMA_SUCCESS,
  payload: data,
});

export const getVersionEstudiantesReporte = (params) => ({
  type: GET_VERSION_ESTUDIANTES_REPORTE,
  payload: params
});

export const getVersionEstudiantesReporteSuccess = (params) => ({
  type: GET_VERSION_ESTUDIANTES_REPORTE_SUCCESS,
  payload: params
});