import { GET_ESTADO_MODULOS_SUCCESS, GET_MODULOS_BY_CODIGO_SUCCESS, GET_MODULOS_SUCCESS, GET_SYLLABUS_MODULO_SUCCESS, RESET_SYLLABUS_MODULO, GET_MODULOS_BY_ACADEMICO_SUCCESS, GET_EVALUACIONES_BY_MODULO_VERSION, GET_EVALUACIONES_BY_MODULO_VERSION_SUCCESS, GET_MODULO_VERSION_SUCCESS, GET_SESIONES_MODULO, GET_SESIONES_MODULO_SUCCESS, GET_ASISTENCIA_MODULO_SUCCESS, UPDATE_NOTA_EVALUACION_MODULO, UPDATE_NOTA_EVALUACION_MODULO_SUCCESS, GET_HORARIO_MODULO_SUCCESS } from "./actionsTypes"

const INIT_STATE = {
  modulos: [],
  modulosByCodigo: [],
  paginacion: {},
  estados: [],
  loading: false,
  syllabus: null,
  modulosByAcademico: [],
  evaluacionesByModuloVersion: [],
  loadingEvaluacionesByModuloVersion: false,
  sesionesModulo: [],
  loadingSesionesModulo: false,
  asistenciaModulo: [],
  moduloVersion: {},
  horarioModulo: [],
}

const Modulos = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MODULOS_SUCCESS:
      return {
        ...state,
        modulos: action.payload.data,
        paginacion: JSON.parse(action.payload.headers["x-pagination"]),
      }
    case GET_MODULOS_BY_CODIGO_SUCCESS:
      return {
        ...state,
        modulosByCodigo: action.payload.data,
      }
    case GET_ESTADO_MODULOS_SUCCESS:
      return {
        ...state,
        estados: action.payload.data,
      }
    case GET_SYLLABUS_MODULO_SUCCESS:
      return {
        ...state,
        syllabus: action.payload.data,
      }
    case GET_MODULO_VERSION_SUCCESS:
      return {
        ...state,
        moduloVersion: action.payload.data,
      }
    case GET_EVALUACIONES_BY_MODULO_VERSION:
      return {
        ...state,
        loadingEvaluacionesByModuloVersion: true,
      }
    case GET_EVALUACIONES_BY_MODULO_VERSION_SUCCESS:
      return {
        ...state,
        evaluacionesByModuloVersion: action.payload.data,
        loadingEvaluacionesByModuloVersion: false,
      }
    case GET_SESIONES_MODULO:
      return {
        ...state,
        loadingSesionesModulo: true,
      }
    case GET_SESIONES_MODULO_SUCCESS:
      return {
        ...state,
        sesionesModulo: action.payload.data,
        loadingSesionesModulo: false,
      }
    case GET_ASISTENCIA_MODULO_SUCCESS:
      return {
        ...state,
        asistenciaModulo: action.payload.data,
      }
    case RESET_SYLLABUS_MODULO:
      return {
        ...state,
        syllabus: null,
      }
    case GET_MODULOS_BY_ACADEMICO_SUCCESS:
      return {
        ...state,
        modulosByAcademico: action.payload.data,
        paginacion: JSON.parse(action.payload.headers["x-pagination"]),
      }
    case GET_HORARIO_MODULO_SUCCESS:
      return {
        ...state,
        horarioModulo: action.payload.data,
      }
    case UPDATE_NOTA_EVALUACION_MODULO_SUCCESS:
      return {
        ...state,
        evaluacionesByModuloVersion: state.evaluacionesByModuloVersion.map(evaluacion => {
          if (evaluacion.id === action.payload.evaluacionId) {
            return ({
              ...evaluacion, notas: evaluacion.notas.map(nota => {
                if (nota.viajeId === action.payload.viajeId) {
                  return { ...nota, nota: action.payload.nota }
                }
                return nota
              })
            })
          }
          return evaluacion;
        })
      }
    default:
      return state
  }
}

export default Modulos