import { put, call, takeLatest, takeEvery } from "redux-saga/effects"
import { toast } from 'react-toastify';
import { isNil } from 'ramda'
import { app } from "../../config";

import {
  GET_ENCUESTA,
  GET_ENCUESTA_BY_GUID, ADD_NEW_ENCUESTA_RESPUESTA
} from './actionTypes'

import {
  getEncuestaSuccess, getEncuestaByGuidSuccess
} from './actions'

import { getEncuesta, getEncuestaByGuid, addNewEncuestaRespuesta} from "../../helpers/backend_helper";



function* fetchEncuesta({ payload: params }) {
  try {
    const response = yield call(getEncuesta, params)
    yield put(getEncuestaSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* fetchEncuestaByGuid({ payload: params }) {
    try {
        const response = yield call(getEncuestaByGuid, params)
        yield put(getEncuestaByGuidSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

function* addEncuestaRespuesta({payload: params}){
    try {
        const response = yield call(addNewEncuestaRespuesta, params)
        toast.success("Encuesta respondida con éxito", { autoClose: 3000 });
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* encuestaSaga() {
  yield takeLatest(GET_ENCUESTA, fetchEncuesta)
  yield takeLatest(GET_ENCUESTA_BY_GUID, fetchEncuestaByGuid)
  yield takeLatest(ADD_NEW_ENCUESTA_RESPUESTA, addEncuestaRespuesta)
}

export default encuestaSaga

