import { takeEvery, call, put, takeLatest, all, fork} from "redux-saga/effects"
import { toast } from 'react-toastify';

import {
    GET_FERIADOS,
    ADD_NEW_FERIADO,
    UPDATE_FERIADO,
    DELETE_FERIADO,
    GET_TIPOBLOQUEOS,
    GET_FERIADOSBYRANGO,
    ADD_NEW_BLOQUEO,
} from './actionTypes'

import { getFeriados, updateFeriado, addNewFeriado, deleteFeriado, getTipoBloqueos, getFeriadosByRango, addNewBloqueo} from "../../helpers/backend_helper";
import { getFeriadosSuccess, getTipoBloqueosSuccess, getFeriadosByRangoSuccess } from "./actions";

function* fetchFeriados({payload : params}){
    try {
        const response = yield call(getFeriados, params)
        yield put(getFeriadosSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* putFeriado({payload: {feriado, fetchData}}){
    try {
        const response = yield call(updateFeriado, feriado)
        fetchData()
        toast.success("Feriado actualizado con éxito", { autoClose: 3000 });
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT 
          });
    }
}

function* addFeriado({payload: {feriado, fetchData}}){
    try {
        const response = yield call(addNewFeriado, feriado)
        fetchData()
        toast.success("Feriado creado con éxito", { autoClose: 3000 });
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* addNewBLoqueo({payload: {bloqueo, fetchData}}){
    try {
        const response = yield call(addNewBloqueo, bloqueo)
        fetchData()
        toast.success("Bloqueo creado con éxito", { autoClose: 3000 });
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* deleteFeriadoSaga({payload: {feriado, fetchData}}){
    try {
        const response = yield call(deleteFeriado, feriado)
        fetchData()
        toast.success("Feriado Eliminado con éxito", { autoClose: 3000 });
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchTipoBloqueos({payload : params}){
    try {
        const response = yield call(getTipoBloqueos, params)
        yield put(getTipoBloqueosSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchFeriadosByRango({payload : params}){
    try {
        const response = yield call(getFeriadosByRango, params)
        yield put(getFeriadosByRangoSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* FechasSaga() {
    yield takeEvery(GET_FERIADOS, fetchFeriados)   
    yield takeLatest(UPDATE_FERIADO, putFeriado)
    yield takeEvery(ADD_NEW_FERIADO, addFeriado)
    yield takeLatest(ADD_NEW_BLOQUEO, addNewBLoqueo)
    yield takeLatest(DELETE_FERIADO, deleteFeriadoSaga)
    yield takeEvery(GET_TIPOBLOQUEOS, fetchTipoBloqueos)   
    yield takeEvery(GET_FERIADOSBYRANGO, fetchFeriadosByRango)   
}

export default FechasSaga