import { toast } from "react-toastify";
import { call, put, takeLatest } from "redux-saga/effects";
import { addNewLinkPago, addNewPagoWebpay, getConfirmaPagoPayPal, getInfoPago } from "../../helpers/backend_helper";
import { ADD_NEW_LINK_PAGO, ADD_NEW_PAGO_WEBPAY, GET_CONFIRMA_PAGO_PAYPAL, GET_INFO_PAGO } from "./actionTypes";
import { getInfoPagoSuccess } from "./actions";

function* postNewLinkPago({ payload: {params, callback} }) {
  try {
    const response = yield call(addNewLinkPago, params);
    toast.success("Link de pago enviado", {
      position: toast.POSITION.TOP_RIGHT,
    });
    callback && callback();
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* postNewPagoWebpay({ payload: { id, callback } }) {
  try {
    const response = yield call(addNewPagoWebpay, { id });
    callback && callback(response.data);
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchInfoPago({ payload: id }) {
  try {
    const response = yield call(getInfoPago, { id });
    yield put(getInfoPagoSuccess(response.data));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchConfirmaPagoPayPal({ payload: { data, callback } }) {
  try {
    const response = yield call(getConfirmaPagoPayPal, data);
    callback && callback(response.data);
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* pagosSaga() {
  yield takeLatest(ADD_NEW_LINK_PAGO, postNewLinkPago);
  yield takeLatest(ADD_NEW_PAGO_WEBPAY, postNewPagoWebpay);
  yield takeLatest(GET_INFO_PAGO, fetchInfoPago);
  yield takeLatest(GET_CONFIRMA_PAGO_PAYPAL, fetchConfirmaPagoPayPal);
}

export default pagosSaga;