import React, { useEffect } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { setAuthorization } from "../helpers/api_helper";
import { useDispatch, useSelector } from "react-redux";

import { useProfile } from "../Components/Hooks/UserHooks";

import { logoutUser } from "../store/actions";
import Cover403 from "../pages/AuthenticationInner/Error403";
import { includes } from "ramda";
import useRoles from "../Components/Hooks/UseRoles";

const AuthProtected = (props) => {
  const dispatch = useDispatch();
  let location = useLocation();
  const { userProfile, loading, token } = useProfile();
  useEffect(() => {
    if (userProfile && !loading && token) {
      setAuthorization(token);
    } else if (!userProfile && loading && !token) {
      dispatch(logoutUser());
    }
  }, [token, userProfile, loading, dispatch]);

  /*
    redirect is un-auth access protected routes via url
    */

  if (!userProfile && loading && !token) {
    return (
      <Redirect to={{ pathname: "/login", state: { from: props.location, path: location.pathname } }} />
    );
  }

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  const dataRoles = useRoles();

  if (dataRoles.length > 0) {
    if (
      rest.path === "/" ||
      rest.path === "/profile" ||
      rest.path === "/home" ||
      dataRoles.find((x) => includes(x, rest.rolesApp)) ||
      dataRoles.find((x) => includes('AllUsers', rest.rolesApp)) // para apps que no necesiten permiso (para todos)
    )
      return (
        <Route
          {...rest}
          render={(props) => {
            return (
              <>
                {" "}
                <Component {...props} />{" "}
              </>
            );
          }}
        />
      );
    else {
      return <Cover403 />;
    }
  }
};

export { AuthProtected, AccessRoute };
