import {
  GET_PROSPECTOS,
  UPDATE_PROSPECTO,
  ADD_NEW_PROSPECTO,
  GET_PROSPECTOS_SUCCESS,
  GET_PROSPECTO_PREVIEW_ENVIA_INFORMACION,
  GET_PROSPECTO_PREVIEW_ENVIA_INFORMACION_SUCCESS,
  POST_PROSPECTO_ENVIA_INFORMACION,
  RESET_PREVIEW_ENVIA_INFORMACION,
  ADD_NEW_SEGUIMIENTO_PROSPECTO,
  UPDATE_EJECUTIVO_PROSPECTO,
  GET_PROSPECTO_BY_GUID,
  GET_PROSPECTO_BY_GUID_SUCCESS,
  ADD_NEW_FICHA_POSTULACION,
  ADD_NEW_DESCUENTO_PROSPECTO,
  UPDATE_DESCUENTO_PROSPECTO,
  DELETE_DESCUENTO_PROSPECTO,
  POST_ENVIA_FICHA_POSTULACION,
  POST_PROCESA_MATRICULA_PROSPECTO,
  GET_PROSPECTOS_CONTADOR,
  GET_PROSPECTOS_CONTADOR_SUCCESS,
  UPDATE_VERSION_PROGRAMA_PROSPECTO,
  GET_PROSPECTOS_BY_EMAIL,
} from './actionTypes'

export const getProspectos = (params = {}) => ({
  type: GET_PROSPECTOS,
  payload: params
});

export const getProspectosSuccess = (prospectos) => ({
  type: GET_PROSPECTOS_SUCCESS,
  payload: prospectos
});

export const getProspectosByEmail = (email, callback) => ({
  type: GET_PROSPECTOS_BY_EMAIL,
  payload: { email, callback }
});

export const getProspectosContador = (params) => ({
  type: GET_PROSPECTOS_CONTADOR,
  payload: params
});

export const getProspectosContadorSuccess = (contador) => ({
  type: GET_PROSPECTOS_CONTADOR_SUCCESS,
  payload: contador
});

export const getProspectoByGuid = (fichaId) => ({
  type: GET_PROSPECTO_BY_GUID,
  payload: fichaId
});

export const getProspectoByGuidSuccess = (prospecto) => ({
  type: GET_PROSPECTO_BY_GUID_SUCCESS,
  payload: prospecto
});

export const getProspectoPreviewEnviaInformacion = (params) => ({
  type: GET_PROSPECTO_PREVIEW_ENVIA_INFORMACION,
  payload: params
});

export const getProspectoPreviewEnviaInformacionSuccess = (prospecto) => ({
  type: GET_PROSPECTO_PREVIEW_ENVIA_INFORMACION_SUCCESS,
  payload: prospecto
});

export const updateProspecto = (params) => ({
  type: UPDATE_PROSPECTO,
  payload: { params }
});

export const updateEjecutivoProspecto = (params, callback) => ({
  type: UPDATE_EJECUTIVO_PROSPECTO,
  payload: { params, callback }
});

export const updateDescuentoProspecto = (params, file, callback) => ({
  type: UPDATE_DESCUENTO_PROSPECTO,
  payload: { params, file, callback }
});

export const updateVersionProgramaProspecto = (params, callback) => ({
  type: UPDATE_VERSION_PROGRAMA_PROSPECTO,
  payload: { params, callback }
});

export const addNewProspecto = (params, callback) => ({
  type: ADD_NEW_PROSPECTO,
  payload: { params, callback },
});

export const addNewSeguimientoProspecto = (params, callback) => ({
  type: ADD_NEW_SEGUIMIENTO_PROSPECTO,
  payload: { params, callback },
});

export const addNewProspectoDescuento = (params, file, callback) => ({
  type: ADD_NEW_DESCUENTO_PROSPECTO,
  payload: { params, file, callback },
});

export const addNewFichaPostulacion = (params, files, filesCarga, callback) => ({
  type: ADD_NEW_FICHA_POSTULACION,
  payload: { params, files, filesCarga, callback },
});

export const postProspectoEnviaInformacion = (params, callback) => ({
  type: POST_PROSPECTO_ENVIA_INFORMACION,
  payload: { params, callback }
});

export const postEnviaFichaPostulacion = (params, callback) => ({
  type: POST_ENVIA_FICHA_POSTULACION,
  payload: { params, callback }
});

export const postProcesaMatriculaProspecto = (params, callback) => ({
  type: POST_PROCESA_MATRICULA_PROSPECTO,
  payload: { params, callback }
});

export const resetPreviewEnviaInformacion = () => ({
  type: RESET_PREVIEW_ENVIA_INFORMACION,
});

export const deleteDescuentoProspecto = (params, callback) => ({
  type: DELETE_DESCUENTO_PROSPECTO,
  payload: { params, callback },
});
