import {
    GET_USERS,
    GET_USERS_SUCCESS,
    GET_USERS_FAIL,
    GET_ALL_PERFILES,
    GET_ALL_PERFILES_SUCCESS,
    GET_ALL_PERFILES_FAIL,
    GET_ALL_ROLES,
    GET_ALL_ROLES_SUCCESS,

    UPDATE_USER,
    UPDATE_USER_PARCIAL,
    UPDATE_PERFIL,
    UPDATE_ROL,

    ADD_NEW_USER,
    ADD_NEW_PERFIL,
    ADD_NEW_ROL,

    ADD_PERFIL_USER,
    ADD_ROL_PERFIL,

    DELETE_PERFIL_USER,
    DELETE_ROL_PERFIL,
    UPDATE_PASSWORD_DCS,
    UPDATE_PASSWORD_DCS_SUCCESS,
    RESET_PASSWORD_USER,
    RECUPERA_PASSWORD_USER,
    RECUPERA_PASSWORD_USER_SUCCESS,
    CAMBIA_PASSWORD_USER,
    CAMBIA_PASSWORD_USER_SUCCESS,
    ADD_NEW_USER_SUCCESS,
    RESET_NEW_USER_MANAGEMENT,
    ADD_NEW_USER_FAIL,
} from './actionTypes'

export const getUsers = (params = {}) => ({
  type: GET_USERS,
  payload: params
});

export const getUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users,
});

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
});

export const getAllPerfiles = () => ({
  type: GET_ALL_PERFILES
});

export const getAllPerfilesSuccess = perfiles => ({
  type: GET_ALL_PERFILES_SUCCESS,
  payload: perfiles,
});

export const getPerfilesUserFail = error => ({
  type: GET_ALL_PERFILES_FAIL,
  payload: error,
});

export const getAllRoles = () => ({
  type: GET_ALL_ROLES
});

export const getAllRolesSuccess = roles => ({
  type: GET_ALL_ROLES_SUCCESS,
  payload: roles,
});

export const updateUser = (user, params) => ({
  type: UPDATE_USER,
  payload: {user, params}
});

export const updateUserParcial = (user, upd, params) => ({
  type: UPDATE_USER_PARCIAL,
  payload: {user, upd, params}
});

export const updatePerfil = (perfil, values) => ({
  type: UPDATE_PERFIL,
  payload: {perfil, values}
});

export const updateRol = (rol, values) => ({
  type: UPDATE_ROL,
  payload: {rol, values}
});

export const updatePasswordDCS = (oldPassword, newPassword) => ({
  type: UPDATE_PASSWORD_DCS,
  payload: {oldPassword, newPassword}
});

export const updatePasswordDCSSuccess = () => ({
  type: UPDATE_PASSWORD_DCS_SUCCESS
});

export const addNewUser = (user, params, perfiles) => ({
  type: ADD_NEW_USER,
  payload: {user, params, perfiles},
});

export const addNewUserSuccess = () => ({
  type: ADD_NEW_USER_SUCCESS
});

export const addNewUserFail = () => ({
  type: ADD_NEW_USER_FAIL
});

export const addNewPerfil = (perfil, roles) => ({
  type: ADD_NEW_PERFIL,
  payload: {perfil, roles},
});

export const addNewRol = rol => ({
  type: ADD_NEW_ROL,
  payload: rol,
});

export const addPerfil = perfil => ({
  type: ADD_PERFIL_USER,
  payload: perfil,
});

export const addRolPerfil = params => ({
  type: ADD_ROL_PERFIL,
  payload: params,
});

export const resetPasswordUser = (user, email) => ({
  type: RESET_PASSWORD_USER,
  payload: {user, email},
});

export const recuperaPasswordUser = (email) => ({
  type: RECUPERA_PASSWORD_USER,
  payload: email,
});

export const recuperaPasswordUserSuccess = () => ({
  type: RECUPERA_PASSWORD_USER_SUCCESS
});

export const cambiaPasswordUser = (idCambio, password) => ({
  type: CAMBIA_PASSWORD_USER,
  payload: {idCambio, password},
});

export const cambiaPasswordUserSuccess = () => ({
  type: CAMBIA_PASSWORD_USER_SUCCESS
});

export const deletePerfil = perfil => ({
  type: DELETE_PERFIL_USER,
  payload: perfil,
});

export const deleteRolPerfil = params => ({
  type: DELETE_ROL_PERFIL,
  payload: params,
});

export const resetUserManagement = () => ({
  type: RESET_NEW_USER_MANAGEMENT,
});