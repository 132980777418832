import {
  ADD_NEW_MODULO_NOTIFICACIONES,
  ADD_NEW_TIPO_NOTIFICACION,
  GET_MODULOS_NOTIFICACIONES,
  GET_MODULOS_NOTIFICACIONES_SUCCESS,
  GET_NOTIFICACIONES_SISTEMA,
  GET_NOTIFICACIONES_SISTEMA_SUCCESS,
  SEND_NOTIFICACION,
  SEND_NOTIFICACION_RESET,
  SEND_NOTIFICACION_SUCCESS,
  UPDATE_ESTADO_TIPO_NOTIFICACION,
  UPDATE_TIPO_NOTIFICACION,
} from "./actionTypes";

export const getNotificacionesSistema = (modulo) => ({
  type: GET_NOTIFICACIONES_SISTEMA,
  payload: modulo,
});

export const getNotificacionesSistemaSuccess = (notificaciones) => ({
  type: GET_NOTIFICACIONES_SISTEMA_SUCCESS,
  payload: notificaciones,
});

export const getModulosNotificaciones = () => ({
  type: GET_MODULOS_NOTIFICACIONES,
});

export const getModulosNotificacionesSuccess = (modulos) => ({
  type: GET_MODULOS_NOTIFICACIONES_SUCCESS,
  payload: modulos,
});

export const postModuloNotificaciones = (modulo) => ({
  type: ADD_NEW_MODULO_NOTIFICACIONES,
  payload: modulo,
});

export const postTipoNotificacion = (tipo, modulo) => ({
  type: ADD_NEW_TIPO_NOTIFICACION,
  payload: {tipo, modulo},
});

export const updateTipoNotificacion = (tipo, modulo) => ({
  type: UPDATE_TIPO_NOTIFICACION,
  payload: {tipo, modulo},
});

export const updateEstadoTipoNotificacion = (id, estado, modulo) => ({
  type: UPDATE_ESTADO_TIPO_NOTIFICACION,
  payload: {id, estado, modulo},
});

export const sendNotificacion = (id) => ({
  type: SEND_NOTIFICACION,
  payload: id,
});

export const sendNotificacionSuccess = () => ({
  type: SEND_NOTIFICACION_SUCCESS
});

export const sendNotificacionReset = () => ({
  type: SEND_NOTIFICACION_RESET
});