//LOGIN
//export const POST_LOGIN = "/Auth/loginportal";
export const POST_LOGIN = "/Auth/login";
export const POST_PERSONIFICAR = "/Auth/Personificar";
export const POST_FINALIZAR_PERSONIFICACION = "/Auth/FinalizarPersonificacion";

//User Management
export const GET_USERS = "/UserManagement/GetUsersFull";
export const GET_PERFILES_USER = "/UserManagement/GetUserPerfiles"
export const GET_ALL_PERFILES = "/UserManagement/GetPerfiles"
export const GET_ALL_ROLES = "/UserManagement/GetRoles"
export const UPDATE_USER = "/UserManagement/UpdateUser"
export const UPDATE_USER_PARCIAL = "/UserManagement/PartiallyUpdateUser"
export const UPDATE_PERFIL = "/UserManagement/UpdatePerfil"
export const UPDATE_ROL = "/UserManagement/UpdateRol"
export const UPDATE_PASSWORD_DCS = "/UserManagement/UpdatePasswordDCS"
export const ADD_NEW_USER = "/UserManagement/CreateUser"
export const ADD_NEW_PERFIL = "/UserManagement/CreatePerfil"
export const ADD_NEW_ROL = "/UserManagement/CreateRol"
export const ADD_PERFIL_USER = "/UserManagement/AddPerfilToUser"
export const ADD_ROL_PERFIL = "/UserManagement/AddRolToPerfil"
export const RESET_PASSWORD_USER = "/UserManagement/ResetPasswordUser"
export const RECUPERA_PASSWORD_USER = "/UserManagement/RecuperarPasswordUser"
export const CAMBIA_PASSWORD_USER = "/UserManagement/UpdatePasswordDCSByRecuperar"
export const DELETE_PERFIL_USER = "/UserManagement/DeletePerfilFromUser"
export const DELETE_ROL_PERFIL = "/UserManagement/DeleteRolFromPerfil"

//Profile
export const GET_USER_PROFILE = "/Profile/GetUserProfile"
export const GET_USER_INFO = "/Profile/GetUserInfo"
export const GET_USER_NOTIFICACIONES = "/Notificaciones/GetNotificacionesUsuario"
export const ADD_NEW_PROFILE_PICTURE = "/Profile/CreateProfilePicture"
export const ADD_NEW_MAILING_PICTURE = "/Profile/CreateMailingPicture"
export const GET_USERS_AVATARS = "/Profile/GetUsersAvatars"
export const UPDATE_USER_NOTIFICACION = "/Notificaciones/ActualizaLecturaNotificacion"
export const UPDATE_USER_INFO = "/Profile/UpdateUserInfo"
export const DELETE_PROFILE_PICTURE = "/Profile/DeleteProfilePicture"
export const DELETE_MAILING_PICTURE = "/Profile/DeleteMailingPicture"

//Premios
export const GET_CROSSREF = "/Premios/GetCrossref"
export const GET_PUBLICACIONES = "/Premios/GetPublicaciones"
export const GET_ADJUDICACIONES = "/Premios/GetAdjudicaciones"
export const ADD_NEW_PREMIO_PUBLICACION = "/Premios/IngresaPremioPublicacion"
export const ADD_NEW_PREMIO_ADJUDICACION = "/Premios/IngresaPremioAdjudicacion"
export const UPDATE_ESTADO_PREMIO = "/Premios/ActualizaEstadoPremio"
export const ADD_NEW_PREMIO_PUB_HISTORIAL = "/Premios/CreatePremioPubHistorial"
export const GET_PREMIO_PUB_HISTORIAL = "/Premios/GetPremioPubHistorial"
export const GET_PREMIO_ADJ_HISTORIAL = "/Premios/GetPremioAdjudicacionHistorial"
export const POST_CARGARANKINGJCR = "/Premios/CargaRanking"
export const POST_CARGADETALLERANKINGJCR = "/Premios/CargaDetalleRanking"
export const GET_RANKINGJCR_BY_ISSN = "/Premios/GetRankingRevistasByISSN"
export const ADD_NEW_PREMIO_ADJ_HISTORIAL = "/Premios/CreatePremioAdjudicacionHistorial"
export const UPDATE_ESTADO_PREMIO_ADJ = "/Premios/ActualizaEstadoPremioAdj"
export const GET_RANKINGJCR_DETALLE_ALL = "/Premios/GetPremioPubRankingAll"
export const GET_MAX_ANIO_PREMIO_ADJUDICACION = "/Premios/GetMaxAnioPremioAdjudicacion"
export const GET_REPORTE_PREMIO_PUB = "/Premios/GetReportePremioPub"
export const GET_REPORTE_ADJUDICACION = "/Premios/GetReporteAdjudicaciones"

//Monedas
export const GET_VALOR_DOLAR = "/Moneda/GetValorDolar"

//Notificaciones
export const GET_NOTIFICACIONES_SISTEMA = "/Notificaciones/GetNotificacionesSistema"
export const GET_MODULOS_NOTIFICACIONES = "/Notificaciones/GetNotificacionesModulos"
export const ADD_NEW_MODULO_NOTIFICACIONES = "/Notificaciones/CrearModuloNotificacion"
export const ADD_NEW_TIPO_NOTIFICACION = "/Notificaciones/CrearTipoNotificacion"
export const UPDATE_TIPO_NOTIFICACION = "/Notificaciones/ActualizaNotificacion"
export const UPDATE_ESTADO_TIPO_NOTIFICACION = "/Notificaciones/ActualizaEstadoNotificacion"
export const SEND_NOTIFICACION = "/Notificaciones/EnviarNotificacion"

//Captcha
export const GET_CAPTCHA = "/Captcha/getcaptcha"
export const GET_IMG_CAPTCHA = "/Captcha/getimgcaptcha"
export const POST_CAPTCHA = "/Captcha/validatecaptcha"

//Empresa
export const GET_EMPRESAS = "/Empresa/GetEmpresas"
export const GET_EMPRESA_CONTACTOS = "/Empresa/GetEmpresaContactos"
export const ADD_NEW_EMPRESA = "/Empresa/CreateEmpresa"
export const ADD_NEW_EMPRESA_CONTACTO = "/Empresa/CreateEmpresaContacto"
export const UPDATE_EMPRESA = "/Empresa/UpdateEmpresa"

// Estudiantes
export const GET_ESTUDIANTES = "/Alumnos/GetAlumnos"
export const GET_ESTUDIOS_ESTUDIANTE = "/Alumnos/GetEstudiosAlumno"
export const GET_LABORAL_ESTUDIANTE = "/Alumnos/GetLaboralAlumno"
export const GET_ESTUDIANTE_BY_DOCUMENTO = "/Alumnos/GetAlumnoByDocumento"
export const ADD_NEW_ESTUDIANTE = "/Alumnos/CrearAlumno"
export const ADD_NEW_ESTUDIO_ESTUDIANTE = "/Alumnos/CrearEstudioAlumno"
export const ADD_NEW_LABORAL_ESTUDIANTE = "/Alumnos/CrearLaboralAlumno"
export const UPDATE_ESTUDIANTE = "/Alumnos/UpdateAlumno"
export const DELETE_ESTUDIO_ESTUDIANTE = "/Alumnos/DeleteEstudioAlumno"
export const GET_ESTUDIANTE = "/Alumnos/GetAlumno"
export const GET_VIAJES_EN_CURSO_ESTUDIANTE = "/Viajes/GetViajesEnCursoEstudiante"
export const GET_MODULOS_VIAJE = "/Viajes/GetModulosViaje"
export const GET_NOTAS_ESTUDIANTE_MODULO = "/Viajes/GetNotasEstudianteModulo"
export const GET_CERTIFICADO_ALUMNO_REGULAR = "/Alumnos/GetCertificadoAlumnoRegular"
export const GET_CERTIFICADO_ALUMNO_REGULAR_INTERNO = "/Alumnos/GetCertificadoAlumnoRegularInterno"
export const GET_CERTIFICADO_ALUMNO_NOTAS = "/Alumnos/GetCertificadoAlumnoNotas"
export const GET_ESTUDIANTE_EXTERNO = "/Alumnos/GetAlumnoExterno"
export const GET_ASISTENCIA_ESTUDIANTE_MODULO = "/Viajes/GetAsistenciaEstudianteModulo"

// Listas
export const GET_LISTA ="/List/"
export const GET_LISTA_GEO ="/Geo/"
export const GET_LISTA_ZONA_PROG ="/List/"
export const GET_LISTA_TIPO_SOLICITUDES ="/List/GetTipoSolicitudes"
export const GET_LISTA_AREASINVESTIGACION ="/AreasInvestigacion/"

//User Prospectos
export const GET_PROSPECTOS = "/Prospectos/GetProspectos"
export const GET_PROSPECTOS_BY_EMAIL = "/Prospectos/GetProspectosByEmail"
export const GET_PROSPECTOS_CONTADOR = "/Prospectos/GetProspectosContador"
export const GET_PROSPECTO_BY_GUID = "/Prospectos/GetProspectoByGuid"
export const GET_PROSPECTO_PREVIEW_ENVIA_INFORMACION = "/Prospectos/ProspectoPreviewEnviaInformacion"
export const ADD_NEW_PROSPECTO = "/Prospectos/CrearProspecto"
export const ADD_NEW_PROSPECTO_SEGUIMIENTO = "/Prospectos/ProspectoIngresoSeguimiento"
export const ADD_NEW_FICHA_POSTULACION = "/Prospectos/GuardaFichaPostulacion"
export const ADD_NEW_DESCUENTO_PROSPECTO = "/Prospectos/CrearDescuentoProspecto"
export const POST_PROSPECTO_ENVIA_INFORMACION = "/Prospectos/ProspectoEnviaInformacion"
export const POST_ENVIA_FICHA_POSTULACION = "/Prospectos/EnviaFichaPostulacion"
export const POST_PROCESA_MATRICULA_PROSPECTO = "/Prospectos/ProcesaMatriculaProspecto"
export const UPDATE_EJECUTIVO_PROSPECTO = "/Prospectos/ProspectoReasignaEjecutivo"
export const UPDATE_DESCUENTO_PROSPECTO = "/Prospectos/ActualizaArchivoDescuento"
export const UPDATE_VERSION_PROGRAMA_PROSPECTO = "/Prospectos/ProspectoCambiaVersion"
export const DELETE_DESCUENTO_PROSPECTO = "/Prospectos/DeleteDescuentoProspecto"

//Finanzas KPI
export const GET_FBYMES = "/Finanzas/GetFByMes"
export const GET_FBYSEMANAS = "/Finanzas/GetFBySemanas"
export const GET_FBYMESRANGO = "/Finanzas/GetFByMesRango"
export const GET_FBYMESRANGOCOMPARATIVO = "/Finanzas/GetFByMesRango"
export const GET_FBYDIAMES = "/Finanzas/GetFByDiaMes"
export const GET_FBYMESRANGOTIPO = "/Finanzas/GetFByMesRangoTipo"
export const GET_BOLETASBYMESRANGO = "/Finanzas/GetBoletaByMesRango"
export const GET_NVBYMES = "/Finanzas/GetNvByMes"
export const GET_NVBYMESHOME = "/Finanzas/GetNvByMes"
export const GET_NVBYMESTIPO = "/Finanzas/GetNvByMesTipo"
export const GET_NVTIPOSPAGO = "/Finanzas/GetNvTiposPago"
export const GET_NVCANTIDADBYMESTIPO = "/Finanzas/GetNvCantidadByMesTipo"
export const POST_CARGADATAAUGE = "/Finanzas/CargaDataAuge"


//-----------------------Cobranza ---------------------------------//
//-----------------------------------------------------------------//
export const GET_FACTURABYRANGO = "/Cobranza/GetFacturaByRango"
export const GET_FACTURABYNUMFACTURA = "/Cobranza/GetFacturaByNumFactura"
export const GET_FACTURAMOVIMIENTOSBYNUMFACTURA = "/Cobranza/GetFacturaMovimientosByNumFactura"
export const GET_FACTURACUOTASBYNUMFACTURA = "/Cobranza/GetFacturaCuotasByNumFactura"
export const GET_FACTURAHISTORICOMEDIOPAGOSBYNUMFACTURA = "/Cobranza/GetFacturaHistoricoMedioPagosByNumFactura"
export const GET_LISTAESTADOFACTURA = "/Cobranza/GetListaEstadoFactura"
export const GET_LISTASUBESTADOFACTURA = "/Cobranza/GetListaSubEstadoFactura"
export const GET_LISTATIPOSEGUIMIENTOMANUAL = "/Cobranza/GetListaTipoSeguimientoManual"

export const GET_LISTAORGANISMOS = "/Cobranza/GetListaOrganismos"
export const GET_LISTABANCOS = "/Cobranza/GetListaBancos"
export const GET_LISTACUENTACORRIENTE = "/Cobranza/GetListaCuentaCorriente"
export const GET_SEGUIMIENTOSFACTURABYNUMFACTURA = "/Cobranza/GetSeguimientosFacturaByNumFactura"
export const GET_MONTOSFACTURASDASHBOARD = "/Cobranza/GetMontosFacturasDashboard"
export const GET_MONTOSFACTURASPAGADASCONMEDIODEPAGODASHBOARD = "/Cobranza/GetMontosFacturasPagadasConMedioDePagoDashboard"
export const GET_MONTOSFACTURASMOROSASSINMEDIODEPAGODASHBOARD = "/Cobranza/GetMontosFacturasMorosasSinMedioDePagoDashboard"

export const GET_FACTURASEMITIDASDASHBOARDPORRANGOFECHAS = "/Cobranza/GetFacturasEmitidasDashboardPorRangoFechas"
export const GET_FACTURASEMITIDASPAGADASDASHBOARDPORRANGOFECHAS = "/Cobranza/GetFacturasEmitidasPagadasDashboardPorRangoFechas"

export const GET_FACTURASEMITIDASVERSUSCOMPORTAMIENTODEPAGODASHBOARDPORRANGOFECHAS = "/Cobranza/GetFacturasEmitidasVersusComportamientoDePagoDashboardPorRangoFechas"
export const GET_GESTIONCOBRANZAFACTURASMOROSASPORRANGOFECHAS = "/Cobranza/GetGestionCobranzaFacturasMorosasPorRangoFechas"

export const ADD_GESTIONSEGUIMIENTOCOBRANZA= "/Cobranza/CreateGestionSeguimientoCobranza"

//-----------------------Cobranza ---------------------------------//

//-----------------------Sesiones ---------------------------------//

export const GET_SESIONES_ALUMNOS = "/Sesion/GetSesionesAlumnos"
export const GET_SESIONES_ACADEMICOS = "/Sesion/GetSesionesAcademicos"
export const GET_LISTASESIONESACADEMICOSPORRANGOFECHAS = "/Sesion/GetListaSesionesAcademicosPorRangoFechas"
export const GET_DIAS_SESIONES = "/Sesion/GetDiasSesiones"
export const DELETE_SESIONES = "/Sesion/DeleteCalendario"
//-----------------------Sesiones ---------------------------------//


//Control de Gestion
export const POST_MOVER_ARCHIVOS_ASIENTOS_CONTABLES = "/Finanzas/MoverArchivosAsientosContables"

//Files
export const POST_FILE = "/Files/PostSingleFile"
export const POST_MULTIPLE_FILE = "/Files/PostMultipleFile"
export const GET_FILE = "/Files/DownloadFile"
export const GET_FILE_BYPATHFILENAME = "Files/DownloadFileByPathFilename"
export const GET_IMG_FILE = "/Files/GetImgFile"

//Historial
export const POST_HISTORIAL = "/Historial/CrearHistorial"
export const GET_HISTORIAL_BY_IDENTIFICADOR = "/Historial/GetHistorialByIdentificador"
export const GET_HISTORIAL_BY_IDENTIFICADOR_ACCION = "/Historial/GetHistorialByIdentificadorAndAccion"

// Operaciones
export const GET_SALAS = "/Salas/GetSalas"
export const GET_SALAS_BY_SEDE = "/Salas/GetSalasBySede"
export const UPDATE_SALA = "/Salas/UpdateSala"
export const ADD_NEW_SALA = "/Salas/CreateSala"
export const GET_TIPO_SALAS = "/List/GetTiposSala"
export const GET_SEDES = "/List/GetSedes"
export const ADD_NEW_EQUIPAMIENTO = "/Salas/CreateEquipamiento"
export const GET_EQUIPAMIENTO = "/Salas/GetEquipamiento"
export const ADD_NEW_SALAEQUIPAMIENTO = "/Salas/CreateSalaEquipamiento"
export const GET_SALAEQUIPAMIENTO = "/Salas/GetSalaEquipamiento"
export const DELETE_SALAEQUIPAMIENTO = "/Salas/DeleteSalaEquipamiento"

//Eventos
export const GET_EVENTO_CATEGORIAS = "/Eventos/GetCategorias"
export const GET_EVENTO_TIPOS = "/Eventos/GetTiposEventos"
export const GET_EVENTO_ITEMS = "/Eventos/GetItemsEvento"
export const GET_EVENTOS_ANIO = "/Eventos/GetEventosAnio"
export const GET_EVENTOS_ALL_ANIO = "/Eventos/GetAllEventosAnio"
export const GET_EVENTOS_CALENDARIO = "/Eventos/GetEventosCalendario"
export const GET_EVENTOS_PROXIMOS_ANIO = "/Eventos/GetProximosEventosAnio"
export const GET_EVENTOS_REALIZADOS_ANIO = "/Eventos/GetEventosRealizadosAnio"
export const GET_EVENTOS_ACTIVIDADES_ANIO = "/Eventos/GetActividadesEventos"
export const GET_EVENTO = "/Eventos/GetEvento"
export const GET_EVENTO_MODALIDADES = "/Eventos/GetModalidadesEventos"
export const GET_EVENTO_ESTADOS = "/Eventos/GetEstadosEventos"
export const GET_EVENTO_ESTADOS_ITEMS = "/Eventos/GetEstadosItemsEventos"
export const GET_REPORTE_EVENTOS_ANIO = "/Eventos/GetReporteEventos"
export const GET_REPORTE_EXCEL = "/Eventos/GetReporteExcel"
export const POST_EVENTO = "/Eventos/CreateEvento"
export const UPDATE_EVENTO = "Eventos/ActualizaEvento"
export const UPDATE_DETALLE_EVENTO = "Eventos/ActualizaDetalleEvento"
export const UPDATE_ESTADO_EVENTO = "Eventos/ActualizaEstadoEvento"
export const UPDATE_GESTION_EVENTO = "Eventos/ActualizaGestionEvento"
export const UPDATE_GESTION_MASIVA_EVENTO = "Eventos/ActualizaActividadesEventos"
export const UPDATE_CANCELA_EVENTO = "Eventos/CancelarEvento"
export const DELETE_EVENTO_DIA = "/Eventos/EliminaDiasEvento"

//Mysql
export const GET_SALDO_PPTO_CENTRO_DIRECTOR = "/Mysql/GetSaldoCentroByDirector"
export const GET_SALDO_PPTO_CENTRO = "/Mysql/GetSaldosCentrosById"

// Fechas
export const GET_FERIADOS = "/Feriados/GetFeriadosList"
export const UPDATE_FERIADO = "/Feriados/UpdateFeriado"
export const ADD_NEW_FERIADO = "/Feriados/CreateFeriado"
export const ADD_NEW_BLOQUEO = "/Feriados/CreateBloqueo"
export const DELETE_FERIADO = "/Feriados/DeleteFeriado"
export const GET_TIPOBLOQUEOS = "/Feriados/GetTipoBloqueos"
export const GET_FERIADOSBYRANGO = "/Feriados/GetFeriadosByRango"
export const GET_FERIADOSPERMANENTES = "/Feriados/GetFeriadosPermanentes"

//Academicos
export const GET_ACADEMICOS = "/Academicos/GetAcademicos"
export const GET_ACADEMICOS_ALL = "/Academicos/GetAllAcademicos"
export const GET_ACADEMICO_BY_USERID = "/Academicos/GetAcademicoByUserId"
export const GET_ACADEMICOS_CENTRO_BY_DIRECTOR = "/Academicos/GetAcademicosCentroByDirector"
export const GET_ACAD_DIAS_DISPONIBLES = "/Academicos/GetDiasDisponiblesAcademicoLista"
export const GET_ACAD_DIAS_DISPONIBLES_ALL = "/Academicos/GetDiasDisponiblesAcademicoAll"
export const GET_ACAD_DIAS_BLOQUEO = "/Academicos/GetDiasBloqueoPorAcademico"
export const ADD_NEW_ACAD_DIAS_DISPONIBLE = "/Academicos/CreateDiasDisponibles"
export const ADD_NEW_ACAD_DIAS_BLOQUEO = "/Academicos/CreateAcadDiasBloqueo"
export const DELETE_ACAD_DIAS_DISPONIBLE = "/Academicos/DeleteAcadDiasDisponibles"
export const DELETE_ACAD_DIAS_BLOQUEO = "/Academicos/DeleteAcadDiasBloqueo"
export const UPDATE_ACAD_DIAS_DISPONIBLE = "/Academicos/UpdateAcadDiasDisponible"
export const GET_REPORTE_DISPONIBILIDAD_YEAR = "/Academicos/GetReporteDisponibilidad"
export const GET_REPORTE_EXCEL_DISPONIBILIDAD = "/Academicos/GetReporteExcelDisponibilidad"
export const GET_REPORTE_BLOQUEOS_YEAR = "/Academicos/GetReporteBloqueos"
export const GET_REPORTE_AMBOS_YEAR = "/Academicos/GetReporteDisponibilidadAcademica"
export const VALIDA_ACADEMICO = "/Academicos/ValidaAcademico"
export const ADD_NEW_ACADEMICO = "/Academicos/CreateAcademico"
export const UPDATE_ACADEMICO = "/Academicos/UpdateAcademico"
export const ADD_NEW_ESTUDIO_ACADEMICO = "/Academicos/CrearEstudioAcademico"
export const GET_ESTUDIOS_ACADEMICO = "/Academicos/GetEstudiosAcademico"
export const ADD_NEW_DOCUMENTO_ACADEMICO = "/Academicos/CrearDocumentosAcademico"
export const GET_DOCUMENTOS_ACADEMICO = "/Academicos/GetDocumentosAcademico"
export const GET_ACADEMICO_USER = "/Academicos/GetAcademicoUser"


// Solicitudes de fondo
export const ADD_NEW_SOLICITUD_FONDO = "/SolicitudesDeFondo/CreateSolicitud"
export const ADD_NEW_FONDO_INVESTIGACION = "/SolicitudesDeFondo/CreateFondoInvestigacion"
export const ADD_NEW_SOLICITUD_FONDO_HISTORIAL = "/SolicitudesDeFondo/CreateSolicitudHistorial"
export const GET_SOLICITUDES_FONDO = "/SolicitudesDeFondo/GetSolicitudesdeFondo"
export const GET_SOLICITUDES_FONDO_POR_ACADEMICO = "/SolicitudesDeFondo/GetSolicitudesdeFondoPorAcademico"
export const UPDATE_SOLICITUD_FONDO = "/SolicitudesDeFondo/UpdateSolicitudDeFondo"
export const UPDATE_ESTADO_SOLICITUD_FONDO = "/SolicitudesDeFondo/UpdateEstadoSolicitudDeFondo"
export const GET_VIATICOPORPAIS = "/SolicitudesDeFondo/GetViaticoInternacionalPorPaisGrado"
export const ADD_DIASVIAJE_SOLICITUD = "/SolicitudesDeFondo/CreateViajeDias"
export const GET_DIASVIAJE_SOLICITUD = "/SolicitudesDeFondo/GetDiasSolicitudViaje"
export const GET_SOLICITUD_HISTORIAL = "/SolicitudesDeFondo/GetSolicitudHistorial"
export const GET_PPTO_INVESTIGACION_POR_ACADEMICO = "/SolicitudesDeFondo/GetPresupuestoInvestigacionPorAcademico"
export const GET_PPTO_ACADEMICO_SALDO_PENDIENTE = "/SolicitudesDeFondo/GetPptoAcademicoSaldoPendiente"
export const GET_REPORTE_SOLICITUDES_FONDOS = "/SolicitudesDeFondo/GetReporteSolicitudesFondos"
export const GET_REPORTE_PRESUPUESTO_INVESTIGACION = "/SolicitudesDeFondo/GetReportePresupuestos"
export const GET_REPORTE_FONDOS_INVESTIGACION = "/SolicitudesDeFondo/GetReporteFondosInvestigacion"
export const GET_ESTADO_SOLICITUD_FONDO ="/List/GetEstadoSolicitudFondo"
export const GET_TIPO_FONDO_INVESTIGACION ="/List/GetTipoFondoInvestigacion"
export const GET_FONDOS_INVESTIGACION = "/SolicitudesDeFondo/GetFondosInvestigacion"
export const UPDATE_ACADEMICOS_FONDO = "/SolicitudesDeFondo/ModificaAcademicosPresupuestoInvestigacion"
export const UPDATE_FONDO_INVESTIGACION = "/SolicitudesDeFondo/UpdateFondoInvestigacion"
export const DELETE_FONDO_INVESTIGACION = "/SolicitudesDeFondo/DeleteFondoInvestigacion"
export const UPDATE_VALORES_SOLICITUD_FONDO = "/SolicitudesDeFondo/UpdateValoresSolicitudDeFondo"
export const GET_PPTO_INVESTIGACION_POR_DEPTO = "/SolicitudesDeFondo/GetPresupuestosInvestigacionDepto"
export const GET_PPTO_DEPTO_PENDIENTE = "/SolicitudesDeFondo/GetPresupuestoInvestigacionDeptoPendiente"
export const GET_PPTO_ACADEMICO_SALDO_PENDIENTE_CONCILIA = "/SolicitudesDeFondo/GetPptoAcademicoSaldoPendienteConcilia"
export const GET_REPORTE_SOLICITUDES_FONDOS_AYUDANTES = "/SolicitudesDeFondo/GetReporteSolicitudesFondosAyudantes"
export const GET_FILES_SOLICITUD = "/SolicitudesDeFondo/GetFilesSolicitud"
export const GET_CECO_SOLICITUD_FONDO = "/SolicitudesDeFondo/GetCecoSolicitudFondo"

export const GET_CECOS_SOLICITUD = "/SolicitudesDeFondo/GetCecosSolicitud"
export const GET_CECO_SOLICITUD = "/SolicitudesDeFondo/GetCecoSolicitud"
export const ADD_NEW_CECO_SOLICITUD = "/SolicitudesDeFondo/CreateCecoSolicitud"
export const UPDATE_CECO_SOLICITUD = "/SolicitudesDeFondo/UpdateCecoSolicitud"
export const GET_CECO_SOLICITUD_BY_CODIGO = "/SolicitudesDeFondo/GetCecoSolicitudByCodigo"


// Ayudantes
export const GET_AYUDANTES = "/Ayudantes/GetAyudantes"
export const ADD_NEW_AYUDANTE = "/Ayudantes/CreateAyudante"
export const GET_AYUDANTE_BY_RUT = "/Ayudantes/GetAyudanteByRut"

// Ayudantes
export const ADD_NEW_SUBAREA_INVESTIGACION = "/SolicitudesDeFondo/CreateSubareaInvestigacion"
export const ADD_NEW_TEMA_INVESTIGACION = "/SolicitudesDeFondo/CreateTemaInvestigacion"

// Mantenedores
export const GET_DOLARES = "/Mantenedores/GetDolares"
export const GET_DOLAR = "/Mantenedores/GetDolar"
export const ADD_NEW_DOLAR = "/Mantenedores/CreateDolar"
export const UPDATE_DOLAR = "/Mantenedores/UpdateDolar"
export const GET_DOLAR_VIGENTE = "/Mantenedores/GetDolarVigente"


// Modulos
export const GET_MODULOS = "Modulos/GetModulos"
export const GET_MODULOS_BY_CODIGO = "Modulos/GetModulosByCodigo"
export const ADD_NEW_MODULO = "Modulos/CreateModulo"
export const ADD_NEW_EVALUACION_MODULO = "Modulos/CreateModuloEvaluaciones"
export const ADD_NEW_NOTA_EVALUACION_MODULO = "Modulos/CreateEvaluacionNota"
export const ADD_NEW_ASISTENCIA_MODULO = "Modulos/CreateAsistenciaModulo"
export const UPDATE_MODULO = "Modulos/UpdateModulo"
export const UPDATE_EVALUACION_MODULO = "Modulos/UpdateModuloEvaluacion"
export const UPDATE_PONDERACION_MODULO_VERSION = "/Modulos/UpdatePonderacionModulo"
export const UPDATE_EVALUABLE_MODULO_VERSION = "/Modulos/UpdateEvaluableModulo"
export const UPDATE_PROMEDIO_MODULO_VERSION = "/Modulos/UpdatePromedioModulo"
export const UPDATE_NOTA_EVALUACION_MODULO = "Modulos/UpdateEvaluacionNota"
export const UPDATE_ESTADO_ACTA_MODULO = "Modulos/UpdateEstadoActa"
export const GET_SYLLABUS_MODULO = "Modulos/GetSyllabus"
export const GET_MODULOS_BY_ACADEMICO = "Modulos/GetModulosByAcademico"
export const GET_MODULO_VERSION = "Modulos/GetModuloVersion"
export const GET_EVALUACIONES_BY_MODULO_VERSION = "Modulos/GetEvaluacionesModulo"
export const GET_SESIONES_MODULO = "Modulos/GetSesionesModulo"
export const GET_ASISTENCIA_MODULO = "Modulos/GetAsistenciaModulo"
export const GET_HORARIO_MODULO = "Modulos/GetHorarioModulo"
export const DELETE_EVALUACION_MODULO = "Modulos/DeleteModuloEvaluacion"
export const DELETE_NOTA_EVALUACION_MODULO = "Modulos/DeleteEvaluacionNota"

// Pagos
export const ADD_NEW_LINK_PAGO = "/Pagos/CreatePago"
export const ADD_NEW_PAGO_WEBPAY = "/Pagos/CreatePagoWebpay"
export const GET_INFO_PAGO = "/Pagos/GetPago"
export const GET_CONFIRMA_PAGO_PAYPAL = "/Pagos/ConfirmPagoPaypal"

// Programas 
export const ADD_NEW_PROGRAMA = "/Programas/CreatePrograma"

//.CAB. Programas Datos Web
export const ADD_DATOSWEBPROGRAMA = "/Programas/UpdateOrCreateProgramasDatosWeb"
export const GET_PROGRAMADATOSWEB = "/Programas/GetProgramaDatosWeb";
export const GET_PROGRAMAVERSIONESDATOSWEB = "/Programas/GetProgramaVersionDatosWeb";

export const GET_PROGRAMAS = "/Programas/GetProgramas"
export const GET_PROGRAMA = "/Programas/GetPrograma"
export const UPDATE_PROGRAMA = "/Programas/UpdatePrograma"
export const GET_ESTADOS_PROGRAMA = "/List/GetEstadosProgramas"
export const GET_AREAS_PROGRAMA = "/List/GetAreas"
export const GET_MODALIDADES_PROGRAMA = "/List/GetPrgTipoPrograma"
export const CLONE_PROGRAMA = "/Programas/ClonarPrograma"
export const GET_PROGRAMAS_SIN_DECRETO = "/Programas/GetProgramasSinDecreto"
export const UPDATE_ESTADO_DECRETO_PROGRAMA = "/Programas/UpdateProgramaDecretoEstado"
export const GET_PROGRAMA_X_COD_BASE = "/Programas/GetProgramaPorCodigoBase"
export const GET_TIPOS_PROGRAMA = "/List/GetModalidadesProgramas"
export const GENERA_SYLLABUS_PROGRAMA = "/Programas/CreateDocsPrograma"

// Validador Documentos
export const POST_VALIDA_DOCUMENTO = "/ValidadorDocumento/ValidaDocumento"

// Versiones Programa
export const ADD_NEW_VERSION_PROGRAMA = "/VersionPrograma/CreateVersion"
export const ADD_NEW_CALENDARIO_PROGRAMA = "/VersionPrograma/CreateCalendario"
export const GET_MODULOS_VERSION_PROGRAMA = "/VersionPrograma/GetModulos"
export const GET_VERSION_PROGRAMA = "/VersionPrograma/GetVersion"
export const GET_VERSIONES_PROGRAMA = "/VersionPrograma/GetVersionesPrograma"
export const GET_VERSIONES_PROGRAMAS = "/VersionPrograma/GetVersionesProgramas"
export const GET_COUNT_VERSIONES_PROGRAMAS = "/VersionPrograma/GetCountVersionesProgramas"
export const GET_VERSIONES_PARRILLA = "/VersionPrograma/GetVersionesParrilla"
export const GET_VERSIONES_PARRILLA_DEE = "/VersionPrograma/GetVersionesParrillaDEE"
export const GET_ESTADOS_VERSION = "/List/GetEstadosVersiones"
export const GET_ESTUDIANTES_VERSION_PROGRAMA = "/VersionPrograma/GetVersionEstudiantes"
export const GET_LIBRO_CLASES_PROGRAMA = "/VersionPrograma/GetLibroClases"
export const GET_SESIONES_VERSION_PROGRAMA = "/VersionPrograma/GetSesionesVersionPrograma"
export const GET_CHOQUES_SESIONES_VERSION_PROGRAMA = "/VersionPrograma/GetChoqueSesionesVersionPrograma"
export const GET_CALENDARIO_PDF = "/VersionPrograma/GetCalendarioPDF"
export const UPDATE_VERSION_PROGRAMA = "/VersionPrograma/UpdateVersionPrograma"
export const UPDATE_ESTADO_VERSION_PROGRAMA = "/VersionPrograma/UpdateEstadoVersionPrograma"
export const UPDATE_PONDERACION_VERSION_PROGRAMA = "/VersionPrograma/UpdatePonderacionVersionPrograma"
export const UPDATE_TIPO_NOTA_VERSION_PROGRAMA = "/VersionPrograma/UpdateTipoNotaVersionPrograma"
export const UPDATE_ESTADO_CALENDARIO_PROGRAMA = "/VersionPrograma/UpdateEstadoCalendario"
export const REAGENDA_SESION_PROGRAMA = "/VersionPrograma/ReagendaSesion"
export const ENROQUE_MODULOS_PROGRAMA = "/VersionPrograma/EnroqueModulos"
export const CAMBIAR_FECHA_CALENDARIO_MODULO_PROGRAMA = "/VersionPrograma/CambiarFechaCalendario"
export const DESPLAZA_SESIONES_CALENDARIO_PROGRAMA = "/VersionPrograma/DesplazarSesiones"
export const ASIGNA_SALA_VERSION_PROGRAMA = "/VersionPrograma/ActualizaSalaVersion"
export const GET_VERSION_ESTUDIANTES_REPORTE = "/VersionPrograma/GetVersionEstudiantesReporte"

//.CAB. Programas Versiones Datos Web

export const ADD_DATOSWEBPROGRAMAVERSIONES = "/Programas/UpdateOrCreateProgramasVersionDatosWeb"


// Viajes
export const ADD_NEW_CARGA_CORPORATIVO = "/Viajes/CargaViajesCorporativo"
export const ADD_NEW_VIAJE_PARTICULAR = "/Viajes/CargaViajeParticular"
export const ADD_NEW_DESCUENTO_VIAJE = "/Viajes/CargaViajeDescuento"
export const ADD_NEW_FINANCIAMIENTO_VIAJE = "/Viajes/CargaViajeFinanciamiento"
export const ADD_DOCUMENTOS_VIAJE = "/Viajes/CargaViajeDocumentos"
export const GET_VIAJES = "/Viajes/GetViajes"
export const GET_VIAJE = "/Viajes/GetViaje"
export const GET_CARGAS_CORPORATIVOS = "/Viajes/GetCargasCorporativo"
export const GET_VALIDA_ESTUDIANTE_VIAJE_PROGRAMA = "/Viajes/GetValidaEstudianteViajePrograma"
export const GET_VISTA_PREVIA_VIAJE = "/Viajes/GetVistaPreviaViaje"
export const UPDATE_ESTADO_VIAJE = "/Viajes/UpdateEstadoViaje"
export const UPDATE_VIAJE_FINANCIAMIENTO = "/Viajes/UpdateViajeFinanciamiento"
export const UPDATE_VIAJE_FINANCIAMIENTO_FACTURA = "/Viajes/UpdateViajeFinanciamientoFactura"
export const UPDATE_VIAJE_DESCUENTO = "/Viajes/UpdateViajeDescuento"
export const ENVIA_FACTURA_FINANCIAMIENTO = "/Viajes/EnviaFacturaFinanciamiento"


// Encuesta
export const GET_ENCUESTA = "/Encuestas/GetEncuesta"
export const GET_ENCUESTA_BY_GUID = "/Encuestas/GetEncuestaByGuid"
export const ADD_NEW_ENCUESTA_RESPUESTA = "/Encuestas/CreateEncuestaRespuesta"
