import { takeEvery, call, put, takeLatest, all, fork} from "redux-saga/effects"
import { toast } from 'react-toastify';

import {
    GET_SALAS,
    ADD_NEW_SALA,
    UPDATE_SALA,
    GET_TIPO_SALAS,
    GET_SEDES,
    GET_EQUIPAMIENTO,
    ADD_NEW_EQUIPAMIENTO,
    GET_SALAEQUIPAMIENTO,
    DELETE_SALAEQUIPAMIENTO,
    GET_SALAS_BY_SEDE,
    GET_SALAS_VIRTUALES,
} from './actionTypes'

import { getSalas, updateSala, addNewSala, getTipoSalas, getSedes, addNewEquipamiento, getEquipamiento, addNewSalaEquipamiento, getSalaEquipamiento, deleteSalaEquipamiento, getSalasBySede} from "../../../helpers/backend_helper";
import { getSalasSuccess, getTipoSalasSuccess, getSedesSuccess, getEquipamientoSuccess, getSalaEquipamientoSuccess, getNewEquipamientoSuccess, getSalasBySedeSuccess, getSalasVirtualesSuccess } from "./actions";


function* fetchSalas({payload : params}){
    try {
        const response = yield call(getSalas, params)
        yield put(getSalasSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
    
}

function* putSala({payload: {sala, params}}){
    try {
        if(sala.id){
            const responsedel = yield call(deleteSalaEquipamiento, {salaId: sala.id})    
            yield all(sala.arrEquipamiento.map(element =>
                fork(addNewSalaEquipamientoSaga, {payload: {salaId: sala.id, equipamientoId: element.id, cantidad: element.cantidad}})
            ))

        }

        const response = yield call(updateSala, sala)
        const responseSalas = yield call(getSalas, params)
        yield put(getSalasSuccess(responseSalas))
        toast.success("Sala actualizada con éxito", { autoClose: 3000 });
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* addSala({payload: {sala, params}}){
    try {
        const response = yield call(addNewSala, sala)
        yield all(sala.arrEquipamiento.map(element =>
            fork(addNewSalaEquipamientoSaga, {payload: {salaId: response.data.objSala.id, equipamientoId: element.id, cantidad: element.cantidad}})
        ));

        const responseSalas = yield call(getSalas, params)
        yield put(getSalasSuccess(responseSalas))
        toast.success("Sala creada con éxito", { autoClose: 3000 });
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* addNewSalaEquipamientoSaga({payload: arrEquip}){
    try {
        const response = yield call(addNewSalaEquipamiento, arrEquip)
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchTipoSalas({payload : params}){
    try {
        const response = yield call(getTipoSalas, params)
        yield put(getTipoSalasSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
    
}

function* fetchSedes({payload : params}){
    try {
        const response = yield call(getSedes, params)
        yield put(getSedesSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
    
}
 

function* addEquipamiento({payload: {equipamiento}}){
    try {
        const response = yield call(addNewEquipamiento, equipamiento)
        yield put(getNewEquipamientoSuccess(response.data.obj))

        const responseEquipamiento = yield call(getEquipamiento)
        yield put(getEquipamientoSuccess(responseEquipamiento))
        toast.success("Equipamiento creado con éxito", { autoClose: 3000 });
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchEquipamiento({payload : params}){
    try {
        const response = yield call(getEquipamiento, params)
        yield put(getEquipamientoSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
    
}

function* fetchSalaEquipamiento({payload : params}){
    try {
        const response = yield call(getSalaEquipamiento, params)
        yield put(getSalaEquipamientoSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
    
}

function* deleteSalaEquipamientoSaga({payload: params}){
    try {
        const response = yield call(deleteSalaEquipamiento, params)
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchSalasBySede({payload : params}){
    try {
        const response = yield call(getSalasBySede, params)
        yield put(getSalasBySedeSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchSalasVirtuales(){
    try {
        const response = yield call(getSalasBySede, {sede_id: 4})
        yield put(getSalasVirtualesSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
    
}

function* operacionesSalasSaga() {
    yield takeEvery(GET_SALAS, fetchSalas)   
    yield takeLatest(UPDATE_SALA, putSala)
    yield takeLatest(ADD_NEW_SALA, addSala)
    yield takeLatest(GET_SALAS_BY_SEDE, fetchSalasBySede)
    yield takeEvery(GET_TIPO_SALAS, fetchTipoSalas)   
    yield takeEvery(GET_SEDES, fetchSedes)   
    yield takeEvery(GET_EQUIPAMIENTO, fetchEquipamiento)   
    yield takeLatest(ADD_NEW_EQUIPAMIENTO, addEquipamiento)
    yield takeEvery(GET_SALAEQUIPAMIENTO, fetchSalaEquipamiento)
    yield takeLatest(GET_SALAS_VIRTUALES, fetchSalasVirtuales)
}



export default operacionesSalasSaga