import {
  POST_FILE,
  POST_FILE_SUCCESS,
  RESET_FILE_STORE,
} from './actionTypes'

const INIT_STATE = {
  arrResult: [],
  loading: false
}

const File = (state = INIT_STATE, action) => {
  switch (action.type) {
    case POST_FILE:
      return {
        ...state,
        loading: true
      }
    case POST_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        arrResult: action.payload.data,
      }
    case RESET_FILE_STORE:
      return INIT_STATE
    default:
      return state
  }
}

export default File