export const GET_FBYMES = "GET_FBYMES";
export const GET_FBYMES_SUCCESS = "GET_FBYMES_SUCCESS";
export const GET_FBYMESRANGO = "GET_FBYMESRANGO";
export const GET_FBYMESRANGO_SUCCESS = "GET_FBYMESRANGO_SUCCESS";
export const GET_FBYMESRANGOCOMPARATIVO = "GET_FBYMESRANGOCOMPARATIVO";
export const GET_FBYMESRANGOCOMPARATIVO_SUCCESS = "GET_FBYMESRANGOCOMPARATIVO_SUCCESS";


export const GET_FBYSEMANAS = "GET_FBYSEMANAS";
export const GET_FBYSEMANAS_SUCCESS = "GET_FBYSEMANAS_SUCCESS";
export const GET_FBYDIAMES = "GET_FBYDIAMES";
export const GET_FBYDIAMES_SUCCESS = "GET_FBYDIAMES_SUCCESS";

export const GET_FBYMESRANGOTIPO = "GET_FBYMESRANGOTIPO";
export const GET_FBYMESRANGOTIPO_SUCCESS = "GET_FBYMESRANGOTIPO_SUCCESS";

export const GET_BOLETABYMESRANGO = "GET_BOLETABYMESRANGO";
export const GET_BOLETABYMESRANGO_SUCCESS = "GET_BOLETABYMESRANGO_SUCCESS";

export const GET_NVBYMES = "GET_NVBYMES";
export const GET_NVBYMES_SUCCESS = "GET_NVBYMES_SUCCESS";
export const GET_NVBYMESHOME = "GET_NVBYMESHOME";
export const GET_NVBYMESHOME_SUCCESS = "GET_NVBYMESHOME_SUCCESS";

export const GET_NVBYMESTIPO = "GET_NVBYMESTIPO";
export const GET_NVBYMESTIPO_SUCCESS = "GET_NVBYMESTIPO_SUCCESS";

export const GET_NVTIPOSPAGO = "GET_NVTIPOSPAGO";
export const GET_NVTIPOSPAGO_SUCCESS = "GET_NVTIPOSPAGO_SUCCESS";

export const GET_NVCANTIDADBYMESTIPO = "GET_NVCANTIDADBYMESTIPO";
export const GET_NVCANTIDADBYMESTIPO_SUCCESS = "GET_NVCANTIDADBYMESTIPO_SUCCESS";

export const POST_CARGADATAAUGE = "POST_CARGADATAAUGE";
export const POST_CARGADATAAUGE_SUCCESS = "POST_CARGADATAAUGE_SUCCESS";
