import { put, call, takeLatest } from "redux-saga/effects"
import { toast } from 'react-toastify';

import {
    GET_CAPTCHA,
    POST_CAPTCHA
} from './actionTypes'

import {
    getCaptchaSuccess,
    validaCaptchaSuccess
} from './actions'

import {
    getCaptcha,
    getImgCaptcha,
    validateCaptcha,
} from '../../helpers/backend_helper'

function* fetchCaptcha(){
    try {
        const response = yield call(getCaptcha)
        yield put(getCaptchaSuccess(response.data.id))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* postCaptcha({payload : {idCaptcha, valor}}){
  try {
      const data = {
        id: idCaptcha,
        valor
      }
      const response = yield call(validateCaptcha, data)
      yield put(validaCaptchaSuccess(response.data))
  } catch (error) {
      toast.error(error, {
          position: toast.POSITION.TOP_RIGHT
        });
  }
}

function* captchaSaga() {
    yield takeLatest(GET_CAPTCHA, fetchCaptcha)
    yield takeLatest(POST_CAPTCHA, postCaptcha)
}

export default captchaSaga