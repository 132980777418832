import { GET_CARGAS_CORPORATIVOS_SUCCESS, GET_VIAJES_SUCCESS } from "./actionTypes";

const INIT_STATE = {
  cargasCorporativos: [],
  paginacionCargasCorporativos: {},
  viajes: [],
  paginacionViajes: {},
}

const Viaje = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CARGAS_CORPORATIVOS_SUCCESS:
      return {
        ...state,
        cargasCorporativos: action.payload.data,
        paginacionCargasCorporativos: JSON.parse(action.payload.headers['x-pagination']),
      };
    case GET_VIAJES_SUCCESS:
      return {
        ...state,
        viajes: action.payload.data,
        paginacionViajes: JSON.parse(action.payload.headers['x-pagination']),
      };
    default:
      return state;
  }
}

export default Viaje;