import { put, call, takeLatest } from "redux-saga/effects"
import { toast } from 'react-toastify';
import { isNil } from 'ramda'

import {
    ADD_NEW_DESCUENTO_PROSPECTO,
    ADD_NEW_FICHA_POSTULACION,
    ADD_NEW_PROSPECTO,
    ADD_NEW_SEGUIMIENTO_PROSPECTO,
    DELETE_DESCUENTO_PROSPECTO,
    GET_PROSPECTO_BY_GUID,
    GET_PROSPECTO_PREVIEW_ENVIA_INFORMACION,
    GET_PROSPECTOS,
    GET_PROSPECTOS_BY_EMAIL,
    GET_PROSPECTOS_CONTADOR,
    POST_ENVIA_FICHA_POSTULACION,
    POST_PROCESA_MATRICULA_PROSPECTO,
    POST_PROSPECTO_ENVIA_INFORMACION,
    UPDATE_DESCUENTO_PROSPECTO,
    UPDATE_EJECUTIVO_PROSPECTO,
    UPDATE_VERSION_PROGRAMA_PROSPECTO,
} from './actionTypes'

import {
    addNewDescuentoProspecto,
    addNewFichaPostulacion,
    addNewProspecto,
    addNewProspectoSeguimiento,
    deleteDescuentoProspecto,
    getProspectoByGuid,
    getProspectoPreviewEnviaInformacion,
    getProspectos,
    GetProspectosByEmail,
    getProspectosContador,
    postEnviaFichaPostulacion,
    postFile,
    postMulitpleFile,
    postProcesaMatriculaProspecto,
    postProspectoEnviaInformacion,
    updateDescuentoProspecto,
    updateEjecutivoProspecto,
    updateVersionProgramaProspecto
} from '../../helpers/backend_helper'
import { getProspectoByGuidSuccess, getProspectoPreviewEnviaInformacionSuccess, getProspectosContadorSuccess, getProspectosSuccess } from "./actions";

function* fetchProspectos({ payload: params }) {
    try {
        const response = yield call(getProspectos, params)
        yield put(getProspectosSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

function* fetchProspectosByEmail({ payload: { email, callback } }) {
    try {
        const response = yield call(GetProspectosByEmail, { email })
        callback && callback(response.data)
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

function* fetchProspectosContador({ payload: params }) {
    try {
        const response = yield call(getProspectosContador, params)
        yield put(getProspectosContadorSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

function* fetchProspectoByGuid({ payload: fichaId }) {
    try {
        const response = yield call(getProspectoByGuid, { fichaId })
        yield put(getProspectoByGuidSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

function* fetchProspectoPreviewEnviaInformacion({ payload: params }) {
    try {
        const response = yield call(getProspectoPreviewEnviaInformacion, params)
        yield put(getProspectoPreviewEnviaInformacionSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

function* postProspecto({ payload: { params, callback } }) {
    try {
        const response = yield call(addNewProspecto, params)
        toast.success("Prospecto guardado correctamente", {
            position: toast.POSITION.TOP_RIGHT
        });
        callback && callback()
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

function* postProspectoSeguimiento({ payload: { params, callback } }) {
    try {
        const response = yield call(addNewProspectoSeguimiento, params)
        toast.success("Seguimiento guardado correctamente", {
            position: toast.POSITION.TOP_RIGHT
        });
        callback && callback()
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

function* procesaMatriculaProspecto({ payload: { params, callback } }) {
    try {
        const response = yield call(postProcesaMatriculaProspecto, params)
        toast.success("Matricula guardada correctamente", {
            position: toast.POSITION.TOP_RIGHT
        });
        callback && callback(response.data)
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

function* prospectoEnviaInformacion({ payload: { params, callback } }) {
    try {
        const response = yield call(postProspectoEnviaInformacion, params)
        toast.success("Email enviado correctamente", {
            position: toast.POSITION.TOP_RIGHT
        });
        callback && callback()
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

function* postFichaPostulacion({ payload: { params, files, filesCarga, callback } }) {
    try {
        let responseFiles
        if (files) {
            responseFiles = yield call(postMulitpleFile, files);
        }

        let index = 0
        filesCarga.forEach((file) => {
            if (file.value === true) {
                params[file.key] = responseFiles.data.filesId[index]
                index++
            }
            else
                params[file.key] = file.value
        })

        const response = yield call(addNewFichaPostulacion, params)
        callback && callback()
    }
    catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

function* prospectoEnviaFichaPostulacion({ payload: { params, callback } }) {
    try {
        const response = yield call(postEnviaFichaPostulacion, params)
        callback && callback()
    }
    catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

function* postDescuentoProspecto({ payload: { params, file, callback } }) {
    try {
        if (!isNil(file)) {
            const responseFile = yield call(postFile, file);
            params.archivoId = responseFile.data.fileId
        }

        const response = yield call(addNewDescuentoProspecto, params)
        toast.success("Descuento guardado correctamente", {
            position: toast.POSITION.TOP_RIGHT
        });
        callback && callback(response.data)
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

function* putReasignaEjecutivo({ payload: { params, callback } }) {
    try {
        const response = yield call(updateEjecutivoProspecto, params)
        toast.success("Ejecutivo reasignado correctamente", {
            position: toast.POSITION.TOP_RIGHT
        });
        callback && callback()
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

function* putDescuentoProspecto({ payload: { params, file, callback } }) {
    try {
        params.archivoId = null
        if (!isNil(file)) {
            const responseFile = yield call(postFile, file);
            params.archivoId = responseFile.data.fileId
        }
        const response = yield call(updateDescuentoProspecto, params)
        toast.success("Descuento actualizado correctamente", {
            position: toast.POSITION.TOP_RIGHT
        });
        callback && callback(params.archivoId)
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

function* putVersionProgramaProspecto({ payload: { params, callback } }) {
    try {
        const response = yield call(updateVersionProgramaProspecto, params)
        toast.success("Versión actualizada correctamente", {
            position: toast.POSITION.TOP_RIGHT
        });
        callback && callback()
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

function* removeDescuentoProspecto({ payload: { params, callback } }) {
    try {
        const response = yield call(deleteDescuentoProspecto, params)
        toast.success("Descuento eliminado correctamente", {
            position: toast.POSITION.TOP_RIGHT
        });
        callback && callback()
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

function* prospectoManagementSaga() {
    yield takeLatest(GET_PROSPECTOS, fetchProspectos)
    yield takeLatest(GET_PROSPECTOS_BY_EMAIL, fetchProspectosByEmail)
    yield takeLatest(GET_PROSPECTOS_CONTADOR, fetchProspectosContador)
    yield takeLatest(GET_PROSPECTO_BY_GUID, fetchProspectoByGuid)
    yield takeLatest(GET_PROSPECTO_PREVIEW_ENVIA_INFORMACION, fetchProspectoPreviewEnviaInformacion)
    yield takeLatest(ADD_NEW_PROSPECTO, postProspecto)
    yield takeLatest(ADD_NEW_SEGUIMIENTO_PROSPECTO, postProspectoSeguimiento)
    yield takeLatest(ADD_NEW_FICHA_POSTULACION, postFichaPostulacion)
    yield takeLatest(ADD_NEW_DESCUENTO_PROSPECTO, postDescuentoProspecto)
    yield takeLatest(POST_PROSPECTO_ENVIA_INFORMACION, prospectoEnviaInformacion)
    yield takeLatest(POST_ENVIA_FICHA_POSTULACION, prospectoEnviaFichaPostulacion)
    yield takeLatest(POST_PROCESA_MATRICULA_PROSPECTO, procesaMatriculaProspecto)
    yield takeLatest(UPDATE_EJECUTIVO_PROSPECTO, putReasignaEjecutivo)
    yield takeLatest(UPDATE_DESCUENTO_PROSPECTO, putDescuentoProspecto)
    yield takeLatest(UPDATE_VERSION_PROGRAMA_PROSPECTO, putVersionProgramaProspecto)
    yield takeLatest(DELETE_DESCUENTO_PROSPECTO, removeDescuentoProspecto)
}

export default prospectoManagementSaga