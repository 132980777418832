import { takeEvery, call, put, takeLatest, all, fork} from "redux-saga/effects"
import { toast } from 'react-toastify';

import { GET_FACTURABYRANGO, GET_FACTURABYNUMFACTURA, GET_FACTURAMOVIMIENTOSBYNUMFACTURA,
         GET_FACTURAHISTORICOMEDIOPAGOSBYNUMFACTURA,GET_FACTURACUOTASBYNUMFACTURA,
         GET_LISTAESTADOFACTURA , GET_LISTASUBESTADOFACTURA,GET_LISTATIPOSEGUIMIENTOMANUAL,
         GET_LISTAORGANISMOS, GET_LISTABANCOS,GET_LISTACUENTACORRIENTE, GET_SEGUIMIENTOSFACTURABYNUMFACTURA, 
         GET_MONTOSFACTURASDASHBOARD, GET_MONTOSFACTURASPAGADASCONMEDIODEPAGODASHBOARD, GET_MONTOSFACTURASMOROSASSINMEDIODEPAGODASHBOARD,
         GET_FACTURASEMITIDASDASHBOARDPORRANGOFECHAS, GET_FACTURASEMITIDASPAGADASDASHBOARDPORRANGOFECHAS,GET_FACTURASEMITIDASVERSUSCOMPORTAMIENTODEPAGODASHBOARDPORRANGOFECHAS,
         GET_GESTIONCOBRANZAFACTURASMOROSASPORRANGOFECHAS,ADD_GESTIONSEGUIMIENTOCOBRANZA } from './actionTypes'

import { getFacturasByRango,getFacturaByNumFactura,getFacturaMovimientosByNumFactura,getFacturaHistoricoMedioPagosByNumFactura,
         getFacturaCuotasByNumFactura,getListaEstadoFactura, getListaSubEstadoFactura,getListaTipoSeguimientoManual,
         getListaOrganismos,getListaBancos,getListaCuentaCorriente,getSeguimientosFacturaByNumFactura, getMontosFacturasDashboard, 
         getMontosFacturasPagadasConMedioDePagoDashboard,getMontosFacturasMorosasSinMedioDePagoDashboard, getFacturasEmitidasDashboardPorRangoFechas, 
         getFacturasEmitidasPagadasDashboardPorRangoFechas, getFacturasEmitidasVersusComportamientoDePagoDashboardPorRangoFechas,
         getGestionCobranzaFacturasMorosasPorRangoFechas, addGestionSeguimientoCobranza} from "../../helpers/backend_helper";


import { getFacturasByRangoSuccess, getFacturaByNumFacturaSuccess,getFacturaMovimientosByNumFacturaSuccess,
         getFacturaHistoricoMedioPagosByNumFacturaSuccess,getFacturaCuotasByNumFacturaSuccess,
         getListaEstadoFacturaSuccess,getListaSubEstadoFacturaSuccess, getListaTipoSeguimientoManualSuccess,
         getListaOrganismosSuccess,getListaBancosSuccess,getListaCuentaCorrienteSuccess, getSeguimientosFacturaByNumFacturaSuccess,
         getMontosFacturasDashboardSuccess,getMontosFacturasPagadasConMedioDePagoDashboardSuccess, getMontosFacturasMorosasSinMedioDePagoDashboardSuccess, 
         getFacturasEmitidasDashboardPorRangoFechasSuccess, getFacturasEmitidasPagadasDashboardPorRangoFechasSuccess, getFacturasEmitidasVersusComportamientoDePagoDashboardPorRangoFechasSuccess,
         getGestionCobranzaFacturasMorosasPorRangoFechasSuccess, addGestionSeguimientoCobranzaAdded } from "./actions";


function* fetchFacturasByRango({ payload: params })
{
    try 
    {
        const response = yield call(getFacturasByRango,params)
        yield put(getFacturasByRangoSuccess(response))

    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}


function* fetchFacturaByNumFactura({ payload: params })
{
    try 
    {
        const response = yield call(getFacturaByNumFactura,params)
        yield put(getFacturaByNumFacturaSuccess(response))

    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}


function* fetchFacturaMovimientosByNumFactura({ payload: params })
{
    try 
    {
        const response = yield call(getFacturaMovimientosByNumFactura,params)
        yield put(getFacturaMovimientosByNumFacturaSuccess(response))

    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}


function* fetchFacturaCuotasByNumFactura({ payload: params })
{
    try 
    {
        const response = yield call(getFacturaCuotasByNumFactura,params)
        yield put(getFacturaCuotasByNumFacturaSuccess(response))

    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}



function* fetchFacturaHistoricoMedioPagosByNumFactura({ payload: params })
{
    try 
    {
        const response = yield call(getFacturaHistoricoMedioPagosByNumFactura,params)
        yield put(getFacturaHistoricoMedioPagosByNumFacturaSuccess(response))

    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}



function* fetchListaEstadoFacturas()
{
    try 
    {
        const response = yield call(getListaEstadoFactura)
        yield put(getListaEstadoFacturaSuccess(response) )

    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}


function* fetchListaSubEstadoFacturas()
{
    try 
    {
        const response = yield call(getListaSubEstadoFactura)
        yield put(getListaSubEstadoFacturaSuccess(response) )

    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}


function* fetchListaTipoSeguimientoManual()
{
    try 
    {
        const response = yield call(getListaTipoSeguimientoManual)
        yield put(getListaTipoSeguimientoManualSuccess(response))

    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}


function* fetchListaOrganismos()
{
    try 
    {
        const response = yield call(getListaOrganismos)
        yield put(getListaOrganismosSuccess(response) )

    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}


function* fetchListaBancos({ payload: params })
{
    try 
    {
        const response = yield call(getListaBancos,params)
        yield put(getListaBancosSuccess(response))

    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchListaCuentaCorriente({ payload: params })
{
    try 
    {
        const response = yield call(getListaCuentaCorriente,params)
        yield put(getListaCuentaCorrienteSuccess(response))

    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}


function* fetchSeguimientosFacturaByNumFactura({ payload: params })
{
    try 
    {
        const response = yield call(getSeguimientosFacturaByNumFactura,params)
        yield put(getSeguimientosFacturaByNumFacturaSuccess(response))

    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchMontosFacturasDashboard({ payload: params })
{
    try 
    {
        const response = yield call( getMontosFacturasDashboard,params)
        yield put(getMontosFacturasDashboardSuccess(response))

    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}


function* fetchMontosFacturasPagadasConMedioDePagoDashboard({ payload: params })
{
    try 
    {
        const response = yield call( getMontosFacturasPagadasConMedioDePagoDashboard,params)
        yield put(getMontosFacturasPagadasConMedioDePagoDashboardSuccess(response))

    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchMontosFacturasMorosasSinMedioDePagoDashboard({ payload: params })
{
    try 
    {
        const response = yield call( getMontosFacturasMorosasSinMedioDePagoDashboard,params)
        yield put(getMontosFacturasMorosasSinMedioDePagoDashboardSuccess(response))

    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}



function* fetchFacturasEmitidasDashboardPorRangoFechas({ payload: params })
{
    try 
    {
        const response = yield call( getFacturasEmitidasDashboardPorRangoFechas,params)
        yield put(getFacturasEmitidasDashboardPorRangoFechasSuccess(response))

    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchFacturasEmitidasPagadasDashboardPorRangoFechas({ payload: params })
{
    try 
    {
        const response = yield call( getFacturasEmitidasPagadasDashboardPorRangoFechas,params)
        yield put(getFacturasEmitidasPagadasDashboardPorRangoFechasSuccess(response))

    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}


function* fetchFacturasEmitidasVersusComportamientoDePagoDashboardPorRangoFechas({ payload: params })
{
    try 
    {
        const response = yield call( getFacturasEmitidasVersusComportamientoDePagoDashboardPorRangoFechas,params)
        yield put(getFacturasEmitidasVersusComportamientoDePagoDashboardPorRangoFechasSuccess(response))

    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}




function* fetchGestionCobranzaFacturasMorosasPorRangoFechas({ payload: params })
{
    try 
    {
        const response = yield call( getGestionCobranzaFacturasMorosasPorRangoFechas,params)
        yield put(getGestionCobranzaFacturasMorosasPorRangoFechasSuccess(response))

    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}



function* add_GestionSeguimientoCobranza({payload: {params,callback}})
{
    try {
        const response = yield call(addGestionSeguimientoCobranza, params)
        yield put(addGestionSeguimientoCobranzaAdded(response))
        callback && callback();
        toast.success("Registro creado con éxito", { autoClose: 3000 });
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}


function* cobranzaSaga() {

    yield takeLatest(GET_FACTURABYRANGO, fetchFacturasByRango)   
    yield takeLatest(GET_FACTURABYNUMFACTURA, fetchFacturaByNumFactura) 
    yield takeLatest(GET_FACTURAMOVIMIENTOSBYNUMFACTURA, fetchFacturaMovimientosByNumFactura)
    yield takeLatest(GET_FACTURACUOTASBYNUMFACTURA, fetchFacturaCuotasByNumFactura)
    yield takeLatest(GET_FACTURAHISTORICOMEDIOPAGOSBYNUMFACTURA, fetchFacturaHistoricoMedioPagosByNumFactura)
    yield takeLatest(GET_LISTAESTADOFACTURA, fetchListaEstadoFacturas)   
    yield takeLatest(GET_LISTASUBESTADOFACTURA, fetchListaSubEstadoFacturas)   
    yield takeLatest(GET_LISTATIPOSEGUIMIENTOMANUAL, fetchListaTipoSeguimientoManual)   
    yield takeLatest(GET_LISTAORGANISMOS, fetchListaOrganismos)   
    yield takeLatest(GET_LISTABANCOS, fetchListaBancos)   
    yield takeLatest(GET_LISTACUENTACORRIENTE, fetchListaCuentaCorriente)   
    yield takeLatest(GET_SEGUIMIENTOSFACTURABYNUMFACTURA, fetchSeguimientosFacturaByNumFactura)   
    yield takeLatest(GET_MONTOSFACTURASDASHBOARD, fetchMontosFacturasDashboard)   
    yield takeLatest(GET_MONTOSFACTURASPAGADASCONMEDIODEPAGODASHBOARD, fetchMontosFacturasPagadasConMedioDePagoDashboard)   

    yield takeLatest(GET_MONTOSFACTURASMOROSASSINMEDIODEPAGODASHBOARD, fetchMontosFacturasMorosasSinMedioDePagoDashboard)   
    
    yield takeLatest(GET_FACTURASEMITIDASDASHBOARDPORRANGOFECHAS, fetchFacturasEmitidasDashboardPorRangoFechas)   
    yield takeLatest(GET_FACTURASEMITIDASPAGADASDASHBOARDPORRANGOFECHAS, fetchFacturasEmitidasPagadasDashboardPorRangoFechas)   
    yield takeLatest(GET_FACTURASEMITIDASVERSUSCOMPORTAMIENTODEPAGODASHBOARDPORRANGOFECHAS, fetchFacturasEmitidasVersusComportamientoDePagoDashboardPorRangoFechas)   
    yield takeLatest(GET_GESTIONCOBRANZAFACTURASMOROSASPORRANGOFECHAS, fetchGestionCobranzaFacturasMorosasPorRangoFechas)   

    yield takeLatest(ADD_GESTIONSEGUIMIENTOCOBRANZA,add_GestionSeguimientoCobranza)

}


export default cobranzaSaga