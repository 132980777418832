export const GET_MODULOS = "GET_MODULOS"
export const GET_MODULOS_SUCCESS = "GET_MODULOS_SUCCESS"
export const GET_MODULOS_BY_CODIGO = "GET_MODULOS_BY_CODIGO"
export const GET_MODULOS_BY_CODIGO_SUCCESS = "GET_MODULOS_BY_CODIGO_SUCCESS"
export const GET_ESTADO_MODULOS = "GET_ESTADO_MODULOS"
export const GET_ESTADO_MODULOS_SUCCESS = "GET_ESTADO_MODULOS_SUCCESS"
export const GET_SYLLABUS_MODULO = "GET_SYLLABUS_MODULO"
export const GET_SYLLABUS_MODULO_SUCCESS = "GET_SYLLABUS_MODULO_SUCCESS"
export const GET_HORARIO_MODULO = "GET_HORARIO_MODULO"
export const GET_HORARIO_MODULO_SUCCESS = "GET_HORARIO_MODULO_SUCCESS"
export const ADD_NEW_MODULO = "ADD_NEW_MODULO"
export const ADD_NEW_EVALUACION_MODULO = "ADD_NEW_EVALUACION_MODULO"
export const ADD_NEW_NOTA_EVALUACION_MODULO = "ADD_NEW_NOTA_EVALUACION_MODULO"
export const ADD_NEW_ASISTENCIA_MODULO = "ADD_NEW_ASISTENCIA_MODULO"
export const UPDATE_MODULO = "UPDATE_MODULO"
export const UPDATE_EVALUACION_MODULO = "UPDATE_EVALUACION_MODULO"
export const UPDATE_PROMEDIO_MODULO_VERSION = "UPDATE_PROMEDIO_MODULO_VERSION"
export const UPDATE_NOTA_EVALUACION_MODULO = "UPDATE_NOTA_EVALUACION_MODULO"
export const UPDATE_NOTA_EVALUACION_MODULO_SUCCESS = "UPDATE_NOTA_EVALUACION_MODULO_SUCCESS"
export const UPDATE_ESTADO_ACTA_MODULO = "UPDATE_ESTADO_ACTA_MODULO"
export const RESET_SYLLABUS_MODULO = "RESET_SYLLABUS_MODULO"
export const GET_MODULOS_BY_ACADEMICO = "GET_MODULOS_BY_ACADEMICO"
export const GET_MODULOS_BY_ACADEMICO_SUCCESS = "GET_MODULOS_BY_ACADEMICO_SUCCESS"
export const GET_MODULO_VERSION = "GET_MODULO_VERSION"
export const GET_MODULO_VERSION_SUCCESS = "GET_MODULO_VERSION_SUCCESS"
export const GET_EVALUACIONES_BY_MODULO_VERSION = "GET_EVALUACIONES_BY_MODULO_VERSION"
export const GET_EVALUACIONES_BY_MODULO_VERSION_SUCCESS = "GET_EVALUACIONES_BY_MODULO_VERSION_SUCCESS"
export const GET_SESIONES_MODULO = "GET_SESIONES_MODULO"
export const GET_SESIONES_MODULO_SUCCESS = "GET_SESIONES_MODULO_SUCCESS"
export const GET_ASISTENCIA_MODULO = "GET_ASISTENCIA_MODULO"
export const GET_ASISTENCIA_MODULO_SUCCESS = "GET_ASISTENCIA_MODULO_SUCCESS"
export const DELETE_EVALUACION_MODULO = "DELETE_EVALUACION_MODULO"
export const DELETE_NOTA_EVALUACION_MODULO = "DELETE_NOTA_EVALUACION_MODULO"