import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import { getVersionesProgramaSuccess, getVersionesParrillaSuccess, getVersionesParrillaDEESuccess, getEstadosVersionSuccess, getVersionesProgramaFullSuccess, getCountVersionesProgramasSuccess, getModulosVersionProgramaSuccess, getVersionProgramaSuccess, getEstudiantesVersionProgramaSuccess, getLibroClasesProgramaSuccess, getSesionesVersionProgramaSuccess, reagendaSesionProgramaSuccess, enroqueModulosProgramaSuccess, cambiarFechaCalendarioModuloProgramaSuccess, desplazaSesionesCalendarioSuccess, asignaSalaVersionSuccess, getVersionEstudiantesReporteSuccess } from "./actions";
import { GET_VERSIONES_PROGRAMA, GET_VERSIONES_PARRILLA, GET_VERSIONES_PARRILLA_DEE, POST_VERSION_PROGRAMA, GET_ESTADOS_VERSION, UPDATE_VERSION_PROGRAMA, CAMBIA_ESTADO_VERSION, GET_COUNT_VERSIONES_PROGRAMAS, GET_MODULOS_VERSION_PROGRAMA, UPDATE_PONDERACION_MODULO_VERSION, UPDATE_EVALUABLE_MODULO_VERSION, UPDATE_PONDERACION_VERSION_PROGRAMA, GET_VERSION_PROGRAMA, GET_ESTUDIANTES_VERSION_PROGRAMA, GET_LIBRO_CLASES_PROGRAMA, GET_SESIONES_VERSION_PROGRAMA, POST_CALENDARIO_PROGRAMA, GET_CHOQUES_SESIONES_VERSION_PROGRAMA, REAGENDA_SESION_PROGRAMA, ENROQUE_MODULOS_PROGRAMA, CAMBIAR_FECHA_CALENDARIO_MODULO_PROGRAMA, UPDATE_TIPO_NOTA_VERSION_PROGRAMA, DESPLAZA_SESIONES_CALENDARIO, ASIGNA_SALA_VERSION_PROGRAMA, UPDATE_ESTADO_CALENDARIO_PROGRAMA, GET_CALENDARIO_PDF, GET_VERSION_ESTUDIANTES_REPORTE } from "./actionTypes";
import { addNewVersionPrograma, getVersionesPrograma, getVersionesParrilla, getVersionesParrillaDEE, getEstadosVersion, putVersionPrograma, putEstadoVersion, getVersionesProgramas, getCountVersionesPrograma, getModulosVersionPrograma, putPonderacionModuloVersion, putEvaluableModuloVersion, putPonderacionVersionPrograma, getVersionPrograma, getEstudiantesVersionPrograma, getLibroClasesPrograma, getSesionesVersionPrograma, addNewCalendarioPrograma, GetChoquesSesionesVersionPrograma, reagendaSesionPrograma, enroqueModulosPrograma, cambiarFechaCalendarioModuloPrograma, putTipoNotaVersionPrograma, desplazaSesionesCalendarioPrograma, asignaSalaVersionPrograma, putEstadoCalendarioPrograma, getCalendarioPDF, getVersionEstudiantesReporte } from "../../helpers/backend_helper";
import { Spinner } from "reactstrap";
import FileSaver from "file-saver";

function* fetchVersionesPrograma({ payload: params }) {
  try {
    if (params.idPrograma === null || params.idPrograma === undefined) {
      const response = yield call(getVersionesProgramas, params)
      yield put(getVersionesProgramaFullSuccess(response));
    }
    else {
      const response = yield call(getVersionesPrograma, params)
      yield put(getVersionesProgramaSuccess(response));
    }
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchVersionPrograma({ payload: id }) {
  try {
    const response = yield call(getVersionPrograma, { id })
    yield put(getVersionProgramaSuccess(response));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchCountVersionesProgramas({ payload: params }) {
  try {
    const response = yield call(getCountVersionesPrograma, params)
    yield put(getCountVersionesProgramasSuccess(response));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchModulosVersionPrograma({ payload: params }) {
  try {
    const response = yield call(getModulosVersionPrograma, params)
    yield put(getModulosVersionProgramaSuccess(response));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchEstudiantesVersionPrograma({ payload: params }) {
  try {
    const response = yield call(getEstudiantesVersionPrograma, { id: params })
    yield put(getEstudiantesVersionProgramaSuccess(response));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchLibroClasesPrograma({ payload: params }) {
  try {
    const response = yield call(getLibroClasesPrograma, params)
    yield put(getLibroClasesProgramaSuccess(response));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchSesionesVersionPrograma({ payload: params }) {
  try {
    const response = yield call(getSesionesVersionPrograma, params)
    yield put(getSesionesVersionProgramaSuccess(response));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchChoquesSesionVersionPrograma({ payload: { sesiones , callback } }) {
  try {
    const response = yield call(GetChoquesSesionesVersionPrograma, sesiones);
    if (callback !== null)
      callback(response.data)
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* postVersionPrograma({ payload: { params, callback } }) {
  try {
    const response = yield call(addNewVersionPrograma, params)
    if (callback !== null)
      callback()
    toast.success("Versión creada correctamente", {
      position: toast.POSITION.TOP_RIGHT,
    });
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* postCalendarioPrograma({ payload: { params, callback, callbackError } }) {
  try {
    const response = yield call(addNewCalendarioPrograma, params)
    if (callback !== null)
      callback()
    toast.success("Calendario creado correctamente", {
      position: toast.POSITION.TOP_RIGHT,
    });
  } catch (error) {
    if (callbackError !== null)
      callbackError()
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchVersionesParrilla({ payload: { params, filtrarPendientes } }) {
  try {
    const response = yield call(getVersionesParrilla, {...params, filtroPendientes: filtrarPendientes})
    yield put(getVersionesParrillaSuccess(response));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchVersionesParrillaDEE({ payload: { params, filtrarPendientes } }) {
  try {
    const response = yield call(getVersionesParrillaDEE, { ...params, filtroPendientes: filtrarPendientes })
    yield put(getVersionesParrillaDEESuccess(response));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchEstadosVersion() {
  try {
    const response = yield call(getEstadosVersion)
    yield put(getEstadosVersionSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

const MensajeCargandoToast = () => (
  <div className="d-flex align-items-center">
    Generando archivo calendario <Spinner className={"ms-3"} size={"sm"}  color="info" />
  </div>
);

function* fetchCalendarioPDF({ payload: params }) {
  try {
    toast(<MensajeCargandoToast />, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: false,
      toastId: "fetchCalendarioPDFToast"
    })
    const response = yield call(getCalendarioPDF, params);
    const blob = new Blob([response.data], { type: 'application/pdf' });
    const fileName = response.headers['content-disposition']
      ? response.headers['content-disposition'].split('filename=')[1].split(";")[0]
      : 'calendario_version.pdf';

    FileSaver.saveAs(blob, fileName);
    toast.dismiss("fetchCalendarioPDFToast");
  } catch (error) {
    toast.dismiss("fetchCalendarioPDFToast");
    
    let errorMessage = 'Error desconocido';
    
    if (error instanceof ArrayBuffer) {
      // Convertir ArrayBuffer a texto
      const decoder = new TextDecoder('utf-8');
      try {
        const decodedError = decoder.decode(error);
        errorMessage = decodedError;
      } catch (e) {
        errorMessage = 'Error al procesar la respuesta del servidor';
      }
    } else {
      errorMessage = error || 'Error al descargar el PDF';
    }

    toast.error(errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* updateVersionPrograma({ payload: { params, callback } }) {
  try {
    const response = yield call(putVersionPrograma, params)
    if (callback !== null)
      callback()
    toast.success("Versión actualizada correctamente", {
      position: toast.POSITION.TOP_RIGHT,
    });
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* updatePonderacionVersionPrograma({ payload: params }) {
  try {
    const responseUp = yield call(putPonderacionVersionPrograma, params)
    toast.success("Promedio programa actualizado correctamente", {
      position: toast.POSITION.TOP_RIGHT,
    });
    const response = yield call(getModulosVersionPrograma, { id: params.versionProgramaId })
    yield put(getModulosVersionProgramaSuccess(response));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* updatePonderacionModuloVersion({ payload: params }) {
  try {
    const responseUp = yield call(putPonderacionModuloVersion, params)
    toast.success("Ponderación actualizada correctamente", {
      position: toast.POSITION.TOP_RIGHT,
    });
    const response = yield call(getModulosVersionPrograma, { id: params.versionId })
    yield put(getModulosVersionProgramaSuccess(response));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* updateEvaluableModuloVersion({ payload: params }) {
  try {
    const responseUp = yield call(putEvaluableModuloVersion, params)
    toast.success("Módulo actualizado correctamente", {
      position: toast.POSITION.TOP_RIGHT,
    });
    const response = yield call(getModulosVersionPrograma, { id: params.versionId })
    yield put(getModulosVersionProgramaSuccess(response));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* updateTipoNotaModuloVersion({ payload: { data, callback } }) {
  try {
    const response = yield call(putTipoNotaVersionPrograma, { ...data })
    if(callback)
      callback()
    toast.success("Tipo de nota actualizado correctamente", {
      position: toast.POSITION.TOP_RIGHT,
    });
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* updateEstadoCalendarioPrograma({ payload: { params, callback } }) {
  try {
    const response = yield call(putEstadoCalendarioPrograma, params)
    if (callback !== null)
      callback()
    toast.success("Estado actualizado correctamente", {
      position: toast.POSITION.TOP_RIGHT,
    });
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* cambiaEstadoVersion({ payload: { obj, params, isDirector } }) {
  try {

    let version = { idVersion: obj.versionId, idEstado: obj.estadoId }
    const responseEstado = yield call(putEstadoVersion, obj.versionId, version);

    if (isDirector) {
      //const response = yield call(getVersionesParrillaDEE, params, true)
      const response = yield call(getVersionesParrillaDEE, { ...params, filtroPendientes: true })
      yield put(getVersionesParrillaDEESuccess(response));
    } else {
      const response = yield call(getVersionesParrilla, { ...params, filtroPendientes: true })
      yield put(getVersionesParrillaSuccess(response));
    }




    toast.success("Versión actualizada con éxito", { autoClose: 3000 });
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* reagendarSesionPrograma({ payload: { params, callback } }) {
  try {
    const response = yield call(reagendaSesionPrograma, params)
    const resp = { ...response, data: { ...response.data, oldSesion: params.sesionId } }
    yield put(reagendaSesionProgramaSuccess(resp))
    if (callback !== null)
      callback()
    toast.success("Sesión reagendada correctamente", {
      position: toast.POSITION.TOP_RIGHT,
    });
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* enroqueModulosVersionPrograma({ payload: { params, callback, callbackError } }) {
  try {
    const response = yield call(enroqueModulosPrograma, params)
    yield put(enroqueModulosProgramaSuccess(response))
    if (callback !== null)
      callback()
    toast.success("Enroque realizado correctamente", {
      position: toast.POSITION.TOP_RIGHT,
    });
  } catch (error) {
    if (callbackError !== null)
      callbackError()
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* cambiarCalendarioModuloPrograma({ payload: { params, callback, callbackError } }) {
  try {
    const response = yield call(cambiarFechaCalendarioModuloPrograma, params)
    yield put(cambiarFechaCalendarioModuloProgramaSuccess(response))
    if (callback !== null)
      callback()
    toast.success("Cambio de fecha realizado correctamente", {
      position: toast.POSITION.TOP_RIGHT,
    });
  } catch (error) {
    if (callbackError !== null)
      callbackError()
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* desplazarSesionesPrograma({ payload: { params, callback, callbackError } }) {
  try {
    const response = yield call(desplazaSesionesCalendarioPrograma, params)
    yield put(desplazaSesionesCalendarioSuccess(response))
    if (callback !== null)
      callback()
    toast.success("Desplazamiento realizado correctamente", {
      position: toast.POSITION.TOP_RIGHT,
    });
  } catch (error) {
    if (callbackError !== null)
      callbackError()
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* asignaSalaVersion({ payload: { params, callback } }) {
  try {
    const response = yield call(asignaSalaVersionPrograma, params)
    yield put(asignaSalaVersionSuccess(response));
    if (callback !== null)
      callback()
    toast.success("Sala asignada correctamente", {
      position: toast.POSITION.TOP_RIGHT,
    });
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchVersionEstudiantesReporte({payload : params}){
    try {
        const response = yield call(getVersionEstudiantesReporte, params)
        yield put(getVersionEstudiantesReporteSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* versionesSaga() {
  yield takeLatest(GET_VERSIONES_PROGRAMA, fetchVersionesPrograma);
  yield takeLatest(GET_VERSION_PROGRAMA, fetchVersionPrograma);
  yield takeLatest(GET_COUNT_VERSIONES_PROGRAMAS, fetchCountVersionesProgramas);
  yield takeLatest(GET_MODULOS_VERSION_PROGRAMA, fetchModulosVersionPrograma);
  yield takeLatest(POST_VERSION_PROGRAMA, postVersionPrograma);
  yield takeLatest(POST_CALENDARIO_PROGRAMA, postCalendarioPrograma);
  yield takeLatest(GET_VERSIONES_PARRILLA, fetchVersionesParrilla);
  yield takeLatest(GET_VERSIONES_PARRILLA_DEE, fetchVersionesParrillaDEE);
  yield takeLatest(GET_ESTADOS_VERSION, fetchEstadosVersion)
  yield takeLatest(GET_ESTUDIANTES_VERSION_PROGRAMA, fetchEstudiantesVersionPrograma)
  yield takeLatest(GET_LIBRO_CLASES_PROGRAMA, fetchLibroClasesPrograma)
  yield takeLatest(GET_SESIONES_VERSION_PROGRAMA, fetchSesionesVersionPrograma)
  yield takeEvery(GET_CHOQUES_SESIONES_VERSION_PROGRAMA, fetchChoquesSesionVersionPrograma)
  yield takeLatest(GET_CALENDARIO_PDF, fetchCalendarioPDF)
  yield takeLatest(UPDATE_VERSION_PROGRAMA, updateVersionPrograma);
  yield takeLatest(UPDATE_PONDERACION_MODULO_VERSION, updatePonderacionModuloVersion);
  yield takeLatest(UPDATE_PONDERACION_VERSION_PROGRAMA, updatePonderacionVersionPrograma);
  yield takeLatest(UPDATE_EVALUABLE_MODULO_VERSION, updateEvaluableModuloVersion);
  yield takeLatest(UPDATE_TIPO_NOTA_VERSION_PROGRAMA, updateTipoNotaModuloVersion)
  yield takeLatest(UPDATE_ESTADO_CALENDARIO_PROGRAMA, updateEstadoCalendarioPrograma)
  yield takeLatest(CAMBIA_ESTADO_VERSION, cambiaEstadoVersion)
  yield takeLatest(REAGENDA_SESION_PROGRAMA, reagendarSesionPrograma)
  yield takeLatest(ENROQUE_MODULOS_PROGRAMA, enroqueModulosVersionPrograma)
  yield takeLatest(CAMBIAR_FECHA_CALENDARIO_MODULO_PROGRAMA, cambiarCalendarioModuloPrograma)
  yield takeLatest(DESPLAZA_SESIONES_CALENDARIO, desplazarSesionesPrograma)
  yield takeLatest(ASIGNA_SALA_VERSION_PROGRAMA, asignaSalaVersion)
  yield takeLatest(GET_VERSION_ESTUDIANTES_REPORTE, fetchVersionEstudiantesReporte)
}

export default versionesSaga;