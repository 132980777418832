import {
  GET_PROGRAMAS,
  GET_PROGRAMAS_SUCCESS,
  GET_ESTADOS_PROGRAMA,
  GET_ESTADOS_PROGRAMA_SUCCESS,
  GET_AREAS_PROGRAMA,
  GET_AREAS_PROGRAMA_SUCCESS,
  ADD_NEW_PROGRAMA,
 
  GET_AREA_PROGRAMA,
  GET_AREA_PROGRAMA_SUCCESS,  
  UPDATE_PROGRAMA,
  CLONE_PROGRAMA,
  GET_PROGRAMAS_SIN_DECRETO,
  GET_PROGRAMAS_SIN_DECRETO_SUCCESS,
  GET_PROGRAMA_X_COD_BASE,
  GET_PROGRAMA_X_COD_BASE_SUCCESS,
  GET_MODALIDADES_PROGRAMA,
  GET_MODALIDADES_PROGRAMA_SUCCESS,
  GET_TIPOS_PROGRAMA,
  GET_TIPOS_PROGRAMA_SUCCESS,  
  UPDATE_ESTADO_DECRETO_PROGRAMA,
  GENERA_SYLLABUS_PROGRAMA,
  GET_PROGRAMA,
  GET_PROGRAMA_SUCCESS,
  
  GET_PROGRAMADATOSWEB,
  GET_PROGRAMADATOSWEB_SUCCESS,
  GET_PROGRAMAVERSIONESDATOSWEB,
  GET_PROGRAMAVERSIONESDATOSWEB_SUCCESS,
  ADD_DATOSWEBPROGRAMA,
  ADD_DATOSWEBPROGRAMA_ADDED,

  ADD_DATOSWEBPROGRAMAVERSIONES,
  ADD_DATOSWEBPROGRAMAVERSIONES_ADDED,

  RESET_PROGRAMA,

  } from "./actionTypes";





export const getProgramas = (params) => ({
  type: GET_PROGRAMAS,
  payload: params
});

export const getProgramasSuccess = (params) => ({
  type: GET_PROGRAMAS_SUCCESS,
  payload: params,
});

export const getPrograma = idPrograma => ({
  type: GET_PROGRAMA,
  payload: {idPrograma},
});

export const getProgramaSuccess = data => ({
  type: GET_PROGRAMA_SUCCESS,
  payload: data,
});

export const getEstadosProgramaSuccess = (data) => ({
  type: GET_ESTADOS_PROGRAMA_SUCCESS,
  payload: data,
});

export const getEstadosPrograma = () => ({
  type: GET_ESTADOS_PROGRAMA
});

export const getAreasProgramaSuccess = (data) => ({
  type: GET_AREAS_PROGRAMA_SUCCESS,
  payload: data,
});

export const getAreasPrograma = () => ({
  type: GET_AREAS_PROGRAMA
});



export const addNewPrograma  = (programa, files, fetchData) => ({
  type: ADD_NEW_PROGRAMA,
  payload: {programa, files, fetchData},
});


export const updatePrograma = (programa, files, fetchData, filesUpdate) => ({
  type: UPDATE_PROGRAMA,
  payload: {programa, files, fetchData, filesUpdate},
});

export const clonePrograma = (data, fetchData, setNuevoProgClon) => ({
  type: CLONE_PROGRAMA,
  payload: {data, fetchData, setNuevoProgClon},
});

export const getProgramaXCodBase = (data) => ({
  type: GET_PROGRAMA_X_COD_BASE,
  payload: data,
});
export const getProgramaXCodBaseSuccess = (data) => ({
  type: GET_PROGRAMA_X_COD_BASE_SUCCESS,
  payload: data,
});

export const getModalidadesPrograma = () => ({
  type: GET_MODALIDADES_PROGRAMA
});

export const getModalidadesProgramaSuccess = (data) => ({
  type: GET_MODALIDADES_PROGRAMA_SUCCESS,
  payload: data
});

export const getTiposPrograma = () => ({
  type: GET_TIPOS_PROGRAMA
});

export const getTiposProgramaSuccess = (data) => ({
  type: GET_TIPOS_PROGRAMA_SUCCESS,
  payload: data
});


export const getProgramasSinDecreto = (params) => ({
  type: GET_PROGRAMAS_SIN_DECRETO,
  payload: params
});

export const getProgramasSinDecretoSuccess = (data) => ({
  type: GET_PROGRAMAS_SIN_DECRETO_SUCCESS,
  payload: data
});

export const updateProgramaDecretoEstado = (data, formFile, fetchData) => ({
  type: UPDATE_ESTADO_DECRETO_PROGRAMA,
  payload: {data, formFile, fetchData}
});

export const generaSyllabusPrograma = (data, setNomSyllabusPrograma) => ({
  type: GENERA_SYLLABUS_PROGRAMA,
  payload: {data, setNomSyllabusPrograma},
});


//.CAB. Programas Datos Web
//----------------------------------------------

export const addDatosWebPrograma = (progDatosWeb, fileTarjeta, fileBanner, callback) => ({
  type: ADD_DATOSWEBPROGRAMA,
  payload: {progDatosWeb, fileTarjeta,fileBanner,callback },
});


export const addDatosWebProgramaAdded = (data) => ({
  type: ADD_DATOSWEBPROGRAMA_ADDED,
  payload: data
});


export const getProgramaDatosWeb = (params) => ({
  type: GET_PROGRAMADATOSWEB,
  payload: params
});

export const getProgramaDatosWebSuccess = (data) => ({
  type: GET_PROGRAMADATOSWEB_SUCCESS,
  payload: data
});


export const getProgramaVersionesDatosWeb = (params) => ({
  type: GET_PROGRAMAVERSIONESDATOSWEB,
  payload: params
});

export const getProgramaVersionesDatosWebSuccess = (data) => ({
  type: GET_PROGRAMAVERSIONESDATOSWEB_SUCCESS,
  payload: data
});


//.CAB. Programas Versiones Datos Web
//----------------------------------------------

export const addDatosWebProgramaVersiones = (progVersionDatosWeb, fileTarjeta, fileBanner, fileBrochure,callback) => ({
  type: ADD_DATOSWEBPROGRAMAVERSIONES,
  payload: {progVersionDatosWeb,fileTarjeta,fileBanner,fileBrochure,callback },
});

export const addDatosWebProgramaVersionesAdded = (data) => ({
  type: ADD_DATOSWEBPROGRAMAVERSIONES_ADDED,
  payload: data
});



export const resetPrograma = () => ({
  type: RESET_PROGRAMA,
});


