import { toast } from "react-toastify";
import { actionChannel, all, call, cancel, cancelled, fork, put, take, takeEvery, takeLatest } from "redux-saga/effects";
import { getLista, getListaGeo } from "../../helpers/backend_helper";
import { getListSuccess } from "./actions";
import { GET_LIST } from "./actionTypes";

let lastParam = null;
let lastTask = null;
let tasks = {};

function* fetchList({ payload: { backendList, paramId } }) {
  try {
    let response;
    if (backendList.includes('getRegiones') || backendList.includes('getProvincias') || backendList.includes('getComunas')) {
      response = yield call(getListaGeo, backendList, paramId)
    }
    else {
      response = yield call(getLista, backendList)
    }
    yield put(getListSuccess(backendList, response.data, paramId))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* listsSaga() {
  yield takeEvery(GET_LIST, fetchList)
}

// function* listsSaga() {
//   const channel = yield actionChannel('GET_LIST');

//   while (true) {
//     const action = yield take(channel);

//     if (tasks[action.payload]) {
//       yield cancel(tasks[action.payload]); // cancela la tarea anterior si existe y los parámetros son iguales
//     }

//     tasks[action.payload] = yield fork(fetchList, action); // inicia una nueva tarea y la guarda en el registro de tareas
//   }
// }

// function* listsSaga() {
//   const channel = yield actionChannel('GET_LIST');

//   while (true) {
//     const action = yield take(channel);

//     if (action.payload !== lastParam) {
//       const newTask = yield fork(fetchList, action); // inicia una nueva tarea solo si los parámetros son diferentes

//       if (lastTask) {
//         yield cancel(lastTask); // cancela la última tarea si existe y los parámetros son iguales
//       }

//       lastTask = newTask;
//     }

//     lastParam = action.payload;
//   }
// }

export default listsSaga;