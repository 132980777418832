export const GET_FACTURABYRANGO = "GET_FACTURABYRANGO";
export const GET_FACTURABYRANGO_SUCCESS = "GET_FACTURABYRANGO_SUCCESS";

export const GET_FACTURABYNUMFACTURA = "GET_FACTURABYNUMFACTURA";
export const GET_FACTURABYNUMFACTURA_SUCCESS = "GET_FACTURABYNUMFACTURA_SUCCESS";

export const GET_FACTURAMOVIMIENTOSBYNUMFACTURA = "GET_FACTURAMOVIMIENTOSBYNUMFACTURA";
export const GET_FACTURAMOVIMIENTOSBYNUMFACTURA_SUCCESS = "GET_FACTURAMOVIMIENTOSBYNUMFACTURA_SUCCESS";

export const GET_FACTURACUOTASBYNUMFACTURA = "GET_FACTURACUOTASBYNUMFACTURA";
export const GET_FACTURACUOTASBYNUMFACTURA_SUCCESS = "GET_FACTURACUOTASBYNUMFACTURA_SUCCESS";

export const GET_FACTURAHISTORICOMEDIOPAGOSBYNUMFACTURA = "GET_FACTURAHISTORICOMEDIOPAGOSBYNUMFACTURA";
export const GET_FACTURAHISTORICOMEDIOPAGOSBYNUMFACTURA_SUCCESS = "GET_FACTURAHISTORICOMEDIOPAGOSBYNUMFACTURA_SUCCESS";

export const GET_LISTAESTADOFACTURA = "GET_LISTAESTADOFACTURA";
export const GET_LISTAESTADOFACTURA_SUCCESS = "GET_LISTAESTADOFACTURA_SUCCESS";

export const GET_LISTASUBESTADOFACTURA = "GET_LISTASUBESTADOFACTURA";
export const GET_LISTASUBESTADOFACTURA_SUCCESS = "GET_LISTASUBESTADOFACTURA_SUCCESS";

export const GET_LISTATIPOSEGUIMIENTOMANUAL = "GET_LISTATIPOSEGUIMIENTOMANUAL";
export const GET_LISTATIPOSEGUIMIENTOMANUAL_SUCCESS = "GET_LISTATIPOSEGUIMIENTOMANUAL_SUCCESS";

export const GET_LISTAORGANISMOS= "GET_LISTAORGANISMOS";
export const GET_LISTAORGANISMOS_SUCCESS = "GET_LISTAORGANISMOS_SUCCESS";

export const GET_LISTABANCOS = "GET_LISTABANCOS";
export const GET_LISTABANCOS_SUCCESS = "GET_LISTABANCOS_SUCCESS";

export const GET_LISTACUENTACORRIENTE = "GET_LISTACUENTACORRIENTE";
export const GET_LISTACUENTACORRIENTE_SUCCESS = "GET_LISTACUENTACORRIENTE_SUCCESS";

export const  GET_SEGUIMIENTOSFACTURABYNUMFACTURA ="GET_SEGUIMIENTOSFACTURABYNUMFACTURA"
export const  GET_SEGUIMIENTOSFACTURABYNUMFACTURA_SUCCESS="GET_SEGUIMIENTOSFACTURABYNUMFACTURA_SUCCESS"

export const  GET_MONTOSFACTURASDASHBOARD ="GET_MONTOSFACTURASDASHBOARD"
export const  GET_MONTOSFACTURASDASHBOARD_SUCCESS="GET_MONTOSFACTURASDASHBOARD_SUCCESS"

export const  GET_MONTOSFACTURASPAGADASCONMEDIODEPAGODASHBOARD ="GET_MONTOSFACTURASPAGADASCONMEDIODEPAGODASHBOARD"
export const  GET_MONTOSFACTURASPAGADASCONMEDIODEPAGODASHBOARD_SUCCESS="GET_MONTOSFACTURASPAGADASCONMEDIODEPAGODASHBOARD_SUCCESS"

export const  GET_MONTOSFACTURASMOROSASSINMEDIODEPAGODASHBOARD ="GET_MONTOSFACTURASMOROSASSINMEDIODEPAGODASHBOARD"
export const  GET_MONTOSFACTURASMOROSASSINMEDIODEPAGODASHBOARD_SUCCESS="GET_MONTOSFACTURASMOROSASSINMEDIODEPAGODASHBOARD_SUCCESS"

export const  GET_FACTURASEMITIDASDASHBOARDPORRANGOFECHAS ="GET_FACTURASEMITIDASDASHBOARDPORRANGOFECHAS"
export const  GET_FACTURASEMITIDASDASHBOARDPORRANGOFECHAS_SUCCESS="GET_FACTURASEMITIDASDASHBOARDPORRANGOFECHAS_SUCCESS"

export const  GET_FACTURASEMITIDASPAGADASDASHBOARDPORRANGOFECHAS ="GET_FACTURASEMITIDASPAGADASDASHBOARDPORRANGOFECHAS"
export const  GET_FACTURASEMITIDASPAGADASDASHBOARDPORRANGOFECHAS_SUCCESS="GET_FACTURASEMITIDASPAGADASDASHBOARDPORRANGOFECHAS_SUCCESS"

export const  GET_FACTURASEMITIDASVERSUSCOMPORTAMIENTODEPAGODASHBOARDPORRANGOFECHAS ="GET_FACTURASEMITIDASVERSUSCOMPORTAMIENTODEPAGODASHBOARDPORRANGOFECHAS"
export const  GET_FACTURASEMITIDASVERSUSCOMPORTAMIENTODEPAGODASHBOARDPORRANGOFECHAS_SUCCESS="GET_FACTURASEMITIDASVERSUSCOMPORTAMIENTODEPAGODASHBOARDPORRANGOFECHAS_SUCCESS"

export const  GET_GESTIONCOBRANZAFACTURASMOROSASPORRANGOFECHAS = "GET_GESTIONCOBRANZAFACTURASMOROSASPORRANGOFECHAS"
export const  GET_GESTIONCOBRANZAFACTURASMOROSASPORRANGOFECHAS_SUCCESS = "GET_GESTIONCOBRANZAFACTURASMOROSASPORRANGOFECHAS_SUCCESS"

export const ADD_GESTIONSEGUIMIENTOCOBRANZA = "ADD_GESTIONSEGUIMIENTOCOBRANZA";
export const ADD_GESTIONSEGUIMIENTOCOBRANZA_ADDED = "ADD_GESTIONSEGUIMIENTOCOBRANZA_ADDED";

