export const EDIT_PROFILE = "EDIT_PROFILE"
export const PROFILE_SUCCESS = "PROFILE_SUCCESS"
export const PROFILE_ERROR = "PROFILE_ERROR"
export const RESET_PROFILE_FLAG = "RESET_PROFILE_FLAG";

export const PROFILE_MENU = "PROFILE_MENU"
export const PROFILE_MENU_SUCCESS = "PROFILE_MENU_SUCCESS"
export const PROFILE_DATA = "PROFILE_DATA"
export const PROFILE_USER_INFO = "PROFILE_USER_INFO"
export const PROFILE_USER_INFO_SUCCESS = "PROFILE_USER_INFO_SUCCESS"
export const ADD_NEW_PROFILE_PICTURE = "ADD_NEW_PROFILE_PICTURE"
export const ADD_NEW_PROFILE_PICTURE_SUCCESS = "ADD_NEW_PROFILE_PICTURE_SUCCESS"
export const ADD_NEW_MAILING_PICTURE = "ADD_NEW_MAILING_PICTURE"
export const ADD_NEW_MAILING_PICTURE_SUCCESS = "ADD_NEW_MAILING_PICTURE_SUCCESS"
export const UPDATE_USER_INFO = "UPDATE_USER_INFO"
export const UPDATE_USER_INFO_SUCCESS = "UPDATE_USER_INFO_SUCCESS"

export const RESET_PROFILE = "RESET_PROFILE"

export const GET_NOTIFICACIONES = "GET_NOTIFICACIONES"
export const GET_NOTIFICACIONES_SUCCESS = "GET_NOTIFICACIONES_SUCCESS"
export const UPDATE_NOTIFICACION = "UPDATE_NOTIFICACION"

export const GET_USERS_AVATARS = "GET_USERS_AVATARS"
export const GET_USERS_AVATARS_SUCCESS = "GET_USERS_AVATARS_SUCCESS"

export const DELETE_PROFILE_PICTURE = "DELETE_PROFILE_PICTURE"
export const DELETE_MAILING_PICTURE = "DELETE_MAILING_PICTURE"