import { useSelector } from "react-redux";

const useRoles = () => {
  const roles = useSelector(
    state => state.Profile.menu,
    (prev, next) => JSON.stringify(prev) === JSON.stringify(next)
  )

  return roles;
};

export default useRoles;