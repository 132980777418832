export const POST_VERSION_PROGRAMA = 'POST_VERSION_PROGRAMA';
export const POST_CALENDARIO_PROGRAMA = 'POST_CALENDARIO_PROGRAMA';
export const POST_CALENDARIO_PROGRAMA_SUCCESS = 'POST_CALENDARIO_PROGRAMA_SUCCESS';
export const GET_MODULOS_VERSION_PROGRAMA = 'GET_MODULOS_VERSION_PROGRAMA';
export const GET_MODULOS_VERSION_PROGRAMA_SUCCESS = 'GET_MODULOS_VERSION_PROGRAMA_SUCCESS';
export const GET_VERSION_PROGRAMA = 'GET_VERSION_PROGRAMA';
export const GET_VERSION_PROGRAMA_SUCCESS = 'GET_VERSION_PROGRAMA_SUCCESS';
export const GET_VERSIONES_PROGRAMA = 'GET_VERSIONES_PROGRAMA';
export const GET_VERSIONES_PROGRAMA_SUCCESS = 'GET_VERSIONES_PROGRAMA_SUCCESS';
export const GET_VERSIONES_PROGRAMA_FULL_SUCCESS = 'GET_VERSIONES_PROGRAMA_FULL_SUCCESS';
export const GET_COUNT_VERSIONES_PROGRAMAS = 'GET_COUNTS_PROGRAMAS';
export const GET_COUNT_VERSIONES_PROGRAMAS_SUCCESS = 'GET_COUNTS_PROGRAMAS_SUCCESS';
export const UPDATE_VERSION_PROGRAMA = 'UPDATE_VERSION_PROGRAMA';
export const UPDATE_PONDERACION_VERSION_PROGRAMA = 'UPDATE_PONDERACION_VERSION_PROGRAMA';
export const UPDATE_PONDERACION_MODULO_VERSION = 'UPDATE_PONDERACION_MODULO_VERSION';
export const UPDATE_EVALUABLE_MODULO_VERSION = 'UPDATE_EVALUABLE_MODULO_VERSION';
export const UPDATE_TIPO_NOTA_VERSION_PROGRAMA = "UPDATE_TIPO_NOTA_VERSION_PROGRAMA"
export const UPDATE_ESTADO_CALENDARIO_PROGRAMA = 'UPDATE_ESTADO_CALENDARIO_PROGRAMA';
export const GET_VERSIONES_PARRILLA = 'GET_VERSIONES_PARRILLA';
export const GET_VERSIONES_PARRILLA_SUCCESS = 'GET_VERSIONES_PARRILLA_SUCCESS';
export const GET_VERSIONES_PARRILLA_DEE = 'GET_VERSIONES_PARRILLA_DEE';
export const GET_VERSIONES_PARRILLA_DEE_SUCCESS = 'GET_VERSIONES_PARRILLA_DEE_SUCCESS';
export const GET_ESTADOS_VERSION = 'GET_ESTADOS_VERSION';
export const GET_ESTADOS_VERSION_SUCCESS = 'GET_ESTADOS_VERSION_SUCCESS';
export const GET_ESTUDIANTES_VERSION_PROGRAMA = 'GET_ESTUDIANTES_VERSION_PROGRAMA';
export const GET_ESTUDIANTES_VERSION_PROGRAMA_SUCCESS = 'GET_ESTUDIANTES_VERSION_PROGRAMA_SUCCESS';
export const GET_LIBRO_CLASES_PROGRAMA = 'GET_LIBRO_CLASES_PROGRAMA';
export const GET_LIBRO_CLASES_PROGRAMA_SUCCESS = 'GET_LIBRO_CLASES_PROGRAMA_SUCCESS';
export const GET_SESIONES_VERSION_PROGRAMA = 'GET_SESIONES_VERSION_PROGRAMA';
export const GET_SESIONES_VERSION_PROGRAMA_SUCCESS = 'GET_SESIONES_VERSION_PROGRAMA_SUCCESS';
export const GET_CHOQUES_SESIONES_VERSION_PROGRAMA = 'GET_CHOQUES_SESIONES_VERSION_PROGRAMA';
export const GET_CALENDARIO_PDF = 'GET_CALENDARIO_PDF';
export const CAMBIA_ESTADO_VERSION = 'CAMBIA_ESTADO_VERSION';
export const RESET_SESIONES_PROGRAMA = 'RESET_SESIONES_PROGRAMA';
export const REAGENDA_SESION_PROGRAMA = 'REAGENDA_SESION_PROGRAMA';
export const REAGENDA_SESION_PROGRAMA_SUCCESS = 'REAGENDA_SESION_PROGRAMA_SUCCESS';
export const ENROQUE_MODULOS_PROGRAMA = 'ENROQUE_MODULOS_PROGRAMA';
export const ENROQUE_MODULOS_PROGRAMA_SUCCESS = 'ENROQUE_MODULOS_PROGRAMA_SUCCESS';
export const CAMBIAR_FECHA_CALENDARIO_MODULO_PROGRAMA = 'CAMBIAR_FECHA_CALENDARIO_MODULO_PROGRAMA';
export const CAMBIAR_FECHA_CALENDARIO_MODULO_PROGRAMA_SUCCESS = 'CAMBIAR_FECHA_CALENDARIO_MODULO_PROGRAMA_SUCCESS';
export const DESPLAZA_SESIONES_CALENDARIO = 'DESPLAZA_SESIONES_CALENDARIO';
export const DESPLAZA_SESIONES_CALENDARIO_SUCCESS = 'DESPLAZA_SESIONES_CALENDARIO_SUCCESS';
export const ASIGNA_SALA_VERSION_PROGRAMA = 'ASIGNA_SALA_VERSION_PROGRAMA';
export const ASIGNA_SALA_VERSION_PROGRAMA_SUCCESS = 'ASIGNA_SALA_VERSION_PROGRAMA_SUCCESS';
export const GET_VERSION_ESTUDIANTES_REPORTE = 'GET_VERSION_ESTUDIANTES_REPORTE';
export const GET_VERSION_ESTUDIANTES_REPORTE_SUCCESS = 'GET_VERSION_ESTUDIANTES_REPORTE_SUCCESS';
