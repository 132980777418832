import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { toast } from 'react-toastify';
import { isNil } from 'ramda'
import { app } from "../../config";
import { GET_ACADEMICOS, GET_ACADEMICOS_CENTRO_BY_DIRECTOR, 
         GET_ACAD_DIAS_DISPONIBLES, GET_ACAD_DIAS_DISPONIBLES_ALL, GET_ACAD_DIAS_BLOQUEO, 
         ADD_NEW_ACAD_DIAS_DISPONIBLE, ADD_NEW_ACAD_DIAS_BLOQUEO,
         DELETE_ACAD_DIAS_DISPONIBLE, DELETE_ACAD_DIAS_BLOQUEO, UPDATE_ACAD_DIAS_DISPONIBLE, GET_REPORTE_DISPONIBILIDAD_YEAR, 
         GET_REPORTE_BLOQUEOS_YEAR, GET_REPORTE_AMBOS_YEAR, GET_ACADEMICO_BY_USERID, GET_ACADEMICOS_ALL, VALIDA_ACADEMICO, 
         ADD_NEW_ACADEMICO, UPDATE_ACADEMICO, ADD_NEW_ESTUDIO_ACADEMICO, GET_ESTUDIOS_ACADEMICO, ADD_NEW_DOCUMENTO_ACADEMICO, 
         GET_DOCUMENTOS_ACADEMICO, GET_ACADEMICO_USER} from "./actionTypes"
import { getAcademicos, getAcademicosCentroByDirector, 
        getAcadDiasDisponibles, getAcadDiasDisponiblesAll, getAcadDiasBloqueo, 
        addAcadDiasDisponibles, addAcadDiasBloqueo,
        deleteAcadDiasDisponible, deleteAcadDiasBloqueo, updateAcadDiasDisponible, getReporteDisponibilidadYear, 
        getReporteBloqueosYear, getReporteAmbosYear, getAcademicoByUserId, getAcademicosAll, validaAcademico, addNewAcademico, 
        postFile, updateAcademico, addNewEstudioAcademico, getEstudiosAcademico, addNewDocumentoAcademico, getDocumentosAcademico, getAcademicoUser} from "../../helpers/backend_helper"
import { getAcademicosCentroByDirectorSuccess, getAcademicosSuccess, 
         getAcadDiasDisponiblesSuccess, getAcadDiasDisponiblesAllSuccess, getAcadDiasBloqueoSuccess, getReporteDisponibilidadYearSuccess, 
         getReporteBloqueosYearSuccess, getReporteAmbosYearSuccess, getAcademicoByUserIdSuccess, getAcademicosSuccessAll, getEstudiosAcademicoSuccess, 
         getDocumentosAcademicoSuccess, getAcademicoUserSuccess} from "./actions"

function* fetchAcademicos({payload : params}){
  try {
    const response = yield call(getAcademicos, params)
    yield put(getAcademicosSuccess(response))
  } catch (error) {
    // yield put(getEventsFail(error))
  }
}


function* fetchAcademicosAll(){
  try {
    const response = yield call(getAcademicosAll)
    yield put(getAcademicosSuccessAll(response))
  } catch (error) {
    // yield put(getEventsFail(error))
  }
}

function* fetchAcademicoByUserId({payload: id}){
  try {
    const response = yield call(getAcademicoByUserId, {id})
    yield put(getAcademicoByUserIdSuccess(response))
  } catch (error) {
    // yield put(getEventsFail(error))
  }
}

function* fetchAcademicosCentroByDirector(){
  try {
    const response = yield call(getAcademicosCentroByDirector)
    yield put(getAcademicosCentroByDirectorSuccess(response))
  } catch (error) {
    // yield put(getEventsFail(error))
  }
}

function* fetchAcadDiasDisponibles({payload : params}){ 
  try {
    const response = yield call(getAcadDiasDisponibles, params)
    yield put(getAcadDiasDisponiblesSuccess(response))
  } catch (error) {
    //
  }
}

function* fetchAcadDiasDisponiblesAll({payload : params}){ 
  try {
    const response = yield call(getAcadDiasDisponiblesAll, params)
    yield put(getAcadDiasDisponiblesAllSuccess(response))
  } catch (error) {
    //
  }
}

function* fetchAcadDiasBloqueo({payload : params}){
  try {
    const response = yield call(getAcadDiasBloqueo, params)
    yield put(getAcadDiasBloqueoSuccess(response))
  } catch (error) {
    //
  }
}

function* addDiasDisponibles({payload: {dias, params}}){
  try {
      const response = yield call(addAcadDiasDisponibles, dias)
      if(params.All === 1){
        const response1 = yield call(getAcadDiasDisponiblesAll, params)
        yield put(getAcadDiasDisponiblesAllSuccess(response1))
      }
      if(params.All === 0){
        const response1 = yield call(getAcadDiasDisponibles, params)
        yield put(getAcadDiasDisponiblesSuccess(response1))      
      }
      
      toast.success("Disponibilidad creada con éxito", { autoClose: 3000 });
  } catch (error) {
      toast.error(error, {
          position: toast.POSITION.TOP_RIGHT
        });
  }
}

function* addDiasBloqueo({payload: {diasbloqueo, params}}){
  try {
      const response = yield call(addAcadDiasBloqueo, diasbloqueo)
  } catch (error) {
      toast.error(error, {
          position: toast.POSITION.TOP_RIGHT
        });
  }
}

function* deleteAcadDiasDisponibleSaga({payload: params}){
  try {
      const response = yield call(deleteAcadDiasDisponible, params)
  } catch (error) {
      toast.error(error, {
          position: toast.POSITION.TOP_RIGHT
        });
  }
}

function* deleteAcadDiasBloqueoSaga({payload: params}){
  try {
      const response = yield call(deleteAcadDiasBloqueo, params)
  } catch (error) {
      toast.error(error, {
          position: toast.POSITION.TOP_RIGHT
        });
  }
}

function* putAcadDiasDisponible({payload: {data, params}}){
  try {
      const response = yield call(updateAcadDiasDisponible, data)
      if(params.All === 1){
        const response1 = yield call(getAcadDiasDisponiblesAll, params)
        yield put(getAcadDiasDisponiblesAllSuccess(response1))
      }
      if(params.All === 0){
        const response1 = yield call(getAcadDiasDisponibles, params)
        yield put(getAcadDiasDisponiblesSuccess(response1))      
      }
      toast.success("Disponibilidad actualizada con éxito", { autoClose: 3000 });
  } catch (error) {
      toast.error(error, {
          position: toast.POSITION.TOP_RIGHT 
        });
  }
}

function* fetchReporteDisponibilidad({ payload: year }) {
  try {
    const response = yield call(getReporteDisponibilidadYear, {year});
    yield put(getReporteDisponibilidadYearSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchReporteBloqueos({ payload: year }) {
  try {
    const response = yield call(getReporteBloqueosYear, {year});
    yield put(getReporteBloqueosYearSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchReporteAmbos({ payload: year }) {
  try {
    const response = yield call(getReporteAmbosYear, {year});
    yield put(getReporteAmbosYearSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* putValidaAcademico({payload: {data, params}}){
  try {
      const response = yield call(validaAcademico, data)
      const response1 = yield call(getAcademicos, params)
      yield put(getAcademicosSuccess(response1))      
      toast.success("Académico validado con éxito", { autoClose: 3000 });

  } catch (error) {
      toast.error(error, {
          position: toast.POSITION.TOP_RIGHT 
        });
  }
}

function* postAcademico({ payload: { academico, formImg, fetchData } }) {
  try {
    if (!isNil(formImg)) {
      const respImg = yield call(postFile, formImg)
      academico = { ...academico, imgFileId: respImg.data.fileId }
    }
    const response = yield call(addNewAcademico, academico)
    if (fetchData) fetchData(response.data)
    toast.success('Académico creado correctamente', {
      position: toast.POSITION.TOP_RIGHT
    });
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* putAcademico({ payload: { academico, formImg, fetchData } }) {
  try {
    if (!isNil(formImg)) {
      const respImg = yield call(postFile, formImg)
      academico = { ...academico, imgFileId: respImg.data.fileId }
    }
    const response = yield call(updateAcademico, academico)
    if (app.site === 'Externo'){
      const responseOtro = yield call(getAcademicoUser)
      yield put(getAcademicoUserSuccess(responseOtro))    
    }    
    if (fetchData && app.site === 'Intranet') fetchData(response.data)
    toast.success('Académico actualizado correctamente', {
      position: toast.POSITION.TOP_RIGHT
    });
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* fetchEstudiosAcademico({ payload: id }) {
  try {
    const response = yield call(getEstudiosAcademico, { id })
    yield put(getEstudiosAcademicoSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* postEstudioAcademico({ payload: { estudio, fetchData, formData } }) {
  try {
    const responseFile = yield call(postFile, formData);
    estudio.archivoId = responseFile.data.fileId

    const response = yield call(addNewEstudioAcademico, estudio)
    if (fetchData) fetchData()
    toast.success('Estudio creado correctamente', {
      position: toast.POSITION.TOP_RIGHT
    });
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* fetchDocumentosAcademico({ payload: id }) {
  try {
    const response = yield call(getDocumentosAcademico, { id })
    yield put(getDocumentosAcademicoSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* postDocumentoAcademico({ payload: { documento, fetchData, formData } }) {
  try {
    const responseFile = yield call(postFile, formData);
    documento.archivoId = responseFile.data.fileId

    const response = yield call(addNewDocumentoAcademico, documento)
    if (fetchData) fetchData()
    toast.success('Documento creado correctamente', {
      position: toast.POSITION.TOP_RIGHT
    });
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* fetchAcademicoUser(){
  try {
    const response = yield call(getAcademicoUser)
    yield put(getAcademicoUserSuccess(response))
  } catch (error) {
    // yield put(getEventsFail(error))
  }
}

function* AcademicosSaga() {
  yield takeEvery(GET_ACADEMICOS, fetchAcademicos)
  yield takeLatest(GET_ACADEMICOS_ALL, fetchAcademicosAll)
  yield takeEvery(GET_ACADEMICO_BY_USERID, fetchAcademicoByUserId)
  yield takeEvery(GET_ACADEMICOS_CENTRO_BY_DIRECTOR, fetchAcademicosCentroByDirector)
  yield takeEvery(GET_ACAD_DIAS_DISPONIBLES, fetchAcadDiasDisponibles)
  yield takeEvery(GET_ACAD_DIAS_DISPONIBLES_ALL, fetchAcadDiasDisponiblesAll)
  yield takeEvery(GET_ACAD_DIAS_BLOQUEO, fetchAcadDiasBloqueo)
  yield takeLatest(ADD_NEW_ACAD_DIAS_DISPONIBLE, addDiasDisponibles)
  yield takeLatest(ADD_NEW_ACAD_DIAS_BLOQUEO, addDiasBloqueo)
  yield takeLatest(DELETE_ACAD_DIAS_DISPONIBLE, deleteAcadDiasDisponibleSaga)
  yield takeLatest(DELETE_ACAD_DIAS_BLOQUEO, deleteAcadDiasBloqueoSaga)
  yield takeLatest(UPDATE_ACAD_DIAS_DISPONIBLE, putAcadDiasDisponible)
  yield takeLatest(GET_REPORTE_DISPONIBILIDAD_YEAR, fetchReporteDisponibilidad);  
  yield takeLatest(GET_REPORTE_BLOQUEOS_YEAR, fetchReporteBloqueos);  
  yield takeLatest(GET_REPORTE_AMBOS_YEAR, fetchReporteAmbos);  
  yield takeLatest(VALIDA_ACADEMICO, putValidaAcademico)
  yield takeLatest(ADD_NEW_ACADEMICO, postAcademico)
  yield takeLatest(UPDATE_ACADEMICO, putAcademico)
  yield takeLatest(ADD_NEW_ESTUDIO_ACADEMICO, postEstudioAcademico)
  yield takeLatest(GET_ESTUDIOS_ACADEMICO, fetchEstudiosAcademico)
  yield takeLatest(ADD_NEW_DOCUMENTO_ACADEMICO, postDocumentoAcademico)
  yield takeLatest(GET_DOCUMENTOS_ACADEMICO, fetchDocumentosAcademico)  
  yield takeEvery(GET_ACADEMICO_USER, fetchAcademicoUser)
  
}

export default AcademicosSaga 