import React, {lazy} from "react";
import { Redirect } from "react-router-dom";

//Home
const Home = lazy(() => import("../pages/Home"))

//login
const Login = lazy(() => import("../pages/Authentication/Login"))
const RecuperarPassword = lazy(() => import("../pages/Authentication/RecuperarPassword"))
const CambiarPassword = lazy(() => import("../pages/Authentication/CambiarPassword"))
const Logout = lazy(() => import("../pages/Authentication/Logout"))
const Register = lazy(() => import("../pages/Authentication/Register"))

// User Profile
const UserProfile = lazy(() => import("../pages/Authentication/user-profile"))

//Administración
const UserManagement = lazy(() => import("../pages/UserManagement"))
const Notificaciones = lazy(() => import("../pages/Notificaciones"))
const DashboardEcommerce = lazy(() => import("../pages/DashboardEcommerce"))

const LineCharts = lazy(() => import("../pages/ListaGraficos/LineCharts"));
const AreaCharts = lazy(() => import("../pages/ListaGraficos/AreaCharts"));
const ColumnCharts = lazy(() => import("../pages/ListaGraficos/ColumnCharts"));
const BarCharts = lazy(() => import("../pages/ListaGraficos/BarCharts"));
const MixedCharts = lazy(() => import("../pages/ListaGraficos/MixedCharts"));
const TimelineCharts = lazy(() => import("../pages/ListaGraficos/TimelineCharts"));
const CandlestickChart = lazy(() => import("../pages/ListaGraficos/CandlestickChart"));
const BoxplotCharts = lazy(() => import("../pages/ListaGraficos/BoxplotCharts"));
const BubbleChart = lazy(() => import("../pages/ListaGraficos/BubbleChart"));
const ScatterCharts = lazy(() => import("../pages/ListaGraficos/ScatterCharts"));
const HeatmapCharts = lazy(() => import("../pages/ListaGraficos/HeatmapCharts"));
const TreemapCharts = lazy(() => import("../pages/ListaGraficos/TreemapCharts"));
const PieCharts = lazy(() => import("../pages/ListaGraficos/PieCharts"));
const RadialbarCharts = lazy(() => import("../pages/ListaGraficos/RadialbarCharts"));
const RadarCharts = lazy(() => import("../pages/ListaGraficos/RadarCharts"));
const PolarCharts = lazy(() => import("../pages/ListaGraficos/PolarCharts"));

//Admisión
const ProspectosManagement = lazy(() => import("../pages/Admision/ProspectosManagement"))
const Ejecutivos = lazy(() => import("../pages/Admision/Ejecutivos"))
const GruposEjecutivos = lazy(() => import("../pages/Admision/Ejecutivos/GruposEjecutivos"))
const MatriculaCorporativos = lazy(() => import("../pages/Matricula/MatriculaCorporativo"))
const MatriculaParticular = lazy(() => import("../pages/Matricula/MatriculaParticular"))
const GestionMatriculas = lazy(() => import("../pages/Matricula/GestionMatriculas"))

//Gestión Académica
const Programas = lazy(() => import("../pages/Programas"))
const GestionDecretos = lazy(() => import("../pages/GestionDecretos"))
//const DetallePrograma = lazy(() => import("../pages/Programas/DetallePrograma"))
//const DetalleVersion = lazy(() => import("../pages/Programas/DetalleVersion2"))
//const Convenios = lazy(() => import("../pages/Convenios"))
const Academicos = lazy(() => import("../pages/Academicos"))
const ListadoSesionesAcademicas = lazy(() => import("../pages/Academicos/Sesiones/ListadoSesionesAcademicas"))
const Encuesta = lazy(() => import("../pages/Encuesta"))
const EncuestaAlumno = lazy(() => import("../pages/Encuesta/EncuestaAlumno"))
const EncuestaManagement = lazy(() => import("../pages/EncuestaManagement"))
const DisponibilidadAcademica = lazy(() => import("../pages/DisponibilidadAcademica"))
//const Parrilla = lazy(() => import("../pages/Programas/Parrilla"))
const CargaCoordinadores = lazy(() => import("../pages/CoordinacionAcademica/CargaCoordinadores"))
//const Asignacion = lazy(() => import("../pages/Programas/Asignacion-coordinador"))
//const AsignacionModulos = lazy(() => import("../pages/Programas/Asignacion-modulos"))
const Modulos = lazy(() => import("../pages/Modulos"))
const VersionesProgramas = lazy(() => import("../pages/VersionesPrograma"))
const VersionesVistaPrograma = lazy(() => import("../pages/VersionesPrograma/VistaPrograma"))
const Parrilla = lazy(() => import("../pages/VersionesPrograma/Parrilla"))
const EvaluacionesModulos = lazy(() => import("../pages/VersionesModulos"))
const DefinirEvaluaciones = lazy(() => import("../pages/VersionesModulos/DefinirEvaluaciones"))
const IngresoNotas = lazy(() => import("../pages/VersionesModulos/IngresoNotas"))
const IngresoAsistencia = lazy(() => import("../pages/VersionesModulos/IngresoAsistencia"))
const LibroClases = lazy(() => import("../pages/VersionesPrograma/LibroClases"))
const Calendario = lazy(() => import("../pages/VersionesPrograma/Calendario"))
const EstudiantesVersion = lazy(() => import("../pages/VersionesPrograma/EstudiantesVersion"))

//Tareas
const Tareas = lazy(() => import("../pages/Tareas"))

//Gestion Estudiantes
const Estudiantes = lazy(() => import("../pages/Estudiantes"))
const HistorialEstudiante = lazy(() => import("../pages/Estudiantes/HistorialEstudiante"))
const DetalleEstudiante = lazy(() => import("../pages/Estudiantes/DetalleEstudiante"))
const EditarEstudiante = lazy(() => import("../pages/Estudiantes/EditarEstudiante"))


// Operaciones
const Fungibles = lazy(() => import("../pages/Operaciones/Fungibles"))
const Inventario = lazy(() => import("../pages/Operaciones/Inventario"))

const Salas = lazy(() => import("../pages/Operaciones/Salas"))
const Fechas = lazy(() => import("../pages/Fechas"))

// Finanzas
const Facturacion = lazy(() => import("../pages/Finanzas/Facturacion"))
const DetalleFacturacion = lazy(() => import("../pages/Finanzas/DetalleFacturacion"))
const NotasVenta = lazy(() => import("../pages/Finanzas/NotasVenta"))
const NotasVentaTipo = lazy(() => import("../pages/Finanzas/NotasVentaTipo"))
const NotasVentaTipoDetalle = lazy(() => import("../pages/Finanzas/NotasVentaTipoDetalle"))
const Comparativo = lazy(() => import("../pages/Finanzas/Comparativo"))


//Cobranza
//-------------------------------------------------------------------------------------------------------
const ListadoFacturas = lazy(() => import("../pages/Cobranza/ListadoFacturas"))
const FacturacionCobranzaDashboard = lazy(() => import("../pages/Cobranza/FacturacionCobranzaDashboard"))
const GestionCobranzaDashboard = lazy(() => import("../pages/Cobranza/GestionCobranzaDashboard"))
const ComportamientoPagoDashboard = lazy(() => import("../pages/Cobranza/ComportamientoPagoDashboard"))
//-------------------------------------------------------------------------------------------------------

// Control de Gestion
const AsientosContables = lazy(() => import("../pages/ControlGestion/AsientosContables"))

// Centros de Investigación
const PresupuestoEvento = lazy(() => import("../pages/CentroInvestigacion/PresupuestoEvento"))
const GestionEvento = lazy(() => import("../pages/CentroInvestigacion/GestionEvento"))

// Academicos
const SolicitudesFondo = lazy(() => import("../pages/Academicos/SolicitudesFondo"))
const Premios = lazy(() => import("../pages/Academicos/Premios"))
const ModulosAcademico = lazy(() => import("../pages/Academicos/Modulos"))
const ModuloSesiones = lazy(() => import("../pages/Academicos/Sesiones/CalendarioSesiones"))

// Academico Externo
const PortalAcademico = lazy(() => import("../pages/Academicos/Portal"))



//Mantenedores
//const Mantenedor = lazy(() => import("../pages/Mantenedores"))
const DolarFen = lazy(() => import("../pages/Mantenedores/DolarFen"))
const MantenedorFondosInvestigacion = lazy(() => import("../pages/Mantenedores/FondosInvestigacion/"))
const RankingJcr = lazy(() => import("../pages/Mantenedores/RankingJcr"))
const MantenedorEmpresa = lazy(() => import("../pages/Mantenedores/Empresas/"))
//const CecoFen = lazy(() => import("../pages/Mantenedores/CecosSolicitudes"))

//Estudiantes
const MiPortalEstudiante = lazy(() => import("../pages/Estudiantes/MiPortal"))
const FichaExterna = lazy(() => import("../pages/Estudiantes/FichaEstudiante/FichaExterna"))

//Pagos
const Pagos = lazy(() => import("../pages/Pagos"))

//ValidadorDocumentos
const ValidadorDocumentos = lazy(() => import("../pages/ValidadorDocumentos"))

const authProtectedRoutes = [
  
  { path: "/home", component: Home },
  
  //User Profile
  { path: "/profile", component: UserProfile },
  
  //Administración
  { path: "/usuarios", component: UserManagement, rolesApp: ['Admin','Usuarios','UsuariosSoloUsers'] },
  { path: "/notificaciones", component: Notificaciones, rolesApp: ['Admin'] },
  { path: "/dashboard", component: DashboardEcommerce, rolesApp: ['Admin'] },

  //Ejemplos
  { path: "/charts-apex-line", component: LineCharts, rolesApp: ['AllUsers'] },
  { path: "/charts-apex-area", component: AreaCharts, rolesApp: ['AllUsers'] },
  { path: "/charts-apex-column", component: ColumnCharts, rolesApp: ['AllUsers'] },
  { path: "/charts-apex-bar", component: BarCharts, rolesApp: ['AllUsers'] },
  { path: "/charts-apex-mixed", component: MixedCharts, rolesApp: ['AllUsers'] },
  { path: "/charts-apex-timeline", component: TimelineCharts, rolesApp: ['AllUsers'] },
  { path: "/charts-apex-candlestick", component: CandlestickChart, rolesApp: ['AllUsers'] },
  { path: "/charts-apex-boxplot", component: BoxplotCharts, rolesApp: ['AllUsers'] },
  { path: "/charts-apex-bubble", component: BubbleChart, rolesApp: ['AllUsers'] },
  { path: "/charts-apex-scatter", component: ScatterCharts, rolesApp: ['AllUsers'] },
  { path: "/charts-apex-heatmap", component: HeatmapCharts, rolesApp: ['AllUsers'] },
  { path: "/charts-apex-treemap", component: TreemapCharts, rolesApp: ['AllUsers'] },
  { path: "/charts-apex-pie", component: PieCharts, rolesApp: ['AllUsers'] },
  { path: "/charts-apex-radialbar", component: RadialbarCharts, rolesApp: ['AllUsers'] },
  { path: "/charts-apex-radar", component: RadarCharts, rolesApp: ['AllUsers'] },
  { path: "/charts-apex-polar", component: PolarCharts, rolesApp: ['AllUsers'] },
  
  //Admision
  { path: "/prospectos", component: ProspectosManagement, rolesApp: ['Admin','SubDirectorAdmision', 'EjecutivoAdmision'] },
  { path: "/ejecutivos", component: Ejecutivos, rolesApp: ['Admin','SubDirectorAdmision'] },
  { path: "/grupos-ejecutivos", component: GruposEjecutivos, rolesApp: ['Admin','SubDirectorAdmision'] },
  { path: "/matricula-corporativos", component: MatriculaCorporativos, rolesApp: ['Admin'] },
  { path: "/matricula-particular", component: MatriculaParticular, rolesApp: ['Admin'] },
  { path: "/gestion-matriculas", component: GestionMatriculas, rolesApp: ['Admin','SubDirectorAdmision','FinanzasGestionMatriculas','EjecutivoAdmision'] },
  
  // Gestión Académica
  { path: "/programas", component: Programas, rolesApp: ['Admin','DirectorEducacionEjecutiva'] },
  { path: "/gestion-decretos", component: GestionDecretos, rolesApp: ['Admin','DirectorEducacionEjecutiva'] },
  //{ path: "/detalle-programa", component: DetallePrograma, rolesApp: ['Admin'] },
  //{ path: "/detalle-version", component: DetalleVersion, rolesApp: ['Admin'] },
  //{ path: "/parrilla", component: Parrilla, rolesApp: ['Admin','DirectorEducacionEjecutiva','SubDirectorAdmision'] },
  { path: "/academicos", component: Academicos, rolesApp: ['Admin','DirectorEducacionEjecutiva'] },
  { path: "/listadosesionesacad", component: ListadoSesionesAcademicas, rolesApp: ['Admin','JefeCoordinacionAcademica','CoordinacionAcademica','CoordinadorAdministrativo'] },

//  { path: "/convenios", component: Convenios, rolesApp: ['Admin'] },
  { path: "/gestion-encuestas", component: EncuestaManagement, rolesApp: ['Admin'] },
  { path: "/disponibilidad-academica", component: DisponibilidadAcademica, rolesApp: ['Admin',"Operaciones","Academico","JefeCoordinacionAcademica","CoordinacionAcademica"] },
  { path: "/carga-coordinadores", component: CargaCoordinadores, rolesApp: ['Admin','JefeCoordinacionAcademica'] },
  //{ path: "/asignacion", component: Asignacion, rolesApp: ['Admin','JefeCoordinacionAcademica'] },
  //{ path: "/asignacion-modulos", component: AsignacionModulos, rolesApp: ['Admin'] },  
  { path: "/modulos", component: Modulos, rolesApp: ['Admin','DirectorEducacionEjecutiva'] },
  { path: "/versiones/:year?/:tab?", component: VersionesProgramas, rolesApp: ['Admin','DirectorEducacionEjecutiva','JefeCoordinacionAcademica','CoordinacionAcademica','CoordinadorAdministrativo','VistaCalendarioVersionNoEditable','VerEditarDatosWeb'] },
  { path: "/versiones-programa/:programaId", component: VersionesVistaPrograma, rolesApp: ['Admin'] },
  { path: "/parrilla", component: Parrilla, rolesApp: ['Admin','DirectorEducacionEjecutiva','SubDirectorAdmision'] },
  { path: "/evaluaciones/:versionId?", component: EvaluacionesModulos, rolesApp: ['Admin','DirectorEducacionEjecutiva','JefeCoordinacionAcademica','CoordinacionAcademica','Academico'] },
  { path: "/definir-evaluaciones/:versionId/:moduloVersionId", component: DefinirEvaluaciones, rolesApp: ['Admin','Academico','DirectorEducacionEjecutiva','JefeCoordinacionAcademica','CoordinacionAcademica'] },
  { path: "/ingreso-notas/:versionId/:moduloVersionId/:evaluacionId?", component: IngresoNotas, rolesApp: ['Admin','Academico','DirectorEducacionEjecutiva','JefeCoordinacionAcademica','CoordinacionAcademica'] },
  { path: "/ingreso-asistencia/:versionId/:moduloVersionId", component: IngresoAsistencia, rolesApp: ['Admin','Academico','DirectorEducacionEjecutiva','JefeCoordinacionAcademica','CoordinacionAcademica'] },
  { path: "/libro-clases/:versionId", component: LibroClases, rolesApp: ['Admin','DirectorEducacionEjecutiva','JefeCoordinacionAcademica','CoordinacionAcademica'] },
  { path: "/calendario/:versionId", component: Calendario, rolesApp: ['Admin','DirectorEducacionEjecutiva','JefeCoordinacionAcademica','CoordinacionAcademica','VistaCalendarioVersionNoEditable'] },
  { path: "/estudiantes-version/:versionId", component: EstudiantesVersion, rolesApp: ['Admin','DirectorEducacionEjecutiva','JefeCoordinacionAcademica','CoordinacionAcademica'] },

  //Tareas
  { path: "/tareas", component: Tareas },
  
  //Gestion Estudiante
  { path: "/estudiantes", component: Estudiantes, rolesApp: ['Admin'] },  
  { path: "/detalle-estudiante", component: DetalleEstudiante, rolesApp: ['Admin'] },  
  { path: "/historial-estudiante", component: HistorialEstudiante, rolesApp: ['Admin'] },  
  { path: "/editar-estudiante", component: EditarEstudiante, rolesApp: ['Admin'] },
  
  //Operaciones
  { path: "/fungibles", component: Fungibles, rolesApp: ['Admin', "Operaciones"]},  
  { path: "/inventario", component: Inventario, rolesApp: ['Admin',"Operaciones"] },  
  { path: "/salas", component: Salas, rolesApp: ['Admin',"Operaciones"] },  
  { path: "/fechas", component: Fechas, rolesApp: ['Admin',"Operaciones","RelacionesPublicas"] },


  //Finanzas
  { path: "/facturacion", component: Facturacion, rolesApp: ['Admin',"KPIFinanzas"]},  
  { path: "/detalle-facturacion", component: DetalleFacturacion, rolesApp: ['Admin',"KPIFinanzas"]},  
  { path: "/notas-venta", component: NotasVenta, rolesApp: ['Admin',"KPIFinanzas"]},  
  { path: "/notas-venta-tipo", component: NotasVentaTipo, rolesApp: ['Admin',"KPIFinanzas"]},  
  { path: "/notas-venta-tipo-detalle", component: NotasVentaTipoDetalle, rolesApp: ['Admin',"KPIFinanzas"]},  
  { path: "/comparativo", component: Comparativo, rolesApp: ['Admin',"KPIFinanzas"]},


 //Cobranza
 //-----------------------------------------------------------------------------------------------------------------//

 {path: "/listadofacturas", component: ListadoFacturas, rolesApp: ['Admin', "Cobranza"]},
 {path: "/facturacionCobranzaDashboard", component: FacturacionCobranzaDashboard, rolesApp: ['Admin', "Cobranza"]},
 {path: "/comportamientoPagoDashboard", component: ComportamientoPagoDashboard, rolesApp: ['Admin', "Cobranza"]},
 {path: "/gestionCobranzaDashboard", component: GestionCobranzaDashboard, rolesApp: ['Admin', "Cobranza"]},

 //-----------------------------------------------------------------------------------------------------------------//       

  //Control de Gestión
  { path: "/asientos-contables", component: AsientosContables, rolesApp: ['Admin','ControlGestion'] },

  //CentroInvestigacion
  { path: "/planificacion-eventos/:eventoId?", component: PresupuestoEvento, rolesApp: ["Admin", "Director", "DirectorCentro","CoordinadorAdministrativo","AsistenteDireccion","CoordinadorMarketing","DirectorEducacionEjecutiva","SubDirectorMarketing","RelacionesPublicas"]},
  { path: "/gestion-eventos", component: GestionEvento, rolesApp: ['Admin',"RelacionesPublicas","Periodista"]},


  // Academicos
  { path: "/solicitudes-fondo", component: SolicitudesFondo, rolesApp: ["Admin","Academico","DirectorInvestigacionFEN","DirectorInvestigacion","AnalistaFEN","CoordinadorAdministrativoFEN","DirectorDepartamentoFEN","AcademicoFEN","AyudanteFEN"]},
  //{ path: "/solicitud-viaje", component: SolicitudViaje, rolesApp: ["Admin"]},
  { path: "/premios-academicos", component: Premios, rolesApp: ["Admin","DirectorInvestigacionFEN","DirectorInvestigacion","AnalistaFEN","CoordinadorAdministrativoFEN","DirectorDepartamentoFEN","AcademicoFEN","Director","AyudanteFEN"]},
  { path: "/acad-modulos", component: ModulosAcademico, rolesApp: ["Admin","Academico"]},
  { path: "/acad-sesiones", component: ModuloSesiones, rolesApp: ["Admin","Academico","DirectorEducacionEjecutiva"]},


  //Mantenedores
  { path: "/dolar-fen", component: DolarFen, rolesApp: ['Admin','AnalistaFEN']},
  { path: "/mant-fondos-investigacion", component: MantenedorFondosInvestigacion, rolesApp: ["Admin","DirectorInvestigacionFEN","AnalistaFEN","DirectorDepartamentoFEN"]},
  { path: "/ranking-jcr", component: RankingJcr, rolesApp: ['Admin','AnalistaFEN']},
  { path: "/mantenedor-empresa", component: MantenedorEmpresa, rolesApp: ['Admin']},
  //{ path: "/ceco-fen", component: CecoFen, rolesApp: ['Admin','AnalistaFEN']},

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/home" />,
  },
];


// ** RUTAS DE PORTAL EXTERNO: ACADEMICOS Y ESTUDIANTES
const authProtectedExternoRoutes = [
  
  { path: "/home", component: Home },

  //Portal Estudiante
  { path: "/mi-portal", component: MiPortalEstudiante, rolesApp: ["Admin","Estudiante"] },
  
  
  //Portal Academico
  { path: "/acad-portal", component: PortalAcademico, rolesApp: ["Admin","Academico"]},
  { path: "/acad-sesiones", component: ModuloSesiones, rolesApp: ["Admin","Academico","DirectorEducacionEjecutiva"]},


  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/home" />,
  },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: Logout },
  { path: "/login/:sitio?", component: Login },
  { path: "/recuperar-password/:sitio?", component: RecuperarPassword },
  { path: "/cambiar-password/:sitio?", component: CambiarPassword },
  { path: "/register", component: Register },

  //Encuestas
  //{ path: "/encuesta/:encuestaId/:alumnoId", component: Encuesta },
  { path: "/encuesta/", component: Encuesta },
  { path: "/encuesta-alumno/:encuestaId?", component: EncuestaAlumno },

  //Estudiantes
  { path: "/ficha-postulacion/:fichaId?", component: FichaExterna },

  //Pagos
  { path: "/pago/:pagoId/:res?", component: Pagos },

  //ValidadorDocumentos
  { path: "/validador-documentos/:docId", component: ValidadorDocumentos},
];

export { authProtectedRoutes, authProtectedExternoRoutes, publicRoutes }; 
