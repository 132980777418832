import {
  GET_CATEGORIAS_SUCCESS,
  GET_EVENTOS_ANIO_SUCCESS,
  GET_EVENTOS_CALENDARIO_SUCCESS,
  GET_EVENTO_ACTIVIDADES_SUCCESS,
  GET_EVENTO_ANIO_ALL_SUCCESS,
  GET_EVENTO_ESTADOS_ITEMS_SUCCESS,
  GET_EVENTO_ESTADOS_SUCCESS,
  GET_EVENTO_MODALIDADES_SUCCESS,
  GET_EVENTO_PROXIMOS_SUCCESS,
  GET_EVENTO_REALIZADOS_SUCCESS,
  GET_EVENTO_SUCCESS,
  GET_ITEMS_EVENTO_SUCCESS,
  GET_REPORTE_EVENTOS_ANIO_SUCCESS,
  GET_SALDO_PPTO_CENTRO_DIRECTOR_SUCCESS,
  GET_SALDO_PPTO_CENTRO_SUCCESS,
  GET_TIPO_EVENTOS_SUCCESS,
  POST_EVENTO,
  POST_EVENTO_FAIL,
  POST_EVENTO_SUCCESS,
  RESET_EVENTO,
  RESET_REPORTE_EVENTO,
} from "./actionTypes";

const INIT_STATE = {
  categorias: [],
  tiposEvento: [],
  itemsEvento: [],
  evento: {},
  eventos: [],
  creado: false,
  loadingCreando: false,
  paginacion: {},
  modalidades: [],
  estados: [],
  estadoItems: [],
  actividades: [],
  paginacionActividades: {},
  saldoPptoCentro: null,
  archivoExcel: null,
  eventosCalendario: null
};

const Eventos = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CATEGORIAS_SUCCESS:
      return {
        ...state,
        categorias: action.payload.data,
      };
    case GET_TIPO_EVENTOS_SUCCESS:
      return {
        ...state,
        tiposEvento: action.payload.data,
      };
    case GET_ITEMS_EVENTO_SUCCESS:
      return {
        ...state,
        itemsEvento: action.payload.data,
      };
    case GET_EVENTOS_ANIO_SUCCESS:
      return {
        ...state,
        eventos: action.payload.data,
        paginacion: JSON.parse(action.payload.headers["x-pagination"]),
      };
    case GET_EVENTO_ANIO_ALL_SUCCESS:
      return {
        ...state,
        eventos: action.payload.data,
        paginacion: JSON.parse(action.payload.headers["x-pagination"]),
      };
    case GET_EVENTO_PROXIMOS_SUCCESS:
      return {
        ...state,
        eventos: action.payload.data,
        paginacion: JSON.parse(action.payload.headers["x-pagination"]),
      };
    case GET_EVENTO_REALIZADOS_SUCCESS:
      return {
        ...state,
        eventos: action.payload.data,
        paginacion: JSON.parse(action.payload.headers["x-pagination"]),
      };
    case GET_EVENTO_ACTIVIDADES_SUCCESS:
      return {
        ...state,
        actividades: action.payload.data,
        paginacionActividades: JSON.parse(
          action.payload.headers["x-pagination"]
        ),
      };
    case GET_EVENTO_SUCCESS:
      return {
        ...state,
        evento: action.payload.data,
      };
    case GET_EVENTO_MODALIDADES_SUCCESS:
      return {
        ...state,
        modalidades: action.payload.data,
      };
    case GET_EVENTO_ESTADOS_SUCCESS:
      return {
        ...state,
        estados: action.payload.data,
      };
    case GET_EVENTO_ESTADOS_ITEMS_SUCCESS:
      return {
        ...state,
        estadosItems: action.payload.data,
      };
    case GET_SALDO_PPTO_CENTRO_DIRECTOR_SUCCESS:
      return {
        ...state,
        saldoPptoCentro: action.payload.data,
      };
    case GET_SALDO_PPTO_CENTRO_SUCCESS:
      return {
        ...state,
        saldoPptoCentro: action.payload.data,
      };
    case GET_REPORTE_EVENTOS_ANIO_SUCCESS:
      return {
        ...state,
        archivoExcel: action.payload.data
      }
    case POST_EVENTO:
      return {
        ...state,
        creado: false,
        loadingCreando: true
      };
    case POST_EVENTO_SUCCESS:
      return {
        ...state,
        creado: true,
        loadingCreando: false
      };
    case POST_EVENTO_FAIL:
      return {
        ...state,
        creado: false,
        loadingCreando: false
      };
    case GET_EVENTOS_CALENDARIO_SUCCESS:
      return {
        ...state,
        eventosCalendario: action.payload.data,
      };
    case RESET_EVENTO:
      return {
        ...state,
        categorias: [],
        tiposEvento: [],
        itemsEvento: [],
        evento: {},
        creado: false,
        saldoPptoCentro: null
      };
    case RESET_REPORTE_EVENTO:
      return {
        ...state,
        archivoExcel: null
      };
    default:
      return state;
  }
};

export default Eventos;
