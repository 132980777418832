import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  PROFILE_MENU_SUCCESS,
  RESET_PROFILE,
  PROFILE_DATA,
  GET_NOTIFICACIONES_SUCCESS,
  GET_USERS_AVATARS_SUCCESS,
  PROFILE_USER_INFO_SUCCESS,
  ADD_NEW_PROFILE_PICTURE_SUCCESS,
  ADD_NEW_MAILING_PICTURE_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  user: {},
  menu: [],
  usuario: {},
  notificaciones: [],
  usersAvatars: [],
  profileData: {},
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
      return { ...state };
    case PROFILE_SUCCESS:
      return {
        ...state,
        success: action.payload.status,
        user: action.payload.data,
      };
    case PROFILE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case RESET_PROFILE_FLAG:
      return {
        ...state,
        success: null,
      };
    case PROFILE_MENU_SUCCESS:
      return {
        ...state,
        menu: action.payload,
      };
    case PROFILE_DATA:
      return {
        ...state,
        usuario: action.payload,
      };
    case PROFILE_USER_INFO_SUCCESS:
      return {
        ...state,
        profileData: action.payload,
      };
    case GET_NOTIFICACIONES_SUCCESS:
      return {
        ...state,
        notificaciones: action.payload,
      };
    case GET_USERS_AVATARS_SUCCESS:
      return {
        ...state,
        // agrega nuevos usuarios a la lista de avatares
        usersAvatars: [...state.usersAvatars, ...action.payload],
      };
    case ADD_NEW_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        usuario: { ...state.usuario, avatar: action.payload },
      };
    case ADD_NEW_MAILING_PICTURE_SUCCESS:
      return {
        ...state,
        usuario: { ...state.usuario, mailingImg: action.payload },
      };
    case RESET_PROFILE:
      return initialState;
    default:
      return state;
  }
};

export default profile;
