import { takeEvery, call, put, takeLatest, all, fork} from "redux-saga/effects"
import { toast } from 'react-toastify';

import { GET_SESIONES_ALUMNOS,GET_SESIONES_ACADEMICOS,GET_LISTASESIONESACADEMICOSPORRANGOFECHAS, GET_DIAS_SESIONES, DELETE_SESIONES } from './actionTypes'
import { getSesionesAlumnos, getSesionesAcademicos,getListaSesionesAcademicosPorRangoFechas, getDiasSesiones, deleteSesiones } from "../../helpers/backend_helper";
import { getSesionesAlumnosSuccess,getSesionesAcademicosSuccess,getListaSesionesAcademicosPorRangoFechasSuccess, getDiasSesionesSuccess } from "./actions";


function* fetchSesionesAlumnos({ payload: params })
{
    try 
    {
        const response = yield call(getSesionesAlumnos,params)
        yield put(getSesionesAlumnosSuccess(response))

    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}



function* fetchSesionesAcademicos({ payload: params })
{
    try 
    {
        const response = yield call(getSesionesAcademicos,params)
        yield put(getSesionesAcademicosSuccess(response))

    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}



function* fetchListaSesionesAcademicosPorRangoFechas({ payload: params })
{
    try 
    {
        const response = yield call(getListaSesionesAcademicosPorRangoFechas,params)
        yield put(getListaSesionesAcademicosPorRangoFechasSuccess(response))

    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}


function* fetchDiasSesiones({ payload: params })
{
    try 
    {   
        const response = yield call(getDiasSesiones,params)
        yield put(getDiasSesionesSuccess(response))

    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchDeleteSesiones({payload: params}){
    try {
        const response = yield call(deleteSesiones, params)
        toast.success("Calendario eliminado con éxito", { autoClose: 3000 });
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* sesionesSaga() {

    yield takeLatest(GET_SESIONES_ALUMNOS,fetchSesionesAlumnos)   
    yield takeLatest(GET_SESIONES_ACADEMICOS,fetchSesionesAcademicos)   
    yield takeLatest(GET_LISTASESIONESACADEMICOSPORRANGOFECHAS,fetchListaSesionesAcademicosPorRangoFechas)   
    yield takeLatest(GET_DIAS_SESIONES,fetchDiasSesiones)   
    yield takeLatest(DELETE_SESIONES,fetchDeleteSesiones)   
    
}


export default  sesionesSaga