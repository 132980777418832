import { GET_HISTORIAL_BY_IDENTIFICADOR_SUCCESS, RESET_HISTORIAL } from "./actionsTypes";

const initialState = {
  historial: [],
};

const Historial = (state = initialState, action) => {
  switch (action.type) {
    case GET_HISTORIAL_BY_IDENTIFICADOR_SUCCESS:
      return {
        ...state,
        historial: action.payload.data,
      };
    case RESET_HISTORIAL:
      return {
        historial: [],
      };
    // add more cases for other actionTypes
    default:
      return state;
  }
};

export default Historial;