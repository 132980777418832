import { takeEvery, put, call, fork, takeLatest, all } from "redux-saga/effects"
import { toast } from 'react-toastify';

import {
  GET_TAREAS,
} from './actionTypes'

import {
  getTareasSuccess,
} from './actions'

const dataTareas = [
    {
      "id": "1",
      "task": "Llamar a Aitor Tilla",
      "subItem": [
        {
          "img": "/static/media/avatar-1.daa0cf150c6b5d8fe31f.jpg"
        },
        {
          "img": "/static/media/avatar-3.db8ab0bd118929359559.jpg"
        }
      ],
      "dueDate": "25 Ene, 2023",
      "status": "Pendiente",
      "priority": "Alta"
    },
    {
      "id": "2",
      "task": "Enviar Brochure a Elton Tito",
      "subItem": [
        {
          "img": "/static/media/avatar-5.8f3500b34579a0560f41.jpg"
        },
        {
          "img": "/static/media/avatar-9.f89ded4b972d52980875.jpg"
        },
        {
          "img": "/static/media/avatar-10.23b25eb5ed63b89150a3.jpg"
        }
      ],
      "dueDate": "23 Abr, 2023",
      "status": "En Progreso",
      "priority": "Media"
    },
    {
      "id": "6",
      "task": "Enviar correo a Esteban Quito",
      "subItem": [
        {
          "img": "/static/media/avatar-3.db8ab0bd118929359559.jpg"
        }
      ],
      "dueDate": "26 Abr, 2023",
      "status": "En Progreso",
      "priority": "Media"
    },
    {
      "id": "7",
      "task": "Enviar correo a Elba Surero",
      "subItem": [
        {
          "img": "/static/media/avatar-4.dd08c9c3678ad2e61fdd.jpg"
        },
        {
          "img": "/static/media/avatar-5.8f3500b34579a0560f41.jpg"
        }
      ],
      "dueDate": "27 Abr, 2023",
      "status": "Completa",
      "priority": "Baja"
    },
    {
      "id": "12",
      "task": "Llamar a Armando Bronca",
      "subItem": [
        {
          "img": "/static/media/avatar-2.d25c05b06966d854ab4a.jpg"
        }
      ],
      "dueDate": "01 May, 2023",
      "status": "Nueva",
      "priority": "Baja"
    },
    {
      "id": "13",
      "task": "Llamar a Lola Mento",
      "subItem": [
        {
          "img": "/static/media/avatar-5.8f3500b34579a0560f41.jpg"
        },
        {
          "img": "/static/media/avatar-6.36f29008cbe773188ed2.jpg"
        },
        {
          "img": "/static/media/avatar-8.7fe13641dd44c8af5ba3.jpg"
        }
      ],
      "dueDate": "02 May, 2023",
      "status": "Completa",
      "priority": "Baja"
    },
    {
      "id": "14",
      "task": "Contactar a Josefa Nática",
      "subItem": [
        {
          "img": "/static/media/avatar-5.8f3500b34579a0560f41.jpg"
        },
        {
          "img": "/static/media/avatar-9.f89ded4b972d52980875.jpg"
        },
        {
          "img": "/static/media/avatar-10.23b25eb5ed63b89150a3.jpg"
        }
      ],
      "dueDate": "02 Abr, 2023",
      "status": "Pendiente",
      "priority": "Media"
    },
    {
      "id": "15",
      "task": "Eliminar a Matías Queroso",
      "subItem": [
        {
          "img": "/static/media/avatar-1.daa0cf150c6b5d8fe31f.jpg"
        },
        {
          "img": "/static/media/avatar-3.db8ab0bd118929359559.jpg"
        }
      ],
      "dueDate": "03 Abr, 2023",
      "status": "En Progreso",
      "priority": "Alta"
    }
  ]


  function* fetchTareas(){
    try {
        const response = dataTareas
        yield put(getTareasSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
      }
  }


  function* tareaSaga() {
    yield takeLatest(GET_TAREAS, fetchTareas)
  }
  
  export default tareaSaga

