export const GET_PROGRAMAS = "GET_PROGRAMAS";
export const GET_PROGRAMAS_SUCCESS = "GET_PROGRAMAS_SUCCESS";
export const GET_PROGRAMA = "GET_PROGRAMA";
export const GET_PROGRAMA_SUCCESS = "GET_PROGRAMA_SUCCESS";
export const GET_ESTADOS_PROGRAMA = "GET_ESTADOS_PROGRAMA"; 
export const GET_ESTADOS_PROGRAMA_SUCCESS = "GET_ESTADOS_PROGRAMA_SUCCESS";
export const GET_AREAS_PROGRAMA = "GET_AREAS_PROGRAMA"; 
export const GET_AREAS_PROGRAMA_SUCCESS = "GET_AREAS_PROGRAMA_SUCCESS";
export const ADD_NEW_PROGRAMA = "ADD_NEW_PROGRAMA";



export const UPDATE_PROGRAMA = "UPDATE_PROGRAMA";
export const CLONE_PROGRAMA = "CLONE_PROGRAMA";
export const GET_PROGRAMA_X_COD_BASE = "GET_PROGRAMA_X_COD_BASE";
export const GET_PROGRAMA_X_COD_BASE_SUCCESS = "GET_PROGRAMA_X_COD_BASE_SUCCESS";
export const GET_MODALIDADES_PROGRAMA = "GET_MODALIDADES_PROGRAMA";
export const GET_MODALIDADES_PROGRAMA_SUCCESS = "GET_MODALIDADES_PROGRAMA_SUCCESS";
export const GET_TIPOS_PROGRAMA = "GET_TIPOS_PROGRAMA";
export const GET_TIPOS_PROGRAMA_SUCCESS = "GET_TIPOS_PROGRAMA_SUCCESS";

export const GET_PROGRAMAS_SIN_DECRETO = "GET_PROGRAMAS_SIN_DECRETO";
export const GET_PROGRAMAS_SIN_DECRETO_SUCCESS = "GET_PROGRAMAS_SIN_DECRETO_SUCCESS";
export const UPDATE_ESTADO_DECRETO_PROGRAMA = "UPDATE_ESTADO_DECRETO_PROGRAMA";
export const GENERA_SYLLABUS_PROGRAMA = "GENERA_SYLLABUS_PROGRAMA";

export const ADD_DATOSWEBPROGRAMA = "ADD_DATOSWEBPROGRAMA";
export const ADD_DATOSWEBPROGRAMA_ADDED = "ADD_DATOSWEBPROGRAMA_ADDED";

export const ADD_DATOSWEBPROGRAMAVERSIONES = "ADD_DATOSWEBPROGRAMAVERSIONES";
export const ADD_DATOSWEBPROGRAMAVERSIONES_ADDED = "ADD_DATOSWEBPROGRAMAVERSIONES_ADDED";

export const GET_PROGRAMADATOSWEB = "GET_PROGRAMADATOSWEB";
export const GET_PROGRAMADATOSWEB_SUCCESS = "GET_PROGRAMADATOSWEB_SUCCESS";
export const GET_PROGRAMAVERSIONESDATOSWEB = "GET_PROGRAMAVERSIONESDATOSWEB";
export const GET_PROGRAMAVERSIONESDATOSWEB_SUCCESS = "GET_PROGRAMAVERSIONESDATOSWEB_SUCCESS";

export const RESET_PROGRAMA = "RESET_PROGRAMA";