import React, { Suspense } from 'react';
import { Switch, Route } from "react-router-dom";

//Layouts
import NonAuthLayout from "../Layouts/NonAuthLayout";
import VerticalLayout from "../Layouts/index";
//routes
import { authProtectedRoutes, authProtectedExternoRoutes, publicRoutes } from "./allRoutes";
import { AuthProtected, AccessRoute } from './AuthProtected';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import * as R from 'ramda'
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Cargando from '../Components/Common/Cargando';
import { app } from "../config";

const Index = () => {
    const availablePublicRoutesPaths = publicRoutes.map((r) => r.path);
    const availableAuthRoutesPath = authProtectedRoutes.map((r) => r.path);
    const availableAuthExternoRoutesPath = authProtectedExternoRoutes.map((r) => r.path);

    return (
        <React.Fragment>
            <ToastContainer />
            <Switch>
                <Route path={availablePublicRoutesPaths}>
                    <NonAuthLayout>
                        <Suspense fallback={<Cargando />}>
                            <Switch>
                                {publicRoutes.map((route, idx) => (
                                    <Route
                                        path={route.path}
                                        component={route.component}
                                        key={idx}
                                        exact={true}
                                    />
                                ))}
                            </Switch>
                        </Suspense>
                    </NonAuthLayout>
                </Route>

                {app.site === "Intranet" &&
                    <Route path={availableAuthRoutesPath}>
                        <AuthProtected>
                            <VerticalLayout>
                                <Suspense fallback={<Cargando />}>
                                    <Switch>
                                        {authProtectedRoutes.map((route, idx) => (
                                            <AccessRoute
                                                path={route.path}
                                                component={route.component}
                                                key={idx}
                                                rolesApp={route.rolesApp !== undefined ? route.rolesApp : null}
                                            />
                                        ))}
                                    </Switch>
                                </Suspense>
                            </VerticalLayout>
                        </AuthProtected>
                    </Route>}

                {app.site === "Externo" &&
                    <Route path={availableAuthExternoRoutesPath}>
                        <AuthProtected>
                            <VerticalLayout>
                                <Suspense fallback={<Cargando />}>
                                    <Switch>
                                        {authProtectedExternoRoutes.map((route, idx) => (
                                            <AccessRoute
                                                path={route.path}
                                                component={route.component}
                                                key={idx}
                                                rolesApp={route.rolesApp !== undefined ? route.rolesApp : null}
                                            />
                                        ))}
                                    </Switch>
                                </Suspense>
                            </VerticalLayout>
                        </AuthProtected>
                    </Route>}
            </Switch>
        </React.Fragment>
    );
};

export default Index;