import {
  GET_ENCUESTA, GET_ENCUESTA_SUCCESS, GET_ENCUESTA_BY_GUID, GET_ENCUESTA_BY_GUID_SUCCESS, ADD_NEW_ENCUESTA_RESPUESTA
} from "./actionTypes";

export const getEncuesta = (params) => ({
  type: GET_ENCUESTA,
  payload: params
});

export const getEncuestaSuccess = (encuesta) => ({
  type: GET_ENCUESTA_SUCCESS,
  payload: encuesta
});

export const getEncuestaByGuid = (params) => ({
  type: GET_ENCUESTA_BY_GUID,
  payload: params
});

export const getEncuestaByGuidSuccess = (encuesta) => ({
  type: GET_ENCUESTA_BY_GUID_SUCCESS,
  payload: encuesta
});

export const addEncuestaRespuesta = (params) => ({
  type: ADD_NEW_ENCUESTA_RESPUESTA,
  payload: params,
});


