export const GET_DOLARES = "GET_DOLARES";
export const GET_DOLARES_SUCCESS = "GET_DOLARES_SUCCESS";
export const UPDATE_DOLAR = "UPDATE_DOLAR";
export const ADD_NEW_DOLAR = "ADD_NEW_DOLAR";
export const GET_DOLAR_VIGENTE = "GET_DOLAR_VIGENTE";
export const GET_DOLAR_VIGENTE_SUCCESS = "GET_DOLAR_VIGENTE_SUCCESS";

export const GET_CECOS_SOLICITUD = "GET_CECOS_SOLICITUD";
export const GET_CECOS_SOLICITUD_SUCCESS = "GET_CECOS_SOLICITUD_SUCCESS";
export const GET_CECO_SOLICITUD = "GET_CECO_SOLICITUD";
export const GET_CECO_SOLICITUD_SUCCESS = "GET_CECO_SOLICITUD_SUCCESS";
export const UPDATE_CECO_SOLICITUD = "UPDATE_CECO_SOLICITUD";
export const ADD_NEW_CECO_SOLICITUD = "ADD_NEW_CECO_SOLICITUD";
