import { ADD_NEW_LINK_PAGO, ADD_NEW_PAGO_WEBPAY, GET_CONFIRMA_PAGO_PAYPAL, GET_INFO_PAGO, GET_INFO_PAGO_SUCCESS } from "./actionTypes";

export const addNewLinkPago = (params, callback) => ({
  type: ADD_NEW_LINK_PAGO,
  payload: { params, callback }
});

export const addNewPagoWebpay = (id, callback) => ({
  type: ADD_NEW_PAGO_WEBPAY,
  payload: { id, callback }
});

export const getInfoPago = (id) => ({
  type: GET_INFO_PAGO,
  payload: id
});

export const getInfoPagoSuccess = (data) => ({
  type: GET_INFO_PAGO_SUCCESS,
  payload: data
});

export const getConfirmaPagoPaypal = (data, callback) => ({
  type: GET_CONFIRMA_PAGO_PAYPAL,
  payload: { data, callback }
});