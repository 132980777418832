import { ADD_NEW_EMPRESA, ADD_NEW_EMPRESA_CONTACTO, GET_EMPRESA_CONTACTOS, GET_EMPRESA_CONTACTOS_SUCCESS, GET_EMPRESAS, GET_EMPRESAS_SUCCESS, RESET_CONTACTOS_EMPRESA, UPDATE_EMPRESA } from "./actionTypes";

export const getEmpresas = (params) => ({
  type: GET_EMPRESAS,
  payload: params
});

export const getEmpresasSuccess = (empresas) => ({
  type: GET_EMPRESAS_SUCCESS,
  payload: empresas
});

export const getEmpresaContactos = (id) => ({
  type: GET_EMPRESA_CONTACTOS,
  payload: id
});

export const getEmpresaContactosSuccess = (contactos) => ({
  type: GET_EMPRESA_CONTACTOS_SUCCESS,
  payload: contactos
});

export const addNewEmpresa = (empresa, fetchData = null) => ({
  type: ADD_NEW_EMPRESA,
  payload: { empresa, fetchData }
});

export const addNewEmpresaContacto = (contacto, fetchData = null) => ({
  type: ADD_NEW_EMPRESA_CONTACTO,
  payload: { contacto, fetchData }
});

export const updateEmpresa = (empresa, fetchData = null) => ({
  type: UPDATE_EMPRESA,
  payload: { empresa, fetchData }
});

export const resetContactosEmpresa = () => ({
  type: RESET_CONTACTOS_EMPRESA
});