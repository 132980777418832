export const GET_SESIONES_ALUMNOS = "GET_SESIONES_ALUMNOS";
export const GET_SESIONES_ALUMNOS_SUCCESS = "GET_SESIONES_ALUMNOS_SUCCESS";
export const GET_SESIONES_ACADEMICOS = "GET_SESIONES_ACADEMICOS";
export const GET_SESIONES_ACADEMICOS_SUCCESS = "GET_SESIONES_ACADEMICOS_SUCCESS";
export const GET_LISTASESIONESACADEMICOSPORRANGOFECHAS = "GET_LISTASESIONESACADEMICOSPORRANGOFECHAS";
export const GET_LISTASESIONESACADEMICOSPORRANGOFECHAS_SUCCESS = "GET_LISTASESIONESACADEMICOSPORRANGOFECHAS_SUCCESS";
export const GET_DIAS_SESIONES = "GET_DIAS_SESIONES";
export const GET_DIAS_SESIONES_SUCCESS = "GET_DIAS_SESIONES_SUCCESS";
export const DELETE_SESIONES = "DELETE_SESIONES";


