import {
  ADD_NEW_PREMIO_ADJUDICACION,
  ADD_NEW_PREMIO_PUBLICACION,
  GET_ADJUDICACIONES,
  GET_ADJUDICACIONES_SUCCESS,
  GET_CROSSREF,
  GET_CROSSREF_FAIL,
  GET_CROSSREF_SUCCESS,
  GET_PUBLICACIONES,
  GET_PUBLICACIONES_SUCCESS,
  RESET_CROSSREF,
  UPDATE_ESTADO_PREMIO,
  GET_PREMIO_PUB_HISTORIAL, 
  GET_PREMIO_PUB_HISTORIAL_SUCCESS,
  POST_CARGARANKINGJCR, POST_CARGARANKINGJCR_SUCCESS,
  POST_CARGADETALLERANKINGJCR, POST_CARGADETALLERANKINGJCR_SUCCESS,
  GET_RANKINGJCR_BY_ISSN, GET_RANKINGJCR_BY_ISSN_SUCCESS,
  UPDATE_ESTADO_PREMIO_ADJ, GET_RANKINGJCR_DETALLE_ALL, GET_RANKINGJCR_DETALLE_ALL_SUCCESS,
  GET_MAX_ANIO_PREMIO_ADJUDICACION, GET_MAX_ANIO_PREMIO_ADJUDICACION_SUCCESS,
  GET_REPORTE_PREMIO_PUB, GET_REPORTE_PREMIO_PUB_SUCCESS,
  GET_REPORTE_ADJUDICACION, GET_REPORTE_ADJUDICACION_SUCCESS, GET_RANKINGJCR_BY_ISSN_VAL, GET_RANKINGJCR_BY_ISSN_VAL_SUCCESS,
  RESET_ARCHIVOEXCEL,
} from "./actionTypes";

export const getCrossref = (doi, checkIngresado = true) => ({
  type: GET_CROSSREF,
  payload: {doi, checkIngresado},
});

export const getCrossrefSuccess = (data) => ({
  type: GET_CROSSREF_SUCCESS,
  payload: data,
});

export const getCrossrefFail = () => ({
  type: GET_CROSSREF_FAIL,
});

export const getPublicaciones = (params) => ({
  type: GET_PUBLICACIONES,
  payload: params,
});

export const getPublicacionesSuccess = (publicaciones) => ({
  type: GET_PUBLICACIONES_SUCCESS,
  payload: publicaciones,
});

export const getAdjudicaciones = (params) => ({
  type: GET_ADJUDICACIONES,
  payload: params,
});

export const getAdjudicacionesSuccess = (adjudicaciones) => ({
  type: GET_ADJUDICACIONES_SUCCESS,
  payload: adjudicaciones,
});

export const addNewPremioPublicacion = (premio, formData, formData1) => ({
  type: ADD_NEW_PREMIO_PUBLICACION,
  payload: { premio, formData, formData1 },
});

export const addNewPremioAdjudicacion = (premio, formDataProyecto, formDataActa, formDataBases) => ({
  type: ADD_NEW_PREMIO_ADJUDICACION,
  payload: { premio, formDataProyecto, formDataActa, formDataBases },
});

export const updateEstadoPremio = (premio, tipo, estado, titulo, 
                                   anio, issn, impactFactor, articleInfluence, 
                                   rankingArticleInfluence, grupo, vcomentario, transicion, 
                                   enviadoAntesFecha, jornadaAcademico, afiliacion, revistaNoEspecial, 
                                   dividir, solicitud180, noSobrepasadoLimite, acadsPremio) => ({
  type: UPDATE_ESTADO_PREMIO,
  payload: { premio, tipo, estado, titulo, 
             anio, issn, impactFactor, articleInfluence, 
             rankingArticleInfluence, grupo, vcomentario, transicion, 
             enviadoAntesFecha, jornadaAcademico, afiliacion, revistaNoEspecial, 
             dividir, solicitud180, noSobrepasadoLimite, acadsPremio},
});

export const updateEstadoPremioAdj = (premio, estado, titulo, tipoProyecto, anosInicio, anosTermino, acadsPremio) => ({
  type: UPDATE_ESTADO_PREMIO_ADJ,
  payload: {premio, estado, titulo, tipoProyecto, anosInicio, anosTermino, acadsPremio},
});


export const resetCrossref = () => ({
  type: RESET_CROSSREF,
});

/*export const getPremioPubHistorial = (params) => ({
  type: GET_PREMIO_PUB_HISTORIAL,
  payload: params
});

export const getPremioPubHistorialSuccess = (params) => ({
    type: GET_PREMIO_PUB_HISTORIAL_SUCCESS,
    payload: params
});*/


export const getPremioPubHistorial = (params, activeTabPremios) => ({
  type: GET_PREMIO_PUB_HISTORIAL,
  payload: {params, activeTabPremios}
});

export const getPremioPubHistorialSuccess = (params, activeTabPremios) => ({
    type: GET_PREMIO_PUB_HISTORIAL_SUCCESS,
    payload: {params, activeTabPremios}
});



export const postCargaRankingjcr = (params) => ({
  type: POST_CARGARANKINGJCR,
  payload: params
});

export const postCargaRankingjcrSuccess = (params) => ({
  type: POST_CARGARANKINGJCR_SUCCESS,
  payload: params
});

export const getRankingJCRByIssn = (params) => ({
  type: GET_RANKINGJCR_BY_ISSN,
  payload: params
});

export const getRankingJCRByIssnSuccess = (params) => ({
  type: GET_RANKINGJCR_BY_ISSN_SUCCESS,
  payload: params
});

export const getRankingJCRByIssnVal = (params) => ({
  type: GET_RANKINGJCR_BY_ISSN_VAL,
  payload: params
});

export const getRankingJCRByIssnValSuccess = (params) => ({
  type: GET_RANKINGJCR_BY_ISSN_VAL_SUCCESS,
  payload: params
});

export const postCargaDetalleRankingjcr = (fileId) => ({
  type: POST_CARGADETALLERANKINGJCR,
  payload: fileId
});

export const postCargaDetalleRankingjcrSuccess = (fileId) => ({
  type: POST_CARGADETALLERANKINGJCR_SUCCESS,
  payload: fileId
});

export const getRankingJCRDetalleAll = (params) => ({
  type: GET_RANKINGJCR_DETALLE_ALL,
  payload: params
});

export const getRankingJCRDetalleAllSuccess = (params) => ({
  type: GET_RANKINGJCR_DETALLE_ALL_SUCCESS,
  payload: params
});

export const getMaxAnioPremioAdjudicacion = () => ({
  type: GET_MAX_ANIO_PREMIO_ADJUDICACION
});

export const getMaxAnioPremioAdjudicacionSuccess = (data) => ({
  type: GET_MAX_ANIO_PREMIO_ADJUDICACION_SUCCESS,
  payload: data
});

export const getReportePremioPub = (params) => ({
  type: GET_REPORTE_PREMIO_PUB,
  payload: params  
})

export const getReportePremioPubSuccess = (params) => ({
  type: GET_REPORTE_PREMIO_PUB_SUCCESS,
  payload: params  
})

export const getReporteAdjudicacion = (params) => ({
  type: GET_REPORTE_ADJUDICACION,
  payload: params  
})

export const getReporteAdjudicacionSuccess = (params) => ({
  type: GET_REPORTE_ADJUDICACION_SUCCESS,
  payload: params  
})

export const resetArchivoexcel = () => ({
  type: RESET_ARCHIVOEXCEL,
});
