import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row, TabContent, TabPane } from 'reactstrap';

//import images
import bell from "../../assets/images/svg/bell.svg";
import avatarDummy from "../../assets/images/users/user-dummy-img.jpg";

//SimpleBar
import SimpleBar from "simplebar-react";

import moment from 'moment';
import 'moment/locale/es';
import { isNil } from 'ramda';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { updateNotificacion } from '../../store/actions';

const NotificationDropdown = () => {
    //Dropdown Toggle
    const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);
    const toggleNotificationDropdown = () => {
        setIsNotificationDropdown(!isNotificationDropdown);
    };

    moment.locale("es");

    const notificaciones = useSelector(state => state.Profile.notificaciones, shallowEqual);

    const dispatch = useDispatch()

    const handleCheckNotificacion = (id) => {
        dispatch(updateNotificacion(id))
        const noti = document.getElementById(`notification-${id}`);
        noti.classList.add('notification-hide')
        setTimeout(() => {
            let arrayNotificaciones = Array.from(notificacionesUsuario)
            arrayNotificaciones = arrayNotificaciones.filter(n => n.id !== id)
            setNotificacionesUsuario(arrayNotificaciones)
        }, 500);
    }

    const permission = Notification.permission;

    if (permission === "default") {
        Notification.requestPermission();
    }

    const showNotificationOS = (body, icon) => {
        const options = {
            body,
            icon: bell, //"https://images.pexels.com/photos/853168/pexels-photo-853168.jpeg?    auto=compress&cs=tinysrgb&dpr=1&w=500",
            dir: "ltr",
        };
        const notification = new Notification(
            "Intranet DCS",
            options
        );
        notification.onclick = () => {
            window.focus();
        };
    };

    const [notificacionesUsuario, setNotificacionesUsuario] = useState([])
    useEffect(() => {
        setNotificacionesUsuario(notificaciones)
        notificaciones.forEach(n => {
            const fecha = new Date(n.fechaEnvio)
            const diff = new Date() - fecha
            if (diff <= 15000) {
                const mensaje = isNil(n.mensajeId) ? n.tipoModulo.mensajeDefecto.replace(/(<([^>]+)>)/ig, '') : n.notificacionMensaje.mensaje.replace(/(<([^>]+)>)/ig, '');
                showNotificationOS(mensaje)
            }
        });
    }, [notificaciones])

    //Tab 
    const [activeTab, setActiveTab] = useState('1');
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };
    return (
        <React.Fragment>
            <Dropdown isOpen={isNotificationDropdown} toggle={toggleNotificationDropdown} className="topbar-head-dropdown ms-1 header-item">
                <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none">
                    <i className='bx bx-bell fs-22'></i>
                    {notificacionesUsuario.length > 0 && <span
                        className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">{notificacionesUsuario.length}<span
                            className="visually-hidden">nuevas notificaciones</span></span>}
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                    <div className="dropdown-head rounded-top">
                        <div className="p-3 pb-1">
                            <Row className="align-items-center">
                                <Col>
                                    <h6 className="m-0 fs-16 fw-semibold"> Notificaciones </h6>
                                </Col>
                                <div className="col-auto dropdown-tabs">
                                    <span className="badge badge-soft-secondary fs-13"> {notificacionesUsuario.length}</span>
                                </div>
                            </Row>
                        </div>
                    </div>

                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1" className="py-2 ps-2">
                            <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">

                                {notificacionesUsuario.length === 0 &&
                                    <>
                                        <div className="w-25 w-sm-50 pt-3 mx-auto img-campana">
                                            <img src={bell} className="img-fluid" alt="user-pic" />
                                        </div>
                                        <div className="text-center px-4 pb-3 mt-2">
                                            <h6 className="fs-18 fw-semibold lh-base">No tiene nuevas notificaciones</h6>
                                        </div>
                                    </>
                                }

                                {notificacionesUsuario.length > 0 && notificacionesUsuario.map(n =>
                                    <div key={n.id} id={`notification-${n.id}`} className="text-reset notification-item d-block dropdown-item position-relative">
                                        <div className="d-flex">
                                            {isNil(n.emisor) ?
                                                <div className="avatar-xs me-3">
                                                    <span className="avatar-title bg-soft-info text-info rounded-circle fs-16">
                                                        <i className={n.tipoModulo.icono}></i>
                                                    </span>
                                                </div>
                                                :
                                                <div className="avatar-xs me-3">
                                                    <img src={avatarDummy} className="me-3 rounded-circle avatar-xs" alt="user-pic" />
                                                </div>
                                            }
                                            <div className="flex-1">
                                                <Link replace to={!isNil(n.notificacionMensaje.url) ? n.notificacionMensaje.url : isNil(n.tipoModulo.url) ? "#" : n.tipoModulo.url} className="stretched-link">
                                                    <h6 className="mt-0 mb-1 fs-13 fw-semibold">
                                                        {isNil(n.emisor) ? n.tipoModulo.notificacionModulo.nombre : n.usuarioEmisor.nombre + " " + n.usuarioEmisor.paterno}
                                                    </h6>
                                                    <div className="fs-13 text-muted">
                                                        <p className="mb-1" dangerouslySetInnerHTML={{ __html: isNil(n.mensajeId) ? n.tipoModulo.mensajeDefecto : n.notificacionMensaje.mensaje }}></p>
                                                    </div>
                                                </Link>
                                                <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                    <span><i className="mdi mdi-clock-outline"></i> {moment(n.fechaEnvio).fromNow()}</span>
                                                </p>
                                            </div>
                                            <div className="ps-2 fs-15">
                                                <div className="form-check notification-check">
                                                    <input className="form-check-input" type="checkbox" value="" id={`notification-check${n.id}`} onClick={() => handleCheckNotificacion(n.id)} />
                                                    <label className="form-check-label" htmlFor={`notification-check${n.id}`}></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                            </SimpleBar>

                        </TabPane>
                    </TabContent>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default NotificationDropdown;