import {
  GET_FILE,
  GET_FILE_BYPATHFILENAME,
  GET_IMG_FILE,
  POST_FILE,
  POST_FILE_SUCCESS,
  POST_MULTIPLE_FILE,
  RESET_FILE_STORE
} from './actionTypes'


export const postFile = (params, callback) => ({
  type: POST_FILE,
  payload: { params, callback }
});

export const postMultipleFile = (params) => ({
  type: POST_MULTIPLE_FILE,
  payload: params
});

export const postFileSuccess = (params) => ({
  type: POST_FILE_SUCCESS,
  payload: params
});

export const getFile = (id) => ({
  type: GET_FILE,
  payload: id
});

export const getImgFile = (id, callback = null) => ({
  type: GET_IMG_FILE,
  payload: { id, callback }
});

export const getFileByPathFilename = (path, filename) => ({
  type: GET_FILE_BYPATHFILENAME,
  payload: { path, filename }
});

export const resetFileStore = () => ({
  type: RESET_FILE_STORE
});