import { GET_ACADEMICOS, GET_ACADEMICOS_CENTRO_BY_DIRECTOR, 
         GET_ACADEMICOS_CENTRO_BY_DIRECTOR_SUCCESS, 
         GET_ACADEMICOS_SUCCESS, GET_ACAD_DIAS_DISPONIBLES, 
         GET_ACAD_DIAS_DISPONIBLES_SUCCESS, GET_ACAD_DIAS_DISPONIBLES_ALL, GET_ACAD_DIAS_DISPONIBLES_ALL_SUCCESS, GET_ACAD_DIAS_BLOQUEO, GET_ACAD_DIAS_BLOQUEO_SUCCESS, 
         ADD_NEW_ACAD_DIAS_DISPONIBLE, ADD_NEW_ACAD_DIAS_BLOQUEO, DELETE_ACAD_DIAS_DISPONIBLE, DELETE_ACAD_DIAS_BLOQUEO, UPDATE_ACAD_DIAS_DISPONIBLE,
         GET_REPORTE_DISPONIBILIDAD_YEAR, GET_REPORTE_DISPONIBILIDAD_YEAR_SUCCESS, RESET_REPORTE_DISPONIBILIDAD,
         GET_REPORTE_BLOQUEOS_YEAR, GET_REPORTE_BLOQUEOS_YEAR_SUCCESS, RESET_REPORTE_BLOQUEOS,
         GET_REPORTE_AMBOS_YEAR, GET_REPORTE_AMBOS_YEAR_SUCCESS, RESET_REPORTE_AMBOS, GET_ACADEMICO_BY_USERID, GET_ACADEMICO_BY_USERID_SUCCESS, 
         GET_ACADEMICOS_ALL, GET_ACADEMICOS_ALL_SUCCESS, VALIDA_ACADEMICO, ADD_NEW_ACADEMICO, UPDATE_ACADEMICO, ADD_NEW_ESTUDIO_ACADEMICO, 
         GET_ESTUDIOS_ACADEMICO, GET_ESTUDIOS_ACADEMICO_SUCCESS, ADD_NEW_DOCUMENTO_ACADEMICO, GET_DOCUMENTOS_ACADEMICO, GET_DOCUMENTOS_ACADEMICO_SUCCESS,
         GET_ACADEMICO_USER, GET_ACADEMICO_USER_SUCCESS, RESET_ACADEMICO_USER} from "./actionTypes";


export const getAcademicos = (params) => ({
  type: GET_ACADEMICOS,
  payload: params  
});

export const getAcademicosSuccess = (data) => ({
  type: GET_ACADEMICOS_SUCCESS,
  payload: data
});

export const getAcademicoByUserId = (id) => ({
  type: GET_ACADEMICO_BY_USERID,
  payload: id
});

export const getAcademicoByUserIdSuccess = (data) => ({
  type: GET_ACADEMICO_BY_USERID_SUCCESS,
  payload: data
});

export const getAcademicosCentroByDirector = () => ({
  type: GET_ACADEMICOS_CENTRO_BY_DIRECTOR,
});

export const getAcademicosCentroByDirectorSuccess = (data) => ({
  type: GET_ACADEMICOS_CENTRO_BY_DIRECTOR_SUCCESS,
  payload: data
});

export const getAcadDiasDisponibles = (params) => ({
  type: GET_ACAD_DIAS_DISPONIBLES,
  payload: params
});

export const getAcadDiasDisponiblesSuccess = (params) => ({
  type: GET_ACAD_DIAS_DISPONIBLES_SUCCESS,
  payload: params
});

export const getAcadDiasDisponiblesAll = (params) => ({
  type: GET_ACAD_DIAS_DISPONIBLES_ALL,
  payload: params
});

export const getAcadDiasDisponiblesAllSuccess = (params) => ({
  type: GET_ACAD_DIAS_DISPONIBLES_ALL_SUCCESS,
  payload: params
});

export const getAcadDiasBloqueo = (params) => ({
  type: GET_ACAD_DIAS_BLOQUEO,
  payload: params
});

export const getAcadDiasBloqueoSuccess = (params) => ({
  type: GET_ACAD_DIAS_BLOQUEO_SUCCESS,
  payload: params
});

export const addNewAcadDiasDisponible = (dias, params) => ({
  type: ADD_NEW_ACAD_DIAS_DISPONIBLE,
  payload: {dias, params}
});

export const addNewAcadDiasBloqueo = (diasbloqueo) => ({
  type: ADD_NEW_ACAD_DIAS_BLOQUEO,
  payload: {diasbloqueo},
});

export const deleteAcadDiasDisponible = params => ({
  type: DELETE_ACAD_DIAS_DISPONIBLE,
  payload: params 
});

export const deleteAcadDiasBloqueo = params => ({
  type: DELETE_ACAD_DIAS_BLOQUEO,
  payload: params 
});

export const updateAcadDiasDisponible = (data, params) => ({
  type: UPDATE_ACAD_DIAS_DISPONIBLE,
  payload: {data, params}
});

export const getReporteDisponibilidadYear = (year) => ({
  type: GET_REPORTE_DISPONIBILIDAD_YEAR,
  payload: year
})
export const getReporteDisponibilidadYearSuccess = (fileName) => ({
  type: GET_REPORTE_DISPONIBILIDAD_YEAR_SUCCESS,
  payload: fileName
})
export const resetReporteDisponibilidad = () => ({
  type: RESET_REPORTE_DISPONIBILIDAD,
});

export const getReporteBloqueosYear = (year) => ({
  type: GET_REPORTE_BLOQUEOS_YEAR,
  payload: year
})
export const getReporteBloqueosYearSuccess = (fileName) => ({
  type: GET_REPORTE_BLOQUEOS_YEAR_SUCCESS,
  payload: fileName
})
export const resetReporteBloqueos = () => ({
  type: RESET_REPORTE_BLOQUEOS,
});
export const getReporteAmbosYear = (year) => ({
  type: GET_REPORTE_AMBOS_YEAR,
  payload: year
})
export const getReporteAmbosYearSuccess = (fileName) => ({
  type: GET_REPORTE_AMBOS_YEAR_SUCCESS,
  payload: fileName
})
export const resetReporteAmbos = () => ({
  type: RESET_REPORTE_AMBOS,
});
export const getAcademicosAll = () => ({
  type: GET_ACADEMICOS_ALL,
});
export const getAcademicosSuccessAll = (data) => ({
  type: GET_ACADEMICOS_ALL_SUCCESS,
  payload: data
});
export const validaAcademico = (data, params) => ({
  type: VALIDA_ACADEMICO,
  payload: {data, params}
});
export const addNewAcademico = (academico, formImg = null, fetchData = null) => ({
  type: ADD_NEW_ACADEMICO,
  payload: { academico, formImg, fetchData }
});
export const updateAcademico = (academico, formImg = null, fetchData = null) => ({
  type: UPDATE_ACADEMICO,
  payload: { academico, formImg, fetchData }
});

export const addNewEstudioAcademico = (estudio, fetchData = null, formData) => ({
  type: ADD_NEW_ESTUDIO_ACADEMICO,
  payload: { estudio, fetchData, formData}
});

export const getEstudiosAcademico = (id) => ({
  type: GET_ESTUDIOS_ACADEMICO,
  payload: id
});

export const getEstudiosAcademicoSuccess = (estudios) => ({
  type: GET_ESTUDIOS_ACADEMICO_SUCCESS,
  payload: estudios
});

export const addNewDocumentoAcademico = (documento, fetchData = null, formData) => ({
  type: ADD_NEW_DOCUMENTO_ACADEMICO,
  payload: { documento, fetchData, formData}
});

export const getDocumentosAcademico = (id) => ({
  type: GET_DOCUMENTOS_ACADEMICO,
  payload: id
});

export const getDocumentosAcademicoSuccess = (documentos) => ({
  type: GET_DOCUMENTOS_ACADEMICO_SUCCESS,
  payload: documentos
});


// ** cliente externo
export const getAcademicoUser = () => ({
  type: GET_ACADEMICO_USER,
});

export const getAcademicoUserSuccess = (data) => ({
  type: GET_ACADEMICO_USER_SUCCESS,
  payload: data
});
export const resetAcademicoUser = () => ({
  type: RESET_ACADEMICO_USER,
});
// ** FIN cliente externo



