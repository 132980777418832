export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";

export const GET_ALL_PERFILES = "GET_ALL_PERFILES";
export const GET_ALL_PERFILES_SUCCESS = "GET_ALL_PERFILES_SUCCESS";
export const GET_ALL_PERFILES_FAIL = "GET_ALL_PERFILES_FAIL";
export const GET_ALL_ROLES = "GET_ALL_ROLES";
export const GET_ALL_ROLES_SUCCESS = "GET_ALL_ROLES_SUCCESS";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_PARCIAL = "UPDATE_USER_PARCIAL";
export const UPDATE_PERFIL = "UPDATE_PERFIL";
export const UPDATE_ROL = "UPDATE_ROL";
export const UPDATE_PASSWORD_DCS = "UPDATE_PASSWORD_DCS";
export const UPDATE_PASSWORD_DCS_SUCCESS = "UPDATE_PASSWORD_DCS_SUCCESS";

export const ADD_NEW_USER = "ADD_NEW_USER";
export const ADD_NEW_USER_SUCCESS = "ADD_NEW_USER_SUCCESS";
export const ADD_NEW_USER_FAIL = "ADD_NEW_USER_FAIL";
export const ADD_NEW_PERFIL =  "ADD_NEW_PERFIL";
export const ADD_NEW_ROL =  "ADD_NEW_ROL";
export const ADD_PERFIL_USER =  "ADD_PERFIL_USER";
export const ADD_ROL_PERFIL =  "ADD_ROL_PERFIL";
export const RESET_PASSWORD_USER =  "RESET_PASSWORD_USER";
export const RECUPERA_PASSWORD_USER =  "RECUPERA_PASSWORD_USER";
export const RECUPERA_PASSWORD_USER_SUCCESS =  "RECUPERA_PASSWORD_USER_SUCCESS";
export const CAMBIA_PASSWORD_USER =  "CAMBIA_PASSWORD_USER";
export const CAMBIA_PASSWORD_USER_SUCCESS =  "CAMBIA_PASSWORD_USER_SUCCESS";

export const DELETE_PERFIL_USER = "DELETE_PERFIL_USER";
export const DELETE_ROL_PERFIL = "DELETE_ROL_PERFIL";

export const RESET_NEW_USER_MANAGEMENT = "RESET_NEW_USER_MANAGEMENT";