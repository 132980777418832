import React, { useCallback, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import avatarDummy from "../../assets/images/users/user-dummy-img.jpg";
import { app } from "../../config";
import { finalizarPersonificar } from "../../store/actions";

const ProfileDropdown = () => {
  const { usuario, perfil } = useSelector((state) => ({
    usuario: state.Profile.usuario,
    perfil: state.Profile.profileData,
  }), shallowEqual);

  const dispatch = useDispatch();
  const history = useHistory();

  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = useCallback(() => {
    setIsProfileDropdown(!isProfileDropdown);
  }, [isProfileDropdown]);

  const handleFinPersonificar = useCallback(() => {
    dispatch(finalizarPersonificar(history));
  }, [dispatch, history]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user"
      >
        <DropdownToggle tag="button" type="button" className="btn shadow-none">
          <span className="d-flex align-items-center">
            {usuario.personificar ?
              <span className="rounded-circle header-profile-user bg-warning">< i className="ri-spy-line fs-24"></i></span>
              :
              <img
                className="rounded-circle header-profile-user"
                src={usuario.avatar || avatarDummy}
                alt="Header Avatar"
              />}
            {/* <span className="rounded-circle header-profile-user border border-primary border-opacity-25 fw-bold fs-14 text-primary bg-soft-primary" style={{paddingTop: "5px"}}>WW</span> */}
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                {(perfil?.nombre && perfil?.nombre + " " + perfil?.paterno + " " + perfil?.materno) || usuario.nombre}
              </span>
              <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                {usuario.usuario}
              </span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu dropdown-menu-end"
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 3],
              },
            },
            {
              name: 'preventOverflow',
              options: {
                padding: 8,
                boundariesElement: 'viewport'
              },
            },
            {
              name: 'flip',
              options: {
                padding: 8,
                fallbackPlacements: ['top-end']
              },
            }
          ]}
        >
          {/* <h6 className="dropdown-header">Bienvenid@ {userName}!</h6> */}
          <DropdownItem>
            <Link to="/profile" className="text-dark w-100 h-100 d-flex align-items-center">
              <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-2"></i>
              <span className="align-middle flex-grow-1">Perfil</span>
            </Link>
          </DropdownItem>
          {/* <DropdownItem href="#">
            <i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Mensajes</span>
          </DropdownItem>
          <DropdownItem href="#">
            <i className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Tareas</span>
          </DropdownItem>
          <DropdownItem href="#">
            <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Ayuda</span>
          </DropdownItem>
          <div className="dropdown-divider"></div>
          <DropdownItem href="#">
            <span className="badge bg-soft-success text-success mt-1 float-end">
              New
            </span>
            <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Opciones</span>
          </DropdownItem> */}
          {!usuario.personificar ? (
            <DropdownItem href={app.path + "logout"}>
              <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
              <span className="align-middle" data-key="t-logout">
                Cerrar Sesión
              </span>
            </DropdownItem>
          ) :
            <DropdownItem onClick={handleFinPersonificar}>
              <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
              <span className="align-middle" data-key="t-logout">
                Finalizar Personificación
              </span>
            </DropdownItem>
          }
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;
