import { Spinner } from "reactstrap";

export default function Cargando({ heightCss = "100vh" }) {
  return (
    <div
      className="d-flex w-100 justify-content-center align-items-center"
      style={{ height: heightCss }}
    >
      <Spinner
        style={{
          height: "3rem",
          width: "3rem",
        }}
        className="m-3"
        color="primary"
        type="grow"
      >
        Loading...
      </Spinner>
      <Spinner
        style={{
          height: "3rem",
          width: "3rem",
        }}
        className="m-3"
        color="primary"
      >
        Loading...
      </Spinner>
      <Spinner
        style={{
          height: "3rem",
          width: "3rem",
        }}
        className="m-3"
        color="primary"
        type="grow"
      >
        Loading...
      </Spinner>
      <Spinner
        style={{
          height: "3rem",
          width: "3rem",
        }}
        className="m-3"
        color="primary"
      >
        Loading...
      </Spinner>
      <Spinner
        style={{
          height: "3rem",
          width: "3rem",
        }}
        className="m-3"
        color="primary"
        type="grow"
      >
        Loading...
      </Spinner>
    </div>
  );
}
