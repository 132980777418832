import { POST_MOVER_ARCHIVOS_PENTAHO, POST_MOVER_ARCHIVOS_PENTAHO_FAIL, POST_MOVER_ARCHIVOS_PENTAHO_SUCCESS, RESET_MOVER_ARCHIVOS_PENTAHO } from "./actionTypes";

export const moverArchivosPentaho = (params) => ({
  type: POST_MOVER_ARCHIVOS_PENTAHO,
  payload: params
});

export const moverArchivosPentahoSuccess = (params) => ({
  type: POST_MOVER_ARCHIVOS_PENTAHO_SUCCESS,
  payload: params
});

export const moverArchivosPentahoFail = (params) => ({
  type: POST_MOVER_ARCHIVOS_PENTAHO_FAIL,
  payload: params
});

export const resetArchivosPentaho = () => ({
  type: RESET_MOVER_ARCHIVOS_PENTAHO
});