import {
    GET_FERIADOS,
    GET_FERIADOS_SUCCESS,
    UPDATE_FERIADO,
    ADD_NEW_FERIADO,
    DELETE_FERIADO,
    GET_TIPOBLOQUEOS,
    GET_TIPOBLOQUEOS_SUCCESS,
    GET_FERIADOSBYRANGO,
    GET_FERIADOSBYRANGO_SUCCESS,
} from './actionTypes'

const INIT_STATE = {
    feriadosArray: [],
    tipobloqueosArray: [],
    feriadosbyrangoArray: [],
    paginacion: {},
    error: "",
    loading: false
}

const Fechas = (state = INIT_STATE, action) => {
    //console.log(action)
    switch(action.type){
        case GET_FERIADOS:
            return {
              ...state,
              loading: true
            }
        case GET_FERIADOS_SUCCESS:
            return {
              ...state,
              loading: false,
              paginacion : JSON.parse(action.payload.headers['x-pagination']),
              feriadosArray: action.payload.data
            }
        case UPDATE_FERIADO:
            return{
                ...state
            }
        case ADD_NEW_FERIADO:
          return {
            ...state,
          }
        case DELETE_FERIADO:
          return {
            ...state,
          }  
        case GET_TIPOBLOQUEOS:
            return {
              ...state,
              loading: true
            }
        case GET_TIPOBLOQUEOS_SUCCESS:
            return {
              ...state,
              loading: false,
              tipobloqueosArray: action.payload.data
            }
        case GET_FERIADOSBYRANGO:
            return {
              ...state,
              loading: true
            }                      
        case GET_FERIADOSBYRANGO_SUCCESS:
            return {
              ...state,
              loading: false,
              feriadosbyrangoArray: action.payload.data
            }                      

        default:
            return state 
    }
}

export default Fechas