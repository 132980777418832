import { put, call, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  DELETE_DIA_EVENTO,
  GET_CATEGORIAS,
  GET_EVENTO,
  GET_EVENTOS_ANIO,
  GET_EVENTOS_CALENDARIO,
  GET_EVENTO_ACTIVIDADES,
  GET_EVENTO_ANIO_ALL,
  GET_EVENTO_ESTADOS,
  GET_EVENTO_ESTADOS_ITEMS,
  GET_EVENTO_MODALIDADES,
  GET_EVENTO_PROXIMOS,
  GET_EVENTO_REALIZADOS,
  GET_ITEMS_EVENTO,
  GET_REPORTE_EVENTOS_ANIO,
  GET_SALDO_PPTO_CENTRO,
  GET_SALDO_PPTO_CENTRO_DIRECTOR,
  GET_TIPO_EVENTOS,
  POST_EVENTO,
  UPDATE_CANCELA_EVENTO,
  UPDATE_DETALLE_EVENTO,
  UPDATE_ESTADO_EVENTO,
  UPDATE_EVENTO,
  UPDATE_GESTION_EVENTO,
  UPDATE_GESTION_MASIVA_EVENTO,
} from "./actionTypes";
import {
  getEvento,
  getEventosAnio,
  getAllEventosAnio,
  getEventosCategorias,
  getEventosEstados,
  getEventosItems,
  getEventosModalidades,
  getEventosTipos,
  postEvento,
  updateDetalleEvento,
  updateEstadoEvento,
  updateEvento,
  getProximosEventosAnio,
  getEventosRealizadosAnio,
  getActividadesEventosAnio,
  updateGestionEvento,
  updateActividadesEventos,
  getEventosEstadosItems,
  deleteDiaEvento,
  getSaldoPptoCentroByDirector,
  getSaldoPptoCentro,
  getReporteEventosAnio,
  getEventosCalendario,
  updateCancelarEvento,
} from "../../helpers/backend_helper";
import {
  crearEventoFail,
  crearEventoSuccess,
  getActividadesEventosSuccess,
  getCategoriasSuccess,
  getEventoEstadosItemsSuccess,
  getEventoEstadosSuccess,
  getEventoModadiladesSuccess,
  getEventoSuccess,
  getEventosAnioSuccess,
  getEventosCalendarioSuccess,
  getEventosProximosAnioSuccess,
  getEventosRealizadosAnioSuccess,
  getItemsEventosSuccess,
  getReporteEventosAnioSuccess,
  getSaldoPptoCentroByDirectorSuccess,
  getSaldoPptoCentroSuccess,
  getTipoEventosSuccess,
} from "./actions";

function* fetchCategorias() {
  try {
    const response = yield call(getEventosCategorias);
    yield put(getCategoriasSuccess(response));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchTipoEventos({ payload: idCategoria }) {
  try {
    const param = { idCategoria: idCategoria };
    const response = yield call(getEventosTipos, param);
    yield put(getTipoEventosSuccess(response));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchItemsEventos({ payload: idTipoEvento }) {
  try {
    const param = { idTipoEvento: idTipoEvento };
    const response = yield call(getEventosItems, param);
    yield put(getItemsEventosSuccess(response));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchEventosAnio({ payload: { eventoParameters, anio } }) {
  try {
    const response = yield call(getEventosAnio, {
      ...eventoParameters,
      anio,
    });
    yield put(getEventosAnioSuccess(response));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchEventosAnioAll({ payload: { eventoParameters, anio } }) {
  try {
    const response = yield call(getAllEventosAnio, {
      ...eventoParameters,
      anio,
    });
    yield put(getEventosAnioSuccess(response));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchEventosAnioProximos({ payload: { eventoParameters, anio } }) {
  try {
    const response = yield call(getProximosEventosAnio, {
      ...eventoParameters,
      anio,
    });
    yield put(getEventosProximosAnioSuccess(response));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchEventosAnioRealizados({ payload: { eventoParameters, anio } }) {
  try {
    const response = yield call(getEventosRealizadosAnio, {
      ...eventoParameters,
      anio,
    });
    yield put(getEventosRealizadosAnioSuccess(response));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchEventosActividades({ payload: { eventoParameters, anio } }) {
  try {
    const response = yield call(getActividadesEventosAnio, {
      ...eventoParameters,
      anio,
      sinGestion: true,
    });
    yield put(getActividadesEventosSuccess(response));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchEvento({ payload: idEvento }) {
  try {
    const response = yield call(getEvento, { idEvento });
    yield put(getEventoSuccess(response));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchEventoModalidades() {
  try {
    const response = yield call(getEventosModalidades);
    yield put(getEventoModadiladesSuccess(response));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchEventoEstados() {
  try {
    const response = yield call(getEventosEstados);
    yield put(getEventoEstadosSuccess(response));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchEventoEstadosItems() {
  try {
    const response = yield call(getEventosEstadosItems);
    yield put(getEventoEstadosItemsSuccess(response));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchSaldoPptoCentroByDirector({ payload: year }) {
  try {
    const response = yield call(getSaldoPptoCentroByDirector, { year });
    yield put(getSaldoPptoCentroByDirectorSuccess(response));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchSaldoPptoCentro({ payload: idCentro, year }) {
  try {
    const response = yield call(getSaldoPptoCentro, idCentro, year);
    yield put(getSaldoPptoCentroSuccess(response));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchReporteEvento({ payload: year }) {
  try {
    const response = yield call(getReporteEventosAnio, { year });
    yield put(getReporteEventosAnioSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchEventosCalendario() {
  try {
    const response = yield call(getEventosCalendario);
    yield put(getEventosCalendarioSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* createEvento({ payload: evento }) {
  try {
    const response = yield call(postEvento, evento);
    yield put(crearEventoSuccess());
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(crearEventoFail());
  }
}

function* actualizaEvento({ payload: { idEvento, dataEvento, fetchData } }) {
  try {
    const response = yield call(updateEvento, idEvento, dataEvento);
    fetchData();
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* actualizaDetalleEvento({ payload: { idEvento, eventoDetalle } }) {
  try {
    const response = yield call(updateDetalleEvento, idEvento, eventoDetalle);
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* actualizaGestionEvento({
  payload: { eventoDetalle, recargaActividad, eventoParameters, anio },
}) {
  try {
    const response = yield call(updateGestionEvento, eventoDetalle);
    if (recargaActividad) {
      const response = yield call(getActividadesEventosAnio, {
        ...eventoParameters,
        anio,
        sinGestion: true,
      });
      yield put(getActividadesEventosSuccess(response));
    }
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* updateEventosActividadesMasiva({
  payload: { eventoParameters, anio, obs, estado },
}) {
  try {
    const response = yield call(updateActividadesEventos, {
      SearchTerm: eventoParameters.SearchTerm,
      anio,
      observaciones: obs,
      estado,
    });
    const responseGet = yield call(getActividadesEventosAnio, {
      ...eventoParameters,
      anio,
      sinGestion: true,
    });
    yield put(getActividadesEventosSuccess(responseGet));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* actualizaEstadoEvento({
  payload: { idEvento, estado, params, anio, observacion },
}) {
  try {
    const response = yield call(updateEstadoEvento, idEvento, { estado, observacion });
    const responseEventos = yield call(getAllEventosAnio, {
      ...params,
      anio,
    });
    yield put(getEventosAnioSuccess(responseEventos));
    toast.success("Estado actualizado correctamente", {
      position: toast.POSITION.TOP_RIGHT,
    });
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* actualizaCancelaEvento({
  payload: { idEvento, params, anio, justificacion },
}) {
  try {
    const response = yield call(updateCancelarEvento, idEvento, { justificacion });
    const responseEventos = yield call(getEventosAnio, {
      ...params,
      anio,
    });
    yield put(getEventosAnioSuccess(responseEventos));
    toast.success("Evento cancelado correctamente", {
      position: toast.POSITION.TOP_RIGHT,
    });
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* deleteDiasEventoEvento({ payload: { idEvento, fechas } }) {
  try {
    const response = yield call(deleteDiaEvento, { idEvento, fechas });
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* EventosSaga() {
  yield takeLatest(GET_CATEGORIAS, fetchCategorias);
  yield takeLatest(GET_TIPO_EVENTOS, fetchTipoEventos);
  yield takeLatest(GET_ITEMS_EVENTO, fetchItemsEventos);
  yield takeLatest(POST_EVENTO, createEvento);
  yield takeLatest(GET_EVENTOS_ANIO, fetchEventosAnio);
  yield takeLatest(GET_EVENTO_ANIO_ALL, fetchEventosAnioAll);
  yield takeLatest(GET_EVENTO_PROXIMOS, fetchEventosAnioProximos);
  yield takeLatest(GET_EVENTO_REALIZADOS, fetchEventosAnioRealizados);
  yield takeLatest(GET_EVENTO_ACTIVIDADES, fetchEventosActividades);
  yield takeLatest(GET_EVENTO, fetchEvento);
  yield takeLatest(GET_EVENTO_MODALIDADES, fetchEventoModalidades);
  yield takeLatest(GET_EVENTO_ESTADOS, fetchEventoEstados);
  yield takeLatest(GET_EVENTO_ESTADOS_ITEMS, fetchEventoEstadosItems);
  yield takeLatest(GET_SALDO_PPTO_CENTRO_DIRECTOR, fetchSaldoPptoCentroByDirector);
  yield takeLatest(GET_SALDO_PPTO_CENTRO, fetchSaldoPptoCentro);
  yield takeLatest(GET_REPORTE_EVENTOS_ANIO, fetchReporteEvento);
  yield takeLatest(GET_EVENTOS_CALENDARIO, fetchEventosCalendario);
  yield takeLatest(UPDATE_EVENTO, actualizaEvento);
  yield takeLatest(UPDATE_DETALLE_EVENTO, actualizaDetalleEvento);
  yield takeLatest(UPDATE_GESTION_EVENTO, actualizaGestionEvento);
  yield takeLatest(UPDATE_ESTADO_EVENTO, actualizaEstadoEvento);
  yield takeLatest(UPDATE_CANCELA_EVENTO, actualizaCancelaEvento);
  yield takeLatest(
    UPDATE_GESTION_MASIVA_EVENTO,
    updateEventosActividadesMasiva
  );
  yield takeLatest(DELETE_DIA_EVENTO, deleteDiasEventoEvento);
}

export default EventosSaga;
