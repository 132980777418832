import { ADD_NEW_HISTORIAL, GET_HISTORIAL_BY_IDENTIFICADOR, GET_HISTORIAL_BY_IDENTIFICADOR_SUCCESS, RESET_HISTORIAL } from "./actionsTypes";

export const addNewHistorial = (data) => ({
  type: ADD_NEW_HISTORIAL,
  payload: data
});

export const getHistorialByIdentificador = (idModulo, idIdentificador) => ({
  type: GET_HISTORIAL_BY_IDENTIFICADOR,
  payload: { idModulo, idIdentificador }
});

export const getHistorialByIdentificadorSuccess = (data) => ({
  type: GET_HISTORIAL_BY_IDENTIFICADOR_SUCCESS,
  payload: data
});

export const resetHistorial = () => ({
  type: RESET_HISTORIAL
});