const {
  GET_VERSIONES_PROGRAMA_SUCCESS,
  GET_VERSIONES_PARRILLA_SUCCESS,
  GET_VERSIONES_PARRILLA_DEE_SUCCESS,
  GET_ESTADOS_VERSION_SUCCESS,
  CAMBIA_ESTADO_VERSION,
  GET_VERSIONES_PROGRAMA_FULL_SUCCESS,
  GET_COUNT_VERSIONES_PROGRAMAS_SUCCESS,
  GET_MODULOS_VERSION_PROGRAMA_SUCCESS,
  GET_VERSION_PROGRAMA_SUCCESS,
  GET_ESTUDIANTES_VERSION_PROGRAMA_SUCCESS,
  GET_LIBRO_CLASES_PROGRAMA_SUCCESS,
  GET_SESIONES_VERSION_PROGRAMA_SUCCESS,
  GET_SESIONES_VERSION_PROGRAMA,
  RESET_SESIONES_PROGRAMA,
  REAGENDA_SESION_PROGRAMA_SUCCESS,
  ENROQUE_MODULOS_PROGRAMA_SUCCESS,
  CAMBIAR_FECHA_CALENDARIO_MODULO_PROGRAMA_SUCCESS,
  DESPLAZA_SESIONES_CALENDARIO_SUCCESS,
  ASIGNA_SALA_VERSION_PROGRAMA_SUCCESS,
  GET_VERSIONES_PROGRAMA,
  GET_VERSION_ESTUDIANTES_REPORTE_SUCCESS
} = require("./actionTypes");

const INIT_STATE = {
  versionesPrograma: [],
  versionesProgramaFull: [],
  loadingVersionesProgramaFull: false,
  countVersionesProgramas: {},
  versionesParrilla: [],
  arrEstadosVersion: [],
  paginacion: {},
  paginacionFull: {},
  modulosVersionPrograma: [],
  versionPrograma: {},
  estudiantesVersionPrograma: [],
  sesionesVersionPrograma: [],
  loadingSesionesVersionPrograma: null,
  libroClases: [],
  reporteXLS: null,
};

const VersionesPrograma = (state = INIT_STATE,
  action) => {
  switch (action.type) {
    case GET_VERSIONES_PROGRAMA_SUCCESS:
      return {
        ...state,
        paginacion: JSON.parse(action.payload.headers['x-pagination']),
        versionesPrograma: action.payload.data,
      };
    case GET_VERSION_PROGRAMA_SUCCESS:
      return {
        ...state,
        versionPrograma: action.payload.data,
      };
    case GET_MODULOS_VERSION_PROGRAMA_SUCCESS:
      return {
        ...state,
        modulosVersionPrograma: action.payload.data,
      };
    case GET_VERSIONES_PARRILLA_SUCCESS:
      return {
        ...state,
        paginacion: JSON.parse(action.payload.headers['x-pagination']),
        versionesParrilla: action.payload.data,
      };
    case GET_VERSIONES_PROGRAMA:
      return {
        ...state,
        loadingVersionesProgramaFull: true,
      };
    case GET_VERSIONES_PROGRAMA_FULL_SUCCESS:
      return {
        ...state,
        loadingVersionesProgramaFull: false,
        paginacionFull: JSON.parse(action.payload.headers['x-pagination']),
        versionesProgramaFull: action.payload.data,
      };
    case GET_COUNT_VERSIONES_PROGRAMAS_SUCCESS:
      return {
        ...state,
        countVersionesProgramas: action.payload.data,
      };
    case GET_VERSIONES_PARRILLA_DEE_SUCCESS:
      return {
        ...state,
        paginacion: JSON.parse(action.payload.headers['x-pagination']),
        versionesParrilla: action.payload.data,
      };
    case GET_ESTADOS_VERSION_SUCCESS:
      return {
        ...state,
        arrEstadosVersion: action.payload.data
      }
    case GET_ESTUDIANTES_VERSION_PROGRAMA_SUCCESS:
      return {
        ...state,
        estudiantesVersionPrograma: action.payload.data
      }
    case GET_LIBRO_CLASES_PROGRAMA_SUCCESS:
      return {
        ...state,
        libroClases: action.payload.data
      }
    case GET_SESIONES_VERSION_PROGRAMA:
      return {
        ...state,
        loadingSesionesVersionPrograma: true
      }
    case GET_SESIONES_VERSION_PROGRAMA_SUCCESS:
      return {
        ...state,
        sesionesVersionPrograma: action.payload.data,
        loadingSesionesVersionPrograma: false
      }
    case RESET_SESIONES_PROGRAMA:
      return {
        ...state,
        sesionesVersionPrograma: [],
        loadingSesionesVersionPrograma: null
      }
    case CAMBIA_ESTADO_VERSION:
      return {
        ...state,
      }
    case REAGENDA_SESION_PROGRAMA_SUCCESS:
      {
        const sesionesActualizadas = state.sesionesVersionPrograma.map(sesion => {
          if (sesion.sesionId === action.payload.data.oldSesion) {
            return {
              ...sesion,
              estado: { id: 3, nombre: "Reagendada" },
              reagenda: {
                "academico": action.payload.data.academico.academico.usuario,
                "fecha": action.payload.data.fecha,
                "sala": action.payload.data.sala
              }
            };
          }
          return sesion;
        });

        delete action.payload.data.oldSesion;

        return {
          ...state,
          sesionesVersionPrograma: [...sesionesActualizadas, action.payload.data]
        };
      }
    case ENROQUE_MODULOS_PROGRAMA_SUCCESS:
      return {
        ...state,
        sesionesVersionPrograma: state.sesionesVersionPrograma.map(sesion => {
          const newData = action.payload.data.find(d => sesion.sesionId === d.sesionId);
          if (newData) {
            return newData;
          }
          return sesion;
        })
      }
    case CAMBIAR_FECHA_CALENDARIO_MODULO_PROGRAMA_SUCCESS:
      return {
        ...state,
        // borrar todas las sesiones que coincidan con moduloId en payload y cargar todas las sesiones que vienen en payload
        sesionesVersionPrograma: state.sesionesVersionPrograma.filter(sesion => sesion.moduloId !== action.payload.data[0].moduloId).concat(action.payload.data)
      }
    case DESPLAZA_SESIONES_CALENDARIO_SUCCESS:
      return {
        ...state,
        sesionesVersionPrograma: action.payload.data
      }
    case ASIGNA_SALA_VERSION_PROGRAMA_SUCCESS:
      return {
        ...state,
        sesionesVersionPrograma: action.payload.data
      }
    case GET_VERSION_ESTUDIANTES_REPORTE_SUCCESS:
        return {
          ...state,
          reporteXLS: action.payload.data
        }      
    default:
      return state;
  }
};


export default VersionesPrograma;