import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

// Import Images
import error403cover from "../../assets/images/403-error-2.png";

const Cover403 = () => {
document.title="Página no existe o no tiene acceso | Intranet DCS";
    return (
        <React.Fragment>
            <div className="auth-page-content">
                <div className="auth-page-wrapper py-5 d-flex justify-content-center align-items-center min-vh-100">
                    <div className="auth-page-content overflow-hidden p-0">
                        <Container>
                            <Row className="justify-content-center">
                                <Col xl={7} lg={8}>
                                    <div className="text-center">
                                        <img src={error403cover} alt="error img" className="img-fluid" style={{height: "350px"}} />
                                        <div className="mt-3">
                                            <h3 className="text-uppercase">ACCESO NO AUTORIZADO</h3>
                                            <p className="text-muted mb-4">No posee permiso al recurso solicitado</p>
                                            {/* <Link to="/dashboard" className="btn btn-success"><i className="mdi mdi-home me-1"></i>Back to home</Link> */}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Cover403;