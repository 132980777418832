import { ADD_NEW_MODULO, GET_ESTADO_MODULOS, GET_ESTADO_MODULOS_SUCCESS, GET_MODULOS, GET_MODULOS_BY_CODIGO, GET_MODULOS_BY_CODIGO_SUCCESS, GET_MODULOS_SUCCESS, GET_SYLLABUS_MODULO, GET_SYLLABUS_MODULO_SUCCESS, RESET_MODULOS_BY_CODIGO, RESET_SYLLABUS_MODULO, UPDATE_MODULO, GET_MODULOS_BY_ACADEMICO, GET_MODULOS_BY_ACADEMICO_SUCCESS, GET_EVALUACIONES_BY_MODULO_VERSION, GET_EVALUACIONES_BY_MODULO_VERSION_SUCCESS, GET_MODULO_VERSION, GET_MODULO_VERSION_SUCCESS, ADD_NEW_EVALUACION_MODULO, UPDATE_EVALUACION_MODULO, DELETE_EVALUACION_MODULO, UPDATE_PROMEDIO_MODULO_VERSION, ADD_NEW_NOTA_EVALUACION_MODULO, UPDATE_NOTA_EVALUACION_MODULO, DELETE_NOTA_EVALUACION_MODULO, GET_SESIONES_MODULO, GET_SESIONES_MODULO_SUCCESS, ADD_NEW_ASISTENCIA_MODULO, GET_ASISTENCIA_MODULO, GET_ASISTENCIA_MODULO_SUCCESS, UPDATE_NOTA_EVALUACION_MODULO_SUCCESS, GET_HORARIO_MODULO, GET_HORARIO_MODULO_SUCCESS, UPDATE_ESTADO_ACTA_MODULO } from "./actionsTypes";

export const getModulos = (params) => ({
  type: GET_MODULOS,
  payload: params
});

export const getModulosSuccess = (data) => ({
  type: GET_MODULOS_SUCCESS,
  payload: data
});

export const getModulosByCodigo = (codigo) => ({
  type: GET_MODULOS_BY_CODIGO,
  payload: codigo
});

export const getModulosByCodigoSuccess = (data) => ({
  type: GET_MODULOS_BY_CODIGO_SUCCESS,
  payload: data
});

export const getEvaluacionesByModuloVersion = (idModuloVersion) => ({
  type: GET_EVALUACIONES_BY_MODULO_VERSION,
  payload: idModuloVersion
});

export const getEvaluacionesByModuloVersionSuccess = (data) => ({
  type: GET_EVALUACIONES_BY_MODULO_VERSION_SUCCESS,
  payload: data
});

export const getSesionesModulo = (id) => ({
  type: GET_SESIONES_MODULO,
  payload: id
});

export const getSesionesModuloSuccess = (data) => ({
  type: GET_SESIONES_MODULO_SUCCESS,
  payload: data
});

export const getAsistenciaModulo = (id) => ({
  type: GET_ASISTENCIA_MODULO,
  payload: id
});

export const getAsistenciaModuloSuccess = (data) => ({
  type: GET_ASISTENCIA_MODULO_SUCCESS,
  payload: data
});

export const getModuloVersion = (idModuloVersion) => ({
  type: GET_MODULO_VERSION,
  payload: idModuloVersion
});

export const getModuloVersionSuccess = (data) => ({
  type: GET_MODULO_VERSION_SUCCESS,
  payload: data
});

export const getHorarioModulo = (id) => ({
  type: GET_HORARIO_MODULO,
  payload: id
});

export const getHorarioModuloSuccess = (data) => ({
  type: GET_HORARIO_MODULO_SUCCESS,
  payload: data
});

export const addNewModulo = (data, fetchData, isFormPrograma, formData) => ({
  type: ADD_NEW_MODULO,
  payload: { data, fetchData, isFormPrograma, formData }
});

export const addNewEvaluacionModulo = (data) => ({
  type: ADD_NEW_EVALUACION_MODULO,
  payload: data
});

export const addNewNotaEvaluacionModulo = (data) => ({
  type: ADD_NEW_NOTA_EVALUACION_MODULO,
  payload: data
});

export const addNewAsistenciaModulo = (data, moduloVersionId, setAsistenciaOk) => ({
  type: ADD_NEW_ASISTENCIA_MODULO,
  payload: { data, moduloVersionId, setAsistenciaOk }
});

export const updateModulo = (data, fetchData, formFileSence = null, archivoSenceId = null, formData = null, archivoSyllabusId = null) => ({
  type: UPDATE_MODULO,
  payload: { data, fetchData, formFileSence, archivoSenceId, formData, archivoSyllabusId}
});

export const updateEvaluacionModulo = (data, moduloVersionId) => ({
  type: UPDATE_EVALUACION_MODULO,
  payload: { data, moduloVersionId }
});

export const updatePromedioModuloVersion = (data, callback = null) => ({
  type: UPDATE_PROMEDIO_MODULO_VERSION,
  payload: { data, callback }
});

export const updateNotaEvaluacionModulo = (data) => ({
  type: UPDATE_NOTA_EVALUACION_MODULO,
  payload: data
});

export const updateNotaEvaluacionModuloSuccess = (data) => ({
  type: UPDATE_NOTA_EVALUACION_MODULO_SUCCESS,
  payload: data
});

export const updateEstadoActaModulo = (data, callback) => ({
  type: UPDATE_ESTADO_ACTA_MODULO,
  payload: { data, callback }
});

export const getEstadoModulos = () => ({
  type: GET_ESTADO_MODULOS
});

export const getEstadoModulosSuccess = (data) => ({
  type: GET_ESTADO_MODULOS_SUCCESS,
  payload: data
});

export const getSyllabusModulo = (idModulo) => ({
  type: GET_SYLLABUS_MODULO,
  payload: idModulo
});

export const getSyllabusModuloSuccess = (data) => ({
  type: GET_SYLLABUS_MODULO_SUCCESS,
  payload: data
});

export const resetSyllabusModulo = () => ({
  type: RESET_SYLLABUS_MODULO
});

export const getModulosByAcademico = (params) => ({
  type: GET_MODULOS_BY_ACADEMICO,
  payload: params
});

export const getModulosByAcademicoSuccess = (data) => ({
  type: GET_MODULOS_BY_ACADEMICO_SUCCESS,
  payload: data
});

export const deleteEvaluacionModulo = (idEvaluacionModulo, moduloVersionId) => ({
  type: DELETE_EVALUACION_MODULO,
  payload: { idEvaluacionModulo, moduloVersionId }
});

export const deleteNotaEvaluacionModulo = (params) => ({
  type: DELETE_NOTA_EVALUACION_MODULO,
  payload: params
});
