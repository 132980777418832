export const GET_SALAS = "GET_SALAS";
export const GET_SALAS_SUCCESS = "GET_SALAS_SUCCESS";
export const UPDATE_SALA = "UPDATE_SALA";
export const ADD_NEW_SALA = "ADD_NEW_SALA";
export const GET_TIPO_SALAS = "GET_TIPO_SALAS";
export const GET_TIPO_SALAS_SUCCESS = "GET_TIPO_SALAS_SUCCESS";
export const GET_SEDES = "GET_TIPO_SALAS";
export const GET_SEDES_SUCCESS = "GET_SEDES_SUCCESS";
export const GET_EQUIPAMIENTO = "GET_EQUIPAMIENTO";
export const GET_EQUIPAMIENTO_SUCCESS = "GET_EQUIPAMIENTO_SUCCESS";
export const GET_SALAS_BY_SEDE = "GET_SALAS_BY_SEDE";
export const GET_SALAS_BY_SEDE_SUCCESS = "GET_SALAS_BY_SEDE_SUCCESS";
export const ADD_NEW_EQUIPAMIENTO = "ADD_NEW_EQUIPAMIENTO";
export const ADD_NEW_SALAEQUIPAMIENTO = "ADD_NEW_SALAEQUIPAMIENTO";
export const GET_SALAEQUIPAMIENTO = "GET_SALAEQUIPAMIENTO";
export const GET_SALAEQUIPAMIENTO_SUCCESS = "GET_SALAEQUIPAMIENTO_SUCCESS";
export const DELETE_SALAEQUIPAMIENTO = "DELETE_SALAEQUIPAMIENTO";
export const GET_NEWEQUIPAMIENTO_SUCCESS = "GET_NEWEQUIPAMIENTO_SUCCESS";
export const GET_SALAS_VIRTUALES = "GET_SALAS_VIRTUALES";
export const GET_SALAS_VIRTUALES_SUCCESS = "GET_SALAS_VIRTUALES_SUCCESS";
