import { takeEvery, call, put, takeLatest, all, fork} from "redux-saga/effects"
import { toast } from 'react-toastify';

import {
    GET_AYUDANTES,
    ADD_NEW_AYUDANTE,
    GET_AYUDANTESADDED,
    GET_AYUDANTE_BY_RUT
} from './actionTypes'

import { getAyudantes, addNewAyudante, postFile, getAyudanteByRut} from "../../helpers/backend_helper";
import { getAyudantesSuccess, getAyudanteAdded, getAyudanteByRutSuccess } from "./actions";


function* fetchAyudantes({payload : params}){
    try {
        const response = yield call(getAyudantes, params)
        yield put(getAyudantesSuccess(response))
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
    
}

function* addAyudante({payload: {ayudante, params, formData, formData1, formData2}}){
    try {

        if(formData){
            const responseFile = yield call(postFile, formData);
            ayudante.ArchivoAlumnoRegularId = responseFile.data.fileId
        }
        if(formData1){
            const responseFile1 = yield call(postFile, formData1);
            ayudante.ArchivoCiId = responseFile1.data.fileId
        }
        if(formData2){
            const responseFile2 = yield call(postFile, formData2);
            ayudante.ArchivoCvId = responseFile2.data.fileId
        }                

        const response = yield call(addNewAyudante, ayudante)
        yield put(getAyudanteAdded(response.data.objAyudante))
        const responseAyudantes = yield call(getAyudantes, params)
        yield put(getAyudantesSuccess(responseAyudantes))
        toast.success("Ayudante creado con éxito", { autoClose: 3000 });
    } catch (error) {
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}

function* fetchAyudanteByRut({payload: rut}){
    try {
      const response = yield call(getAyudanteByRut, rut)
      yield put(getAyudanteByRutSuccess(response.data))
    } catch (error) {
      // yield put(getEventsFail(error))
    }
  }

function* ayudantesSaga() {
    yield takeEvery(GET_AYUDANTES, fetchAyudantes)   
    yield takeLatest(ADD_NEW_AYUDANTE, addAyudante)
    yield takeEvery(GET_AYUDANTE_BY_RUT, fetchAyudanteByRut)
}

export default ayudantesSaga