import { POST_MOVER_ARCHIVOS_PENTAHO, POST_MOVER_ARCHIVOS_PENTAHO_FAIL, POST_MOVER_ARCHIVOS_PENTAHO_SUCCESS, RESET_MOVER_ARCHIVOS_PENTAHO } from "./actionTypes"

const INIT_STATE = {
  resultado: false,
  errores: [],
  loading: false
}

const ControlGestion = (state = INIT_STATE, action) => {
  switch (action.type) {
    case POST_MOVER_ARCHIVOS_PENTAHO:
      return {
        ...state,
        errores: [],
        resultado: false,
        loading: true,
      }
    case POST_MOVER_ARCHIVOS_PENTAHO_SUCCESS:
      return {
        ...state,
        loading: false,
        resultado: true,
      }
    case POST_MOVER_ARCHIVOS_PENTAHO_FAIL:
      return {
        ...state,
        loading: false,
        resultado: false,
        errores: action.payload,
      }
    case RESET_MOVER_ARCHIVOS_PENTAHO:
      return INIT_STATE
    default:
      return state
  }
}

export default ControlGestion