import { put, call, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

import { GET_FILE, GET_FILE_BYPATHFILENAME, GET_IMG_FILE, POST_FILE, POST_MULTIPLE_FILE } from "./actionTypes";
import {
  getFileById,
  getFileByPathFilename,
  getImgFile,
  postFile,
  postMulitpleFile
} from "../../helpers/backend_helper";
import { postFileSuccess } from "./actions";
import FileSaver from "file-saver";

function* sendFile({ payload: { params, callback } }) {
  try {
    const response = yield call(postFile, params);
    yield put(postFileSuccess(response));
    callback && callback(response.data);
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* sendMultipleFile({ payload: params }) {
  //console.log(params)
  try {
    const response = yield call(postMulitpleFile, params);
    yield put(postFileSuccess(response));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchFile({ payload: id }) {
  try {
    const response = yield call(getFileById, id);
    const contentDisposition = response.headers["content-disposition"];

    let fileName = "descarga";

    if (contentDisposition) {
      contentDisposition.match(/filename="(.+)"|filename=(.+);/).forEach(element => {
        if (element && !element.includes("filename=")) {
          fileName = element
        }
      });
    }

    FileSaver.saveAs(response.data, fileName);
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchImgFile({ payload: { id, callback } }) {
  try {
    const response = yield call(getImgFile, id);
    if (callback)
      callback(response.data);
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchFileByPathFilename({ payload: params }) {
  try {
    const response = yield call(getFileByPathFilename, params);
    FileSaver.saveAs(response.data, params.filename);
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* FileSaga() {
  yield takeLatest(POST_FILE, sendFile);
  yield takeLatest(POST_MULTIPLE_FILE, sendMultipleFile);
  yield takeLatest(GET_FILE, fetchFile);
  yield takeLatest(GET_FILE_BYPATHFILENAME, fetchFileByPathFilename);
  yield takeLatest(GET_IMG_FILE, fetchImgFile);
}

export default FileSaga;
