import { GET_FACTURABYRANGO,
         GET_FACTURABYRANGO_SUCCESS,

         GET_FACTURABYNUMFACTURA,
         GET_FACTURABYNUMFACTURA_SUCCESS,

         GET_FACTURAMOVIMIENTOSBYNUMFACTURA,
         GET_FACTURAMOVIMIENTOSBYNUMFACTURA_SUCCESS,
         
         GET_FACTURACUOTASBYNUMFACTURA,
         GET_FACTURACUOTASBYNUMFACTURA_SUCCESS,

         GET_FACTURAHISTORICOMEDIOPAGOSBYNUMFACTURA,
         GET_FACTURAHISTORICOMEDIOPAGOSBYNUMFACTURA_SUCCESS,
         
         GET_LISTAESTADOFACTURA,
         GET_LISTAESTADOFACTURA_SUCCESS,

         GET_LISTASUBESTADOFACTURA,
         GET_LISTASUBESTADOFACTURA_SUCCESS ,
        
         GET_LISTATIPOSEGUIMIENTOMANUAL,
         GET_LISTATIPOSEGUIMIENTOMANUAL_SUCCESS,
         
         GET_LISTAORGANISMOS,
         GET_LISTAORGANISMOS_SUCCESS,

         GET_LISTABANCOS,
         GET_LISTABANCOS_SUCCESS,

        GET_LISTACUENTACORRIENTE,
        GET_LISTACUENTACORRIENTE_SUCCESS,

        GET_SEGUIMIENTOSFACTURABYNUMFACTURA,
        GET_SEGUIMIENTOSFACTURABYNUMFACTURA_SUCCESS,

        GET_MONTOSFACTURASDASHBOARD,
        GET_MONTOSFACTURASDASHBOARD_SUCCESS,

        GET_MONTOSFACTURASPAGADASCONMEDIODEPAGODASHBOARD,
        GET_MONTOSFACTURASPAGADASCONMEDIODEPAGODASHBOARD_SUCCESS,

       GET_MONTOSFACTURASMOROSASSINMEDIODEPAGODASHBOARD,
       GET_MONTOSFACTURASMOROSASSINMEDIODEPAGODASHBOARD_SUCCESS,

        GET_FACTURASEMITIDASDASHBOARDPORRANGOFECHAS,
        GET_FACTURASEMITIDASDASHBOARDPORRANGOFECHAS_SUCCESS,

        GET_FACTURASEMITIDASPAGADASDASHBOARDPORRANGOFECHAS,
        GET_FACTURASEMITIDASPAGADASDASHBOARDPORRANGOFECHAS_SUCCESS,

        GET_FACTURASEMITIDASVERSUSCOMPORTAMIENTODEPAGODASHBOARDPORRANGOFECHAS,
        GET_FACTURASEMITIDASVERSUSCOMPORTAMIENTODEPAGODASHBOARDPORRANGOFECHAS_SUCCESS,

        GET_GESTIONCOBRANZAFACTURASMOROSASPORRANGOFECHAS,
        GET_GESTIONCOBRANZAFACTURASMOROSASPORRANGOFECHAS_SUCCESS,

        ADD_GESTIONSEGUIMIENTOCOBRANZA,
        ADD_GESTIONSEGUIMIENTOCOBRANZA_ADDED, 

} from './actionTypes'

export const getFacturasByRango = (params) => ({
  type: GET_FACTURABYRANGO,
  payload: params
});

export const getFacturasByRangoSuccess = (data) => ({
    type: GET_FACTURABYRANGO_SUCCESS,
    payload: data
});

export const getFacturaByNumFactura = (params) => ({
  type: GET_FACTURABYNUMFACTURA,
  payload: params
});

export const getFacturaByNumFacturaSuccess = (data) => ({
  type: GET_FACTURABYNUMFACTURA_SUCCESS,
  payload: data
});


export const getFacturaMovimientosByNumFactura = (params) => ({
  type: GET_FACTURAMOVIMIENTOSBYNUMFACTURA,
  payload: params
});

export const getFacturaMovimientosByNumFacturaSuccess = (data) => ({
  type: GET_FACTURAMOVIMIENTOSBYNUMFACTURA_SUCCESS,
  payload: data
});


export const getFacturaCuotasByNumFactura = (params) => ({
  type: GET_FACTURACUOTASBYNUMFACTURA,
  payload: params
});

export const getFacturaCuotasByNumFacturaSuccess = (data) => ({
  type: GET_FACTURACUOTASBYNUMFACTURA_SUCCESS,
  payload: data
});


export const getFacturaHistoricoMedioPagosByNumFactura = (params) => ({
  type: GET_FACTURAHISTORICOMEDIOPAGOSBYNUMFACTURA,
  payload: params
});

export const getFacturaHistoricoMedioPagosByNumFacturaSuccess = (data) => ({
  type: GET_FACTURAHISTORICOMEDIOPAGOSBYNUMFACTURA_SUCCESS,
  payload: data
});

export const getListaEstadoFactura = () => ({
  type: GET_LISTAESTADOFACTURA
});

export const getListaEstadoFacturaSuccess = (data) => ({
  type: GET_LISTAESTADOFACTURA_SUCCESS,
  payload: data
});

export const getListaSubEstadoFactura = () => ({
  type: GET_LISTASUBESTADOFACTURA
});

export const getListaSubEstadoFacturaSuccess = (data) => ({
  type: GET_LISTASUBESTADOFACTURA_SUCCESS,
  payload: data
});

export const getListaTipoSeguimientoManual = () => ({
  type: GET_LISTATIPOSEGUIMIENTOMANUAL
});

export const getListaTipoSeguimientoManualSuccess = (data) => ({
  type: GET_LISTATIPOSEGUIMIENTOMANUAL_SUCCESS,
  payload: data
});


export const getListaOrganismos = () => ({
  type: GET_LISTAORGANISMOS
});

export const getListaOrganismosSuccess = (data) => ({
  type: GET_LISTAORGANISMOS_SUCCESS,
  payload: data
});


export const getListaBancos = (params) => ({
  type: GET_LISTABANCOS,
  payload: params
});

export const getListaBancosSuccess = (data) => ({
  type: GET_LISTABANCOS_SUCCESS,
  payload: data
});

export const getListaCuentaCorriente = (params) => ({
  type: GET_LISTACUENTACORRIENTE,
  payload: params
});

export const getListaCuentaCorrienteSuccess = (data) => ({
  type: GET_LISTACUENTACORRIENTE_SUCCESS,
  payload: data
});

export const getSeguimientosFacturaByNumFactura = (params) => ({
  type: GET_SEGUIMIENTOSFACTURABYNUMFACTURA,
  payload: params
});

export const getSeguimientosFacturaByNumFacturaSuccess = (data) => ({
    type: GET_SEGUIMIENTOSFACTURABYNUMFACTURA_SUCCESS,
    payload: data
});



export const  getMontosFacturasDashboard = (params) => ({
  type: GET_MONTOSFACTURASDASHBOARD,
  payload: params
});


export const getMontosFacturasDashboardSuccess = (data) => ({
  type: GET_MONTOSFACTURASDASHBOARD_SUCCESS,
  payload: data
});

export const  getMontosFacturasPagadasConMedioDePagoDashboard = (params) => ({
  type: GET_MONTOSFACTURASPAGADASCONMEDIODEPAGODASHBOARD,
  payload: params
});


export const  getMontosFacturasPagadasConMedioDePagoDashboardSuccess = (data) => ({
  type: GET_MONTOSFACTURASPAGADASCONMEDIODEPAGODASHBOARD_SUCCESS,
  payload: data
});


export const  getMontosFacturasMorosasSinMedioDePagoDashboard = (params) => ({
  type: GET_MONTOSFACTURASMOROSASSINMEDIODEPAGODASHBOARD,
  payload: params
});


export const  getMontosFacturasMorosasSinMedioDePagoDashboardSuccess = (data) => ({
  type: GET_MONTOSFACTURASMOROSASSINMEDIODEPAGODASHBOARD_SUCCESS,
  payload: data
});


export const  getFacturasEmitidasDashboardPorRangoFechas = (params) => ({
  type: GET_FACTURASEMITIDASDASHBOARDPORRANGOFECHAS,
  payload: params
});


export const  getFacturasEmitidasDashboardPorRangoFechasSuccess = (data) => ({
  type: GET_FACTURASEMITIDASDASHBOARDPORRANGOFECHAS_SUCCESS,
  payload: data
});

export const  getFacturasEmitidasPagadasDashboardPorRangoFechas = (params) => ({
  type: GET_FACTURASEMITIDASPAGADASDASHBOARDPORRANGOFECHAS,
  payload: params
});

export const  getFacturasEmitidasPagadasDashboardPorRangoFechasSuccess = (data) => ({
  type: GET_FACTURASEMITIDASPAGADASDASHBOARDPORRANGOFECHAS_SUCCESS,
  payload: data
});


export const  getFacturasEmitidasVersusComportamientoDePagoDashboardPorRangoFechas = (params) => ({
  type: GET_FACTURASEMITIDASVERSUSCOMPORTAMIENTODEPAGODASHBOARDPORRANGOFECHAS,
  payload: params
});

export const  getFacturasEmitidasVersusComportamientoDePagoDashboardPorRangoFechasSuccess = (data) => ({
  type: GET_FACTURASEMITIDASVERSUSCOMPORTAMIENTODEPAGODASHBOARDPORRANGOFECHAS_SUCCESS,
  payload: data
});


export const  getGestionCobranzaFacturasMorosasPorRangoFechas = (params) => ({
  type: GET_GESTIONCOBRANZAFACTURASMOROSASPORRANGOFECHAS,
  payload: params
});

export const  getGestionCobranzaFacturasMorosasPorRangoFechasSuccess = (data) => ({
  type: GET_GESTIONCOBRANZAFACTURASMOROSASPORRANGOFECHAS_SUCCESS,
  payload: data
});


export const addGestionSeguimientoCobranza = (params, callback) => ({
  type: ADD_GESTIONSEGUIMIENTOCOBRANZA,
  payload: {params,callback}
});

export const addGestionSeguimientoCobranzaAdded = (data) => ({
  type: ADD_GESTIONSEGUIMIENTOCOBRANZA_ADDED,
  payload: data
});