export const GET_CARGAS_CORPORATIVOS = "GET_CARGAS_CORPORATIVOS";
export const GET_CARGAS_CORPORATIVOS_SUCCESS = "GET_CARGAS_CORPORATIVOS_SUCCESS";
export const GET_VIAJES = "GET_VIAJES";
export const GET_VIAJES_SUCCESS = "GET_VIAJES_SUCCESS";
export const GET_VIAJE = "GET_VIAJE";
export const GET_VISTA_PREVIA_VIAJE = "GET_VISTA_PREVIA_VIAJE";
export const ADD_NEW_CARGA_CORPORATIVO = "ADD_NEW_CARGA_CORPORATIVO";
export const ADD_NEW_VIAJE_PARTICULAR = "ADD_NEW_VIAJE_PARTICULAR";
export const ADD_NEW_DESCUENTO_VIAJE = "ADD_NEW_DESCUENTO_VIAJE";
export const ADD_NEW_FINANCIAMIENTO_VIAJE = "ADD_NEW_FINANCIAMIENTO_VIAJE";
export const ADD_DOCUMENTOS_VIAJE = "ADD_DOCUMENTOS_VIAJE";
export const UPDATE_ESTADO_VIAJE = "UPDATE_ESTADO_VIAJE";
export const UPDATE_VIAJE_FINANCIAMIENTO = "UPDATE_VIAJE_FINANCIAMIENTO";
export const UPDATE_VIAJE_FINANCIAMIENTO_FACTURA = "UPDATE_VIAJE_FINANCIAMIENTO_FACTURA";
export const UPDATE_VIAJE_DESCUENTO = "UPDATE_VIAJE_DESCUENTO";
export const ENVIA_FACTURA_FINANCIAMIENTO = "ENVIA_FACTURA_FINANCIAMIENTO";