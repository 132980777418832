import { PROFILE_ERROR, PROFILE_SUCCESS, EDIT_PROFILE, RESET_PROFILE_FLAG, PROFILE_MENU_SUCCESS, PROFILE_MENU, RESET_PROFILE, PROFILE_DATA, GET_NOTIFICACIONES, UPDATE_NOTIFICACION, GET_NOTIFICACIONES_SUCCESS, GET_USERS_AVATARS, GET_USERS_AVATARS_SUCCESS, PROFILE_DATA_SUCCESS, PROFILE_USER_INFO, PROFILE_USER_INFO_SUCCESS, ADD_NEW_PROFILE_PICTURE, ADD_NEW_PROFILE_PICTURE_SUCCESS, UPDATE_USER_INFO, UPDATE_USER_INFO_SUCCESS, DELETE_PROFILE_PICTURE, ADD_NEW_MAILING_PICTURE, ADD_NEW_MAILING_PICTURE_SUCCESS, DELETE_MAILING_PICTURE } from "./actionTypes"

export const editProfile = user => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  }
}

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
}

export const profileMenu = user => {
  return {
    type: PROFILE_MENU,
    payload: user
  }
}

export const profileMenuSuccess = menu => {
  return {
    type: PROFILE_MENU_SUCCESS,
    payload: menu
  }
}

export const profileData = user => {
  return {
    type: PROFILE_DATA,
    payload: user
  }
}

export const profileUserInfo = () => {
  return {
    type: PROFILE_USER_INFO
  }
}

export const profileUserInfoSuccess = data => {
  return {
    type: PROFILE_USER_INFO_SUCCESS,
    payload: data
  }
}

export const resetProfile = () => {
  return {
    type: RESET_PROFILE
  }
}

export const getNotificaciones = user => {
  return {
    type: GET_NOTIFICACIONES,
    payload: user
  }
}

export const getNotificacionesSuccess = notificaciones => {
  return {
    type: GET_NOTIFICACIONES_SUCCESS,
    payload: notificaciones
  }
}

export const updateNotificacion = id => {
  return {
    type: UPDATE_NOTIFICACION,
    payload: id
  }
}

export const getUsersAvatars = (users) => {
  return {
    type: GET_USERS_AVATARS,
    payload: users
  }
}

export const getUsersAvatarsSuccess = (data) => {
  return {
    type: GET_USERS_AVATARS_SUCCESS,
    payload: data
  }
}

export const addNewProfilePicture = picture => {
  return {
    type: ADD_NEW_PROFILE_PICTURE,
    payload: picture
  }
}

export const addNewProfilePictureSuccess = picture => {
  return {
    type: ADD_NEW_PROFILE_PICTURE_SUCCESS,
    payload: picture
  }
}

export const addNewMailingPicture = picture => {
  return {
    type: ADD_NEW_MAILING_PICTURE,
    payload: picture
  }
}

export const addNewMailingPictureSuccess = picture => {
  return {
    type: ADD_NEW_MAILING_PICTURE_SUCCESS,
    payload: picture
  }
}

export const updateUserInfo = user => {
  return {
    type: UPDATE_USER_INFO,
    payload: user
  }
}

export const updateUserInfoSuccess = user => {
  return {
    type: UPDATE_USER_INFO_SUCCESS,
    payload: user
  }
}

export const deleteProfilePicture = () => {
  return {
    type: DELETE_PROFILE_PICTURE
  }
}

export const deleteMailingPicture = () => {
  return {
    type: DELETE_MAILING_PICTURE
  }
}