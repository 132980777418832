import { call, put, takeLatest } from "redux-saga/effects";
import { getCargasCorporativosSuccess, getViajesSuccess } from "./actions";
import { toast } from "react-toastify";
import { ADD_DOCUMENTOS_VIAJE, ADD_NEW_CARGA_CORPORATIVO, ADD_NEW_DESCUENTO_VIAJE, ADD_NEW_FINANCIAMIENTO_VIAJE, ADD_NEW_VIAJE_PARTICULAR, ENVIA_FACTURA_FINANCIAMIENTO, GET_CARGAS_CORPORATIVOS, GET_VIAJE, GET_VIAJES, GET_VISTA_PREVIA_VIAJE, UPDATE_ESTADO_VIAJE, UPDATE_VIAJE_DESCUENTO, UPDATE_VIAJE_FINANCIAMIENTO, UPDATE_VIAJE_FINANCIAMIENTO_FACTURA } from "./actionTypes";
import { addDocumentosViaje, addNewCargaCorporativo, addNewDescuentoViaje, addNewFinanciamientoViaje, addNewViajeParticular, enviaFacturaFinanciamiento, getCargasCorporativos, getViaje, getViajes, getVistaPreviaViaje, postFile, updateEstadoViaje, updateViajeDescuento, updateViajeFinanciamiento, updateViajeFinanciamientoFactura } from "../../helpers/backend_helper";
import { isNil } from "ramda";
import FileSaver from "file-saver";
import { Spinner } from "reactstrap";

function* fetchCargasCorporativos({ payload: params }) {
  try {
    const response = yield call(getCargasCorporativos, params);
    yield put(getCargasCorporativosSuccess(response));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
  }
}

function* fetchViajes({ payload: params }) {
  try {
    const response = yield call(getViajes, params);
    yield put(getViajesSuccess(response));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
  }
}

function* fetchViaje({ payload: { params, callback } }) {
  try {
    const response = yield call(getViaje, params);
    callback && callback(response.data);
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
  }
}

const MensajeCargandoToast = () => (
  <div className="d-flex align-items-center fs-16">
    Cargando <Spinner className={"ms-3"} size={"md"} color="info" />
  </div>
);

function* fetchVistaPreviaViaje({ payload: params }) {
  try {
    toast(<MensajeCargandoToast />, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: false,
      toastId: "fetchVistaPreviaViajeToast"
    })
    const response = yield call(getVistaPreviaViaje, params);
    const blob = new Blob([response.data], { type: 'application/pdf' });
    const fileName = response.headers['content-disposition']
      ? response.headers['content-disposition'].split('filename=')[1].split(";")[0]
      : 'vista_previa_viaje.pdf';

    FileSaver.saveAs(blob, fileName);
    toast.dismiss("fetchVistaPreviaViajeToast");
  } catch (error) {
    toast.dismiss("fetchVistaPreviaViajeToast");
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
  }
}

function* postCargaCorporativo({ payload: { cargaCorporativo, file, callback } }) {
  try {
    if (!isNil(file)) {
      const respFile = yield call(postFile, file)
      cargaCorporativo = { ...cargaCorporativo, archivoCargaId: respFile.data.fileId }
    }
    const response = yield call(addNewCargaCorporativo, cargaCorporativo);
    callback && callback();
    toast.success("Carga realizada correctamente", {
      position: toast.POSITION.TOP_RIGHT,
    });
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* postViajeParticular({ payload: { viajeParticular, callback } }) {
  try {
    const response = yield call(addNewViajeParticular, viajeParticular);
    toast.success("Matrícula creada correctamente", {
      position: toast.POSITION.TOP_RIGHT,
    });
    callback && callback();
  }
  catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* postDescuentoViaje({ payload: { descuentoViaje, file, callback } }) {
  try {
    if (!isNil(file)) {
      const respFile = yield call(postFile, file)
      descuentoViaje = { ...descuentoViaje, archivoRespaldoId: respFile.data.fileId }
    }
    const response = yield call(addNewDescuentoViaje, descuentoViaje);
    toast.success("Descuento creado correctamente", {
      position: toast.POSITION.TOP_RIGHT,
    });
    callback && callback();
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* postFinanciamientoViaje({ payload: { financiamientoViaje, callback } }) {
  try {
    const response = yield call(addNewFinanciamientoViaje, financiamientoViaje);
    toast.success("Financiamiento creado correctamente", {
      position: toast.POSITION.TOP_RIGHT,
    });
    callback && callback();
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* postDocumentosViaje({ payload: { params, callback } }) {
  try {
    let fileId = null;
    if (!isNil(params.file)) {
      const respFile = yield call(postFile, params.file)
      params.documentosViaje = { ...params.documentosViaje, archivoId: respFile.data.fileId }
      fileId = respFile.data.fileId;
    }
    const response = yield call(addDocumentosViaje, params.documentosViaje);
    toast.success(!isNil(params.file) ? "Documento cargado correctamente" : "Documento eliminado correctamente", {
      position: toast.POSITION.TOP_RIGHT,
    });
    callback && callback(fileId);
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* putViajeEstado({ payload: { viaje, callback } }) {
  try {
    const response = yield call(updateEstadoViaje, viaje);
    toast.success("Estado actualizado correctamente", {
      position: toast.POSITION.TOP_RIGHT,
    });
    callback && callback();
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* putViajeFinanciamiento({ payload: { viajeFinanciamiento, callback } }) {
  try {
    const response = yield call(updateViajeFinanciamiento, viajeFinanciamiento);
    toast.success("Financiamiento actualizado correctamente", {
      position: toast.POSITION.TOP_RIGHT,
    });
    callback && callback();
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* putViajeFinanciamientoFactura({ payload: { viajeFinanciamiento, file, callback } }) {
  try {
    const respFile = yield call(postFile, file)
    viajeFinanciamiento = { ...viajeFinanciamiento, archivoFacturaId: respFile.data.fileId }
    const response = yield call(updateViajeFinanciamientoFactura, viajeFinanciamiento);
    toast.success("Factura actualizada correctamente", {
      position: toast.POSITION.TOP_RIGHT,
    });
    callback && callback();
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* putViajeDescuento({ payload: { viajeDescuento, callback } }) {
  try {
    const response = yield call(updateViajeDescuento, viajeDescuento);
    toast.success("Descuento actualizado correctamente", {
      position: toast.POSITION.TOP_RIGHT,
    });
    callback && callback();
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* sendFacturaFinanciamiento({ payload: { financiamientoId, callback } }) {
  try {
    const response = yield call(enviaFacturaFinanciamiento, { financiamientoId });
    toast.success("Factura enviada correctamente", {
      position: toast.POSITION.TOP_RIGHT,
    });
    callback && callback();
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* viajesSaga() {
  yield takeLatest(GET_CARGAS_CORPORATIVOS, fetchCargasCorporativos);
  yield takeLatest(GET_VIAJES, fetchViajes);
  yield takeLatest(GET_VIAJE, fetchViaje);
  yield takeLatest(GET_VISTA_PREVIA_VIAJE, fetchVistaPreviaViaje);
  yield takeLatest(ADD_NEW_CARGA_CORPORATIVO, postCargaCorporativo);
  yield takeLatest(ADD_NEW_VIAJE_PARTICULAR, postViajeParticular);
  yield takeLatest(ADD_NEW_DESCUENTO_VIAJE, postDescuentoViaje);
  yield takeLatest(ADD_NEW_FINANCIAMIENTO_VIAJE, postFinanciamientoViaje);
  yield takeLatest(ADD_DOCUMENTOS_VIAJE, postDocumentosViaje);
  yield takeLatest(UPDATE_ESTADO_VIAJE, putViajeEstado);
  yield takeLatest(UPDATE_VIAJE_FINANCIAMIENTO, putViajeFinanciamiento);
  yield takeLatest(UPDATE_VIAJE_FINANCIAMIENTO_FACTURA, putViajeFinanciamientoFactura);
  yield takeLatest(UPDATE_VIAJE_DESCUENTO, putViajeDescuento);
  yield takeLatest(ENVIA_FACTURA_FINANCIAMIENTO, sendFacturaFinanciamiento);
}

export default viajesSaga;