import axios from "axios";
import { api, app } from "../config";

// default
axios.defaults.baseURL = api.API_URL;
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

const token = JSON.parse(localStorage.getItem("authUser")) ? JSON.parse(localStorage.getItem("authUser")).token : null;
if(token)
axios.defaults.headers.common["Authorization"] = "Bearer " + token;

// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    if (response.headers['x-version'] > app.version) { // if server version newer
      window.localStorage.setItem('version-update-needed', 'true'); // set version update item so can refresh app later
    }
    return response;
  },
  function (error) {
    let response = '';
    if(error.response === undefined) response = 'Error al conectar con el servidor'
    else if(error.response.status === 500) response = 'Error en la petición al servidor'
    else response = error.response.data ? error.response.data : "Error al obtener los datos"
    return Promise.reject(response);
  }
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class APIClient {
  /**
   * Fetches data from given url
   */

  //  get = (url, params) => {
  //   return axios.get(url, params);
  // };
  get = (url, params, options) => {
    let response;

    let paramKeys = [];

    if (params) {
      Object.keys(params).map(key => {
        paramKeys.push(key + '=' + params[key]);
        return paramKeys;
      });

      const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : "";
      response = axios.get(`${url}?${queryString}`, {...options});
    } else {
      response = axios.get(`${url}`, {...options});
    }

    return response;
  };
  /**
   * post given data to url
   */
  create = (url, data, options) => {
    // return axios.post(url, data, {
    //   headers: {
    //     'Content-Type': _headers
    //   },
    //   responseType: _responseType
    // });
    return axios.post(url, data, options);
  };
  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.patch(url, data);
  };

  put = (url, data) => {
    return axios.put(url, data);
  };
  /**
   * Delete
   */
  delete = (url, config) => {
    return axios.delete(url, { ...config });
  };
}
const getLoggedinUser = () => {
  const user = localStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

export { APIClient, setAuthorization, getLoggedinUser };