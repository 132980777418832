export const GET_PROSPECTOS = "GET_PROSPECTOS";
export const GET_PROSPECTOS_BY_EMAIL = "GET_PROSPECTOS_BY_EMAIL";
export const GET_PROSPECTOS_SUCCESS = "GET_PROSPECTOS_SUCCESS";
export const GET_PROSPECTOS_CONTADOR = "GET_PROSPECTOS_CONTADOR";
export const GET_PROSPECTOS_CONTADOR_SUCCESS = "GET_PROSPECTOS_CONTADOR_SUCCESS";
export const GET_PROSPECTO_BY_GUID = "GET_PROSPECTO_BY_GUID";
export const GET_PROSPECTO_BY_GUID_SUCCESS = "GET_PROSPECTO_BY_GUID_SUCCESS";
export const GET_PROSPECTO_PREVIEW_ENVIA_INFORMACION = "GET_PROSPECTO_PREVIEW_ENVIA_INFORMACION";
export const GET_PROSPECTO_PREVIEW_ENVIA_INFORMACION_SUCCESS = "GET_PROSPECTO_PREVIEW_ENVIA_INFORMACION_SUCCESS";
export const UPDATE_PROSPECTO = "UPDATE_PROSPECTO";
export const UPDATE_EJECUTIVO_PROSPECTO = "UPDATE_EJECUTIVO_PROSPECTO";
export const UPDATE_DESCUENTO_PROSPECTO = "UPDATE_DESCUENTO_PROSPECTO";
export const UPDATE_VERSION_PROGRAMA_PROSPECTO = "UPDATE_VERSION_PROGRAMA_PROSPECTO";
export const ADD_NEW_PROSPECTO = "ADD_NEW_PROSPECTO";
export const ADD_NEW_SEGUIMIENTO_PROSPECTO = "ADD_NEW_SEGUIMIENTO_PROSPECTO";
export const ADD_NEW_FICHA_POSTULACION = "ADD_NEW_FICHA_POSTULACION";
export const ADD_NEW_DESCUENTO_PROSPECTO = "ADD_NEW_DESCUENTO_PROSPECTO";
export const POST_PROSPECTO_ENVIA_INFORMACION = "POST_PROSPECTO_ENVIA_INFORMACION";
export const POST_ENVIA_FICHA_POSTULACION = "POST_ENVIA_FICHA_POSTULACION";
export const POST_PROCESA_MATRICULA_PROSPECTO = "POST_PROCESA_MATRICULA_PROSPECTO";
export const RESET_PREVIEW_ENVIA_INFORMACION = "RESET_PREVIEW_ENVIA_INFORMACION";
export const DELETE_DESCUENTO_PROSPECTO = "DELETE_DESCUENTO_PROSPECTO";