import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

//Calendar
import Calendar from "./calendar/reducer";

//Control de Gestión
import ControlGestion from "./finanza/control-gestion/reducer";

//UserManagement
import UserManagement from "./user-management/reducer";

//Captcha
import Captcha from "./captcha/reducer";

//Empresa
import Empresa from "./empresas/reducer";

//Pagos
import Pagos from "./pagos/reducer";

//ProspectosManagement
import ProspectosManagement from "./prospectos-management/reducer";

//Programas
import Programa from "./programa/reducer";

//Tareas
import Tarea from "./tarea/reducer";

//Estudiantes
import Estudiante from "./estudiante/reducer";

//Finanzas Dashboard
import FinanzasDashboard from "./finanza/dashboard/reducer";


//Files
import File from "./file/reducer";

//Historial
import Historial from "./historial/reducer";

// Operaciones Salas
import OperacionesSalas from "./operaciones/salas/reducer";

//Eventos
import Eventos from "./eventos/reducer";

//Academicos
import Academicos from "./academicos/reducer";

//Fechas
import Fechas from "./fechas/reducer";

//Monedas
import Monedas from "./moneda/reducer";

//Notificaciones
import Notificaciones from "./notificaciones-management/reducer";

//Premios
import Premios from "./premios/reducer";

//Solicitudes de fondo
import SolicitudesFondo from "./solicitudes-fondo/reducer";

//Ayudantes
import Ayudantes from "./ayudantes/reducer";

//Mantenedores
import Mantenedores from "./mantenedores/reducer";

//Cobranza
import Cobranza from "./cobranza/reducer";

//Sesiones
import Sesiones from "./sesiones/reducer";

//Modulos
import Modulos from "./modulos/reducer";

//Valida Documentos
import ValidaDocumento from "./valida-documento/reducer";

// Versiones Programa
import VersionesPrograma from "./versiones-programa/reducer";

// List
import Lists from "./listas/reducer";

// Viajes
import Viaje from "./viajes/reducer";

// Encuesta
import Encuesta from "./encuesta/reducer";

const rootReducer = combineReducers({
    // public
    Layout,
    Login,
    Account,
    ForgetPassword,
    Profile,
    Calendar,
    ControlGestion,
    UserManagement,
    Captcha,
    Empresa,
    Pagos,
    ProspectosManagement,
    Programa,
    Tarea,
    Estudiante,
    FinanzasDashboard,
    Cobranza,
    Sesiones,
    File,
    Historial,
    OperacionesSalas,
    Eventos,
    Academicos,
    Fechas,
    Premios,
    Monedas,
    Notificaciones,
    SolicitudesFondo,
    Ayudantes,
    Mantenedores,
    Modulos,
    ValidaDocumento,
    VersionesPrograma,
    Lists,
    Viaje,
    Encuesta
});

export default rootReducer;

