export const GET_NOTIFICACIONES_SISTEMA = "GET_NOTIFICACIONES_SISTEMA";
export const GET_NOTIFICACIONES_SISTEMA_SUCCESS = "GET_NOTIFICACIONES_SISTEMA_SUCCESS";
export const GET_MODULOS_NOTIFICACIONES = "GET_MODULOS_NOTIFICACIONES";
export const GET_MODULOS_NOTIFICACIONES_SUCCESS = "GET_MODULOS_NOTIFICACIONES_SUCCESS";

export const ADD_NEW_MODULO_NOTIFICACIONES = "ADD_NEW_MODULO_NOTIFICACIONES"
export const ADD_NEW_TIPO_NOTIFICACION = "ADD_NEW_TIPO_NOTIFICACION"

export const SEND_NOTIFICACION = "SEND_NOTIFICACION"
export const SEND_NOTIFICACION_SUCCESS = "SEND_NOTIFICACION_SUCCESS"
export const SEND_NOTIFICACION_RESET = "SEND_NOTIFICACION_RESET"

export const UPDATE_TIPO_NOTIFICACION = "UPDATE_TIPO_NOTIFICACION"
export const UPDATE_ESTADO_TIPO_NOTIFICACION = "UPDATE_ESTADO_TIPO_NOTIFICACION"