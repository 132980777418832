import {
    GET_PROSPECTO_BY_GUID,
    GET_PROSPECTO_BY_GUID_SUCCESS,
    GET_PROSPECTO_PREVIEW_ENVIA_INFORMACION_SUCCESS,
    GET_PROSPECTOS,
    GET_PROSPECTOS_CONTADOR_SUCCESS,
    GET_PROSPECTOS_SUCCESS,
    RESET_PREVIEW_ENVIA_INFORMACION,
} from './actionTypes'

const INIT_STATE = {
    prospectos: [],
    contadorProspectos: [],
    previewEnvioInformacion : "",
    error: "",
    paginacion: {},
    loading: false,
    prospectoExterno: {},
    loadingProspectoExterno: false
}

const ProspectosManagement = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PROSPECTOS:
            return {
                ...state,
                loading: true,
            }
        case GET_PROSPECTOS_SUCCESS:
            return {
                ...state,
                loading: false,
                prospectos: action.payload.data,
                paginacion: JSON.parse(action.payload.headers['x-pagination'])
            }
        case GET_PROSPECTOS_CONTADOR_SUCCESS:
            return {
                ...state,
                contadorProspectos: action.payload.data
            }
        case GET_PROSPECTO_BY_GUID:
            return {
                ...state,
                loadingProspectoExterno: true
            }
        case GET_PROSPECTO_BY_GUID_SUCCESS:
            return {
                ...state,
                prospectoExterno: action.payload.data,
                loadingProspectoExterno: false
            }
        case GET_PROSPECTO_PREVIEW_ENVIA_INFORMACION_SUCCESS:
            return {
                ...state,
                previewEnvioInformacion: action.payload.data
            }
        case RESET_PREVIEW_ENVIA_INFORMACION:
            return {
                ...state,
                previewEnvioInformacion: ""
            }
        default:
            return state
    }
}

export default ProspectosManagement