
import { GET_ESTUDIANTES_SUCCESS, GET_ESTUDIOS_ESTUDIANTE_SUCCESS, GET_LABORAL_ESTUDIANTE_SUCCESS, GET_ESTUDIANTE_SUCCESS, 
  GET_INFO_ESTUDIANTE_CARGA_CORPORATIVOS_SUCCESS, GET_VIAJES_EN_CURSO_ESTUDIANTE_SUCCESS, GET_MODULOS_VIAJE_SUCCESS, 
  GET_NOTAS_ESTUDIANTE_MODULO_SUCCESS, GET_CERTIFICADO_ALUMNO_REGULAR_SUCCESS, RESET_CERTIFICADO, GET_CERTIFICADO_ALUMNO_NOTAS_SUCCESS,
  GET_ESTUDIANTE_BY_DOCUMENTO_SUCCESS,
  RESET_ESTUDIANTE_BY_DOCUMENTO,
  RESET_ESTUDIANTE, GET_ESTUDIANTE_EXTERNO_SUCCESS, GET_ASISTENCIA_ESTUDIANTE_MODULO_SUCCESS,
  GET_CERTIFICADO_ALUMNO_REGULAR_INTERNO_SUCCESS
 } from "./actionTypes"

const INIT_STATE = {
  estudiantes: [],
  estudiosEstudiante: [],
  laboralEstudiante: {},
  EstudianteArray: [],
  viajesestudiante: [],
  modulosviaje: [],
  notasmodulosviaje: [],
  paginacion: {},
  loading: false,
  infoEstudiantesCargaCorporativos: [],
  certificado: {},
  estudianteByDocumento: {},
  asistenciamodulosviaje: [],
};

const Estudiante = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ESTUDIANTES_SUCCESS:
      return {
        ...state,
        paginacion: JSON.parse(action.payload.headers['x-pagination']),
        estudiantes: action.payload.data,
      };
    case GET_ESTUDIOS_ESTUDIANTE_SUCCESS:
      return {
        ...state,
        estudiosEstudiante: action.payload.data,
      };
    case GET_LABORAL_ESTUDIANTE_SUCCESS:
      return {
        ...state,
        laboralEstudiante: action.payload.data !== "" ? action.payload.data : {},
      };
    case GET_INFO_ESTUDIANTE_CARGA_CORPORATIVOS_SUCCESS:
      if(action.payload.data !== "")
        return {
          ...state,
          infoEstudiantesCargaCorporativos: state.infoEstudiantesCargaCorporativos.filter(estudiante => estudiante.id !== action.payload.data.id).concat(action.payload.data)
        };
      else
        return state;
    case GET_ESTUDIANTE_SUCCESS:
      return {
        ...state,
        EstudianteArray: action.payload.data,
      }
    case GET_ESTUDIANTE_BY_DOCUMENTO_SUCCESS:
      return {
        ...state,
        estudianteByDocumento: action.payload.data
      }
    case GET_VIAJES_EN_CURSO_ESTUDIANTE_SUCCESS:
      return {
        ...state,
        viajesestudiante: action.payload.data,
      };  
    case GET_MODULOS_VIAJE_SUCCESS:
      return {
        ...state,
        modulosviaje: action.payload.data,
      };        
    case GET_NOTAS_ESTUDIANTE_MODULO_SUCCESS:
      return {
        ...state,
        notasmodulosviaje: action.payload.data,
      };     
    case GET_CERTIFICADO_ALUMNO_REGULAR_SUCCESS:
      return {
        ...state,
        certificado: action.payload.data
      }
    case GET_CERTIFICADO_ALUMNO_REGULAR_INTERNO_SUCCESS:
        return {
          ...state,
          certificado: action.payload.data
        }                       
    case RESET_CERTIFICADO:
      return {
        ...state,
        certificado: {}
      }
    case RESET_ESTUDIANTE:
      return {
        ...state,
        EstudianteArray: []
      }
    case RESET_ESTUDIANTE_BY_DOCUMENTO:
      return {
        ...state,
        estudianteByDocumento: {}
      }                        
    case GET_CERTIFICADO_ALUMNO_NOTAS_SUCCESS:
      return {
        ...state,
        certificado: action.payload.data
      }    
    case GET_ESTUDIANTE_EXTERNO_SUCCESS:
      return {
        ...state,
        EstudianteArray: action.payload.data,
      }        
    case GET_ASISTENCIA_ESTUDIANTE_MODULO_SUCCESS:
      return {
        ...state,
        asistenciamodulosviaje: action.payload.data,
      };     
           
    default:
      return state;
  }
};

export default Estudiante; 
