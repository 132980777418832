import { put, call, takeLatest, takeEvery } from "redux-saga/effects"
import { toast } from 'react-toastify';
import { isNil } from 'ramda'
import { app } from "../../config";

import {
  ADD_NEW_ESTUDIANTE,
  ADD_NEW_ESTUDIO_ESTUDIANTE,
  ADD_NEW_LABORAL_ESTUDIANTE,
  GET_ESTUDIANTES,
  GET_ESTUDIOS_ESTUDIANTE,
  GET_LABORAL_ESTUDIANTE,
  UPDATE_ESTUDIANTE,
  GET_ESTUDIANTE,
  GET_ESTUDIANTE_BY_DOCUMENTO,
  GET_INFO_ESTUDIANTE_CARGA_CORPORATIVOS,
  GET_VIAJES_EN_CURSO_ESTUDIANTE,
  GET_MODULOS_VIAJE, 
  GET_NOTAS_ESTUDIANTE_MODULO,
  GET_CERTIFICADO_ALUMNO_REGULAR,
  GET_CERTIFICADO_ALUMNO_NOTAS,
  GET_ESTUDIANTE_EXTERNO,
  GET_ASISTENCIA_ESTUDIANTE_MODULO,
  GET_CERTIFICADO_ALUMNO_REGULAR_INTERNO,
} from './actionTypes'

import {
  getEstudiantesSuccess,
  getEstudiosEstudianteSuccess,
  getLaboralEstudianteSuccess,
  getEstudianteSuccess,
  getInfoEstudianteCargaCorporativosSuccess,
  getViajesEnCursoEstudianteSuccess,
  getModulosViajeSuccess,
  getNotasEstudianteModuloSuccess,
  getCertificadoAlumnoRegularSuccess,
  getCertificadoAlumnoNotasSuccess,
  getEstudianteByDocumentoSuccess,
  getEstudianteExternoSuccess,
  getAsistenciaEstudianteModuloSuccess,
  getCertificadoAlumnoRegularInternoSuccess,
} from './actions'
import { addNewEstudiante, addNewEstudioEstudiante, addNewLaboralEstudiante, getEstudiantes, getEstudiosEstudiante, 
  getLaboralEstudiante, postFile, updateEstudiante, getEstudiante, getEstudianteByDocumento , getViajesEnCursoEstudiante, getModulosViaje, getNotasEstudianteModulo,
  getCertificadoAlumnoRegular, getCertificadoAlumnoNotas, getEstudianteExterno, getAsistenciaEstudianteModulo,
  getCertificadoAlumnoRegularInterno} from "../../helpers/backend_helper";

function* fetchEstudiantes({ payload: params }) {
  try {
    const response = yield call(getEstudiantes, params)
    yield put(getEstudiantesSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* fetchEstudiantesExterno() {
  try {
    const response = yield call(getEstudiantes)
    yield put(getEstudiantesSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* fetchEstudiosEstudiante({ payload: id }) {
  try {
    const response = yield call(getEstudiosEstudiante, { id })
    yield put(getEstudiosEstudianteSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* fetchLaboralEstudiante({ payload: id }) {
  try {
    const response = yield call(getLaboralEstudiante, { id })
    yield put(getLaboralEstudianteSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* fetchEstudianteCargaCorporativos({ payload:  id }) {
  try {
    const response = yield call(getEstudianteByDocumento, { documento: id })
    yield put(getInfoEstudianteCargaCorporativosSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* fetchEstudianteByDocumento({ payload: {documento} }) {
  try {
    const response = yield call(getEstudianteByDocumento, documento)
    yield put(getEstudianteByDocumentoSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* postEstudiante({ payload: { estudiante, formImg, fetchData } }) {
  try {
    if (!isNil(formImg)) {
      const respImg = yield call(postFile, formImg)
      estudiante = { ...estudiante, imgFileId: respImg.data.fileId }
    }
    const response = yield call(addNewEstudiante, estudiante)
    if (fetchData) fetchData(response.data)
    toast.success('Estudiante creado correctamente', {
      position: toast.POSITION.TOP_RIGHT
    });
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* postEstudioEstudiante({ payload: { estudio, fetchData } }) {
  try {
    const response = yield call(addNewEstudioEstudiante, estudio)
    if (fetchData) fetchData()
    toast.success('Estudio creado correctamente', {
      position: toast.POSITION.TOP_RIGHT
    });
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* postLaboralEstudiante({ payload: { laboral, fetchData } }) {
  try {
    const response = yield call(addNewLaboralEstudiante, laboral)
    if (fetchData) fetchData()
    toast.success('Información laboral guardada correctamente', {
      position: toast.POSITION.TOP_RIGHT
    });
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* putEstudiante({ payload: { estudiante, formImg, fetchData } }) {
  try {
    if (!isNil(formImg)) {
      const respImg = yield call(postFile, formImg)
      estudiante = { ...estudiante, imgFileId: respImg.data.fileId }
    }
    
    const response = yield call(updateEstudiante, estudiante)
    if (fetchData && app.site === 'Intranet') fetchData(response.data)
    toast.success('Estudiante actualizado correctamente', {
      position: toast.POSITION.TOP_RIGHT
    });
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* fetchEstudiante({ payload: params }) {
  try {
    const response = yield call(getEstudiante, params)
    yield put(getEstudianteSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* fetchViajesEnCursoEstudiante() {
  try {
    const response = yield call(getViajesEnCursoEstudiante)
    yield put(getViajesEnCursoEstudianteSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* fetchModulosViaje({ payload: viajeId }) {
  try {

    const response = yield call(getModulosViaje, viajeId)
    yield put(getModulosViajeSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* fetchNotasEstudianteModulo({ payload: params }) {
  try {
    const response = yield call(getNotasEstudianteModulo, params)
    yield put(getNotasEstudianteModuloSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* fetchCertificadoAlumnoRegular({ payload: params }) {
  try {
    const response = yield call(getCertificadoAlumnoRegular, params)
    //console.log("response", response)
    yield put(getCertificadoAlumnoRegularSuccess(response))

  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* fetchCertificadoAlumnoRegularInterno({ payload: params }) {
  try {
    const response = yield call(getCertificadoAlumnoRegularInterno, params)
    yield put(getCertificadoAlumnoRegularInternoSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* fetchCertificadoAlumnoNotas({ payload: params }) {
  try {
    const response = yield call(getCertificadoAlumnoNotas, params)
    yield put(getCertificadoAlumnoNotasSuccess(response))

  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* fetchEstudianteExterno({ payload: params }) {
  try {
    const response = yield call(getEstudianteExterno, params)
    yield put(getEstudianteExternoSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* fetchAsistenciaEstudianteModulo({ payload: params }) {
  try {
    const response = yield call(getAsistenciaEstudianteModulo, params)
    yield put(getAsistenciaEstudianteModuloSuccess(response))
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}



function* estudianteSaga() {
  yield takeLatest(GET_ESTUDIANTES, fetchEstudiantes)
  yield takeLatest(GET_ESTUDIOS_ESTUDIANTE, fetchEstudiosEstudiante)
  yield takeLatest(GET_LABORAL_ESTUDIANTE, fetchLaboralEstudiante)
  yield takeLatest(GET_ESTUDIANTE_BY_DOCUMENTO, fetchEstudianteByDocumento)
  yield takeEvery(GET_INFO_ESTUDIANTE_CARGA_CORPORATIVOS, fetchEstudianteCargaCorporativos)
  yield takeLatest(ADD_NEW_ESTUDIANTE, postEstudiante)
  yield takeLatest(ADD_NEW_ESTUDIO_ESTUDIANTE, postEstudioEstudiante)
  yield takeLatest(ADD_NEW_LABORAL_ESTUDIANTE, postLaboralEstudiante)
  yield takeLatest(UPDATE_ESTUDIANTE, putEstudiante)
  yield takeLatest(GET_ESTUDIANTE, fetchEstudiante)
  yield takeLatest(GET_VIAJES_EN_CURSO_ESTUDIANTE, fetchViajesEnCursoEstudiante)
  yield takeLatest(GET_MODULOS_VIAJE, fetchModulosViaje)
  yield takeLatest(GET_NOTAS_ESTUDIANTE_MODULO, fetchNotasEstudianteModulo)
  yield takeLatest(GET_CERTIFICADO_ALUMNO_REGULAR, fetchCertificadoAlumnoRegular)
  yield takeLatest(GET_CERTIFICADO_ALUMNO_REGULAR_INTERNO, fetchCertificadoAlumnoRegularInterno)
  yield takeLatest(GET_CERTIFICADO_ALUMNO_NOTAS, fetchCertificadoAlumnoNotas)
  yield takeLatest(GET_ESTUDIANTE_EXTERNO, fetchEstudianteExterno)
  yield takeLatest(GET_ASISTENCIA_ESTUDIANTE_MODULO, fetchAsistenciaEstudianteModulo)
}

export default estudianteSaga

