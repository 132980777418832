import { GET_EMPRESA_CONTACTOS_SUCCESS, GET_EMPRESAS_SUCCESS, RESET_CONTACTOS_EMPRESA } from "./actionTypes";

const INIT_STATE = {
  empresas: [],
  contactosEmpresa: [],
  paginacion: {},
  loading: false,
};

const Empresa = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EMPRESAS_SUCCESS:
      return {
        ...state,
        paginacion: JSON.parse(action.payload.headers['x-pagination']),
        empresas: action.payload.data,
      };
    case GET_EMPRESA_CONTACTOS_SUCCESS:
      return {
        ...state,
        contactosEmpresa: action.payload.data,
      };
    case RESET_CONTACTOS_EMPRESA:
      return {
        ...state,
        contactosEmpresa: [],
      };
    default:
      return state;
  }
};

export default Empresa; 