const VALID_ENVIRONMENTS = ['local', 'localExterno', 'test', 'testExterno', 'produccion', 'produccionExterno'];
const ENV = process.env.REACT_APP_NODE_ENV || 'local';

if (!VALID_ENVIRONMENTS.includes(ENV)) {
  throw new Error(`Ambiente no válido: ${ENV}`);
}

const baseConfig = {
  path: "/",
  version: 17
};

const environments = {
  local: {
    API_URL: process.env.REACT_APP_API_URL_LOCAL,
    site: "Intranet",
    ambiente: "desarrollo",
    PAYPAL_CLIENT_ID: process.env.REACT_APP_PAYPAL_CLIENT_ID_DEV
  },
  localExterno: {
    API_URL: process.env.REACT_APP_API_URL_LOCAL,
    site: "Externo",
    ambiente: "desarrollo",
    PAYPAL_CLIENT_ID: process.env.REACT_APP_PAYPAL_CLIENT_ID_DEV
  },
  test: {
    API_URL: process.env.REACT_APP_API_URL_TEST,
    site: "Intranet",
    ambiente: "testing",
    PAYPAL_CLIENT_ID: process.env.REACT_APP_PAYPAL_CLIENT_ID_TEST
  },
  testExterno: {
    API_URL: process.env.REACT_APP_API_URL_TEST_EXT,
    site: "Externo",
    ambiente: "testing",
    PAYPAL_CLIENT_ID: process.env.REACT_APP_PAYPAL_CLIENT_ID_TEST
  },
  produccion: {
    API_URL: process.env.REACT_APP_API_URL_PROD,
    site: "Intranet",
    ambiente: "produccion",
    PAYPAL_CLIENT_ID: process.env.REACT_APP_PAYPAL_CLIENT_ID_PROD
  },
  produccionExterno: {
    API_URL: process.env.REACT_APP_API_URL_PROD,
    site: "Externo",
    ambiente: "produccion",
    PAYPAL_CLIENT_ID: process.env.REACT_APP_PAYPAL_CLIENT_ID_PROD
  }
};

const config = {
  api: {
    API_URL: environments[ENV].API_URL
  },
  app: {
    ...baseConfig,
    ...environments[ENV]
  }
};

export const { api, app } = config;
export default config;