export const GET_EVENTO = "GET_EVENTO"
export const GET_EVENTO_SUCCESS = "GET_EVENTO_SUCCESS"
export const GET_EVENTO_ANIO_ALL = "GET_EVENTO_ANIO_ALL"
export const GET_EVENTO_ANIO_ALL_SUCCESS = "GET_EVENTO_ANIO_ALL_SUCCESS"
export const GET_EVENTO_PROXIMOS = "GET_EVENTO_PROXIMOS"
export const GET_EVENTO_PROXIMOS_SUCCESS = "GET_EVENTO_PROXIMOS_SUCCESS"
export const GET_EVENTO_REALIZADOS = "GET_EVENTO_REALIZADOS"
export const GET_EVENTO_REALIZADOS_SUCCESS = "GET_EVENTO_REALIZADOS_SUCCESS"
export const GET_EVENTO_ACTIVIDADES = "GET_EVENTO_ACTIVIDADES"
export const GET_EVENTO_ACTIVIDADES_SUCCESS = "GET_EVENTO_ACTIVIDADES_SUCCESS"
export const GET_CATEGORIAS = "GET_CATEGORIAS"
export const GET_CATEGORIAS_SUCCESS = "GET_CATEGORIAS_SUCCESS"
export const GET_TIPO_EVENTOS = "GET_TIPO_EVENTOS"
export const GET_TIPO_EVENTOS_SUCCESS = "GET_TIPO_EVENTOS_SUCCESS"
export const GET_ITEMS_EVENTO = "GET_ITEMS_EVENTO"
export const GET_ITEMS_EVENTO_SUCCESS = "GET_ITEMS_EVENTO_SUCCESS"
export const GET_EVENTOS_ANIO = "GET_EVENTOS_ANIO"
export const GET_EVENTOS_ANIO_SUCCESS = "GET_EVENTOS_ANIO_SUCCESS"
export const GET_EVENTO_MODALIDADES = "GET_EVENTO_MODALIDADES"
export const GET_EVENTO_MODALIDADES_SUCCESS = "GET_EVENTO_MODALIDADES_SUCCESS"
export const GET_EVENTO_ESTADOS = "GET_EVENTO_ESTADOS"
export const GET_EVENTO_ESTADOS_SUCCESS = "GET_EVENTO_ESTADOS_SUCCESS"
export const GET_EVENTO_ESTADOS_ITEMS = "GET_EVENTO_ESTADOS_ITEMS"
export const GET_EVENTO_ESTADOS_ITEMS_SUCCESS = "GET_EVENTO_ESTADOS_ITEMS_SUCCESS"
export const GET_SALDO_PPTO_CENTRO_DIRECTOR = "GET_SALDO_PPTO_CENTRO_DIRECTOR"
export const GET_SALDO_PPTO_CENTRO_DIRECTOR_SUCCESS = "GET_SALDO_PPTO_CENTRO_DIRECTOR_SUCCESS"
export const GET_SALDO_PPTO_CENTRO = "GET_SALDO_PPTO_CENTRO"
export const GET_SALDO_PPTO_CENTRO_SUCCESS = "GET_SALDO_PPTO_CENTRO_SUCCESS"
export const GET_REPORTE_EVENTOS_ANIO = "GET_REPORTE_EVENTOS_ANIO"
export const GET_REPORTE_EVENTOS_ANIO_SUCCESS = "GET_REPORTE_EVENTOS_ANIO_SUCCESS"
export const GET_EVENTOS_CALENDARIO = "GET_EVENTOS_CALENDARIO"
export const GET_EVENTOS_CALENDARIO_SUCCESS = "GET_EVENTOS_CALENDARIO_SUCCESS"

export const POST_EVENTO = "POST_EVENTO"
export const POST_EVENTO_SUCCESS = "POST_EVENTO_SUCCESS"
export const POST_EVENTO_FAIL = "POST_EVENTO_FAIL"
export const UPDATE_EVENTO = "UPDATE_EVENTO"
export const UPDATE_EVENTO_SUCCESS = "UPDATE_EVENTO_SUCCESS"
export const UPDATE_DETALLE_EVENTO = "UPDATE_DETALLE_EVENTO"
export const UPDATE_DETALLE_EVENTO_SUCCESS = "UPDATE_DETALLE_EVENTO_SUCCESS"
export const UPDATE_GESTION_EVENTO = "UPDATE_GESTION_EVENTO"
export const UPDATE_GESTION_EVENTO_SUCCESS = "UPDATE_GESTION_EVENTO_SUCCESS"
export const UPDATE_ESTADO_EVENTO = "UPDATE_ESTADO_EVENTO"
export const UPDATE_GESTION_MASIVA_EVENTO = "UPDATE_GESTION_MASIVA_EVENTO"
export const UPDATE_CANCELA_EVENTO = "UPDATE_CANCELA_EVENTO"

export const DELETE_DIA_EVENTO = "DELETE_DIA_EVENTO"

export const RESET_EVENTO = "RESET_EVENTO"
export const RESET_REPORTE_EVENTO = "RESET_REPORTE_EVENTO"