
import { GET_TAREAS_SUCCESS } from "./actionTypes"


const INIT_STATE = {
  tareas: [],
  loading: false,
};

const Tarea = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TAREAS_SUCCESS:
      return {
        ...state,
        tareas: action.payload,
      };
    default:
      return state;
  }
};

export default Tarea; 
