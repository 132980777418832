import {

  GET_PROGRAMAS,
  GET_PROGRAMAS_SUCCESS,
  GET_ESTADOS_PROGRAMA_SUCCESS,
  GET_AREAS_PROGRAMA_SUCCESS,
  GET_PROGRAMA_X_COD_BASE,
  GET_PROGRAMA_X_COD_BASE_SUCCESS,
  GET_MODALIDADES_PROGRAMA_SUCCESS,
  GET_TIPOS_PROGRAMA_SUCCESS,
  GET_PROGRAMAS_SIN_DECRETO_SUCCESS,
  GET_PROGRAMA_SUCCESS,
  
  ADD_DATOSWEBPROGRAMA,
  ADD_DATOSWEBPROGRAMA_ADDED,
  ADD_DATOSWEBPROGRAMAVERSIONES,
  ADD_DATOSWEBPROGRAMAVERSIONES_ADDED,

  GET_PROGRAMADATOSWEB,
  GET_PROGRAMADATOSWEB_SUCCESS,
  GET_PROGRAMAVERSIONESDATOSWEB,
  GET_PROGRAMAVERSIONESDATOSWEB_SUCCESS,

  RESET_PROGRAMA,


} from "./actionTypes";

const INIT_STATE = {
  programas: [],
  programa: {},
  error: "",
  paginacion: {},
  arrEstadosPrograma: [],
  arrAreasPrograma: [],
  arrPrograma: [],
  modalidades: [],
  tipos: [],
  loading: false,
  programasSinDecreto: [],
  paginacionSinDecreto: {},
  resultDatosWebProg:{},
  resultDatosWebProgVersiones:{},
  programaDatosWeb: {},
  programaVersionesDatosWeb: {},
  

};

const Programa = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROGRAMAS:
      return {
        ...state,
        loading: true
      }
    case GET_PROGRAMAS_SUCCESS:
      return {
        ...state,
        paginacion: JSON.parse(action.payload.headers['x-pagination']),
        programas: action.payload.data,
      };
    case GET_PROGRAMA_SUCCESS:
      return {
        ...state,
        programa: action.payload.data,
      };
    case GET_ESTADOS_PROGRAMA_SUCCESS:
      return {
        ...state,
        arrEstadosPrograma: action.payload.data
      }
    case GET_AREAS_PROGRAMA_SUCCESS:
      return {
        ...state,
        arrAreasPrograma: action.payload.data
      }
    case GET_PROGRAMA_X_COD_BASE:
      return {
        ...state,
        loading: true
      }
    case GET_PROGRAMA_X_COD_BASE_SUCCESS:
      return {
        ...state,
        arrPrograma: action.payload.data,
      };
    case GET_MODALIDADES_PROGRAMA_SUCCESS:
      return {
        ...state,
        modalidades: action.payload.data,
      }
    case GET_TIPOS_PROGRAMA_SUCCESS:
      return {
        ...state,
        tipos: action.payload.data,
      }
    case GET_PROGRAMAS_SIN_DECRETO_SUCCESS:
      return {
        ...state,
        programasSinDecreto: action.payload.data,
        paginacionSinDecreto: JSON.parse(action.payload.headers['x-pagination']),
      };
    case GET_PROGRAMADATOSWEB:
      return {
        ...state,
        loading: true
      }
    case GET_PROGRAMADATOSWEB_SUCCESS:
      return {
        ...state,
        loading: false,
        programaDatosWeb: action.payload.data,
      };
    case GET_PROGRAMAVERSIONESDATOSWEB:
      return {
        ...state,
        loading: true
      }
    case GET_PROGRAMAVERSIONESDATOSWEB_SUCCESS:
      return {
        ...state,
        loading: false,
        programaVersionesDatosWeb: action.payload.data,
      };
    case ADD_DATOSWEBPROGRAMA:
      return {
        ...state,
        loading: true
      }
    case ADD_DATOSWEBPROGRAMA_ADDED:
      return {
        ...state,
        loading: false,
        resultDatosWebProg: action.payload.data,
      };
    case ADD_DATOSWEBPROGRAMAVERSIONES:
        return {
          ...state,
          loading: true
        }
    case ADD_DATOSWEBPROGRAMAVERSIONES_ADDED:
        return {
          ...state,
          loading: false,
          resultDatosWebProgVersiones: action.payload.data,
        };  
    case RESET_PROGRAMA:
        return {
          ...state,
          programa: []
        }                          
    default:
      return state;
  }
};

export default Programa;
