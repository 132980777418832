import {
    GET_AYUDANTES, GET_AYUDANTES_SUCCESS, 
    ADD_NEW_AYUDANTE,
    GET_AYUDANTESADDED, GET_AYUDANTE_BY_RUT, GET_AYUDANTE_BY_RUT_SUCCESS
} from './actionTypes'

export const getAyudantes = (params) => ({
  type: GET_AYUDANTES,
  payload: params
});

export const getAyudantesSuccess = (params) => ({
    type: GET_AYUDANTES_SUCCESS,
    payload: params
});
  
export const addNewAyudante = (ayudante, params, formData, formData1, formData2) => ({
  type: ADD_NEW_AYUDANTE,
  payload: {ayudante, params, formData, formData1, formData2},
});

export const getAyudanteAdded = (params) => ({
  type: GET_AYUDANTESADDED,
  payload: params
});

export const getAyudanteByRut = (params) => ({
  type: GET_AYUDANTE_BY_RUT,
  payload: params
});

export const getAyudanteByRutSuccess = (params) => ({
  type: GET_AYUDANTE_BY_RUT_SUCCESS,
  payload: params
});

