export const GET_ACADEMICOS = "GET_ACADEMICOS";
export const GET_ACADEMICOS_SUCCESS = "GET_ACADEMICOS_SUCCESS";
export const GET_ACADEMICOS_ALL = "GET_ACADEMICOS_ALL";
export const GET_ACADEMICOS_ALL_SUCCESS = "GET_ACADEMICOS_ALL_SUCCESS";

export const GET_ACADEMICO_BY_USERID = "GET_ACADEMICO_BY_USERID";
export const GET_ACADEMICO_BY_USERID_SUCCESS = "GET_ACADEMICO_BY_USERID_SUCCESS";

export const GET_ACADEMICOS_CENTRO_BY_DIRECTOR = "GET_ACADEMICOS_CENTRO_BY_DIRECTOR";
export const GET_ACADEMICOS_CENTRO_BY_DIRECTOR_SUCCESS = "GET_ACADEMICOS_CENTRO_BY_DIRECTOR_SUCCESS";

export const GET_ACAD_DIAS_DISPONIBLES = "GET_ACAD_DIAS_DISPONIBLES";
export const GET_ACAD_DIAS_DISPONIBLES_SUCCESS = "GET_ACAD_DIAS_DISPONIBLES_SUCCESS";
export const GET_ACAD_DIAS_DISPONIBLES_ALL = "GET_ACAD_DIAS_DISPONIBLES_ALL";
export const GET_ACAD_DIAS_DISPONIBLES_ALL_SUCCESS = "GET_ACAD_DIAS_DISPONIBLES_ALL_SUCCESS";
export const GET_ACAD_DIAS_BLOQUEO = "GET_ACAD_DIAS_BLOQUEO";
export const GET_ACAD_DIAS_BLOQUEO_SUCCESS = "GET_ACAD_DIAS_BLOQUEO_SUCCESS";
export const ADD_NEW_ACAD_DIAS_DISPONIBLE = "ADD_NEW_ACAD_DIAS_DISPONIBLE";
export const ADD_NEW_ACAD_DIAS_BLOQUEO = "ADD_NEW_ACAD_DIAS_BLOQUEO";
export const DELETE_ACAD_DIAS_DISPONIBLE = "DELETE_ACAD_DIAS_DISPONIBLE";
export const DELETE_ACAD_DIAS_BLOQUEO = "DELETE_ACAD_DIAS_BLOQUEO";
export const UPDATE_ACAD_DIAS_DISPONIBLE = "UPDATE_ACAD_DIAS_DISPONIBLE";

export const GET_REPORTE_DISPONIBILIDAD_YEAR = "GET_REPORTE_DISPONIBILIDAD_YEAR"
export const GET_REPORTE_DISPONIBILIDAD_YEAR_SUCCESS = "GET_REPORTE_DISPONIBILIDAD_YEAR_SUCCESS"
export const RESET_REPORTE_DISPONIBILIDAD = "RESET_REPORTE_DISPONIBILIDAD"

export const GET_REPORTE_BLOQUEOS_YEAR = "GET_REPORTE_BLOQUEOS_YEAR"
export const GET_REPORTE_BLOQUEOS_YEAR_SUCCESS = "GET_REPORTE_BLOQUEOS_YEAR_SUCCESS"
export const RESET_REPORTE_BLOQUEOS = "RESET_REPORTE_BLOQUEOS"

export const GET_REPORTE_AMBOS_YEAR = "GET_REPORTE_AMBOS_YEAR"
export const GET_REPORTE_AMBOS_YEAR_SUCCESS = "GET_REPORTE_AMBOS_YEAR_SUCCESS"
export const RESET_REPORTE_AMBOS = "RESET_REPORTE_AMBOS"
export const VALIDA_ACADEMICO = "VALIDA_ACADEMICO"
export const ADD_NEW_ACADEMICO = "ADD_NEW_ACADEMICO"
export const UPDATE_ACADEMICO = "UPDATE_ACADEMICO"
export const ADD_NEW_ESTUDIO_ACADEMICO = "ADD_NEW_ESTUDIO_ACADEMICO"
export const GET_ESTUDIOS_ACADEMICO = "GET_ESTUDIOS_ACADEMICO"
export const GET_ESTUDIOS_ACADEMICO_SUCCESS = "GET_ESTUDIOS_ACADEMICO_SUCCESS"
export const ADD_NEW_DOCUMENTO_ACADEMICO = "ADD_NEW_DOCUMENTO_ACADEMICO"
export const GET_DOCUMENTOS_ACADEMICO = "GET_DOCUMENTOS_ACADEMICO"
export const GET_DOCUMENTOS_ACADEMICO_SUCCESS = "GET_DOCUMENTOS_ACADEMICO_SUCCESS"
export const GET_ACADEMICO_USER = "GET_ACADEMICO_USER"
export const GET_ACADEMICO_USER_SUCCESS = "GET_ACADEMICO_USER_SUCCESS"
export const RESET_ACADEMICO_USER = "RESET_ACADEMICO_USER"



