import { APIClient } from "./api_helper";

import * as url from "./url_helper";

const api = new APIClient();

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

// //is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Login Method
export const postLogin = data => api.create(url.POST_LOGIN, data);
export const postPersonificar = data => api.create(url.POST_PERSONIFICAR, data);
export const postFinalizarPersonificacion = data => api.create(url.POST_FINALIZAR_PERSONIFICACION, data);

// User Management
export const getUsers = data => api.get(url.GET_USERS, data);
export const getAllPerfiles = () => api.get(url.GET_ALL_PERFILES);
export const getAllRoles = () => api.get(url.GET_ALL_ROLES);
export const updateUser = data => api.put(url.UPDATE_USER + "/" + data.id, data)
export const updateUserParcial = (id, data) => api.update(url.UPDATE_USER_PARCIAL + "/" + id, data)
export const updatePerfil = (id, data) => api.put(url.UPDATE_PERFIL + "/" + id, data)
export const updateRol = (id, data) => api.put(url.UPDATE_ROL + "/" + id, data)
export const updatePasswordDCS = (data) => api.put(url.UPDATE_PASSWORD_DCS, data)
export const addNewUser = data => api.create(url.ADD_NEW_USER, data)
export const addNewPerfil = data => api.create(url.ADD_NEW_PERFIL, data)
export const addNewRol = data => api.create(url.ADD_NEW_ROL, data)
export const addNewPerfilUser = data => api.create(url.ADD_PERFIL_USER, data)
export const addNewRolPerfil = data => api.create(url.ADD_ROL_PERFIL, data)
export const resetPasswordUser = id => api.create(url.RESET_PASSWORD_USER + "/" + id)
export const recuperaPasswordUser = email => api.create(url.RECUPERA_PASSWORD_USER + "/" + email)
export const cambiaPasswordUser = data => api.put(url.CAMBIA_PASSWORD_USER, data)
export const deletePerfilUser = data => api.delete(url.DELETE_PERFIL_USER, { headers: { 'Content-Type': 'application/json'}, data })
export const deleteRolPerfil = data => api.delete(url.DELETE_ROL_PERFIL, { headers: { 'Content-Type': 'application/json'}, data })

// Captcha
export const getCaptcha = () => api.get(url.GET_CAPTCHA);
export const getImgCaptcha = data => api.get(url.GET_IMG_CAPTCHA + "/" + data.id);
export const validateCaptcha = data => api.create(url.POST_CAPTCHA, data)

// Empresa
export const getEmpresas = data => api.get(url.GET_EMPRESAS, data);
export const getEmpresaContactos = data => api.get(url.GET_EMPRESA_CONTACTOS, data);
export const addNewEmpresa = data => api.create(url.ADD_NEW_EMPRESA, data)
export const addNewEmpresaContacto = data => api.create(url.ADD_NEW_EMPRESA_CONTACTO, data)
export const updateEmpresa = data => api.put(url.UPDATE_EMPRESA, data)

// Estudiantes
export const getEstudiantes = data => api.get(url.GET_ESTUDIANTES, data);
export const getEstudiosEstudiante = data => api.get(url.GET_ESTUDIOS_ESTUDIANTE, data);
export const getLaboralEstudiante = data => api.get(url.GET_LABORAL_ESTUDIANTE, data);
export const getEstudianteByDocumento = data => api.get(url.GET_ESTUDIANTE_BY_DOCUMENTO, data);
export const addNewEstudiante = data => api.create(url.ADD_NEW_ESTUDIANTE, data);
export const addNewEstudioEstudiante = data => api.create(url.ADD_NEW_ESTUDIO_ESTUDIANTE, data);
export const addNewLaboralEstudiante = data => api.create(url.ADD_NEW_LABORAL_ESTUDIANTE, data);
export const updateEstudiante = data => api.put(url.UPDATE_ESTUDIANTE, data)
export const deleteEstudioEstudiante = data => api.delete(url.DELETE_ESTUDIO_ESTUDIANTE, { headers: { 'Content-Type': 'application/json'}, data });
export const getEstudiante = data => api.get(url.GET_ESTUDIANTE, data);
export const getViajesEnCursoEstudiante = data => api.get(url.GET_VIAJES_EN_CURSO_ESTUDIANTE, data);
export const getModulosViaje = data => api.get(url.GET_MODULOS_VIAJE, data);
export const getNotasEstudianteModulo = data => api.get(url.GET_NOTAS_ESTUDIANTE_MODULO, data);
export const getCertificadoAlumnoRegular = data => api.get(url.GET_CERTIFICADO_ALUMNO_REGULAR, data);
export const getCertificadoAlumnoRegularInterno = data => api.get(url.GET_CERTIFICADO_ALUMNO_REGULAR_INTERNO, data);
export const getCertificadoAlumnoNotas = data => api.get(url.GET_CERTIFICADO_ALUMNO_NOTAS, data);
export const getEstudianteExterno = data => api.get(url.GET_ESTUDIANTE_EXTERNO, data);
export const getAsistenciaEstudianteModulo = data => api.get(url.GET_ASISTENCIA_ESTUDIANTE_MODULO, data);

// Listas
export const getLista = (backendList) => api.get(url.GET_LISTA + backendList)
export const getListaGeo = (backendList, paramId) => api.get(url.GET_LISTA_GEO + backendList, paramId)
export const getListaZonaProg = (backendList, paramId) => api.get(url.GET_LISTA_ZONA_PROG + backendList, paramId)
export const getListaTipoSolicitudes = data => api.get(url.GET_LISTA_TIPO_SOLICITUDES, data);
export const getListaAreasInvestigacion = (backendList, paramId) => api.get(url.GET_LISTA_AREASINVESTIGACION + backendList, paramId)

// Prospectos Management
export const getProspectos = data => api.get(url.GET_PROSPECTOS, data);
export const GetProspectosByEmail = data => api.get(url.GET_PROSPECTOS_BY_EMAIL, data);
export const getProspectosContador = data => api.get(url.GET_PROSPECTOS_CONTADOR, data);
export const getProspectoByGuid = data => api.get(url.GET_PROSPECTO_BY_GUID, data);
export const getProspectoPreviewEnviaInformacion = data => api.get(url.GET_PROSPECTO_PREVIEW_ENVIA_INFORMACION, data);
export const addNewProspecto = data => api.create(url.ADD_NEW_PROSPECTO, data)
export const addNewProspectoSeguimiento = data => api.create(url.ADD_NEW_PROSPECTO_SEGUIMIENTO, data)
export const addNewDescuentoProspecto = data => api.create(url.ADD_NEW_DESCUENTO_PROSPECTO, data)
export const addNewFichaPostulacion = data => api.create(url.ADD_NEW_FICHA_POSTULACION, data)
export const postProspectoEnviaInformacion = data => api.create(url.POST_PROSPECTO_ENVIA_INFORMACION, data);
export const postEnviaFichaPostulacion = data => api.create(url.POST_ENVIA_FICHA_POSTULACION, data);
export const postProcesaMatriculaProspecto = data => api.create(url.POST_PROCESA_MATRICULA_PROSPECTO, data);
export const updateEjecutivoProspecto = data => api.put(url.UPDATE_EJECUTIVO_PROSPECTO, data)
export const updateDescuentoProspecto = data => api.put(url.UPDATE_DESCUENTO_PROSPECTO, data)
export const updateVersionProgramaProspecto = data => api.put(url.UPDATE_VERSION_PROGRAMA_PROSPECTO, data)
export const deleteDescuentoProspecto = data => api.delete(url.DELETE_DESCUENTO_PROSPECTO, { headers: { 'Content-Type': 'application/json'}, data })

// Finanzas Dashboard
export const getFacturasByMes = data => api.get(url.GET_FBYMES, data);
export const getFacturasByMesRango = data => api.get(url.GET_FBYMESRANGO, data);
export const getFacturasByMesRangoComparativo = data => api.get(url.GET_FBYMESRANGOCOMPARATIVO, data);
export const getFacturasBySemanas = data => api.get(url.GET_FBYSEMANAS, data);
export const getFacturasByDiaMes = data => api.get(url.GET_FBYDIAMES, data);
export const getFacturasByMesRangoTipo = data => api.get(url.GET_FBYMESRANGOTIPO, data);
export const getBoletasByMesRango = data => api.get(url.GET_BOLETASBYMESRANGO, data);
export const getNVByMes = data => api.get(url.GET_NVBYMES, data);
export const getNVByMesHome = data => api.get(url.GET_NVBYMESHOME, data);
export const getNVByMesTipo = data => api.get(url.GET_NVBYMESTIPO, data);
export const getNVTiposPago = data => api.get(url.GET_NVTIPOSPAGO, data);
export const getNVCantidadByMesTipo = data => api.get(url.GET_NVCANTIDADBYMESTIPO, data);
export const postCargaDataAuge = data => api.create(url.POST_CARGADATAAUGE, data);

//-----------------------Cobranza ---------------------------------//
//-----------------------------------------------------------------//
export const getFacturasByRango = data => api.get(url.GET_FACTURABYRANGO, data);
export const getFacturaByNumFactura = data => api.get(url.GET_FACTURABYNUMFACTURA, data);
export const getFacturaMovimientosByNumFactura = data => api.get(url.GET_FACTURAMOVIMIENTOSBYNUMFACTURA,data);
export const getFacturaCuotasByNumFactura = data => api.get(url.GET_FACTURACUOTASBYNUMFACTURA,data);
export const getFacturaHistoricoMedioPagosByNumFactura = data => api.get(url.GET_FACTURAHISTORICOMEDIOPAGOSBYNUMFACTURA,data);
export const getListaEstadoFactura = data => api.get(url.GET_LISTAESTADOFACTURA, data);
export const getListaSubEstadoFactura = data => api.get(url.GET_LISTASUBESTADOFACTURA, data);
export const getListaTipoSeguimientoManual = data => api.get(url.GET_LISTATIPOSEGUIMIENTOMANUAL, data);
export const getListaOrganismos = data => api.get(url.GET_LISTAORGANISMOS, data);
export const getListaBancos = data => api.get(url.GET_LISTABANCOS, data);
export const getListaCuentaCorriente = data => api.get(url.GET_LISTACUENTACORRIENTE, data);
export const getSeguimientosFacturaByNumFactura= data=> api.get(url.GET_SEGUIMIENTOSFACTURABYNUMFACTURA,data);
export const getMontosFacturasDashboard = data=> api.get(url.GET_MONTOSFACTURASDASHBOARD,data);
export const getMontosFacturasPagadasConMedioDePagoDashboard= data => api.get(url.GET_MONTOSFACTURASPAGADASCONMEDIODEPAGODASHBOARD,data);
export const getMontosFacturasMorosasSinMedioDePagoDashboard= data => api.get(url.GET_MONTOSFACTURASMOROSASSINMEDIODEPAGODASHBOARD,data);

export const getFacturasEmitidasDashboardPorRangoFechas = data => api.get(url.GET_FACTURASEMITIDASDASHBOARDPORRANGOFECHAS,data);
export const getFacturasEmitidasPagadasDashboardPorRangoFechas = data => api.get(url.GET_FACTURASEMITIDASPAGADASDASHBOARDPORRANGOFECHAS,data);
export const getFacturasEmitidasVersusComportamientoDePagoDashboardPorRangoFechas = data => api.get(url.GET_FACTURASEMITIDASVERSUSCOMPORTAMIENTODEPAGODASHBOARDPORRANGOFECHAS,data);

export const getGestionCobranzaFacturasMorosasPorRangoFechas = data => api.get(url.GET_GESTIONCOBRANZAFACTURASMOROSASPORRANGOFECHAS,data);

export const addGestionSeguimientoCobranza = data => api.create(url.ADD_GESTIONSEGUIMIENTOCOBRANZA, data);

//-----------------------------------------------------------------//

//-----------------------Sesiones----------------------------------//

export const getSesionesAlumnos = data => api.get(url.GET_SESIONES_ALUMNOS, data);
export const getSesionesAcademicos = data => api.get(url.GET_SESIONES_ACADEMICOS, data);
export const getListaSesionesAcademicosPorRangoFechas = data => api.get(url.GET_LISTASESIONESACADEMICOSPORRANGOFECHAS, data);
export const getDiasSesiones = data => api.get(url.GET_DIAS_SESIONES, data);
export const deleteSesiones = data => api.delete(url.DELETE_SESIONES, { headers: { 'Content-Type': 'application/json'}, data });


//-----------------------------------------------------------------//

// Control Gestión
export const postMoverArchivosAsientosContables = data => api.create(url.POST_MOVER_ARCHIVOS_ASIENTOS_CONTABLES, data);

//FILES
export const postFile = (data) => api.create(url.POST_FILE, data, {headers: {'Content-Type': 'multipart/form-data'}})
export const postMulitpleFile = (data) => api.create(url.POST_MULTIPLE_FILE, data, {headers: {'Content-Type': 'multipart/form-data'}})
export const getFileById = (data) => api.create(url.GET_FILE, data, {responseType: 'blob'})
export const getFileByPathFilename = (data) => api.create(url.GET_FILE_BYPATHFILENAME, data, {responseType: 'blob'})
export const getImgFile = (data) => api.create(url.GET_IMG_FILE, data, {responseType: 'blob'})

//Historial
export const postHistorial = (data) => api.create(url.POST_HISTORIAL, data)
export const getHistorialByIdentificador = (data) => api.get(url.GET_HISTORIAL_BY_IDENTIFICADOR, data)
export const GetHistorialByIdentificadorAndAccion = (data) => api.get(url.GET_HISTORIAL_BY_IDENTIFICADOR_ACCION, data)

//Premios
export const getCrossref = (data) => api.get(url.GET_CROSSREF, data)
export const getPublicaciones = (data) => api.get(url.GET_PUBLICACIONES, data)
export const getAdjudicaciones = (data) => api.get(url.GET_ADJUDICACIONES, data)
export const addNewPremioPublicacion = (premio) => api.create(url.ADD_NEW_PREMIO_PUBLICACION, premio)
export const addNewPremioAdjudicacion = (premio) => api.create(url.ADD_NEW_PREMIO_ADJUDICACION, premio)
export const updateEstadoPremio = (data) => api.put(url.UPDATE_ESTADO_PREMIO, data)
export const addNewPremioPubHistorial = data => api.create(url.ADD_NEW_PREMIO_PUB_HISTORIAL, data)
export const getPremioPubHistorial = data => api.get(url.GET_PREMIO_PUB_HISTORIAL, data);
export const getPremioAdjHistorial = data => api.get(url.GET_PREMIO_ADJ_HISTORIAL, data);
export const postCargaRankingjcr = data => api.create(url.POST_CARGARANKINGJCR, data);
export const postCargaDetalleRankingjcr = data => api.create(url.POST_CARGADETALLERANKINGJCR, data); 
export const getRankingJCRByIssn = (data) => api.get(url.GET_RANKINGJCR_BY_ISSN, data)
export const addNewPremioAdjHistorial = data => api.create(url.ADD_NEW_PREMIO_ADJ_HISTORIAL, data)
export const updateEstadoPremioAdj = (data) => api.put(url.UPDATE_ESTADO_PREMIO_ADJ, data)
export const getRankingJCRDetalleAll = (data) => api.get(url.GET_RANKINGJCR_DETALLE_ALL, data)
export const getMaxAnioPremioAdjudicacion = () => api.get(url.GET_MAX_ANIO_PREMIO_ADJUDICACION)
export const getReportePremioPub = data => api.get(url.GET_REPORTE_PREMIO_PUB, data);
export const getReporteAdjudicacion = data => api.get(url.GET_REPORTE_ADJUDICACION, data);



//PROFILE
export const getProfileMenu = () => api.get(url.GET_USER_PROFILE)
export const getProfileInfo = () => api.get(url.GET_USER_INFO)
export const getUsersAvatars = (data) => api.create(url.GET_USERS_AVATARS, data)
export const getNotificacionesUser = () => api.get(url.GET_USER_NOTIFICACIONES)
export const addNewProfilePicture = data => api.create(url.ADD_NEW_PROFILE_PICTURE, data)
export const addNewMailingPicture = data => api.create(url.ADD_NEW_MAILING_PICTURE, data)
export const updateNotificacionUser = (id) => api.put(url.UPDATE_USER_NOTIFICACION + "?id=" + id)
export const updateUserInfo = data => api.put(url.UPDATE_USER_INFO, data)
export const deleteProfilePicture = () => api.delete(url.DELETE_PROFILE_PICTURE)
export const deleteMailingPicture = () => api.delete(url.DELETE_MAILING_PICTURE)

//Monedas
export const getValorDolar = data => api.get(url.GET_VALOR_DOLAR, data);

//Notificaciones
export const getNotificacionesSistema = (modulo) => api.get(url.GET_NOTIFICACIONES_SISTEMA + "?modulo=" + modulo)
export const getModulosNotificaciones = () => api.get(url.GET_MODULOS_NOTIFICACIONES)
export const postModuloNotificacion = (modulo) => api.create(url.ADD_NEW_MODULO_NOTIFICACIONES, modulo)
export const postTipoNotificacion = (tipo) => api.create(url.ADD_NEW_TIPO_NOTIFICACION, tipo)
export const updateTipoNotificacion = (tipo) => api.put(url.UPDATE_TIPO_NOTIFICACION, tipo)
export const updateEstadoTipoNotificacion = (data) => api.put(url.UPDATE_ESTADO_TIPO_NOTIFICACION, data)
export const sendNotificacion = (id) => api.create(url.SEND_NOTIFICACION, id)

//Operaciones
export const getSalas = data => api.get(url.GET_SALAS, data);
export const getSalasBySede = data => api.get(url.GET_SALAS_BY_SEDE, data);
export const addNewSala = data => api.create(url.ADD_NEW_SALA, data)
export const updateSala = data => api.put(url.UPDATE_SALA + "/" + data.id, data)
export const getTipoSalas = data => api.get(url.GET_TIPO_SALAS, data);
export const getSedes = data => api.get(url.GET_SEDES, data);
export const addNewEquipamiento = data => api.create(url.ADD_NEW_EQUIPAMIENTO, data)
export const getEquipamiento = data => api.get(url.GET_EQUIPAMIENTO, data);
export const addNewSalaEquipamiento = data => api.create(url.ADD_NEW_SALAEQUIPAMIENTO, data)
export const getSalaEquipamiento = data => api.get(url.GET_SALAEQUIPAMIENTO, data);
export const deleteSalaEquipamiento = data => api.delete(url.DELETE_SALAEQUIPAMIENTO, { headers: { 'Content-Type': 'application/json'}, data });
//export const deleteSalaEquipamiento = data => api.delete(url.DELETE_SALAEQUIPAMIENTO, data );

//Eventos
export const getEventosCategorias = () => api.get(url.GET_EVENTO_CATEGORIAS);
export const getEventosTipos = (data) => api.get(url.GET_EVENTO_TIPOS, data);
export const getEventosItems = (data) => api.get(url.GET_EVENTO_ITEMS, data);
export const getEventosAnio = (data) => api.get(url.GET_EVENTOS_ANIO, data);
export const getAllEventosAnio = (data) => api.get(url.GET_EVENTOS_ALL_ANIO, data);
export const getProximosEventosAnio = (data) => api.get(url.GET_EVENTOS_PROXIMOS_ANIO, data);
export const getEventosRealizadosAnio = (data) => api.get(url.GET_EVENTOS_REALIZADOS_ANIO, data);
export const getActividadesEventosAnio = (data) => api.get(url.GET_EVENTOS_ACTIVIDADES_ANIO, data);
export const getEventosCalendario = (data) => api.get(url.GET_EVENTOS_CALENDARIO, data);
export const getEvento = (data) => api.get(url.GET_EVENTO, data);
export const getEventosModalidades = () => api.get(url.GET_EVENTO_MODALIDADES);
export const getEventosEstados = () => api.get(url.GET_EVENTO_ESTADOS);
export const getEventosEstadosItems = () => api.get(url.GET_EVENTO_ESTADOS_ITEMS);
export const getReporteEventosAnio = (data) => api.get(url.GET_REPORTE_EVENTOS_ANIO, data);
export const postEvento = (data) => api.create(url.POST_EVENTO, data);
export const updateEvento = (idEvento, data) => api.put(url.UPDATE_EVENTO + "?idEvento=" + idEvento, data);
export const updateDetalleEvento = (idEvento, data) => api.put(url.UPDATE_DETALLE_EVENTO + "?idEvento=" + idEvento, data);
export const updateGestionEvento = (data) => api.put(url.UPDATE_GESTION_EVENTO, data);
export const updateEstadoEvento = (idEvento, data) => api.put(url.UPDATE_ESTADO_EVENTO + "?idEvento=" + idEvento, data);
export const updateCancelarEvento = (idEvento, data) => api.put(url.UPDATE_CANCELA_EVENTO + "?idEvento=" + idEvento, data);
export const updateActividadesEventos = (data) => api.put(url.UPDATE_GESTION_MASIVA_EVENTO, data);
export const deleteDiaEvento = data => api.delete(url.DELETE_EVENTO_DIA, { headers: { 'Content-Type': 'application/json'}, data })

//Mysql
export const getSaldoPptoCentroByDirector = (data) => api.get(url.GET_SALDO_PPTO_CENTRO_DIRECTOR, data);
export const getSaldoPptoCentro = (data) => api.get(url.GET_SALDO_PPTO_CENTRO, data);

//Fechas
export const getFeriados = data => api.get(url.GET_FERIADOS, data);
export const addNewFeriado = data => api.create(url.ADD_NEW_FERIADO, data)
export const addNewBloqueo = data => api.create(url.ADD_NEW_BLOQUEO, data)
export const updateFeriado = data => api.put(url.UPDATE_FERIADO + "/" + data.id, data)
export const deleteFeriado = data => api.delete(url.DELETE_FERIADO, { headers: { 'Content-Type': 'application/json'}, data }); 
export const getTipoBloqueos = data => api.get(url.GET_TIPOBLOQUEOS, data);
export const getFeriadosByRango = data => api.get(url.GET_FERIADOSBYRANGO, data);
export const getFeriadosPermanentes = data => api.get(url.GET_FERIADOSPERMANENTES, data);

//Academicos
export const getAcademicos = (data) => api.get(url.GET_ACADEMICOS, data);
export const getAcademicosAll = () => api.get(url.GET_ACADEMICOS_ALL);
export const getAcademicoByUserId = (id) => api.get(url.GET_ACADEMICO_BY_USERID, id);
export const getAcademicosCentroByDirector = () => api.get(url.GET_ACADEMICOS_CENTRO_BY_DIRECTOR);
export const getAcadDiasDisponibles = data => api.get(url.GET_ACAD_DIAS_DISPONIBLES, data);
export const getAcadDiasDisponiblesAll = data => api.get(url.GET_ACAD_DIAS_DISPONIBLES_ALL, data);
export const getAcadDiasBloqueo = data => api.get(url.GET_ACAD_DIAS_BLOQUEO, data);
export const addAcadDiasDisponibles = data => api.create(url.ADD_NEW_ACAD_DIAS_DISPONIBLE, data)
export const addAcadDiasBloqueo = data => api.create(url.ADD_NEW_ACAD_DIAS_BLOQUEO, data)
export const deleteAcadDiasDisponible = data => api.delete(url.DELETE_ACAD_DIAS_DISPONIBLE, { headers: { 'Content-Type': 'application/json'}, data }); 
export const deleteAcadDiasBloqueo = data => api.delete(url.DELETE_ACAD_DIAS_BLOQUEO, { headers: { 'Content-Type': 'application/json'}, data }); 
export const updateAcadDiasDisponible = (data) => api.put(url.UPDATE_ACAD_DIAS_DISPONIBLE + "/" + data.id, data)
export const getReporteDisponibilidadYear = (data) => api.get(url.GET_REPORTE_DISPONIBILIDAD_YEAR, data); 
export const getReporteBloqueosYear = (data) => api.get(url.GET_REPORTE_BLOQUEOS_YEAR, data); 
export const getReporteAmbosYear = (data) => api.get(url.GET_REPORTE_AMBOS_YEAR, data); 
export const validaAcademico = data => api.put(url.VALIDA_ACADEMICO + "/" + data.idAcademico, data)
export const addNewAcademico = data => api.create(url.ADD_NEW_ACADEMICO, data)
export const updateAcademico = data => api.put(url.UPDATE_ACADEMICO, data)
export const addNewEstudioAcademico = data => api.create(url.ADD_NEW_ESTUDIO_ACADEMICO, data);
export const getEstudiosAcademico = data => api.get(url.GET_ESTUDIOS_ACADEMICO, data);
export const addNewDocumentoAcademico = data => api.create(url.ADD_NEW_DOCUMENTO_ACADEMICO, data);
export const getDocumentosAcademico = data => api.get(url.GET_DOCUMENTOS_ACADEMICO, data);
export const getAcademicoUser = () => api.get(url.GET_ACADEMICO_USER);



// Solicitudes de fondo
export const addNewSolicitudFondo = data => api.create(url.ADD_NEW_SOLICITUD_FONDO, data)
export const addNewFondoInvestigacion = data => api.create(url.ADD_NEW_FONDO_INVESTIGACION, data)
export const addNewSolicitudFondoHistorial = data => api.create(url.ADD_NEW_SOLICITUD_FONDO_HISTORIAL, data)
//export const getSolicitudes = (data) => api.get(url.GET_SOLICITUDES_FONDO, data);
export const getSolicitudes = (data, filtrarPendientes) => api.get(url.GET_SOLICITUDES_FONDO, data, filtrarPendientes)
export const getSolicitudesPorAcademico = data => api.get(url.GET_SOLICITUDES_FONDO_POR_ACADEMICO, data);
export const updateSolicitudFondo = data => api.put(url.UPDATE_SOLICITUD_FONDO + "/" + data.id, data)
export const updateEstadoSolicitudFondo = (idSolicitud, data) => api.put(url.UPDATE_ESTADO_SOLICITUD_FONDO + "?idSolicitud=" + idSolicitud, data);
//export const getViaticoPorPais = (codPais) => api.get(url.GET_VIATICOPORPAIS, codPais);
export const getViaticoPorPais = (data) => api.get(url.GET_VIATICOPORPAIS, data);
export const addDiasViajeSolicitud = data => api.create(url.ADD_DIASVIAJE_SOLICITUD, data)
export const getDiasViajeSolicitud = data => api.get(url.GET_DIASVIAJE_SOLICITUD, data);
export const getSolicitudHistorial = data => api.get(url.GET_SOLICITUD_HISTORIAL, data);
export const getPptoInvestigacionPorAcademico = data => api.get(url.GET_PPTO_INVESTIGACION_POR_ACADEMICO, data);
export const getPptoAcademicoSaldoPendiente = data => api.get(url.GET_PPTO_ACADEMICO_SALDO_PENDIENTE, data);
export const getReporteSolicitudesFondos = data => api.get(url.GET_REPORTE_SOLICITUDES_FONDOS, data);
export const getReportePresupuestoInvestigacion = data => api.get(url.GET_REPORTE_PRESUPUESTO_INVESTIGACION, data);
export const getReporteFondosInvestigacion = data => api.get(url.GET_REPORTE_FONDOS_INVESTIGACION, data);
export const getEstadoSolicitudFondo = () => api.get(url.GET_ESTADO_SOLICITUD_FONDO);
export const getTipoFondoInvestigacion = () => api.get(url.GET_TIPO_FONDO_INVESTIGACION);
export const getFondosInvestigacion = (data) => api.get(url.GET_FONDOS_INVESTIGACION, data);
export const updateAcademicosFondo = (data) => api.create(url.UPDATE_ACADEMICOS_FONDO, data);
export const updateFondoInvestigacion = (id, data) => api.put(url.UPDATE_FONDO_INVESTIGACION + "/" + id, data);
export const deleteFondoInvestigacion = data => api.delete(url.DELETE_FONDO_INVESTIGACION, { headers: { 'Content-Type': 'application/json'}, data });
export const updateValoresSolicitudFondo = (idSolicitud, data) => api.put(url.UPDATE_VALORES_SOLICITUD_FONDO + "?idSolicitud=" + idSolicitud, data);
export const getPptoInvestigacionPorDepto = data => api.get(url.GET_PPTO_INVESTIGACION_POR_DEPTO, data);
export const getPptoDeptoPendiente = data => api.get(url.GET_PPTO_DEPTO_PENDIENTE, data);
export const getPptoAcademicoSaldoPendienteConcilia = data => api.get(url.GET_PPTO_ACADEMICO_SALDO_PENDIENTE_CONCILIA, data);
export const getReporteSolicitudesFondosAyudantes = data => api.get(url.GET_REPORTE_SOLICITUDES_FONDOS_AYUDANTES, data);
export const getFilesSolicitudById = (data) => api.create(url.GET_FILES_SOLICITUD, data, {responseType: 'blob'})
export const getCecoSolicitudFondo = data => api.get(url.GET_CECO_SOLICITUD_FONDO, data);
export const getCecoSolicitudByCodigo = (codigo) => api.get(url.GET_CECO_SOLICITUD_BY_CODIGO, codigo);

//Ayudantes
export const getAyudantes = data => api.get(url.GET_AYUDANTES, data);
export const addNewAyudante = data => api.create(url.ADD_NEW_AYUDANTE, data)
export const getAyudanteByRut = (rut) => api.get(url.GET_AYUDANTE_BY_RUT, rut);

// Add Sub-area investigacion y Tema Investigacion
export const addNewSubAreaInvestigacion = data => api.create(url.ADD_NEW_SUBAREA_INVESTIGACION, data)
export const addNewTemaInvestigacion = data => api.create(url.ADD_NEW_TEMA_INVESTIGACION, data)

//Mantenedores
export const getDolares = data => api.get(url.GET_DOLARES, data);
export const getDolar = data => api.get(url.GET_DOLAR, data);
export const addNewDolar = data => api.create(url.ADD_NEW_DOLAR, data)
export const updateDolar = data => api.put(url.UPDATE_DOLAR + "/" + data.id, data) 
export const getDolarVigente = data => api.get(url.GET_DOLAR_VIGENTE, data);
export const getCecosSolicitud = data => api.get(url.GET_CECOS_SOLICITUD, data);
export const getCecoSolicitud = data => api.get(url.GET_CECO_SOLICITUD, data);
export const addNewCecoSolicitud = data => api.create(url.ADD_NEW_CECO_SOLICITUD, data)
export const updateCecoSolicitud = data => api.put(url.UPDATE_CECO_SOLICITUD + "/" + data.id, data) 


// Modulos
export const getModulos = (data) => api.get(url.GET_MODULOS, data)
export const getModulosByCodigo = (data) => api.get(url.GET_MODULOS_BY_CODIGO, data)
export const addNewModulo = data => api.create(url.ADD_NEW_MODULO, data)
export const addNewEvaluacionModulo = data => api.create(url.ADD_NEW_EVALUACION_MODULO, data)
export const addNewEvaluacionNota = data => api.create(url.ADD_NEW_NOTA_EVALUACION_MODULO, data)
export const addNewAsistenciaModulo = data => api.create(url.ADD_NEW_ASISTENCIA_MODULO, data)
export const updateModulo = data => api.put(url.UPDATE_MODULO, data)
export const updateEvaluacionModulo = data => api.put(url.UPDATE_EVALUACION_MODULO, data)
export const updateNotaEvaluacionModulo = data => api.put(url.UPDATE_NOTA_EVALUACION_MODULO, data)
export const updateEstadoActaModulo = data => api.put(url.UPDATE_ESTADO_ACTA_MODULO, data)
export const getSyllabusModulo = data => api.get(url.GET_SYLLABUS_MODULO, data)
export const getModulosByAcademico = (data) => api.get(url.GET_MODULOS_BY_ACADEMICO, data)
export const getModuloVersion = (data) => api.get(url.GET_MODULO_VERSION, data)
export const getEvaluacionesByModuloVersion = (data) => api.get(url.GET_EVALUACIONES_BY_MODULO_VERSION, data)
export const getSesionesModulo = (data) => api.get(url.GET_SESIONES_MODULO, data)
export const getAsistenciaModulo = (data) => api.get(url.GET_ASISTENCIA_MODULO, data)
export const getHorarioModulo = (data) => api.get(url.GET_HORARIO_MODULO, data)
export const putPonderacionModuloVersion = (data) => api.put(url.UPDATE_PONDERACION_MODULO_VERSION, data)
export const putEvaluableModuloVersion = (data) => api.put(url.UPDATE_EVALUABLE_MODULO_VERSION, data)
export const updatePromedioModuloVersion = (data) => api.put(url.UPDATE_PROMEDIO_MODULO_VERSION, data)
export const removeEvaluacionModulo = data => api.delete(url.DELETE_EVALUACION_MODULO, { headers: { 'Content-Type': 'application/json'}, data });
export const removeNotaEvaluacionModulo = data => api.delete(url.DELETE_NOTA_EVALUACION_MODULO, { headers: { 'Content-Type': 'application/json'}, data });

// Pago
export const addNewLinkPago = data => api.create(url.ADD_NEW_LINK_PAGO, data)
export const addNewPagoWebpay = data => api.create(url.ADD_NEW_PAGO_WEBPAY, data)
export const getInfoPago = data => api.get(url.GET_INFO_PAGO, data)
export const getConfirmaPagoPayPal = data => api.get(url.GET_CONFIRMA_PAGO_PAYPAL, data)

// Programas
export const addNewPrograma = data => api.create(url.ADD_NEW_PROGRAMA, data);


// CAB. Programas Datos Web 
export const addDatosWebPrograma = data => api.create(url.ADD_DATOSWEBPROGRAMA, data);
export const getProgramaDatosWeb= (data)=> api.get(url.GET_PROGRAMADATOSWEB, data);
export const getProgramaVersionesDatosWeb= (data)=> api.get(url.GET_PROGRAMAVERSIONESDATOSWEB, data);



export const getProgramas = (data) => api.get(url.GET_PROGRAMAS, data)
export const getPrograma = (data) => api.get(url.GET_PROGRAMA, data)
export const updatePrograma = (id, data) => api.put(url.UPDATE_PROGRAMA + "/" + id, data)
export const getEstadosPrograma = (data) => api.get(url.GET_ESTADOS_PROGRAMA, data)
export const getAreasPrograma = (data) => api.get(url.GET_AREAS_PROGRAMA, data)
export const clonePrograma = (data) => api.create(url.CLONE_PROGRAMA, data);
export const getProgramaXCodBase = (data) => api.get(url.GET_PROGRAMA_X_COD_BASE + "?codigo=" + data);
export const getModalidadesPrograma = (data) => api.get(url.GET_MODALIDADES_PROGRAMA, data)
export const getProgramasSinDecreto = (data) => api.get(url.GET_PROGRAMAS_SIN_DECRETO, data);
export const updateEstadoDecretoPrograma = (id, data) => api.put(url.UPDATE_ESTADO_DECRETO_PROGRAMA + "/" + id, data)
export const generaSyllabusPrograma = (data) => api.create(url.GENERA_SYLLABUS_PROGRAMA, data);

// Validador Documentos
export const postValidaDocumento = data => api.create(url.POST_VALIDA_DOCUMENTO, data)

// Versiones Programa
export const getVersionesPrograma = (data) => api.get(url.GET_VERSIONES_PROGRAMA, data);
export const getVersionesProgramas = (data) => api.get(url.GET_VERSIONES_PROGRAMAS, data);
export const getVersionPrograma = (data) => api.get(url.GET_VERSION_PROGRAMA, data);
export const getModulosVersionPrograma = (data) => api.get(url.GET_MODULOS_VERSION_PROGRAMA, data);
export const getCountVersionesPrograma = (data) => api.get(url.GET_COUNT_VERSIONES_PROGRAMAS, data);
export const addNewVersionPrograma = data => api.create(url.ADD_NEW_VERSION_PROGRAMA, data);
export const addNewCalendarioPrograma = data => api.create(url.ADD_NEW_CALENDARIO_PROGRAMA, data);
export const getVersionesParrilla = (data) => api.get(url.GET_VERSIONES_PARRILLA, data)
export const getVersionesParrillaDEE = (data, filtrarPendientes) => api.get(url.GET_VERSIONES_PARRILLA_DEE, data, filtrarPendientes)
export const getEstadosVersion = (data) => api.get(url.GET_ESTADOS_VERSION, data)
export const getEstudiantesVersionPrograma = (data) => api.get(url.GET_ESTUDIANTES_VERSION_PROGRAMA, data)
export const getLibroClasesPrograma = (data) => api.get(url.GET_LIBRO_CLASES_PROGRAMA, data)
export const getSesionesVersionPrograma = (data) => api.get(url.GET_SESIONES_VERSION_PROGRAMA, data)
export const GetChoquesSesionesVersionPrograma = (data) => api.create(url.GET_CHOQUES_SESIONES_VERSION_PROGRAMA, data)
export const getCalendarioPDF = (data) => api.get(url.GET_CALENDARIO_PDF, data, {responseType: 'arraybuffer', headers: {'Accept': 'application/pdf'}})
export const putVersionPrograma = (data) => api.put(url.UPDATE_VERSION_PROGRAMA, data)
export const putEstadoVersion = (idVersion, data) => api.put(url.UPDATE_ESTADO_VERSION_PROGRAMA + "?idVersion=" + idVersion, data);
export const putPonderacionVersionPrograma = (data) => api.put(url.UPDATE_PONDERACION_VERSION_PROGRAMA, data)
export const putTipoNotaVersionPrograma = (data) => api.put(url.UPDATE_TIPO_NOTA_VERSION_PROGRAMA, data)
export const putEstadoCalendarioPrograma = (data) => api.put(url.UPDATE_ESTADO_CALENDARIO_PROGRAMA, data)
export const reagendaSesionPrograma = (data) => api.create(url.REAGENDA_SESION_PROGRAMA, data)
export const enroqueModulosPrograma = (data) => api.create(url.ENROQUE_MODULOS_PROGRAMA, data)
export const cambiarFechaCalendarioModuloPrograma = (data) => api.create(url.CAMBIAR_FECHA_CALENDARIO_MODULO_PROGRAMA, data)
export const desplazaSesionesCalendarioPrograma = (data) => api.create(url.DESPLAZA_SESIONES_CALENDARIO_PROGRAMA, data)
export const asignaSalaVersionPrograma = (data) => api.put(url.ASIGNA_SALA_VERSION_PROGRAMA, data)
export const getVersionEstudiantesReporte = data => api.get(url.GET_VERSION_ESTUDIANTES_REPORTE, data);

//.CAB. Versiones Programa Datos Web
export const addDatosWebProgramaVersiones = data => api.create(url.ADD_DATOSWEBPROGRAMAVERSIONES, data);


// Viajes
export const addNewCargaCorporativo = data => api.create(url.ADD_NEW_CARGA_CORPORATIVO, data)
export const addNewViajeParticular = data => api.create(url.ADD_NEW_VIAJE_PARTICULAR, data)
export const addNewDescuentoViaje = data => api.create(url.ADD_NEW_DESCUENTO_VIAJE, data)
export const addNewFinanciamientoViaje = data => api.create(url.ADD_NEW_FINANCIAMIENTO_VIAJE, data)
export const addDocumentosViaje = data => api.create(url.ADD_DOCUMENTOS_VIAJE, data)
export const getViajes = data => api.get(url.GET_VIAJES, data);
export const getViaje = data => api.get(url.GET_VIAJE, data);
export const getCargasCorporativos = data => api.get(url.GET_CARGAS_CORPORATIVOS, data);
export const getValidaEstudianteViajePrograma = data => api.get(url.GET_VALIDA_ESTUDIANTE_VIAJE_PROGRAMA, data);
export const getVistaPreviaViaje = data => api.get(url.GET_VISTA_PREVIA_VIAJE, data, {responseType: 'arraybuffer', headers: {'Accept': 'application/pdf'}});
export const updateEstadoViaje = (data) => api.put(url.UPDATE_ESTADO_VIAJE, data);
export const updateViajeFinanciamiento = data => api.put(url.UPDATE_VIAJE_FINANCIAMIENTO, data)
export const updateViajeFinanciamientoFactura = data => api.put(url.UPDATE_VIAJE_FINANCIAMIENTO_FACTURA, data)
export const updateViajeDescuento = data => api.put(url.UPDATE_VIAJE_DESCUENTO, data)
export const enviaFacturaFinanciamiento = data => api.create(url.ENVIA_FACTURA_FINANCIAMIENTO, data)


//Encuesta
export const getEncuesta = (data) => api.get(url.GET_ENCUESTA, data)
export const getEncuestaByGuid = data => api.get(url.GET_ENCUESTA_BY_GUID, data);
export const addNewEncuestaRespuesta = data => api.create(url.ADD_NEW_ENCUESTA_RESPUESTA, data)