import { includes } from "ramda";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { app } from "../config";
import useRoles from "../Components/Hooks/UseRoles";

const Navdata = () => {
    const history = useHistory();
    // Menú Intranet
    const [isGestionAcademica, setIsGestionAcademica] = useState(false)
    const [isGestionEstudiante, setIsGestionEstudiante] = useState(false)
    const [isCentrosInvestigacion, setIsCentrosInvestigacion] = useState(false)
    //const [isProspectos, setIsProspectos] = useState(false)
    const [isAdmision, setIsAdmision] = useState(false)
    const [isPrograma, setIsPrograma] = useState(false)
    const [isTareas, setIsTareas] = useState(false)
    const [isOperaciones, setIsOperaciones] = useState(false)
    const [isFinanzas, setIsFinanzas] = useState(false)
    const [isCobranza, setIsCobranza] = useState(false)
    const [isKpi, setIsKpi] = useState(false)
    const [isCoordinacionAcademica, setIsCoordinacionAcademica] = useState(false)
    const [isAcademicos, setIsAcademicos] = useState(false)
    const [isSolicitud, setIsSolicitud] = useState(false)
    const [isControlGestion, setIsControlGestion] = useState(false)
    const [isEstudiante, setIsEstudiante] = useState(false)

    //state data
    const [isDashboard, setIsDashboard] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isApps, setIsApps] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [isPages, setIsPages] = useState(false);
    const [isBaseUi, setIsBaseUi] = useState(false);
    const [isAdvanceUi, setIsAdvanceUi] = useState(false);
    const [isForms, setIsForms] = useState(false);
    const [isTables, setIsTables] = useState(false);
    const [isCharts, setIsCharts] = useState(false);
    const [isIcons, setIsIcons] = useState(false);
    const [isMaps, setIsMaps] = useState(false);
    const [isMultiLevel, setIsMultiLevel] = useState(false);

    const [isGraficos, setIsGraficos] = useState(false);
    const [isEjemplo, setIsEjemplo] = useState(false)
    const [isMantenedor, setIsMantenedor] = useState(false)

    const [iscurrentState, setIscurrentState] = useState('Dashboard');

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'Dashboard') {
            setIsDashboard(false);
        }
        if (iscurrentState !== 'Admin') {
            setIsAdmin(false);
        }
        if (iscurrentState !== 'Admision') {
            setIsAdmision(false);
        }
        if (iscurrentState !== 'Apps') {
            setIsApps(false);
        }
        if (iscurrentState !== 'Auth') {
            setIsAuth(false);
        }
        if (iscurrentState !== 'Pages') {
            setIsPages(false);
        }
        if (iscurrentState !== 'BaseUi') {
            setIsBaseUi(false);
        }
        if (iscurrentState !== 'AdvanceUi') {
            setIsAdvanceUi(false);
        }
        if (iscurrentState !== 'Forms') {
            setIsForms(false);
        }
        if (iscurrentState !== 'Tables') {
            setIsTables(false);
        }
        if (iscurrentState !== 'Charts') {
            setIsCharts(false);
        }
        if (iscurrentState !== 'Icons') {
            setIsIcons(false);
        }
        if (iscurrentState !== 'Maps') {
            setIsMaps(false);
        }
        if (iscurrentState !== 'MuliLevel') {
            setIsMultiLevel(false);
        }
        if (iscurrentState === 'Widgets') {
            history.push("/widgets");
            document.body.classList.add('twocolumn-panel');
        }
        if (iscurrentState !== 'GestionAcademica') {
            setIsGestionAcademica(false);
        }
        if (iscurrentState !== 'GestionEstudiante') {
            setIsGestionEstudiante(false);
        }
        if (iscurrentState !== 'CentrosInvestigacion') {
            setIsCentrosInvestigacion(false);
        }
        if (iscurrentState !== 'CoordinacionAcademica') {
            setIsCoordinacionAcademica(false);
        }
        if (iscurrentState !== 'Academicos') {
            setIsAcademicos(false);
        }
        if (iscurrentState !== 'ControlGestion') {
            setIsControlGestion(false);
        }
        if (iscurrentState !== 'Ejemplos') {
            setIsEjemplo(false);
        }
        if (iscurrentState !== 'Mantenedores') {
            setIsMantenedor(false);
        }   
        if (iscurrentState !== 'Estudiante') {
            setIsEstudiante(false);
        }             
    }, [
        history,
        iscurrentState,
        isDashboard,
        isAdmin,
        isApps,
        isAuth,
        isPages,
        isBaseUi,
        isAdvanceUi,
        isForms,
        isTables,
        isCharts,
        isIcons,
        isMaps,
        isMultiLevel,
        isGestionAcademica,
        isGestionEstudiante,
        isCentrosInvestigacion,
        isAcademicos,
        isControlGestion,
        isEjemplo,
        isMantenedor,
        isEstudiante
    ]);

    const roles = useRoles();

    const menuItems = [
        {
            label: "Menu",
            isHeader: true,
        },
        {
            id: "admin",
            hasAccess: includes("Admin", roles) || includes("Usuarios", roles) || includes("UsuariosSoloUsers", roles),
            label: "Administración",
            icon: "mdi mdi-view-grid-plus-outline",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsAdmin(!isAdmin);
                setIscurrentState('Admin');
                updateIconSidebar(e);
            },
            stateVariables: isAdmin,
            subItems: [
                {
                    id: "usuarios",
                    hasAccess: includes("Admin", roles) || includes("Usuarios", roles) || includes("UsuariosSoloUsers", roles),
                    label: "Usuarios",
                    link: "/usuarios",
                    parentId: "admin",
                },
                {
                    id: "notificaciones",
                    hasAccess: includes("Admin", roles),
                    label: "Notificaciones",
                    link: "/notificaciones",
                    parentId: "admin",
                },
                {
                    id: "dashboardprueba",
                    hasAccess: includes("Admin", roles) || includes("DashboardPrueba", roles),
                    label: "Dashboard Prueba",
                    link: "/dashboard",
                    parentId: "admin",
                },
            ]
        },
        {
            id: "admision",
            hasAccess: includes("Admin", roles) || includes("SubDirectorAdmision", roles) || includes("EjecutivoAdmision", roles) || includes("FinanzasGestionMatriculas", roles), //(roles.find(x => x.perfil === "Postulaciones") !== undefined) || false,
            label: "Admisión",
            icon: "mdi mdi-comment-account",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsAdmision(!isAdmision);
                setIscurrentState('Admision');
                updateIconSidebar(e);
            },
            stateVariables: isAdmision,
            subItems: [
                {
                    id: "prospectos",
                    hasAccess: includes("Admin", roles) || includes("SubDirectorAdmision", roles) || includes("EjecutivoAdmision", roles), //(roles.find(x => x.perfil === "Postulaciones") !== undefined) || false,
                    label: "Leads",
                    link: "/prospectos",
                    parentId: "admision",
                },
                {
                    id: "ejecutivos",
                    hasAccess: includes("Admin", roles), //(roles.find(x => x.perfil === "Postulaciones") !== undefined) || false,
                    label: "Ejecutivos",
                    link: "/ejecutivos",
                    parentId: "admision",
                },
                {
                    id: "grupos-ejecutivos",
                    hasAccess: includes("Admin", roles), //(roles.find(x => x.perfil === "Postulaciones") !== undefined) || false,
                    label: "Grupos Ejecutivos",
                    link: "/grupos-ejecutivos",
                    parentId: "admision",
                },
                {
                    id: "matricula-corporativos",
                    hasAccess: includes("Admin", roles), //(roles.find(x => x.perfil === "Postulaciones") !== undefined) || false,
                    label: "Matrícula Corporativos",
                    link: "/matricula-corporativos",
                    parentId: "admision",
                },
                {
                    id: "gestion-matriculas",
                    hasAccess: includes("Admin", roles) || includes("SubDirectorAdmision", roles) || includes("EjecutivoAdmision", roles) || includes("FinanzasGestionMatriculas", roles), //(roles.find(x => x.perfil === "Postulaciones") !== undefined) || false,
                    // hasAccess: includes("Admin", roles), //(roles.find(x => x.perfil === "Postulaciones") !== undefined) || false,
                    label: "Gestión Matrículas",
                    link: "/gestion-matriculas",
                    parentId: "admision",
                }
            ]
        },
        {
            id: "g-acad",
            hasAccess: includes("Admin", roles) 
                || includes("DirectorEducacionEjecutiva", roles) || includes("SubDirectorAdmision", roles) 
                || includes("JefeCoordinacionAcademica", roles) || includes("CoordinacionAcademica", roles) 
                || includes("CoordinadorAdministrativo", roles) || includes("VistaCalendarioVersionNoEditable", roles) 
                || includes("VerEditarDatosWeb", roles),  
            label: "Gestión Académica",
            icon: "mdi mdi-school-outline",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsGestionAcademica(!isGestionAcademica);
                setIscurrentState('GestionAcademica');
                updateIconSidebar(e);
            },
            stateVariables: isGestionAcademica,
            subItems: [
                {
                    id: "programas",
                    hasAccess: includes("Admin", roles) || includes("DirectorEducacionEjecutiva", roles),
                    label: "Programas",
                    link: "/programas",
                    parentId: "g-acad",
                },
                {
                    id: "gestion-decretos",
                    hasAccess: includes("Admin", roles) || includes("DirectorEducacionEjecutiva", roles), //(roles.find(x => x.perfil === "Académicos") !== undefined) || false,
                    label: "Gestión Decretos",
                    link: "/gestion-decretos",
                    parentId: "g-acad",
                },
                {
                    id: "versiones",
                    hasAccess: includes("Admin", roles) 
                        || includes("DirectorEducacionEjecutiva", roles) 
                        || includes("JefeCoordinacionAcademica", roles) 
                        || includes("CoordinacionAcademica", roles) 
                        || includes("CoordinadorAdministrativo", roles)
                        || includes("VistaCalendarioVersionNoEditable", roles)
                        || includes("VerEditarDatosWeb", roles),
                    label: "Versiones",
                    link: "/versiones",
                    parentId: "g-acad",
                },
                {
                    id: "academicos",
                    hasAccess: includes("Admin", roles) || includes("DirectorEducacionEjecutiva", roles),//(roles.find(x => x.perfil === "Académicos") !== undefined) || false,
                    label: "Académicos",
                    link: "/academicos",
                    parentId: "g-acad",
                },
                {
                    id: "listasesionesacad",
                    hasAccess: includes("Admin", roles)|| includes("JefeCoordinacionAcademica", roles) || includes("CoordinacionAcademica", roles) || includes("CoordinadorAdministrativo", roles),
                    label: "Listado de Sesiones",
                    link: "/listadosesionesacad",
                    parentId: "g-acad",
                },
                {
                    id: "convenios",
                    hasAccess: includes("Admin", roles), //(roles.find(x => x.perfil === "Convenios") !== undefined) || false,
                    label: "Convenios",
                    link: "/convenios",
                    parentId: "g-acad",
                },
                {
                    id: "encuestas",
                    hasAccess: includes("Admin", roles), //(roles.find(x => x.perfil === "Encuestas") !== undefined) || false,
                    label: "Encuestas",
                    link: "/gestion-encuestas",
                    parentId: "g-acad",
                },
                {
                    id: "disponibilidad",
                    hasAccess: includes("Admin", roles) || includes("Operaciones", roles) || includes("JefeCoordinacionAcademica", roles) || includes("Academico", roles) || includes("CoordinacionAcademica", roles), //(roles.find(x => x.perfil === "DisponibilidadAcademica") !== undefined) || false,
                    label: "Disponibilidad Académica",
                    link: "/disponibilidad-academica",
                    parentId: "g-acad",
                },
                {
                    id: "coordinacion-academica",
                    hasAccess: includes("Admin", roles) || includes("JefeCoordinacionAcademica", roles),
                    label: "Coordinación Académica",
                    link: "#",
                    parentId: "g-acad",
                    isChildItem: true,
                    click: function (e) {
                        e.preventDefault();
                        setIsCoordinacionAcademica(!isCoordinacionAcademica);
                    },
                    stateVariables: isCoordinacionAcademica,
                    childItems: [
                        {
                            id: 1,
                            hasAccess: includes("Admin", roles) || includes("JefeCoordinacionAcademica", roles),
                            label: "Carga Laboral",
                            link: "/carga-coordinadores",
                            parentId: "coordinacion-academica"
                        },
                        {
                            id: 2,
                            hasAccess: includes("Admin", roles) || includes("JefeCoordinacionAcademica", roles),
                            label: "Asignación de Coordinadores a Programa",
                            link: "/asignacion",
                            parentId: "coordinacion-academica"
                        },
                    ]
                },
                {
                    id: "modulos",
                    hasAccess: includes("Admin", roles) || includes("DirectorEducacionEjecutiva", roles),
                    label: "Módulos",
                    link: "/modulos",
                    parentId: "g-acad",
                },
                {
                    id: "parrilla",
                    hasAccess: includes("Admin", roles) || includes("DirectorEducacionEjecutiva", roles) || includes("SubDirectorAdmision", roles),
                    label: "Parrilla",
                    link: "/parrilla",
                    parentId: "g-acad",
                },                
            ]
        },
        {
            id: "g-alum",
            hasAccess: includes("Admin", roles), //(roles.find(x => x.perfil === "Estudiantes") !== undefined) || false,
            label: "Gestión Estudiante",
            icon: "mdi mdi-account-outline",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsGestionEstudiante(!isGestionEstudiante);
                setIscurrentState('GestionEstudiante');
                updateIconSidebar(e);
            },
            stateVariables: isGestionEstudiante,
            subItems: [
                {
                    id: "estudiantes",
                    hasAccess: includes("Admin", roles), //(roles.find(x => x.perfil === "Estudiantes") !== undefined) || false,
                    label: "Estudiantes",
                    link: "/estudiantes",
                    parentId: "g-alum",
                },
                // {
                //     id: "matriculas-corporativos",
                //     hasAccess: includes("Admin", roles), //(roles.find(x => x.perfil === "Matriculas") !== undefined) || false,
                //     label: "Matrícula Corporativos",
                //     link: "/matricula-corporativo",
                //     parentId: "g-alum",
                // }
            ]
        },
        {
            id: "g-centros",
            hasAccess: (includes("Admin", roles) || includes("Director", roles) || includes("DirectorCentro", roles) || includes("RelacionesPublicas", roles) ||
                includes("CoordinadorAdministrativo", roles) || includes("AsistenteDireccion", roles) || includes("Periodista", roles)
                || includes("DirectorEducacionEjecutiva", roles) || includes("SubDirectorMarketing", roles) || includes("CoordinadorMarketing", roles)) && app.site === "Intranet",
            label: "Centros Investigación",
            icon: "mdi mdi-bank",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsCentrosInvestigacion(!isCentrosInvestigacion);
                setIscurrentState('CentrosInvestigacion');
                updateIconSidebar(e);
            },
            stateVariables: isCentrosInvestigacion,
            subItems: [
                {
                    id: "gestion-eventos",
                    hasAccess: includes("Admin", roles) || includes("RelacionesPublicas", roles) || includes("Periodista", roles),
                    label: "Gestion Eventos",
                    link: "/gestion-eventos",
                    parentId: "g-centros",
                },
                {
                    id: "planificacion-eventos",
                    hasAccess: includes("Admin", roles) || includes("Director", roles) || includes("DirectorCentro", roles)
                        || includes("CoordinadorAdministrativo", roles) || includes("AsistenteDireccion", roles) 
                        || includes("CoordinadorMarketing", roles) || includes("DirectorEducacionEjecutiva", roles) || includes("SubDirectorMarketing", roles) || includes("RelacionesPublicas", roles),
                    label: "Planificación Eventos",
                    link: "/planificacion-eventos",
                    parentId: "g-centros",
                },
            ]
        },
        {
            id: "g-academicos",
            hasAccess: includes("Admin", roles) || includes("Academico", roles) || includes("DirectorInvestigacionFEN", roles) 
                        || includes("DirectorInvestigacion", roles) || includes("AnalistaFEN", roles) || includes("CoordinadorAdministrativoFEN", roles) 
                        || includes("DirectorDepartamentoFEN", roles) || includes("AcademicoFEN", roles) || includes("Director", roles) || includes("AyudanteFEN", roles),
            //  || includes("Academico", roles),
            label: "Académicos",
            icon: "mdi mdi-book-education-outline",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsAcademicos(!isAcademicos);
                setIscurrentState('Academicos');
                updateIconSidebar(e);
            },
            stateVariables: isAcademicos,
            subItems: [
                {
                    /*id: "solicitudes-fondo",
                    hasAccess:  includes("Admin", roles),
                    label: "Solicitudes de Fondo",
                    parentId: "g-academicos",
                    isChildItem: true,
                    link: "/solicitudes-fondo",
                    click: function (e) {
                        e.preventDefault();
                        setIsSolicitud(!isSolicitud);
                    },
                    stateVariables: isSolicitud,*/
                    id: "solicitudes-fondo",
                    hasAccess: (includes("Admin", roles) || includes("DirectorInvestigacionFEN", roles) 
                            || includes("AnalistaFEN", roles) 
                            || includes("CoordinadorAdministrativoFEN", roles) || includes("DirectorDepartamentoFEN", roles) 
                            || includes("AcademicoFEN", roles) || includes("AyudanteFEN", roles)) && app.site === "Intranet",
                    label: "Solicitudes de Fondo",
                    link: "/solicitudes-fondo",
                    parentId: "g-academicos"
                },
                {
                    id: "premios-academicos",
                    hasAccess: includes("Admin", roles) || includes("DirectorInvestigacionFEN", roles) || includes("DirectorInvestigacion", roles) || includes("AnalistaFEN", roles) || includes("CoordinadorAdministrativoFEN", roles) || includes("AcademicoFEN", roles) || includes("Director", roles) || includes("AyudanteFEN", roles),
                    label: "Premios",
                    link: "/premios-academicos",
                    parentId: "g-academicos",
                },
                {
                    id: "acad-modulos",
                    hasAccess: (includes("Admin", roles) || includes("Academico", roles) || includes("DirectorEducacionEjecutiva", roles)) && app.site === "Intranet" && app.ambiente !== "produccion",
                    label: "Mis Módulos",
                    link: "/acad-modulos",
                    parentId: "g-academicos",
                },                
                {
                    id: "acad-sesiones",
                    hasAccess: (includes("Admin", roles) || includes("Academico", roles) || includes("DirectorEducacionEjecutiva", roles)) && app.ambiente !== "produccion",
                    label: "Mis Sesiones",
                    link: "/acad-sesiones",
                    parentId: "g-academicos",
                }, 
                // ** portal externo academico
                {
                    id: "acad-portal",
                    hasAccess: (includes("Admin", roles) || includes("Academico", roles) || includes("DirectorEducacionEjecutiva", roles)) && app.ambiente !== "produccion",
                    label: "Mi Portal",
                    link: "/acad-portal",
                    parentId: "g-academicos",
                },                 
            ]
        },
        {
            id: "g-opera",
            hasAccess: includes("Admin", roles) || includes("Operaciones", roles) || includes("RelacionesPublicas", roles), //((roles.find(x => x.perfil === "Fungibles") || 
            // roles.find(x => x.perfil === "Inventario") || 
            // roles.find(x => x.perfil === "Salas")) !== undefined) || false,
            label: "Operaciones",
            icon: "mdi mdi-wrench",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsOperaciones(!isOperaciones);
                setIscurrentState('Operaciones');
                updateIconSidebar(e);
            },
            stateVariables: isOperaciones,
            subItems: [
                {
                    id: "fungibles",
                    hasAccess: includes("Admin", roles) || includes("Operaciones", roles), //(roles.find(x => x.perfil === "Fungibles") !== undefined) || false,
                    label: "Fungibles",
                    link: "/fungibles",
                    parentId: "g-opera",
                },
                {
                    id: "inventario",
                    hasAccess: includes("Admin", roles) || includes("Operaciones", roles), //(roles.find(x => x.perfil === "Inventario") !== undefined) || false,
                    label: "Inventario",
                    link: "/inventario",
                    parentId: "g-opera",
                },
                {
                    id: "salas",
                    hasAccess: includes("Admin", roles) || includes("Operaciones", roles), //(roles.find(x => x.perfil === "Salas") !== undefined) || false,
                    label: "Salas",
                    link: "/salas",
                    parentId: "g-opera",
                },
                {
                    id: "fechas",
                    hasAccess: includes("Admin", roles) || includes("Operaciones", roles) || includes("RelacionesPublicas", roles), //((roles.find(x => x.perfil === "FeriadosBloqueos")) !== undefined) || false,
                    label: "Feriados y Bloqueos",
                    link: "/fechas",
                    parentId: "g-opera",
                },
            ]
        },
        {
            id: "g-finan",
            hasAccess: includes("Admin", roles) || includes("KPIFinanzas", roles), //((roles.find(x => x.perfil === "Facturación") || 
            // roles.find(x => x.perfil === "DetalleFacturacion") ||
            // roles.find(x => x.perfil === "NotasVenta") ||
            // roles.find(x => x.perfil === "NotasVentaTipoDetalle") ||
            // roles.find(x => x.perfil === "NotasVentaTipo") ||
            // roles.find(x => x.perfil === "Comparativo")) !== undefined) || false,
            label: "Finanzas",
            icon: "mdi mdi-cash-multiple",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsFinanzas(!isFinanzas);
                setIscurrentState('Finanzas');
                updateIconSidebar(e);
            },
            stateVariables: isFinanzas,
            subItems: [
                {
                    id: "kpi",
                    hasAccess: includes("Admin", roles) || includes("KPIFinanzas", roles), //((roles.find(x => x.perfil === "Facturación") || 
                    label: "KPI",
                    link: "/#",
                    parentId: "g-finan",
                    isChildItem: true,
                    click: function (e) {
                        e.preventDefault();
                        setIsKpi(!isKpi);
                    },
                    stateVariables: isKpi,
                    childItems: [
                        {
                            id: 1,
                            hasAccess: includes("Admin", roles) || includes("KPIFinanzas", roles), //(roles.find(x => x.perfil === "Facturación") !== undefined) || false,
                            label: "Facturación AUGE",
                            link: "/facturacion",
                            parentId: "kpi"
                        },
                        {
                            id: 2,
                            hasAccess: includes("Admin", roles) || includes("KPIFinanzas", roles), //(roles.find(x => x.perfil === "DetalleFacturacion") !== undefined) || false,
                            label: "Detalle Anual Facturación AUGE",
                            link: "/detalle-facturacion",
                            parentId: "kpi"
                        },
                        {
                            id: 3,
                            hasAccess: includes("Admin", roles) || includes("KPIFinanzas", roles), //(roles.find(x => x.perfil === "NotasVenta") !== undefined) || false,
                            label: "Notas de Venta",
                            link: "/notas-venta",
                            parentId: "kpi"
                        },
                        {
                            id: 4,
                            hasAccess: includes("Admin", roles) || includes("KPIFinanzas", roles), //(roles.find(x => x.perfil === "NotasVentaTipoDetalle") !== undefined) || false,
                            label: "Notas de Venta Tipo Promedio",
                            link: "/notas-venta-tipo-detalle",
                            parentId: "kpi"
                        },
                        {
                            id: 5,
                            hasAccess: includes("Admin", roles) || includes("KPIFinanzas", roles), //(roles.find(x => x.perfil === "NotasVentaTipo") !== undefined) || false,
                            label: "Notas de Venta Tipo Cantidad/Monto",
                            link: "/notas-venta-tipo",
                            parentId: "kpi"
                        },
                        {
                            id: 6,
                            hasAccess: includes("Admin", roles) || includes("KPIFinanzas", roles), //(roles.find(x => x.perfil === "Comparativo") !== undefined) || false,
                            label: "Comparativo Facturación / NVs",
                            link: "/comparativo",
                            parentId: "kpi"
                        },
                    ]
                },

            ]
        },
        {
            id: "g-cobranza",
            hasAccess: includes("Admin", roles) || includes("Cobranza", roles), 
            label: "Cobranza",
            icon: "ri-funds-box-line",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsCobranza(!isCobranza);
                setIscurrentState('Cobranza');
                updateIconSidebar(e);
            },
            stateVariables: isCobranza,
            subItems:[
                {
                    id: "listadoFacturas",
                    hasAccess: includes("Admin", roles) || includes("Cobranza", roles), 
                    label: "Listado Facturas",
                    link: "/listadofacturas",
                    parentId: "g-cobranza",
                },
                {
                    id: "dashboard",
                    hasAccess: includes("Admin", roles) || includes("Cobranza", roles), 
                    label: "Dashboard",
                    link: "/#",
                    parentId: "g-cobranza",
                    isChildItem: true,
                    click: function (e) {
                        e.preventDefault();
                        setIsGraficos(!isGraficos);
                    },
                    stateVariables: isGraficos,
                    childItems: [
                        { id: 1, label: "Facturación y Cobranza", link: "/FacturacionCobranzaDashboard", parentId: "dashboard", hasAccess: true },
                        { id: 2, label: "Gestión de Cobranza", link: "/GestionCobranzaDashboard", parentId: "dashboard", hasAccess: true },
                        { id: 3, label: "Facturación y Comportamiento de Pago", link: "/ComportamientoPagoDashboard", parentId: "dashboard", hasAccess: true }, 
                        { id: 4, label: "Línea", link: "/charts-apex-line", parentId: "dashboard", hasAccess: true },
                        { id: 5, label: "Área", link: "/charts-apex-area", parentId: "dashboard", hasAccess: true },
                        { id: 6, label: "Columna", link: "/charts-apex-column", parentId: "dashboard", hasAccess: true },
                        { id: 7, label: "Barra", link: "/charts-apex-bar", parentId: "dashboard", hasAccess: true },
                        { id: 8, label: "Mixto", link: "/charts-apex-mixed", parentId: "dashboard", hasAccess: true },
                     ]

              } 

            ]
        },
        {
            id: "control-gestion",
            hasAccess: includes("Admin", roles) || includes("ControlGestion", roles),
            label: "Control de Gestión",
            icon: "mdi mdi-finance",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsControlGestion(!isControlGestion);
                setIscurrentState('ControlGestion');
                updateIconSidebar(e);
            },
            stateVariables: isControlGestion,
            subItems: [
                {
                    id: "asientos-contables",
                    hasAccess: includes("Admin", roles) || includes("ControlGestion", roles),
                    label: "Asientos Contables",
                    link: "/asientos-contables",
                    parentId: "control-gestion",
                },
            ]
        },
        {
            id: "ejemplos",
            hasAccess: includes("Admin", roles),
            label: "Ejemplos",
            icon: "mdi mdi-view-grid-plus-outline",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsEjemplo(!isEjemplo);
                setIscurrentState('Ejemplos');
                updateIconSidebar(e);
            },
            stateVariables: isEjemplo,
            subItems: [
                {
                    id: "graficos",
                    hasAccess: includes("Admin", roles),
                    label: "Gráficos",
                    link: "/#",
                    parentId: "ejemplos",
                    isChildItem: true,
                    click: function (e) {
                        e.preventDefault();
                        setIsGraficos(!isGraficos);
                    },
                    stateVariables: isGraficos,
                    childItems: [
                        { id: 1, label: "Línea", link: "/charts-apex-line", parentId: "graficos", hasAccess: true },
                        { id: 2, label: "Área", link: "/charts-apex-area", parentId: "graficos", hasAccess: true },
                        { id: 3, label: "Columna", link: "/charts-apex-column", parentId: "graficos", hasAccess: true },
                        { id: 4, label: "Barra", link: "/charts-apex-bar", parentId: "graficos", hasAccess: true },
                        { id: 5, label: "Mixto", link: "/charts-apex-mixed", parentId: "graficos", hasAccess: true },
                        { id: 6, label: "Línea Tiempo", link: "/charts-apex-timeline", parentId: "graficos", hasAccess: true },
                        { id: 7, label: "Velas", link: "/charts-apex-candlestick", parentId: "graficos", hasAccess: true },
                        { id: 8, label: "Diagrama de Caja", link: "/charts-apex-boxplot", parentId: "graficos", hasAccess: true },
                        { id: 9, label: "Burbujas", link: "/charts-apex-bubble", parentId: "graficos", hasAccess: true },
                        { id: 10, label: "Dispersión", link: "/charts-apex-scatter", parentId: "graficos", hasAccess: true },
                        { id: 11, label: "Mapa Calor", link: "/charts-apex-heatmap", parentId: "graficos", hasAccess: true },
                        { id: 12, label: "Rectángulos", link: "/charts-apex-treemap", parentId: "graficos", hasAccess: true },
                        { id: 13, label: "Circular", link: "/charts-apex-pie", parentId: "graficos", hasAccess: true },
                        { id: 14, label: "Radial", link: "/charts-apex-radialbar", parentId: "graficos", hasAccess: true },
                        { id: 15, label: "Radar", link: "/charts-apex-radar", parentId: "graficos", hasAccess: true },
                        { id: 16, label: "Área Polar", link: "/charts-apex-polar", parentId: "graficos", hasAccess: true },
                    ]
                },
            ]
        },
        {
            id: "mantenedores",
            hasAccess: includes("Admin", roles) || includes("AnalistaFEN", roles),
            label: "Mantenedores",
            icon: "mdi mdi-cog",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsMantenedor(!isMantenedor);
                setIscurrentState('Mantenedores');
                updateIconSidebar(e);
            },
            stateVariables: isMantenedor,
            subItems: [
                {
                    id: "mant-fondos-investigacion",
                    hasAccess: includes("Admin", roles) || includes("DirectorInvestigacionFEN", roles) || includes("AnalistaFEN", roles) || includes("DirectorDepartamentoFEN", roles),
                    label: "Mantenedor Fondos Investigación",
                    link: "/mant-fondos-investigacion",
                    parentId: "g-academicos",
                },                                
                {
                    id: "dolar-fen",
                    hasAccess: includes("Admin", roles) || includes("AnalistaFEN", roles),
                    label: "Dólar FEN",
                    link: "/dolar-fen",
                    parentId: "mantenedores",
                },
                /*{
                    id: "ceco-fen",
                    hasAccess: includes("Admin", roles) || includes("AnalistaFEN", roles),
                    label: "Centros de Costos",
                    link: "/ceco-fen",
                    parentId: "mantenedores",
                },*/                
                {
                    id: "ranking-jcr",
                    hasAccess: includes("Admin", roles) || includes("AnalistaFEN", roles),
                    label: "Ranking JCR",
                    link: "/ranking-jcr",
                    parentId: "mantenedores",
                },
                {
                    id: "mantenedor-empresa",
                    hasAccess: includes("Admin", roles),
                    label: "Mantenedor Empresa",
                    link: "/mantenedor-empresa",
                    parentId: "mantenedores",
                }             
            ]
        },    
        {
            id: "g-estudiante",
            hasAccess: includes("Admin", roles) || includes("Estudiante", roles), //(roles.find(x => x.perfil === "Estudiantes") !== undefined) || false,
            label: "Estudiante",
            icon: "mdi mdi-account-outline",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsEstudiante(!isEstudiante);
                setIscurrentState('Estudiante');
                updateIconSidebar(e);
            },
            stateVariables: isEstudiante,
            subItems: [
                {
                    id: "miportal",
                    hasAccess: includes("Admin", roles) || includes("Estudiante", roles), //(roles.find(x => x.perfil === "Estudiantes") !== undefined) || false,
                    label: "Mi Portal",
                    link: "/mi-portal",
                    parentId: "g-alum",
                },                
            ]
        },            
        {
            id: "tareas",
            label: "Recordatorios",
            icon: "mdi mdi-newspaper-variant-multiple",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsTareas(!isTareas);
                setIscurrentState('Tareas');
                updateIconSidebar(e);
            },
            stateVariables: isTareas,
            subItems: [
                {
                    id: "home",
                    label: "Home",
                    link: "/tareas",
                    parentId: "tarea",
                }
            ]
        },
        {
            id: "apps",
            label: "Apps",
            icon: "mdi mdi-view-grid-plus-outline",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsApps(!isApps);
                setIscurrentState('Apps');
                updateIconSidebar(e);
            },
            stateVariables: isApps,
            subItems: [
                {
                    id: "encuesta",
                    label: "Encuesta",
                    link: "encuesta",
                    parentId: "apps",
                },
            ],
        },
        
    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;