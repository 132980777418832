import { all, fork } from "redux-saga/effects";
//layout
import LayoutSaga from "./layouts/saga";
//Auth
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";

//calendar
import calendarSaga from "./calendar/saga";

//Control de Gestión
import ControlGestionSaga from "./finanza/control-gestion/saga";

//userManagement
import userManagementSaga from "./user-management/saga";

//captchaSaga
import captchaSaga from "./captcha/saga";

//Empresa
import EmpresaSaga from "./empresas/saga";

//Pagos
import pagosSaga from "./pagos/saga";

//Programa
import progamaSaga from "./programa/saga";

//Tarea
import tareaSaga from "./tarea/saga";

//Estudiante
import estudianteSaga from "./estudiante/saga";

//Finanzas dashboard
import finanzasDashboardSaga from "./finanza/dashboard/saga";

//Cobranza
import cobranzaSaga from "./cobranza/saga";

//Sesiones
import sesionesSaga from "./sesiones/saga";

//Files
import FileSaga from "./file/saga";

//Historial
import HistorialSaga from "./historial/saga";

//Operaciones Salas
import operacionesSalasSaga from "./operaciones/salas/saga";

//Eventos
import EventosSaga from "./eventos/saga";

//Fechas
import FechasSaga from "./fechas/saga";

//Academicos
import AcademicosSaga from "./academicos/saga";

//Monedas
import monedasSaga from "./moneda/saga";

//Notificaciones
import NotificacionesSaga from "./notificaciones-management/saga";

//Premios
import PremiosSaga from "./premios/saga";

//Leads
import prospectoManagementSaga from "./prospectos-management/saga";

//Solicitudes de Fondo
import SolicitudesFondoSaga from "./solicitudes-fondo/saga";

//Ayudantes
import ayudantesSaga from "./ayudantes/saga";

//Mantenedores
import mantenedoresSaga from "./mantenedores/saga";

//Modulos
import modulosSaga from "./modulos/saga";

//Valida Documento
import validaDocumentoSaga from "./valida-documento/saga";

// Versiones Programa
import versionesSaga from "./versiones-programa/saga";

// List
import listSaga from "./listas/saga";

// Viajes
import viajesSaga from "./viajes/saga";

// Encuesta
import encuestaSaga from "./encuesta/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(calendarSaga),
    fork(ControlGestionSaga),
    fork(userManagementSaga),
    fork(captchaSaga),
    fork(EmpresaSaga),
    fork(pagosSaga),
    fork(progamaSaga),
    fork(tareaSaga),
    fork(estudianteSaga),
    fork(finanzasDashboardSaga),
    fork(cobranzaSaga),
    fork(sesionesSaga),
    fork(FileSaga),
    fork(HistorialSaga),
    fork(operacionesSalasSaga),
    fork(EventosSaga),
    fork(FechasSaga),
    fork(AcademicosSaga),
    fork(monedasSaga),
    fork(NotificacionesSaga),
    fork(PremiosSaga),
    fork(prospectoManagementSaga),
    fork(SolicitudesFondoSaga),
    fork(ayudantesSaga),
    fork(mantenedoresSaga),
    fork(modulosSaga),
    fork(validaDocumentoSaga),
    fork(versionesSaga),
    fork(listSaga),
    fork(viajesSaga),
    fork(encuestaSaga),
  ]);
}